import Colors from "../../utils/theme/color";
import { Box, CardContent, Container, styled } from "@mui/material";

export const PreviewContainer = styled(Container)({
  display: "flex",
  backgroundColor: Colors.white,
  justifyContent: "center",
  flexDirection: "column",
});
export const RowStyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

export const WrappedBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  width: "100%",
});

export const ColumnStyledBox = styled(Box)({
  padding: 20,
  paddingLeft: 32,
  paddingRight: 32,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  backgroundColor: Colors.cyanColor,
});
export const PhotoContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "40%",
});

export const TermsAndConditionContainer = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  pl: 2,
  pr: 2,
});

export const BottomContainer = styled(Box)(({ theme }) => ({
  marginTop: 25,
  background: Colors.white,
  display: "flex",
  flexDirection: "column",
}));

export const ApplicantContainer = styled(Box)(({ theme }) => ({
  marginBottom: 5,
  display: "flex",
  alignItems: "center",
  width: "100%",
}));

export const LegalNameEmailAndAddressContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "40%",
}));

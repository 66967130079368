import React from "react";
import { CardActions, CardHeader, Collapse, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ExpandMore, Title } from "./styled-component";
import { DeleteIconSvg } from "../../assets/svg/delete-icon";
import Colors from "../../utils/theme/color";
import "./expandable-card.css";

interface ExpandableItemCardPropsTypes {
  title: string;
  cardContent: React.ReactNode;
  onPressDelete?(): void;
  cardId: number | string;
  handleExpandClick(id: number | string): void;
  selectedCard: number | string;
  isDeleteRequired?: boolean;
}

const ExpandableItemCard = ({
  title,
  cardContent,
  onPressDelete = () => {},
  cardId,
  handleExpandClick,
  selectedCard,
  isDeleteRequired = true,
}: ExpandableItemCardPropsTypes) => {
  const renderCardHeader = () => (
    <CardHeader
      component={() => (
        <div className="card-header-container">
          {renderTitle()}
          {renderCardAction()}
        </div>
      )}
    />
  );

  const renderTitle = () => <Title>{title}</Title>;

  const renderCardAction = () => (
    <CardActions disableSpacing>
      {cardId !== 0 && isDeleteRequired ? (
        <IconButton onClick={onPressDelete} disabled={selectedCard === cardId}>
          <DeleteIconSvg
            color={Colors.redColor}
            opacity={selectedCard === cardId ? 0.3 : 1}
          />
        </IconButton>
      ) : null}
      <ExpandMore
        expand={selectedCard === cardId}
        onClick={() => handleExpandClick(cardId)}
        aria-expanded={selectedCard === cardId}
        aria-label="show more"
      >
        <ExpandMoreIcon />
      </ExpandMore>
    </CardActions>
  );

  return (
    <Card sx={{ marginBottom: 2 }} raised={selectedCard === cardId}>
      {renderCardHeader()}
      <Collapse in={selectedCard === cardId} timeout="auto" unmountOnExit>
        {cardContent}
      </Collapse>
    </Card>
  );
};

export default ExpandableItemCard;

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PaymentDataTypes } from "../../utils/booking-utils";
import LabelAndValue from "../label-and-value";
import { ColumnStyledBox, WrappedBox } from "./styled-components";
import { formatPrice } from "../../utils/functions";

interface PaymentDetailsProps {
  details: PaymentDataTypes;
}
const PaymentDetails = ({ details }: PaymentDetailsProps) => {
  const { t } = useTranslation();
  const getPaymentDetails = () => {
    return [
      {
        id: t("booking_preview.received_amount"),
        label: t("booking_preview.received_amount"),
        value: formatPrice(parseInt(details?.receivedAmount)),
      },
      {
        id: t("booking_preview.check_or_demand_draft_date"),
        label: t("booking_preview.check_or_demand_draft_date"),
        value: details?.chequeOrDemandDraftDate,
      },
      {
        id: t("booking_preview.check_or_demand_draft_number"),
        label: t("booking_preview.check_or_demand_draft_number"),
        value: details?.chequeOrDemandDraftNumber,
      },
      {
        id: t("booking_preview.bank"),
        label: t("booking_preview.bank"),
        value: details?.bank,
      },
      {
        id: t("booking_preview.branch"),
        label: t("booking_preview.branch"),
        value: details?.branch,
      },
      {
        id: t("booking_preview.city"),
        label: t("booking_preview.city"),
        value: details?.city,
      },
      {
        id: t("booking_preview.source_of_booking"),
        label: t("booking_preview.source_of_booking"),
        value: details?.sourceOfBooking,
      },
      {
        id: t("booking_preview.enquiry_no"),
        label: t("booking_preview.enquiry_no"),
        value: details?.enquiryNo,
      },
    ];
  };

  const renderLabelAndValue = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => <LabelAndValue key={label} label={label} value={value} />;

  return (
    <ColumnStyledBox>
      <WrappedBox>
        {getPaymentDetails()?.map((item) => (
          <Box sx={{ width: "30%" }}>
            {renderLabelAndValue({
              label: item?.label,
              value: item?.value,
            })}
          </Box>
        ))}
      </WrappedBox>
    </ColumnStyledBox>
  );
};

export default PaymentDetails;

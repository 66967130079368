import Box from "@mui/material/Box";
import Colors from "../utils/theme/color";

interface TabPanelProps {
  children?: React.ReactNode;
  index?: number;
  value?: number;
  styles?: { tabPanelContainer?: {} };
}

export function TabPanel(props: TabPanelProps) {
  const { children = <div />, value = 0, index = 0, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        backgroundColor: Colors.white,
        borderTopRightRadius: 5,
        margin: 0,
      }}
      {...other}
    >
      {value === index && (
        <Box sx={{ ...{ p: 4, pt: 0 }, ...props?.styles?.tabPanelContainer }}>
          {children}
        </Box>
      )}
    </div>
  );
}

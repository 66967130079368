import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Box, Card, CardContent, CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import ApplicantBasicDetailsForm from "./applicant-basic-details-form";
import DrawerComponent from "../../components/drawer";
import CustomizedBottomBar from "../../components/bottom-bar";
import ExpandableItemCard from "../../components/expandable-card/expandable-item-card";
import CustomizedDialogs from "../../components/customized-dialog";
import IdentificationDetailsForm from "./applicant-identification-details";
import ProfessionalDetailsForm from "./applicant-professional-details";
import ApplicantSignature from "./applicant-signature";
import AddApplicant from "./add-applicant-button";
import PaymentDetails from "./payment-details";
import BookingDetailsTitle from "./booking-details-title";
import { isEmailValid, isPhoneNumberValid } from "../../utils/functions";
import Colors from "../../utils/theme/color";
import HomeLoan from "./home-loan";
import LegalForm from "./legal-form";
import ListPrice from "../../components/list-price";
import {
  EMPTY_LIST_PRICE_DETAILS,
  EMPTY_APPLICANT_DETAILS,
  ApplicantDetails,
  ApplicantTab,
  BasicDetailsTypes,
  IdentificationDetailsTypes,
  SignatureDetailsTypes,
  ProfessionalDetailsTypes,
  tabItems,
  ProjectAndUnitType,
  legalDataTypes,
  APPLICANT_MANDATORY_FIELDS,
  EMPTY_PAYMENT_DETAILS,
  EMPTY_HOME_LOAN_DETAILS,
  EMPTY_LEGAL_DETAILS,
  PaymentDataTypes,
  HomeLoanDataTypes,
  PRIMARY_APPLICANT,
  PAYMENT_MANDATORY_FIELDS,
  NRI_APPLICANT_MANDATORY_FIELDS,
  DEFAULT_HEAD_OF_SALES_WORKFLOW_ACTION,
  DEFAULT_Channel_Partner_WORKFLOW_ACTION,
  EMPTY_OVER_VIEW_DETAILS,
  CommentsProps,
  OverViewDetailsProps,
} from "../../utils/booking-utils";
import ProjectAndUnitDetails from "./project-and-unit";
import {
  useRedirectToLogin,
  useGetUserDetailsHooks,
  useScreenSizeApplier,
  useNavigateHooks,
} from "../../hooks";
import "./applicant-form.css";
import {
  CustomizedDivider,
  CustomizedText,
} from "../../components/styled-componnets";
import {
  ApplicantTabContainer,
  LoaderContainer,
  RowStyledBox,
} from "./styled-component";
import { TabPanel } from "../../components/tab-panel-hoc";
import {
  useAddApplicantsMutation,
  useDeleteApplicantsMutation,
  useFetchBookingCommentsQuery,
  useFetchBookingDocumentQuery,
  useGetBookingDetailsByIdQuery,
  useGetBookingNotesQuery,
  useUpdateBookingCommentsMutation,
  useUpdateBookingMutation,
  useUpdateBookingNotesMutation,
} from "../../../features/api/booking-api-slice";
import { BookingEditableStatuses, Status } from "../../constants/statuses";
import BookingPreview from "../../components/booking-preview";
import MaterialDialog from "../../components/dialog";
import BookingUserDetails from "./over-view";
import MaterialModal from "../../components/modal";
import { CustomizedModalContainer } from "../create-opportunity/styled-component";
import { YYYY_MM_DD_WITH_HYPHEN } from "../../utils/date";
import Notes from "./booking-notes";
import moment from "moment";
import BookingTermsAndConditions from "./terms-and-conditions";
import ActionButton from "./action-buttons";
import ConfirmBookingActionComponent from "../../components/confirm-booking-action";
import { Action } from "../../constants/action";
import { DocumentProps } from "@app/utils/interface";
import { isEqual } from "lodash";

const ONE = 1;
const BASIC_DETAILS = "BASIC_DETAILS";
const PROFESSIONAL_DETAILS = "PROFESSIONAL_DETAILS";
const IDENTIFICATION_DETAILS = "IDENTIFICATION_DETAILS";
const PAYMENT_DETAILS = "PAYMENT_DETAILS";
const HOME_LOAN = "HOME_LOAN";
const LEGAL = "LEGAL";
const APPLICANT_DETAILS = "APPLICANT_DETAILS";
const PROJECT_AND_UNIT = "PROJECT_AND_UNIT";
const LIST_PRICE = "LIST_PRICE";
const ERROR = "error";
const SUCCESS = "success";
const APPLICANT_DETAILS_MENU_INDEX = 2;
const YES = "YES";
const NO = "NO";
const PAYMENT_DETAILS_MENU_INDEX = 3;
const HOME_LOAN_MENU_INDEX = 4;
const LEGAL_MENU_INDEX = 5;
const NOTES_MENU_INDEX = 6;
const TERMS_MENU_INDEX = 7;
const NOTES = "NOTES";
const SIGNATURE = "SIGNATURE";
const TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS";

export const tabIndicatorProps = {
  sx: {
    bgcolor: Colors.primaryColor,
    borderRadius: 8,
    borderWidth: 2,
  },
};

const materialModalProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

const nonEditableModalProps = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-start",
  width: "100%",
  p: 3,
};

const bottomTabContainerStyle = {
  width: "100%",
  justifyContent: "space-between",
  display: "flex",
  marginLeft: 30,
};
export interface IAction {
  action: Action;
  next_state: Status;
}
const BookingForm = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { mergeStyles } = useScreenSizeApplier();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();
  const applicant = EMPTY_APPLICANT_DETAILS;
  const bookingId = params?.id;
  const listPriceDetails = EMPTY_LIST_PRICE_DETAILS;
  const { getUserToken, isProjectHead } = useGetUserDetailsHooks();
  const { navigateToBookings } = useNavigateHooks();

  const [drawerMenu, setDrawerMenu] = useState(
    require("../../constants/booking-drawer-menus.json")
  );
  const [applicantList, setApplicantList] = useState([applicant]);

  const [paymentDetails, setPaymentDetails] = useState(EMPTY_PAYMENT_DETAILS);
  const [homeLoan, setHomeLoan] = useState(EMPTY_HOME_LOAN_DETAILS);
  const [legalDetails, setLegalDetails] = useState(EMPTY_LEGAL_DETAILS);
  const [projectAndUnits, setProjectAndUnits] = useState<ProjectAndUnitType>({
    project: "",
    unit: "",
    unitType: "",
    floor: "",
    bhk: "",
    saleableArea: "",
    carpetArea: "",
    block: "",
  });
  const [bookingDetails, setBookingDetails] = useState<OverViewDetailsProps>(
    EMPTY_OVER_VIEW_DETAILS
  );

  const [currentMenuId, setCurrentMenuId] = useState(PROJECT_AND_UNIT);
  const [numberOfApplicant, setNumberOfApplicant] = useState(ONE);
  const [selectedApplicant, setSelectedApplicant] = useState(ONE);

  const [selectedTab, setSelectedTab] = useState({
    tabIndex: 0,
    tabId: BASIC_DETAILS,
    tabLabel: t("create_booking.basic_details.title"),
  });
  const [selectedCard, setSelectedCard] = useState(0);

  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [isSavedAsDraft, setIsSavedAsDaft] = useState(false);

  const [isUpdateBookingDetailsLoading, setIsUpdateBookingDetailsLoading] =
    useState(false);
  const [isAddApplicantsLoading, setIsAddApplicantsLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showNonEditableMessage, setShowNonEditableMessage] = useState(false);
  const [isBookingEditable, setIsBookingEditable] = useState(true);
  const [saveBookingNotesLoading, setSaveBookingNotesLoading] = useState(false);
  const [isSignatureConfirmed, setSignatureConfirmed] = useState(false);
  const [isTermsAndConditionChecked, setIsTermsAndConditionChecked] =
    useState(false);
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false);
  const [isActionConfirmed, setIsActionConfirmed] = useState(false);
  const [isAddNoteLoading, setIsAddNoteLoading] = useState<boolean>(false);
  const [isSubmitButtonVisible, setIsSubmitButtonVisible] =
    useState<boolean>(true);
  const [isBookingDetailsLoading, setIsBookingDetailsLoading] =
    useState<boolean>(true);
  const [isDeleteApplicantsLoading, setIsDeleteApplicantsLoading] =
    useState(false);

  const [buttonText, setButtonText] = useState("");
  const [bookingStatus, setBookingStatus] = useState<Status>(Status.DRAFT);
  const [bookingNotes, setBookingNotes] = useState("");
  const [action, setAction] = useState<IAction>(
    DEFAULT_HEAD_OF_SALES_WORKFLOW_ACTION || DEFAULT_Channel_Partner_WORKFLOW_ACTION
  );
  const [bookingDocumentPDF, setBookingDocumentPDF] = useState("");

  const [bookingSignatures, setBookingSignatures] = useState<any>([]);
  const [documents, setDocuments] = useState<DocumentProps[]>([]);
  const [comments, setComments] = useState<CommentsProps[]>([]);

  const setApplicantDetails = (applicants: any) => {
    let newApplicantDetails: any = [];
    return applicants?.forEach((applicant: ApplicantDetails, index: number) => {
      let applicantDetails = {
        key: applicant?.idx,
        idx: applicant?.idx,
        name: applicant?.name,
        salutation: applicant?.salutation,
        firstName: applicant?.applicant_name,
        lastName: applicant?.last_name,
        isFathersName: applicant?.is_fathers_name,
        fatherOrHusbandName: applicant?.father_or_husband_name,
        gender: applicant?.gender,
        mobile: applicant?.mobile,
        email: applicant?.email_id,
        dob:
          applicant?.date_of_birth ?? moment().format(YYYY_MM_DD_WITH_HYPHEN),
        photo: {
          name: applicant?.profile_attachment_name,
          type: "",
          data: "",
          attachment: applicant?.profile_pic_attachment,
        },
        correspondenceAddress: applicant?.correspondence_address,
        permanentAddress: applicant?.permanent_address,
        pin: applicant?.pincode,
        telephone: applicant?.tel,
        permanentPin: applicant?.permanent_pincode,
        permanentTelephone: applicant?.permanent_tel,
        isAddressSameAsPrimaryApplicant: Boolean(
          applicant?.address_same_as_primary_applicant
        )
          ? YES
          : NO,
        isPermanentAddressSameAsCorrespondence:
          Boolean(applicant?.correspondence_address) &&
          applicant?.correspondence_address === applicant?.permanent_address
            ? YES
            : NO,
        isApplicantNRI: Boolean(applicant?.passport_number),
        aadhaarNumber: applicant?.aadhar_number,
        aadhaarPhoto: {
          name: applicant?.aadhar_attachment_name,
          type: "",
          data: "",
          attachment: applicant?.aadhar_attachment,
        },
        panNumber: applicant?.pan_number,
        panPhoto: {
          name: applicant?.pan_attachment_name,
          type: "",
          data: "",
          attachment: applicant?.pan_attachment,
        },
        passportNumber: applicant?.passport_number,
        passportPhoto: {
          name: applicant?.passport_attachment_name,
          type: "",
          data: "",
          attachment: applicant?.passport_attachment,
        },
        profession: applicant?.profession,
        industry: applicant?.industry,
        employedAt: applicant?.employed_at,
        designation: applicant?.designation,
        signature: {
          name: applicant?.signature_name,
          type: "",
          data: "",
          attachment: applicant?.signature,
        },
        tabDetails: tabItems,
        relationShipWithPrimaryUser:
          index === 0 ? PRIMARY_APPLICANT : applicant.relationship,
      };
      newApplicantDetails[index] = applicantDetails;
      return setApplicantList(newApplicantDetails);
    });
  };

  const [
    updateBooking,
    {
      isSuccess: isUpdateBookingSuccess,
      isError: isUpdateBookingFailed,
      data: updateBookingResponse,
      error: updateBookingError,
    },
  ] = useUpdateBookingMutation();

  const {
    data: getBookingDetailsByIdResponse,
    isSuccess: isGetBookingDetailsByIdSuccess,
    isError: isGetBookingDetailsByIdFailed,
    error: getBookingDetailsByIdError,
    refetch: refetchBookingDetails,
    isFetching: isBookingDetailsFetching,
  } = useGetBookingDetailsByIdQuery(
    {
      bookingId,
      userToken: getUserToken(),
    },
    { skip: !Boolean(bookingId) }
  );

  const [
    addApplicants,
    {
      isSuccess: isAddApplicantsSuccess,
      isError: isAddApplicantsFailed,
      data: addApplicantsResponse,
      error: addApplicantsError,
    },
  ] = useAddApplicantsMutation();

  const [
    updateBookingNotes,
    {
      isSuccess: isSaveBookingNotesSuccess,
      isError: isSaveBookingNotesFailed,
      data: saveBookingNotesResponse,
      error: saveBookingNotesError,
    },
  ] = useUpdateBookingNotesMutation();

  const {
    data: getBookingNotesResponse,
    isSuccess: isGetBookingNotesByIdSuccess,
    isError: isGetBookingNotesByIdFailed,
    error: getBookingNotesByIdError,
    refetch: refetchBookingNotes,
  } = useGetBookingNotesQuery(
    {
      userToken: getUserToken(),
      bookingId,
    },
    { skip: !Boolean(bookingId) }
  );

  const {
    data: getBookingDocumentResponse,
    isSuccess: isGetBookingDocumentSuccess,
    isError: isGetBookingDocumentsFailed,
    error: getBookingDocumentsError,
    refetch: refetchBookingDocuments,
  } = useFetchBookingDocumentQuery(
    {
      userToken: getUserToken(),
      bookingId,
    },
    { skip: !Boolean(bookingId) }
  );

  const {
    data: getBookingCommentsResponse,
    isSuccess: isGetBookingCommentsSuccess,
    isError: isGetBookingCommentsFailed,
    error: getBookingCommentsError,
    refetch: refetchBookingComments,
  } = useFetchBookingCommentsQuery(
    {
      userToken: getUserToken(),
      bookingId,
    },
    { skip: !Boolean(bookingId) }
  );

  const [
    updateBookingComments,
    {
      isSuccess: isUpdateBookingCommentsSuccess,
      isError: isUpdateBookingCommentsFailed,
      data: updateBookingCommentsResponse,
      error: updateBookingCommentsError,
    },
  ] = useUpdateBookingCommentsMutation();

  const [
    deleteApplicants,
    {
      isSuccess: isDeleteApplicantsSuccess,
      isError: isDeleteApplicantsFailed,
      data: deleteApplicantsResponse,
      error: deleteApplicantsError,
    },
  ] = useDeleteApplicantsMutation();

  const fetchBookingDetails = useCallback(() => {
    setIsBookingDetailsLoading(true);
    refetchBookingDetails();
    refetchBookingNotes();
    refetchBookingDocuments();
    refetchBookingComments();
  }, [
    refetchBookingComments,
    refetchBookingDetails,
    refetchBookingDocuments,
    refetchBookingNotes,
  ]);

  useEffect(() => {
    fetchBookingDetails();
  }, [fetchBookingDetails]);

  useEffect(() => {
    if (isUpdateBookingDetailsLoading) {
      if (isUpdateBookingSuccess) {
        if (updateBookingResponse?.data?.booking_id) {
          toast(updateBookingResponse.message, {
            type: "success",
          });
          fetchBookingDetails();
          setIsUpdateBookingDetailsLoading(false);
          if (isSavedAsDraft) {
            navigateToBookings();
          }
        } else if (updateBookingResponse?.message) {
          toast(updateBookingResponse?.message, {
            type: ERROR,
          });
          setIsSavedAsDaft(false);
          setIsUpdateBookingDetailsLoading(false);
          setIsSubmitButtonVisible(true);
        }
      } else if (isUpdateBookingFailed) {
        redirectToLoginBasedOnResponse(
          updateBookingError,
          updateBookingResponse
        );
        setIsSavedAsDaft(false);
        setIsUpdateBookingDetailsLoading(false);
        setIsSubmitButtonVisible(true);
      }
      setIsNextButtonDisabled(false);
    }
    // eslint-disable-next-line
  }, [
    fetchBookingDetails,
    isSavedAsDraft,
    isUpdateBookingDetailsLoading,
    isUpdateBookingFailed,
    isUpdateBookingSuccess,
    navigateToBookings,
    updateBookingError,
    updateBookingResponse,
  ]);

  const getBookingDetails = useMemo(() => {
    if (getBookingDetailsByIdResponse?.data) {
      let {
        name,
        received_amount,
        branch,
        cheque_demand_date,
        cheque_demand_draft_no,
        city,
        source_of_booking,
        booking_bank,
        enquiry_no,
        planning_to_take_home_loan,
        bank,
        services_by,
        annual_income,
        loan_amount_interested,
        loan_sanctioned,
        sanctioned_amount,
        poa_holder_name,
        poa_holder_email,
        poa_holder_phone,
        poa_holder_address,
        payment_mode,
        terms_and_conditions_read,
        declaration_read,
        payment_proof_filename,
        payment_byte_array,
      } = getBookingDetailsByIdResponse.data;
      return {
        booking_id: name,
        ...projectAndUnits,
        status: Status.DRAFT,
        received_amount,
        branch,
        cheque_demand_date,
        cheque_demand_draft_no,
        city,
        source_of_booking,
        booking_bank,
        enquiry_no,
        planning_to_take_home_loan: Boolean(planning_to_take_home_loan),
        bank,
        services_by,
        annual_income,
        loan_amount_interested,
        loan_sanctioned: Boolean(loan_sanctioned),
        sanctioned_amount,
        poa_holder_name,
        poa_holder_email,
        poa_holder_phone,
        poa_holder_address,
        payment_mode,
        terms_and_conditions_read: Boolean(terms_and_conditions_read),
        declaration_read: Boolean(declaration_read),
        payment_proof: payment_byte_array,
        payment_proof_filename,
      };
    }
  }, [getBookingDetailsByIdResponse?.data, projectAndUnits]);

  const updateBookingDetails = useCallback(
    ({ isSubmitted = false }: { isSubmitted?: boolean }) => {
      let payload = {
        booking_id: bookingId,
        ...projectAndUnits,
        received_amount: paymentDetails?.receivedAmount,
        branch: paymentDetails?.branch,
        cheque_demand_date: paymentDetails?.chequeOrDemandDraftDate,
        cheque_demand_draft_no: paymentDetails?.chequeOrDemandDraftNumber,
        city: paymentDetails?.city,
        source_of_booking: paymentDetails?.sourceOfBooking,
        booking_bank: paymentDetails?.bank,
        enquiry_no: paymentDetails?.enquiryNo,
        planning_to_take_home_loan: homeLoan?.isHomeLoan,
        bank: homeLoan?.fromBank,
        services_by: homeLoan?.serviceBy,
        annual_income: homeLoan?.annualIncome,
        loan_amount_interested: homeLoan?.loanAmountInterested,
        loan_sanctioned: homeLoan?.isLoanSanctioned,
        sanctioned_amount: homeLoan?.sanctionedAmount,
        poa_holder_name: legalDetails?.powerOfAttorneyHolderName,
        poa_holder_email: legalDetails?.powerOfAttorneyHolderEmailId,
        poa_holder_phone: legalDetails?.powerOfAttorneyHolderPhone,
        poa_holder_address: legalDetails?.powerOfAttorneyHolderAddress,
        status: isSubmitted ? Status.SUBMITTED : Status.DRAFT,
        payment_mode: paymentDetails?.paymentMode,
        terms_and_conditions_read: isTermsAndConditionChecked,
        declaration_read: isDeclarationChecked,
        payment_proof: paymentDetails?.paymentProof.data,
        payment_proof_filename: paymentDetails?.paymentProof.name,
      };
      if (!isEqual(getBookingDetails, payload)) {
        setIsUpdateBookingDetailsLoading(true);
        setIsNextButtonDisabled(true);
        if (isSubmitted) {
          setShowPreview(false);
        }
        updateBooking({ userToken: getUserToken(), payload });
      } else {
        fetchBookingDetails();
        if (isSavedAsDraft) {
          setShowPreview(false);
          navigateToBookings();
        }
      }
    },
    [
      bookingId,
      fetchBookingDetails,
      getBookingDetails,
      getUserToken,
      homeLoan?.annualIncome,
      homeLoan?.fromBank,
      homeLoan?.isHomeLoan,
      homeLoan?.isLoanSanctioned,
      homeLoan?.loanAmountInterested,
      homeLoan?.sanctionedAmount,
      homeLoan?.serviceBy,
      isDeclarationChecked,
      isSavedAsDraft,
      isTermsAndConditionChecked,
      legalDetails?.powerOfAttorneyHolderAddress,
      legalDetails?.powerOfAttorneyHolderEmailId,
      legalDetails?.powerOfAttorneyHolderName,
      legalDetails?.powerOfAttorneyHolderPhone,
      navigateToBookings,
      paymentDetails?.bank,
      paymentDetails?.branch,
      paymentDetails?.chequeOrDemandDraftDate,
      paymentDetails?.chequeOrDemandDraftNumber,
      paymentDetails?.city,
      paymentDetails?.enquiryNo,
      paymentDetails?.paymentMode,
      paymentDetails?.paymentProof.data,
      paymentDetails?.paymentProof.name,
      paymentDetails?.receivedAmount,
      paymentDetails?.sourceOfBooking,
      projectAndUnits,
      updateBooking,
    ]
  );

  useEffect(() => {
    if (saveBookingNotesLoading) {
      if (isSaveBookingNotesSuccess) {
        if (saveBookingNotesResponse?.data?.booking_id) {
          setSaveBookingNotesLoading(false);
          updateBookingDetails({ isSubmitted: false });
        } else if (saveBookingNotesResponse?.message) {
          toast(saveBookingNotesResponse?.message, {
            type: ERROR,
          });
          setIsSavedAsDaft(false);
          setSaveBookingNotesLoading(false);
        }
      } else if (isSaveBookingNotesFailed) {
        redirectToLoginBasedOnResponse(
          saveBookingNotesError,
          saveBookingNotesResponse
        );
        setIsSavedAsDaft(false);
        setSaveBookingNotesLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [
    isSaveBookingNotesFailed,
    isSaveBookingNotesSuccess,
    refetchBookingNotes,
    saveBookingNotesError,
    saveBookingNotesLoading,
    saveBookingNotesResponse,
  ]);

  const getApplicantSignature = useCallback((signatures: any) => {
    return signatures?.map(
      (item: {
        signature_name: any;
        signature: any;
        signature_byte_array: any;
      }) => {
        return { name: item?.signature_name, attachment: item?.signature };
      }
    );
  }, []);

  useEffect(() => {
    if (isGetBookingNotesByIdSuccess) {
      if (getBookingNotesResponse?.data?.name) {
        setBookingNotes(getBookingNotesResponse?.data?.booking_notes);
        setBookingSignatures(
          getApplicantSignature(getBookingNotesResponse?.data?.applicants)
        );
      }
    } else if (isGetBookingNotesByIdFailed) {
      redirectToLoginBasedOnResponse(
        getBookingNotesByIdError,
        getBookingNotesResponse
      );
    }
    // eslint-disable-next-line
  }, [
    getApplicantSignature,
    getBookingNotesByIdError,
    getBookingNotesResponse,
    isGetBookingNotesByIdFailed,
    isGetBookingNotesByIdSuccess,
  ]);

  useEffect(() => {
    if (isBookingDetailsLoading && !isBookingDetailsFetching) {
      if (isGetBookingDetailsByIdSuccess) {
        if (getBookingDetailsByIdResponse?.data?.name) {
          let data = getBookingDetailsByIdResponse?.data;
          setProjectAndUnits({
            project: data?.project,
            unit: data?.unit,
            unitType: data?.unit_type,
            saleableArea: data?.saleable_area,
            carpetArea: data?.carpet_area,
            bhk: data?.bhk,
            floor: data?.floor,
            block: data?.block,
          });
          setPaymentDetails({
            receivedAmount: data?.received_amount,
            branch: data?.branch,
            chequeOrDemandDraftNumber: data?.cheque_demand_draft_no,
            chequeOrDemandDraftDate:
              data?.cheque_demand_date ??
              moment().format(YYYY_MM_DD_WITH_HYPHEN),
            city: data?.city,
            sourceOfBooking: data?.source_of_booking,
            bank: data?.booking_bank,
            enquiryNo: data?.enquiry_no,
            chequeOrDemandDraftNumberErrorMessage: "",
            paymentMode: data?.payment_mode,
            paymentProof: {
              data: data?.payment_byte_array,
              name: data?.payment_proof_filename,
              attachment: data?.payment_proof,
              type: "",
            },
          });
          setHomeLoan({
            fromBank: data?.bank ?? "",
            serviceBy: data?.services_by ?? "",
            annualIncome: data?.annual_income ?? "",
            loanAmountInterested: data?.loan_amount_interested ?? "",
            sanctionedAmount: data?.sanctioned_amount ?? "",
            isHomeLoan: Boolean(data?.planning_to_take_home_loan),
            isLoanSanctioned: Boolean(data?.loan_sanctioned),
          });
          setLegalDetails({
            powerOfAttorneyHolderName: data?.poa_holder_name,
            powerOfAttorneyHolderEmailId: data?.poa_holder_email,
            powerOfAttorneyHolderPhone: data?.poa_holder_phone,
            powerOfAttorneyHolderAddress: data?.poa_holder_address,
            termsAndConditions: data?.terms_of_allotment,
            phoneErrorMsg: "",
            emailErrorMsg: "",
            nameErrorMsg: "",
          });
          setApplicantDetails(data?.applicants);
          setBookingStatus(data?.workflow_state);
          setIsTermsAndConditionChecked(
            Boolean(data?.terms_and_conditions_read)
          );
          setIsDeclarationChecked(Boolean(data?.declaration_read));
          setBookingDetails({
            id: data?.name,
            firstName: data?.first_name,
            lastName: data?.last_name,
            project: data?.project,
            unit: data?.unit,
            applicant: data?.applicants[0],
            allocatedSalesTeam: data?.allocated_sales_team,
          });
          setBookingStatus(data?.workflow_state);
          setDocuments(data?.documents);
          setIsBookingDetailsLoading(false);
          setNumberOfApplicant(
            data?.applicants?.length > 1 ? data?.applicants?.length : 1
          );
          refetchBookingNotes();
        } else if (getBookingDetailsByIdResponse?.message) {
          toast(getBookingDetailsByIdResponse?.message, {
            type: ERROR,
          });
          setIsBookingDetailsLoading(false);
        }
      } else if (isGetBookingDetailsByIdFailed) {
        redirectToLoginBasedOnResponse(
          getBookingDetailsByIdError,
          getBookingDetailsByIdResponse
        );
        setIsBookingDetailsLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [
    getBookingDetailsByIdError,
    getBookingDetailsByIdResponse,
    isBookingDetailsFetching,
    isBookingDetailsLoading,
    isGetBookingDetailsByIdFailed,
    isGetBookingDetailsByIdSuccess,
    setApplicantDetails,
    refetchBookingNotes,
  ]);

  useEffect(() => {
    if (isGetBookingDocumentSuccess) {
      if (getBookingDocumentResponse?.data) {
        setBookingDocumentPDF(getBookingDocumentResponse?.data);
      } else if (getBookingDocumentResponse?.message) {
        toast(getBookingDocumentResponse?.message, {
          type: ERROR,
        });
        setBookingDocumentPDF("");
      }
    } else if (isGetBookingDocumentsFailed) {
      redirectToLoginBasedOnResponse(
        getBookingDocumentsError,
        getBookingDocumentResponse
      );
      setBookingDocumentPDF("");
    }
    // eslint-disable-next-line
  }, [
    getBookingDocumentResponse,
    isGetBookingDocumentSuccess,
    isGetBookingDocumentsFailed,
    getBookingDocumentsError,
  ]);

  useEffect(() => {
    if (isGetBookingCommentsSuccess) {
      if (Array.isArray(getBookingCommentsResponse?.data)) {
        setComments(getBookingCommentsResponse?.data);
      } else if (getBookingCommentsResponse?.message) {
        toast(getBookingCommentsResponse?.message, {
          type: ERROR,
        });
        setComments([]);
      }
    } else if (isGetBookingCommentsFailed) {
      redirectToLoginBasedOnResponse(
        getBookingCommentsError,
        getBookingCommentsResponse
      );
      setComments([]);
    }
    // eslint-disable-next-line
  }, [
    getBookingCommentsResponse,
    isGetBookingCommentsSuccess,
    isGetBookingCommentsFailed,
    t,
    getBookingCommentsError,
  ]);

  useEffect(() => {
    if (isAddNoteLoading) {
      if (isUpdateBookingCommentsSuccess) {
        if (updateBookingCommentsResponse?.data) {
          setIsAddNoteLoading(false);
          toast(updateBookingCommentsResponse?.message, {
            type: SUCCESS,
          });
          refetchBookingComments();
        } else if (updateBookingCommentsResponse?.message) {
          setIsAddNoteLoading(false);
          toast(updateBookingCommentsResponse?.message, {
            type: ERROR,
          });
        }
      } else if (isUpdateBookingCommentsFailed) {
        redirectToLoginBasedOnResponse(
          updateBookingCommentsError,
          updateBookingCommentsResponse
        );
        setIsAddNoteLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [
    setIsAddNoteLoading,
    isUpdateBookingCommentsSuccess,
    isUpdateBookingCommentsFailed,
    updateBookingCommentsResponse,
    updateBookingCommentsError,
    isAddNoteLoading,
  ]);

  useEffect(() => {
    if (applicantList?.length) {
      let subMenuList: any[] = [];
      let drawerMenuList = [...drawerMenu];
      for (let i = 0; i <= applicantList?.length - 1; i++) {
        subMenuList = [...subMenuList, applicantList[i]?.tabDetails];
      }
      drawerMenuList[APPLICANT_DETAILS_MENU_INDEX].subMenus = subMenuList;
      setDrawerMenu(drawerMenuList);
    }
    // eslint-disable-next-line
  }, [applicantList?.length, t, applicantList]);

  const isAadharValidated = useCallback(
    () =>
      applicantList?.some((applicant) => Boolean(applicant?.aadhaarPhoto.name)),
    [applicantList]
  );

  const isPanValidated = useCallback(
    () => applicantList?.some((applicant) => Boolean(applicant?.panPhoto.name)),
    [applicantList]
  );

  const isMobileValidated = useCallback(
    () =>
      applicantList?.some((applicant) => isPhoneNumberValid(applicant?.mobile)),
    [applicantList]
  );

  const hasDuplicateMobiles = useMemo(
    () =>
      applicantList?.some((applicant, index) => {
        const nextApplicant = applicantList[index + 1];
        return nextApplicant && applicant.mobile === nextApplicant.mobile;
      }),
    [applicantList]
  );

  const isEmailValidated = useCallback(
    () => applicantList?.some((applicant) => isEmailValid(applicant?.email)),
    [applicantList]
  );

  const isApplicantNRI = useCallback(
    () =>
      applicantList?.some((applicant) => Boolean(applicant?.isApplicantNRI)),
    [applicantList]
  );

  const isPassportValidated = useCallback(
    () =>
      applicantList?.some((applicant) =>
        Boolean(applicant?.passportPhoto?.name)
      ),
    [applicantList]
  );

  const setButtonTitle = useCallback(() => {
    if (
      currentMenuId === APPLICANT_DETAILS &&
      selectedTab?.tabIndex <
        applicantList[selectedCard]?.tabDetails?.length - 1
    ) {
      return t("create_booking.add_applicant_details", {
        applicantDetails:
          applicantList[selectedCard]?.tabDetails[selectedTab?.tabIndex + 1]
            .label,
      });
    } else {
      return t("create_booking.proceed_to_add_payment_details");
    }
  }, [t, selectedTab, applicantList, selectedCard, currentMenuId]);

  useEffect(() => {
    setIsNextButtonDisabled(false);
    switch (currentMenuId) {
      case PROJECT_AND_UNIT:
        setButtonText(t("app_common.next"));
        break;
      case LIST_PRICE:
        setButtonText(t("create_booking.proceed_to_add_applicant_details"));
        break;
      case APPLICANT_DETAILS:
        setButtonText(setButtonTitle());
        break;
      case PAYMENT_DETAILS:
        setButtonText(t("create_booking.proceed_to_add_home_loan_details"));
        break;
      case HOME_LOAN:
        setButtonText(t("create_booking.proceed_to_add_legal_details"));
        break;
      case LEGAL:
        setButtonText(t("create_booking.proceed_to_add_booking_notes"));
        break;
      case NOTES:
        setButtonText(t("create_booking.save_notes"));
        break;
      case TERMS_AND_CONDITIONS:
        setButtonText(t("create_booking.save_to_review_submission"));
        break;
      default:
        setButtonText(t("app_common.next"));
        break;
    }
  }, [currentMenuId, setButtonTitle, t, selectedTab]);

  const isMandatoryFieldCompleted = useCallback(
    (
      tab: ApplicantTab,
      applicant: ApplicantDetails = applicantList[selectedCard]
    ) => {
      if (selectedCard > -1) {
        let completedMandatoryField: string[] = [];
        for (let i = 0; i <= Object?.keys(applicant)?.length - 1; i++) {
          if (tab.mandatoryFields?.includes(Object?.keys(applicant)[i])) {
            if (Object?.keys(applicant)[i].toString() === "isApplicantNRI") {
              if (Boolean(Object?.values(applicant)[i])) {
                if (applicant?.passportNumber) {
                  completedMandatoryField.push(Object.keys(applicant)[i]);
                  if (!Boolean(applicant?.aadhaarNumber))
                    completedMandatoryField.push("aadhaarNumber");
                }
              } else {
                completedMandatoryField.push(Object?.keys(applicant)[i]);
              }
            } else if (
              Boolean(Object?.values(applicant)[i]) &&
              typeof Object?.values(applicant)[i] !== "object"
            ) {
              completedMandatoryField.push(Object.keys(applicant)[i]);
            } else if (Boolean(Object.values(applicant)[i]?.name)) {
              completedMandatoryField.push(Object.keys(applicant)[i]);
            }
          }
        }
        return (
          JSON.stringify(getSortedData(completedMandatoryField)) ===
          JSON.stringify(getSortedData(tab?.mandatoryFields))
        );
      }
    },
    [applicantList, selectedCard]
  );

  const isTabHasError = (
    tab: ApplicantTab,
    applicant: ApplicantDetails = applicantList[selectedCard]
  ) => {
    if (selectedCard > -1 && tab?.tabErrorFields?.length) {
      let errorFields: string[] = [];
      for (let i = 0; i <= Object?.keys(applicant)?.length - 1; i++) {
        if (tab?.tabErrorFields?.includes(Object?.keys(applicant)[i])) {
          if (Boolean(Object?.values(applicant)[i])) {
            errorFields.push(Object?.keys(applicant)[i]);
          }
        }
      }
      return Boolean(errorFields?.length);
    }
  };

  useEffect(() => {
    setSelectedTab({
      tabIndex: 0,
      tabId: BASIC_DETAILS,
      tabLabel: t("create_booking.basic_details.title"),
    });
    // eslint-disable-next-line
  }, [selectedCard, t]);

  const getUnFilledFields = useCallback(() => {
    let unFilledFields: string[] = [];
    applicantList?.forEach((applicant) => {
      if (applicant?.isApplicantNRI) {
        for (let i = 0; i <= Object.keys(applicant)?.length - 1; i++) {
          if (
            NRI_APPLICANT_MANDATORY_FIELDS?.includes(Object.keys(applicant)[i])
          ) {
            if (
              Boolean(
                applicant?.panErrorMessage ||
                  applicant?.aadhaarErrorMessage ||
                  applicant?.mobileErrorMsg ||
                  applicant?.emailErrorMsg ||
                  applicant?.passportErrorMessage
              )
            ) {
              unFilledFields.push(Object.keys(applicant)[i]);
            } else if (
              typeof Object.values(applicant)[i] == "object" &&
              !Boolean(Object.values(applicant)[i]?.name)
            ) {
              unFilledFields.push(Object.keys(applicant)[i]);
            } else if (!Boolean(Object.values(applicant)[i])) {
              unFilledFields.push(Object.keys(applicant)[i]);
            }
          }
        }
      } else {
        for (let i = 0; i <= Object.keys(applicant)?.length - 1; i++) {
          if (APPLICANT_MANDATORY_FIELDS?.includes(Object.keys(applicant)[i])) {
            if (
              Boolean(
                applicant?.panErrorMessage ||
                  applicant?.aadhaarErrorMessage ||
                  applicant?.mobileErrorMsg ||
                  applicant?.emailErrorMsg ||
                  applicant?.passportErrorMessage
              )
            ) {
              unFilledFields.push(Object.keys(applicant)[i]);
            } else if (
              typeof Object.values(applicant)[i] == "object" &&
              !Boolean(Object.values(applicant)[i]?.name)
            ) {
              unFilledFields.push(Object.keys(applicant)[i]);
            } else if (!Boolean(Object.values(applicant)[i])) {
              unFilledFields.push(Object.keys(applicant)[i]);
            }
          }
        }
      }
    });

    return unFilledFields;
  }, [applicantList]);

  const getUnFilledPaymentDetails = useCallback(() => {
    let unFilledFields: string[] = [];
    for (let i = 0; i <= Object.keys(paymentDetails)?.length - 1; i++) {
      if (PAYMENT_MANDATORY_FIELDS.includes(Object.keys(paymentDetails)[i])) {
        if (!Boolean(Object.values(paymentDetails)[i])) {
          unFilledFields.push(Object.keys(paymentDetails)[i]);
        }
      }
    }
    if (!Boolean(paymentDetails?.paymentProof.name)) {
      unFilledFields.push("paymentProof");
    }
    return unFilledFields;
  }, [paymentDetails]);

  useEffect(() => {
    let drawerMenuList = [...drawerMenu];
    drawerMenuList.forEach((menu: { isDisabled: boolean }) => {
      if (!getUnFilledFields()?.length) {
        menu.isDisabled = false;
        setDrawerMenu(drawerMenuList);
      } else {
        drawerMenuList[PAYMENT_DETAILS_MENU_INDEX].isDisabled = true;
        drawerMenuList[HOME_LOAN_MENU_INDEX].isDisabled = true;
        drawerMenuList[LEGAL_MENU_INDEX].isDisabled = true;
        drawerMenuList[NOTES_MENU_INDEX].isDisabled = true;
        drawerMenuList[TERMS_MENU_INDEX].isDisabled = true;
      }
    });
    // eslint-disable-next-line
  }, [getUnFilledFields, currentMenuId]);

  useEffect(() => {
    if (bookingStatus && !BookingEditableStatuses.includes(bookingStatus)) {
      setIsBookingEditable(false);
      setCurrentMenuId(PROJECT_AND_UNIT);
    } else {
      setIsBookingEditable(true);
      setCurrentMenuId(APPLICANT_DETAILS);
    }
  }, [bookingStatus]);

  const disableButtonBasedOnFilledDetails = useCallback(() => {
    if (getUnFilledFields()?.length) {
      setIsNextButtonDisabled(true);
    } else {
      setIsNextButtonDisabled(false);
    }
  }, [setIsNextButtonDisabled, getUnFilledFields]);

  const updateTabItem = useCallback((item: ApplicantTab, index: number) => {
    setSelectedTab({
      tabId: item?.id,
      tabIndex: index,
      tabLabel: item?.label,
    });
  }, []);

  const applicantDetails = useMemo(() => {
    return getBookingDetailsByIdResponse?.data?.applicants?.map(
      (applicant: any, index: number) => {
        const {
          idx,
          name,
          applicant_name,
          last_name,
          father_or_husband_name,
          date_of_birth,
          pan_number,
          aadhar_number,
          email_id,
          passport_number,
          profession,
          industry,
          employed_at,
          designation,
          mobile,
          correspondence_address,
          pincode,
          permanent_pincode,
          permanent_address,
          gender,
          tel,
          permanent_tel,
          is_nri,
          relationship,
          salutation,
          address_same_as_primary_applicant,
          is_fathers_name,
        } = applicant;
        return {
          key: index,
          name,
          idx,
          first_name: applicant_name,
          last_name,
          father_or_husband_name,
          date_of_birth,
          pan_number,
          aadhar_number,
          email_id,
          passport_number,
          profession,
          industry,
          employed_at,
          designation,
          mobile,
          correspondence_address,
          pincode,
          permanent_pincode,
          permanent_address,
          gender,
          tel,
          permanent_tel,
          is_nri: Boolean(is_nri),
          relationship: relationship === PRIMARY_APPLICANT ? "" : relationship,
          salutation,
          address_same_as_primary_applicant: Boolean(
            address_same_as_primary_applicant
          ),
          is_fathers_name,
        };
      }
    );
  }, [getBookingDetailsByIdResponse?.data?.applicants]);

  const isAddressSameAsPrimaryApplicant = (
    index: number,
    applicant: { isAddressSameAsPrimaryApplicant: string }
  ) => index > 0 && applicant?.isAddressSameAsPrimaryApplicant === YES;

  const getApplicantDetails = useCallback(() => {
    return applicantList?.map((applicant, index) => {
      return {
        ...(applicant?.name && { name: applicant?.name }),
        key: index,
        ...(applicant?.idx && { idx: applicant?.idx }),
        salutation: applicant?.salutation,
        first_name: applicant?.firstName?.trimEnd(),
        last_name: applicant?.lastName?.trimEnd(),
        is_fathers_name: applicant?.isFathersName,
        father_or_husband_name: applicant?.fatherOrHusbandName,
        date_of_birth: applicant?.dob,
        pan_number: applicant?.panNumber,
        aadhar_number: applicant?.aadhaarNumber,
        email_id: applicant?.email,
        passport_number: applicant?.passportNumber,
        profession: applicant?.profession,
        industry: applicant?.industry,
        employed_at: applicant?.employedAt,
        designation: applicant?.designation,
        mobile: `${applicant?.mobile}`,
        address_same_as_primary_applicant:
          index === 0
            ? false
            : applicant?.isAddressSameAsPrimaryApplicant === YES,
        correspondence_address: isAddressSameAsPrimaryApplicant(
          index,
          applicant
        )
          ? applicantList[0]?.correspondenceAddress
          : applicant?.correspondenceAddress,
        pincode: isAddressSameAsPrimaryApplicant(index, applicant)
          ? applicantList[0]?.pin
          : applicant?.pin,
        permanent_pincode: isAddressSameAsPrimaryApplicant(index, applicant)
          ? applicantList[0]?.permanentPin
          : applicant?.permanentPin,
        permanent_address: isAddressSameAsPrimaryApplicant(index, applicant)
          ? applicantList[0]?.permanentAddress
          : applicant?.permanentAddress,
        gender: applicant?.gender,
        tel: isAddressSameAsPrimaryApplicant(index, applicant)
          ? applicantList[0]?.telephone
          : applicant?.telephone,
        permanent_tel: isAddressSameAsPrimaryApplicant(index, applicant)
          ? applicantList[0]?.permanentTelephone
          : applicant?.permanentTelephone,
        is_nri: applicant?.isApplicantNRI,
        relationship:
          applicant.relationShipWithPrimaryUser === PRIMARY_APPLICANT
            ? ""
            : applicant.relationShipWithPrimaryUser,
      };
    });
  }, [applicantList]);

  const toggleBookingPreviewModal = useCallback(
    () => setShowPreview(!showPreview),
    [showPreview]
  );

  const setCurrentMenu = useCallback(() => {
    switch (currentMenuId) {
      case PROJECT_AND_UNIT:
        setCurrentMenuId(LIST_PRICE);
        break;
      case LIST_PRICE:
        setCurrentMenuId(APPLICANT_DETAILS);
        break;
      case APPLICANT_DETAILS:
        setCurrentMenuId(PAYMENT_DETAILS);
        break;
      case PAYMENT_DETAILS:
        setCurrentMenuId(HOME_LOAN);
        break;
      case HOME_LOAN:
        setCurrentMenuId(LEGAL);
        break;
      case LEGAL:
        setCurrentMenuId(NOTES);
        break;
      case NOTES:
        setCurrentMenuId(TERMS_AND_CONDITIONS);
        break;
      case TERMS_AND_CONDITIONS:
        toggleBookingPreviewModal();
        break;
      default:
        setButtonText(t("app_common.next"));
        break;
    }
  }, [currentMenuId, t, toggleBookingPreviewModal]);

  const getBookingSignatures = useCallback(() => {
    return applicantList?.map((applicant) => {
      return {
        first_name: applicant?.firstName,
        last_name: applicant?.lastName,
        signature:
          applicant?.signature?.attachment ?? applicant?.signature?.data,
        signature_name: applicant?.signature?.name,
      };
    });
  }, [applicantList]);

  const existingSignatureResponse = useMemo(() => {
    return getBookingDetailsByIdResponse?.data?.applicants?.map(
      (applicant: {
        applicant_name: string;
        last_name: string;
        signature: string;
        signature_name: string;
      }) => {
        const { applicant_name, last_name, signature, signature_name } =
          applicant;
        return {
          first_name: applicant_name,
          last_name,
          signature,
          signature_name,
        };
      }
    );
  }, [getBookingDetailsByIdResponse?.data?.applicants]);

  const isBookingNoteAlreadyExist = useCallback(() => {
    const { booking_notes } = getBookingNotesResponse?.data;
    const payload = {
      booking_id: bookingId,
      booking_notes: bookingNotes,
      applicants_notes_sign: getBookingSignatures(),
    };
    const existingData = {
      booking_id: bookingId,
      booking_notes,
      applicants_notes_sign: existingSignatureResponse,
    };
    return isEqual(existingData, payload);
  }, [
    bookingId,
    bookingNotes,
    existingSignatureResponse,
    getBookingNotesResponse?.data,
    getBookingSignatures,
  ]);

  const updateNotes = useCallback(() => {
    if (!isBookingNoteAlreadyExist()) {
      const payload = {
        booking_id: bookingId,
        booking_notes: bookingNotes,
        applicants_notes_sign: getBookingSignatures(),
      };
      setSaveBookingNotesLoading(true);
      updateBookingNotes({ userToken: getUserToken(), payload });
    } else {
      updateBookingDetails({ isSubmitted: false });
    }
  }, [
    bookingId,
    bookingNotes,
    getBookingSignatures,
    getUserToken,
    isBookingNoteAlreadyExist,
    updateBookingDetails,
    updateBookingNotes,
  ]);

  const addBookingNotes = useCallback(() => {
    updateNotes();
  }, [updateNotes]);

  useEffect(() => {
    if (isAddApplicantsLoading) {
      if (isAddApplicantsSuccess) {
        if (addApplicantsResponse?.data?.booking_id) {
          setIsAddApplicantsLoading(false);
          addBookingNotes();
        } else if (addApplicantsResponse?.message) {
          toast(addApplicantsResponse?.message, {
            type: ERROR,
          });
          setIsSavedAsDaft(false);
          setIsAddApplicantsLoading(false);
        }
      } else if (isAddApplicantsFailed) {
        redirectToLoginBasedOnResponse(
          addApplicantsError,
          addApplicantsResponse
        );
        setIsSavedAsDaft(false);
        setIsAddApplicantsLoading(false);
      }
      setIsNextButtonDisabled(false);
    }
    // eslint-disable-next-line
  }, [
    addApplicantsError,
    addApplicantsResponse,
    addBookingNotes,
    isAddApplicantsFailed,
    isAddApplicantsLoading,
    isAddApplicantsSuccess,
  ]);

  useEffect(() => {
    if (isDeleteApplicantsLoading) {
      if (isDeleteApplicantsSuccess) {
        if (deleteApplicantsResponse?.data?.booking_id) {
          setIsDeleteApplicantsLoading(false);
          fetchBookingDetails();
        } else if (deleteApplicantsResponse?.message) {
          toast(deleteApplicantsResponse?.message, {
            type: ERROR,
          });
          setIsSavedAsDaft(false);
          setIsDeleteApplicantsLoading(false);
        }
      } else if (isDeleteApplicantsFailed) {
        redirectToLoginBasedOnResponse(
          deleteApplicantsError,
          deleteApplicantsResponse
        );
        setIsSavedAsDaft(false);
        setIsDeleteApplicantsLoading(false);
      }
      setIsNextButtonDisabled(false);
    }
    // eslint-disable-next-line
  }, [
    deleteApplicantsError,
    deleteApplicantsResponse,
    fetchBookingDetails,
    isDeleteApplicantsFailed,
    isDeleteApplicantsLoading,
    isDeleteApplicantsSuccess,
  ]);

  const updateApplicants = useCallback(() => {
    const payload = {
      booking_id: bookingId,
      applicants: getApplicantDetails(),
    };
    const newDetails = {
      booking_id: bookingId,
      applicants: applicantDetails,
    };
    if (!isEqual(newDetails, payload)) {
      setIsNextButtonDisabled(true);
      setIsAddApplicantsLoading(true);
      addApplicants({ userToken: getUserToken(), payload });
    } else {
      updateNotes();
    }
  }, [
    addApplicants,
    applicantDetails,
    bookingId,
    getApplicantDetails,
    getUserToken,
    updateNotes,
  ]);

  const addBookingApplicantsDetails = useCallback(() => {
    setCurrentMenu();
    updateApplicants();
  }, [setCurrentMenu, updateApplicants]);

  const validateMobileAndEmail = useCallback(() => {
    let newApplicants = [...applicantList];
    if (
      isPhoneNumberValid(newApplicants[selectedCard].mobile) &&
      isEmailValid(newApplicants[selectedCard]?.email)
    ) {
      addBookingApplicantsDetails();
    } else {
      setIsSavedAsDaft(false);
      updateTabItem(applicantList[selectedCard]?.tabDetails[0], 0);
      newApplicants[selectedCard].mobileErrorMsg = isPhoneNumberValid(
        newApplicants[selectedCard].mobile
      )
        ? ""
        : t("create_booking.basic_details.invalid_mobile");
      newApplicants[selectedCard].emailErrorMsg = isEmailValid(
        newApplicants[selectedCard].email
      )
        ? ""
        : t("create_booking.basic_details.invalid_email");
    }
    setApplicantList(newApplicants);
  }, [
    applicantList,
    selectedCard,
    addBookingApplicantsDetails,
    updateTabItem,
    t,
  ]);

  const validatePoaName = useCallback(() => {
    if (legalDetails?.powerOfAttorneyHolderName) {
      setIsSavedAsDaft(false);
      addBookingApplicantsDetails();
    } else {
      setLegalDetails({
        ...legalDetails,
        nameErrorMsg: legalDetails?.powerOfAttorneyHolderName
          ? ""
          : t("app_common.cannot_be_empty", {
              requiredField: t("app_common.name"),
            }),
      });
    }
  }, [legalDetails, addBookingApplicantsDetails, t]);

  const validatePoaEmailAndMobile = useCallback(() => {
    if (
      legalDetails?.powerOfAttorneyHolderPhone &&
      legalDetails?.powerOfAttorneyHolderEmailId
    ) {
      if (
        isPhoneNumberValid(legalDetails?.powerOfAttorneyHolderPhone) &&
        isEmailValid(legalDetails?.powerOfAttorneyHolderEmailId)
      ) {
        validatePoaName();
      } else {
        setIsSavedAsDaft(false);
        setLegalDetails({
          ...legalDetails,
          phoneErrorMsg: !legalDetails?.powerOfAttorneyHolderPhone
            ? ""
            : isPhoneNumberValid(legalDetails?.powerOfAttorneyHolderPhone)
            ? ""
            : t("create_booking.basic_details.invalid_mobile"),
          emailErrorMsg: !legalDetails?.powerOfAttorneyHolderEmailId
            ? ""
            : isEmailValid(legalDetails?.powerOfAttorneyHolderEmailId)
            ? ""
            : t("create_booking.basic_details.invalid_email"),
        });
      }
    } else if (legalDetails?.powerOfAttorneyHolderPhone) {
      if (isPhoneNumberValid(legalDetails?.powerOfAttorneyHolderPhone)) {
        if (legalDetails?.powerOfAttorneyHolderEmailId) {
          validatePoaName();
        } else {
          setLegalDetails({
            ...legalDetails,
            emailErrorMsg: legalDetails?.powerOfAttorneyHolderEmailId
              ? ""
              : t("app_common.cannot_be_empty", {
                  requiredField: t(
                    "create_booking.legal.power_of_attorney_holder_email"
                  ),
                }),
          });
        }
      } else {
        setIsSavedAsDaft(false);
        setLegalDetails({
          ...legalDetails,
          phoneErrorMsg: !legalDetails?.powerOfAttorneyHolderPhone
            ? ""
            : isPhoneNumberValid(legalDetails?.powerOfAttorneyHolderPhone)
            ? ""
            : t("create_booking.basic_details.invalid_mobile"),
        });
      }
    } else if (legalDetails?.powerOfAttorneyHolderEmailId) {
      if (isEmailValid(legalDetails?.powerOfAttorneyHolderEmailId)) {
        if (legalDetails?.powerOfAttorneyHolderPhone) {
          validatePoaName();
        } else {
          setLegalDetails({
            ...legalDetails,
            phoneErrorMsg: legalDetails?.powerOfAttorneyHolderPhone
              ? ""
              : t("app_common.cannot_be_empty", {
                  requiredField: t(
                    "create_booking.legal.power_of_attorney_holder_phone"
                  ),
                }),
          });
        }
      } else {
        setIsSavedAsDaft(false);
        setLegalDetails({
          ...legalDetails,
          emailErrorMsg: !legalDetails?.powerOfAttorneyHolderEmailId
            ? ""
            : isEmailValid(legalDetails?.powerOfAttorneyHolderEmailId)
            ? ""
            : t("create_booking.basic_details.invalid_email"),
        });
      }
    } else {
      if (legalDetails?.powerOfAttorneyHolderName) {
        setIsSavedAsDaft(false);
        setLegalDetails({
          ...legalDetails,
          phoneErrorMsg: legalDetails?.powerOfAttorneyHolderPhone
            ? ""
            : t("app_common.cannot_be_empty", {
                requiredField: t(
                  "create_booking.legal.power_of_attorney_holder_phone"
                ),
              }),
          emailErrorMsg: legalDetails?.powerOfAttorneyHolderEmailId
            ? ""
            : t("app_common.cannot_be_empty", {
                requiredField: t(
                  "create_booking.legal.power_of_attorney_holder_email"
                ),
              }),
        });
      } else {
        setIsSavedAsDaft(false);
        addBookingApplicantsDetails();
      }
    }
  }, [addBookingApplicantsDetails, legalDetails, t, validatePoaName]);

  const validateLegalDetails = useCallback(() => {
    validatePoaEmailAndMobile();
  }, [validatePoaEmailAndMobile]);

  const updateBasedOnApplicantTab = useCallback(() => {
    if (currentMenuId === APPLICANT_DETAILS) {
      if (
        selectedTab?.tabIndex <
        applicantList[selectedCard].tabDetails?.length - 1
      ) {
        updateTabItem(
          applicantList[selectedCard]?.tabDetails[selectedTab?.tabIndex + 1],
          selectedTab?.tabIndex + 1
        );
      } else if (
        selectedTab?.tabIndex ===
        applicantList[selectedCard].tabDetails?.length - 1
      ) {
        validateMobileAndEmail();
      } else {
        setIsNextButtonDisabled(false);
      }
    } else if (currentMenuId === LEGAL) {
      validateLegalDetails();
    } else {
      addBookingApplicantsDetails();
    }
  }, [
    currentMenuId,
    selectedTab?.tabIndex,
    applicantList,
    selectedCard,
    updateTabItem,
    validateMobileAndEmail,
    validateLegalDetails,
    addBookingApplicantsDetails,
  ]);

  const onPressButton = useCallback(() => {
    if (currentMenuId === TERMS_AND_CONDITIONS) {
      let drawerMenuList = [...drawerMenu];
      if (isApplicantNRI()) {
        if (!isPassportValidated()) {
          toast("Missing Passport details", { type: "error" });
          drawerMenuList[APPLICANT_DETAILS_MENU_INDEX].hasError = true;
        } else if (!isPanValidated()) {
          toast("Missing KYC details", { type: "error" });
          drawerMenuList[APPLICANT_DETAILS_MENU_INDEX].hasError = true;
        } else {
          drawerMenuList[APPLICANT_DETAILS_MENU_INDEX].hasError = false;
        }
      } else if (!isAadharValidated()) {
        toast("Missing KYC details", { type: "error" });
        drawerMenuList[APPLICANT_DETAILS_MENU_INDEX].hasError = true;
      } else if (!isPanValidated()) {
        toast("Missing KYC details", { type: "error" });
        drawerMenuList[APPLICANT_DETAILS_MENU_INDEX].hasError = true;
      } else if (!isMobileValidated()) {
        toast("Invalid Mobile", { type: "error" });
        drawerMenuList[APPLICANT_DETAILS_MENU_INDEX].hasError = true;
      } else if (!isEmailValidated()) {
        toast("Invalid Email", { type: "error" });
        drawerMenuList[APPLICANT_DETAILS_MENU_INDEX].hasError = true;
      } else if (hasDuplicateMobiles) {
        toast("Mobile number should be unique for every applicant", {
          type: "error",
        });
        drawerMenuList[APPLICANT_DETAILS_MENU_INDEX].hasError = true;
      } else {
        drawerMenuList[APPLICANT_DETAILS_MENU_INDEX].hasError = false;
      }
      if (getUnFilledPaymentDetails()?.length) {
        toast("Missing payment details", { type: "error" });
        drawerMenuList[PAYMENT_DETAILS_MENU_INDEX].hasError = true;
      } else {
        drawerMenuList[PAYMENT_DETAILS_MENU_INDEX].hasError = false;
      }
      if (!bookingNotes || !isSignatureConfirmed) {
        toast("Missing booking notes", { type: "error" });
        drawerMenuList[NOTES_MENU_INDEX].hasError = true;
      } else {
        drawerMenuList[NOTES_MENU_INDEX].hasError = false;
      }
      if (!isTermsAndConditionChecked || !isDeclarationChecked) {
        toast("Missing Terms and conditions", { type: "error" });
        drawerMenuList[TERMS_MENU_INDEX].hasError = true;
      } else {
        drawerMenuList[TERMS_MENU_INDEX].hasError = false;
      }
      setDrawerMenu(drawerMenuList);
    }
    updateBasedOnApplicantTab();
  }, [
    currentMenuId,
    updateBasedOnApplicantTab,
    drawerMenu,
    isApplicantNRI,
    isAadharValidated,
    isPanValidated,
    isMobileValidated,
    isEmailValidated,
    hasDuplicateMobiles,
    getUnFilledPaymentDetails,
    bookingNotes,
    isSignatureConfirmed,
    isTermsAndConditionChecked,
    isDeclarationChecked,
    isPassportValidated,
  ]);

  const saveAsDraft = () => {
    setIsSavedAsDaft(true);
  };

  useEffect(() => {
    if (isSavedAsDraft) {
      validateMobileAndEmail();
    }
    // eslint-disable-next-line
  }, [isSavedAsDraft]);

  const onPressSubmitBooking = () => {
    setIsSubmitButtonVisible(false);
    setIsUpdateBookingDetailsLoading(true);
    updateBookingDetails({ isSubmitted: true });
  };

  const renderBottomBar = () => (
    <CustomizedBottomBar
      isButtonDisabled={isNextButtonDisabled}
      onPressButton={onPressButton}
      title={buttonText}
      styles={{
        bottomTabContainer: mergeStyles({
          styles: { marginLeft: 330 },
          tabletStyles: bottomTabContainerStyle,
        }),
      }}
      saveAsDraft={saveAsDraft}
      isRightButtonRequired={true}
      isButtonLoading={
        isUpdateBookingDetailsLoading ||
        isAddApplicantsLoading ||
        saveBookingNotesLoading
      }
    />
  );

  const addApplicant = () => {
    let applicants = [...applicantList];
    let newApplicant = applicant;
    applicants.push(newApplicant);
    setApplicantList(applicants);
    setNumberOfApplicant(numberOfApplicant + 1);
  };

  const deleteApplicant = () => {
    toggleDeleteModal();
    const deleteApplicantIndex =
      getBookingDetailsByIdResponse?.data?.applicants?.findIndex(
        (applicant: ApplicantDetails, index: number) =>
          index === selectedApplicant
      );
    if (deleteApplicantIndex !== -1) {
      const payload = {
        booking_id: bookingId,
        applicant_names: [applicantList[deleteApplicantIndex]?.name],
      };
      setIsNextButtonDisabled(true);
      setIsDeleteApplicantsLoading(true);
      deleteApplicants({ userToken: getUserToken(), payload });
    } else {
      applicantList.splice(selectedApplicant, 1);
      setNumberOfApplicant(numberOfApplicant - 1);
    }
  };

  const toggleDeleteModal = () => {
    setDeleteModalVisible(!isDeleteModalVisible);
  };

  const onClickCancel = () => {
    toggleDeleteModal();
  };

  const confirmDelete = (applicantIndex: number) => {
    if (applicantList?.length > 1) {
      toggleDeleteModal();
      setSelectedApplicant(applicantIndex);
    } else {
      toast("Reached minimum limit", { type: "error" });
    }
  };

  const onClickDelete = () => {
    deleteApplicant();
  };

  const getSortedData = (data: string[]) =>
    data.sort((a, b) => (a > b ? 1 : -1));

  const onUpdateDetails = useCallback(
    (
      details:
        | BasicDetailsTypes
        | IdentificationDetailsTypes
        | SignatureDetailsTypes
        | ProfessionalDetailsTypes
        | any,
      applicantIndex: number
    ) => {
      let newApplicants = [...applicantList];
      newApplicants[applicantIndex] = details;
      setApplicantList(newApplicants);
    },
    [setApplicantList, applicantList]
  );

  const onUpdateOtherDetails = (
    details: any,
    callbackFn:
      | Dispatch<SetStateAction<HomeLoanDataTypes>>
      | Dispatch<SetStateAction<PaymentDataTypes>>
  ) => {
    callbackFn(details);
  };

  const onUpdateLegalDetails = (details: legalDataTypes) => {
    setLegalDetails(details);
  };

  const saveFiles = useCallback(
    (payload: { booking_id?: string; applicants?: any[] }) => {
      addApplicants({ userToken: getUserToken(), payload });
    },
    [addApplicants, getUserToken]
  );

  const savePhoto = useCallback(
    (data?: string, name?: string) => {
      const applicant = getApplicantDetails()[selectedCard];
      const getOtherApplicant = getApplicantDetails().filter(
        (item, index) => index !== selectedCard
      );
      if (!isBookingDetailsLoading && isGetBookingDetailsByIdSuccess) {
        setIsAddApplicantsLoading(true);
        const payload = {
          booking_id: bookingId,
          applicants: [
            ...getOtherApplicant,
            {
              ...applicant,
              name: applicant?.name,
              profile_pic_attachment: data ?? "",
              profile_attachment_name: name ?? "",
            },
          ],
        };
        saveFiles(payload);
      }
    },
    [
      bookingId,
      getApplicantDetails,
      isBookingDetailsLoading,
      isGetBookingDetailsByIdSuccess,
      saveFiles,
      selectedCard,
    ]
  );

  const isAadhaar = useCallback(
    (type: string) =>
      type === t("create_booking.identification_details.aadhaar"),
    [t]
  );

  const isPan = useCallback(
    (type: string) => type === t("create_booking.identification_details.pan"),
    [t]
  );

  const isPassport = useCallback(
    (type: string) =>
      type === t("create_booking.identification_details.passport"),
    [t]
  );

  const saveKyc = useCallback(
    (type?: string, data?: string, name?: string) => {
      const applicant = getApplicantDetails()[selectedCard];
      const getOtherApplicant = getApplicantDetails().filter(
        (item, index) => index !== selectedCard
      );
      if (!isBookingDetailsLoading && isGetBookingDetailsByIdSuccess) {
        setIsAddApplicantsLoading(true);
        const payload = {
          booking_id: bookingId,
          applicants: [
            ...getOtherApplicant,
            {
              ...applicant,
              ...(type && isAadhaar(type)
                ? {
                    aadhar_attachment: data ?? "",
                    aadhar_attachment_name: name ?? "",
                  }
                : type && isPan(type)
                ? {
                    pan_attachment: data ?? "",
                    pan_attachment_name: name ?? "",
                  }
                : type && isPassport(type)
                ? {
                    passport_attachment: data ?? "",
                    passport_attachment_name: name ?? "",
                  }
                : {
                    signature: data ?? "",
                    signature_name: name ?? "",
                  }),
            },
          ],
        };
        saveFiles(payload);
      }
    },
    [
      bookingId,
      getApplicantDetails,
      isAadhaar,
      isBookingDetailsLoading,
      isGetBookingDetailsByIdSuccess,
      isPan,
      isPassport,
      saveFiles,
      selectedCard,
    ]
  );

  const disableButtonBasedOnUnFilledData = useCallback(() => {
    let isDisabled = false;
    if (getUnFilledFields()?.length) {
      isDisabled = true;
    } else {
      isDisabled = false;
    }
    return isDisabled;
  }, [getUnFilledFields]);

  const renderTabPanel = useCallback(() => {
    return selectedTab?.tabId === BASIC_DETAILS ? (
      <ApplicantBasicDetailsForm
        basicData={applicantList[selectedCard]}
        numberOfApplicant={selectedCard + 1}
        onUpdate={(details) => onUpdateDetails(details, selectedCard)}
        isEmailMandatory={true}
        isEditable={isBookingEditable}
        savePhoto={savePhoto}
        isSalutationRequired={true}
      />
    ) : selectedTab?.tabId === IDENTIFICATION_DETAILS ? (
      <IdentificationDetailsForm
        identificationData={applicantList[selectedCard]}
        numberOfApplicant={selectedCard + 1}
        onUpdate={(details) => onUpdateDetails(details, selectedCard)}
        isEditable={isBookingEditable}
        saveFile={saveKyc}
      />
    ) : selectedTab?.tabId === PROFESSIONAL_DETAILS ? (
      <ProfessionalDetailsForm
        professionalData={applicantList[selectedCard]}
        onUpdate={(details) => onUpdateDetails(details, selectedCard)}
        isEditable={isBookingEditable}
      />
    ) : (
      <ApplicantSignature
        signatureData={applicantList[selectedCard]}
        onUpdate={(details) => onUpdateDetails(details, selectedCard)}
        numberOfApplicant={selectedCard + 1}
        isEditable={isBookingEditable}
        saveSignature={saveKyc}
      />
    );
  }, [
    selectedTab?.tabId,
    applicantList,
    selectedCard,
    isBookingEditable,
    savePhoto,
    saveKyc,
    onUpdateDetails,
  ]);

  useEffect(() => {
    if (applicantList[selectedCard]?.tabDetails?.length) {
      applicantList[selectedCard]?.tabDetails?.forEach((tab, index) => {
        if (tab?.id !== selectedTab?.tabId) {
          return (tab.isSelected = false);
        } else {
          return (tab.isSelected = true);
        }
      });
    }
  }, [selectedTab?.tabId, applicantList, selectedCard]);

  useEffect(() => {
    if (
      applicantList[selectedCard]?.tabDetails?.length &&
      currentMenuId === APPLICANT_DETAILS
    ) {
      if (
        selectedTab?.tabIndex ===
        applicantList[selectedCard]?.tabDetails?.length - 1
      ) {
        disableButtonBasedOnFilledDetails();
      } else {
        setIsNextButtonDisabled(false);
      }
    } else {
      setIsNextButtonDisabled(false);
    }
  }, [
    selectedTab,
    applicantList,
    disableButtonBasedOnFilledDetails,
    selectedCard,
    currentMenuId,
    getUnFilledFields,
    disableButtonBasedOnUnFilledData,
    bookingNotes,
    isSignatureConfirmed,
    isDeclarationChecked,
    isTermsAndConditionChecked,
    getUnFilledPaymentDetails,
  ]);

  const onChangeNotes = useCallback(
    (value: string) => setBookingNotes(value),
    []
  );

  const onChangeConfirmSignature = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setSignatureConfirmed(checked);
    },
    []
  );

  const renderTabs = (applicant: ApplicantDetails) => {
    return (
      <>
        <Box sx={{ padding: "0rem 2rem 0rem 2rem" }}>
          <ApplicantTabContainer>
            {applicant?.tabDetails?.map((item: any, index: any) => {
              const Icon = item?.icon;
              return (
                <>
                  <RowStyledBox
                    key={item?.id}
                    sx={{
                      color: item?.isSelected
                        ? Colors.primaryColor
                        : Colors.greyColor,
                    }}
                  >
                    {isTabHasError(item, applicant) ? (
                      <InfoIcon
                        fontSize={"small"}
                        htmlColor={Colors.redColor}
                      />
                    ) : isMandatoryFieldCompleted(item, applicant) ? (
                      <CheckCircleIcon
                        htmlColor={Colors.greenColor}
                        fontSize={"small"}
                        sx={{ mr: 0.5 }}
                      />
                    ) : (
                      <Icon fontSize={"small"} sx={{ mr: 0.5 }} />
                    )}
                    <CustomizedText
                      fontSize={24}
                      fontColor={
                        item?.isSelected
                          ? Colors.primaryColor
                          : Colors.greyColor
                      }
                      font={"Lato-Regular"}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => updateTabItem(item, index)}
                    >
                      {item?.label}
                    </CustomizedText>
                  </RowStyledBox>
                  {index !== applicant?.tabDetails?.length - 1 && (
                    <CustomizedDivider
                      key={index}
                      width={"9%"}
                      sx={{
                        m: 0,
                        borderWidth: 0.1,
                      }}
                    />
                  )}
                </>
              );
            })}
          </ApplicantTabContainer>
          <CustomizedDivider width={"100%"} sx={{ m: 0 }} />
        </Box>
        {(selectedCard > -1 && isBookingDetailsLoading) ||
        isAddApplicantsLoading ? (
          <LoaderContainer>{renderLoader()}</LoaderContainer>
        ) : (
          <TabPanel>{renderTabPanel()}</TabPanel>
        )}
      </>
    );
  };

  const renderAddApplicant = () => (
    <AddApplicant
      title={t("create_booking.applicant", {
        number: numberOfApplicant + 1,
      })}
      addApplicant={addApplicant}
    />
  );

  const handleExpandClick = (id: number) => {
    if (id === selectedCard) {
      setSelectedCard(-1);
    } else {
      setSelectedCard(id);
    }
  };

  const editSignatureToConfirmBookingNotes = useCallback(() => {
    setCurrentMenuId(APPLICANT_DETAILS);
    setSelectedTab({
      tabIndex: 3,
      tabId: SIGNATURE,
      tabLabel: t("create_booking.applicant_signature.title"),
    });
  }, [t]);

  const handleTermsAndCondition = (e: any, checked: boolean) => {
    setIsTermsAndConditionChecked(checked);
  };

  const handleDeclaration = (e: any, checked: boolean) => {
    setIsDeclarationChecked(checked);
  };

  const toggleActionConfirmation = useCallback(() => {
    setIsActionConfirmed(!isActionConfirmed);
  }, [isActionConfirmed]);

  const saveAction = useCallback(
    (action: IAction) => {
      setAction(action);
      toggleActionConfirmation();
    },
    [toggleActionConfirmation]
  );

  const addNote = (comment: string) => {
    setIsAddNoteLoading(true);
    const payload = {
      booking_id: bookingId,
      comment,
    };
    updateBookingComments({ userToken: getUserToken(), payload });
  };

  const renderApplicants = () => {
    return (
      <div className="details-container">
        <BookingDetailsTitle title={t("create_booking.title")} />
        {applicantList?.map((applicant: ApplicantDetails, index: number) => {
          return (
            <ExpandableItemCard
              key={index}
              cardId={index}
              title={t("create_booking.applicant", {
                number: index + 1,
              })}
              cardContent={renderTabs(applicant)}
              onPressDelete={() => confirmDelete(index)}
              handleExpandClick={handleExpandClick}
              selectedCard={selectedCard}
              isDeleteRequired={isBookingEditable}
            />
          );
        })}
        {isBookingEditable && renderAddApplicant()}
      </div>
    );
  };

  const renderPaymentDetails = () => (
    <PaymentDetails
      paymentData={paymentDetails}
      onUpdate={(details) => onUpdateOtherDetails(details, setPaymentDetails)}
      isEditable={isBookingEditable}
      numberOfApplicant={numberOfApplicant}
    />
  );

  const renderHomeLoanForm = () => (
    <HomeLoan
      homeLoanData={homeLoan}
      onUpdate={(details) => onUpdateOtherDetails(details, setHomeLoan)}
      isEditable={isBookingEditable}
    />
  );

  const renderLegalForm = () => (
    <LegalForm
      legalData={legalDetails}
      onUpdate={onUpdateLegalDetails}
      bookingId={bookingId}
      isEditable={isBookingEditable}
    />
  );

  const renderProjectAndUnit = () => (
    <div className="details-container">
      <BookingDetailsTitle title={t("create_booking.project_and_unit.title")} />
      <Card sx={{ pl: 2, pr: 2 }} raised>
        <CardContent sx={{ pt: 1 }}>
          <ProjectAndUnitDetails bookingId={bookingId} />
        </CardContent>
      </Card>
    </div>
  );

  const renderListPrice = () => (
    <div className="details-container">
      <BookingDetailsTitle title={t("create_booking.list_price_title")} />
      <Card sx={{ pl: 2, pr: 2 }} raised>
        <CardContent sx={{ pt: 1 }}>
          <ListPrice listPrice={listPriceDetails} bookingId={bookingId} />
        </CardContent>
      </Card>
    </div>
  );

  const isFormSubmissionDisabled = () =>
    drawerMenu?.some((menu: { hasError: boolean }) => menu?.hasError);

  const renderBookingPreview = () => (
    <BookingPreview
      handleClose={toggleBookingPreviewModal}
      projectsAndUnits={projectAndUnits}
      homeLoanDetails={homeLoan}
      paymentDetails={paymentDetails}
      legalDetails={legalDetails}
      applicants={applicantList}
      onPressSubmit={onPressSubmitBooking}
      isButtonLoading={
        isUpdateBookingDetailsLoading ||
        isAddApplicantsLoading ||
        saveBookingNotesLoading
      }
      isTermsAndConditionAgreed={isTermsAndConditionChecked}
      isDeclarationAgreed={isDeclarationChecked}
      bookingNotes={bookingNotes}
      bookingSignatures={bookingSignatures}
      disableSubmitButton={
        isFormSubmissionDisabled() ||
        isUpdateBookingDetailsLoading ||
        isAddApplicantsLoading ||
        saveBookingNotesLoading
      }
      isSubmitButtonVisible={isSubmitButtonVisible}
    />
  );

  const renderNonEditableMessageModal = () => (
    <>
      <CustomizedModalContainer sx={{ p: 2 }}>
        <CustomizedText fontSize={24} fontColor={Colors.redColor}>
          {t("create_booking.booking_cannot_be_edited_text")}
        </CustomizedText>
      </CustomizedModalContainer>
      <CancelIcon
        htmlColor={Colors.white}
        sx={{ ml: 1 }}
        fontSize={"small"}
        onClick={navigateToBookings}
      />
    </>
  );

  const renderNotes = () => (
    <div className="details-container">
      <BookingDetailsTitle title={t("create_booking.notes.title")} />
      <Notes
        bookingNotes={bookingNotes}
        onChangeNotes={onChangeNotes}
        isSignatureConfirmed={isSignatureConfirmed}
        onChangeCheckBox={onChangeConfirmSignature}
        signatures={bookingSignatures}
        onClickEditApplicantSignature={editSignatureToConfirmBookingNotes}
        isEditable={isBookingEditable}
      />
    </div>
  );

  const renderTermsAndConditions = () => (
    <div className="details-container">
      <BookingDetailsTitle
        title={t("create_booking.terms_and_conditions.title")}
      />
      <BookingTermsAndConditions
        terms={legalDetails?.termsAndConditions}
        handleTermsAndCondition={handleTermsAndCondition}
        isTermsAndConditionsAgreed={isTermsAndConditionChecked}
        isAgreedToDeclaration={isDeclarationChecked}
        handleDeclaration={handleDeclaration}
        isEditable={isBookingEditable}
      />
    </div>
  );

  const renderLoader = () => <CircularProgress size={"1.5rem"} />;

  const renderActions = () => (
    <ActionButton
      styles={{
        bottomTabContainer: mergeStyles({
          styles: { marginLeft: 330 },
          tabletStyles: bottomTabContainerStyle,
        }),
      }}
      onPressApprove={saveAction}
      onPressReject={saveAction}
      onPressReview={saveAction}
    />
  );

  const renderActionConfirmation = () => (
    <ConfirmBookingActionComponent
      action={action}
      handleClose={toggleActionConfirmation}
      bookingId={bookingId}
      navigateToBooking={toggleActionConfirmation}
      updateBookingDetails={fetchBookingDetails}
    />
  );

  return (
    <div className="applicants-details-container">
      <DrawerComponent
        menus={drawerMenu}
        selectedMenuId={currentMenuId}
        children={
          bookingId && (
            <BookingUserDetails
              isEditable={isBookingEditable}
              details={bookingDetails}
              status={bookingStatus}
              documents={documents}
              documentPdf={bookingDocumentPDF}
              comments={comments}
              onClickAddNote={addNote}
              isAddNoteLoading={isAddNoteLoading}
            />
          )
        }
        drawerContent={
          currentMenuId === PROJECT_AND_UNIT
            ? renderProjectAndUnit()
            : currentMenuId === LIST_PRICE
            ? renderListPrice()
            : currentMenuId === APPLICANT_DETAILS
            ? renderApplicants()
            : currentMenuId === PAYMENT_DETAILS
            ? renderPaymentDetails()
            : currentMenuId === HOME_LOAN
            ? renderHomeLoanForm()
            : currentMenuId === LEGAL
            ? renderLegalForm()
            : currentMenuId === NOTES
            ? renderNotes()
            : renderTermsAndConditions()
        }
        bottomComponent={
          isBookingEditable
            ? renderBottomBar()
            : Boolean(isProjectHead) &&
            (bookingStatus === Status.SALES_APPROVAL || bookingStatus === Status.SALES_MANAGER_REVIEW) &&
              renderActions()
        }
        styles={{
          drawerContainer: {
            display: "flex",
            flex: 1,
          },
        }}
        setSelectedMenuId={(id) => setCurrentMenuId(id)}
        subMenuTitle={t("create_booking.drawer_sub_menu_title")}
        selectedSubMenu={selectedCard}
        setSelectedSubMenu={(cardId) => setSelectedCard(cardId)}
        isMandatoryFieldCompleted={isMandatoryFieldCompleted}
        isError={isTabHasError}
        hideBottomBar={
          Boolean(isProjectHead)
          ? (bookingStatus !== Status.SALES_APPROVAL && bookingStatus !== Status.SALES_MANAGER_REVIEW) && !isBookingEditable
          : !isBookingEditable
      }      
        isHighMarginRequired={
          Boolean(projectAndUnits?.unit) &&
          !getBookingCommentsResponse?.data?.length
        }
        isVeryHighMarginRequired={getBookingCommentsResponse?.data?.length}
      />
      <CustomizedDialogs
        handleOpenAndClose={onClickCancel}
        open={isDeleteModalVisible}
        subTitle={t("create_booking.delete_dialog_title")}
        description={t("create_booking.delete_dialog_description")}
        primaryButtonTitle={t("app_common.cancel")}
        secondaryButtonTitle={t("app_common.delete")}
        onClickSecondaryButton={onClickDelete}
        onClickPrimaryButton={onClickCancel}
      />
      <MaterialDialog
        children={renderBookingPreview()}
        sx={materialModalProps}
        open={showPreview}
        onClose={toggleBookingPreviewModal}
        maxWidth={"lg"}
      />
      <MaterialModal
        children={renderNonEditableMessageModal()}
        sx={nonEditableModalProps}
        open={showNonEditableMessage}
        onClose={() => setShowNonEditableMessage(true)}
      />
      <MaterialModal
        open={!bookingId}
        sx={materialModalProps}
        children={renderLoader()}
      />
      <MaterialModal
        open={isActionConfirmed}
        sx={materialModalProps}
        children={renderActionConfirmation()}
      />

      <ToastContainer />
    </div>
  );
};
export default BookingForm;

import { Box } from "@mui/material";
import Colors from "../../utils/theme/color";
import { CustomizedText } from "../styled-componnets";
import SelectedFile from "../../pages/create-booking/selected-file";

const BookingNotes = ({
  notes,
  signatures,
}: {
  notes: string;
  signatures: { name: string }[];
}) => {
  return (
    <Box sx={{ padding: 5, backgroundColor: Colors.cyanColor }}>
      <CustomizedText
        fontSize={20}
        fontColor={Colors.titleColor}
        font={"Lora-bold"}
      >
        {notes}
      </CustomizedText>
      {signatures?.map((signature) => (
        <SelectedFile
          fileName={signature.name}
          styles={{
            container: { width: "40%", marginTop: 10 },
          }}
          isCloseIconRequired={false}
          file={signature}
        />
      ))}
    </Box>
  );
};

export default BookingNotes;

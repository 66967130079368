import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { useTranslation } from "react-i18next";
import { Box, TableCell } from "@mui/material";
import Colors from "../../utils/theme/color";
import {
  CustomizedTableBodyCell,
  CustomizedTableBodyRow,
  CustomizedTableContainer,
  CustomizedTableHeaderCell,
} from "./styled-component";
import { getColorBasedOnWorkflowState } from "../../utils/functions";
import MultipleSelectCheckmark from "../customized-drop-down";
import { CustomizedText, StatusBox } from "../styled-componnets";
import ThreeDotMenu from "../three-dot-menu";
import { APP_CONSTANTS } from "../../constants";
import { Status } from "../../constants/statuses";
import MaterialButton from "../button";
import { ListColumnType, RowColumnType } from "../../utils/interface";

interface ColumnGroupingTableTypes {
  rows: RowColumnType[];
  columns?: ListColumnType[];
  onUpdateValue?: (values: string[] | Status[], label: string) => void;
  handlePageChange(page: number, size: number): void;
  paginationData: { page: number; size: number };
  count?: number;
  onClickTableRow?: (id: string) => void;
  isPaginationInitialStage?: boolean;
  onClickSingleOption?: (id?: string, row?: RowColumnType) => void;
  onClickEdit?: (id?: string) => void;
  paginationInitialized?: boolean;
  onClickApproveButton?(name: string): void;
}
const STATUS = "status";
export default function ColumnGroupingTable({
  rows = [],
  columns = [],
  onUpdateValue = () => {},
  handlePageChange = () => {},
  paginationData = { page: 1, size: 6 },
  count = 0,
  onClickTableRow = () => {},
  isPaginationInitialStage = false,
  onClickSingleOption = () => {},
  onClickEdit = () => {},
  onClickApproveButton = () => {},
}: ColumnGroupingTableTypes) {
  const [pagination, setPagination] = React.useState<{
    page: number;
    size: number;
  }>({
    page: paginationData.page,
    size: paginationData.size,
  });
  const [tableColumns, setTableColumn] = React.useState<ListColumnType[]>([]);
  const [tableRows, setTableRows] = React.useState<RowColumnType[]>([]);
  const [filterValues, setFilterValues] = React.useState<
    { label: string; values: string[] | Status[] }[]
  >([]);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (
      !Object.is(pagination, {
        page: APP_CONSTANTS.defaultPage,
        size: APP_CONSTANTS.defaultPageSize,
      })
    ) {
      handlePageChange(pagination.page, pagination.size);
    }
    // eslint-disable-next-line
  }, [pagination]);

  React.useEffect(() => {
    setTableRows(rows);
  }, [rows]);

  React.useEffect(() => {
    setPagination({
      page: paginationData?.page,
      size: paginationData?.size,
    });
  }, [paginationData?.page, paginationData?.size, isPaginationInitialStage]);

  const handleChangePage = React.useCallback(
    (event: unknown, newPage: number) => {
      setPagination({
        ...pagination,
        page: newPage + 1,
      });
    },
    [pagination]
  );

  const handleChangeRowsPerPage = React.useCallback(
    (event: { target: { value: string | number } }) => {
      setPagination({
        page: 1,
        size: +event.target.value,
      });
    },
    []
  );

  React.useEffect(() => {
    setTableColumn(columns);
  }, [columns]);

  const isColumnShowsStatus = (column: { id: string }) => column?.id === STATUS;

  const toggleMultiSelectionDropdown = React.useCallback(
    (index: number) => {
      if (index) {
        let newColumns = [...tableColumns];
        newColumns[index].isMultiSelectionDropdownOpen =
          !newColumns[index].isMultiSelectionDropdownOpen;
        setTableColumn(newColumns);
      }
    },
    [tableColumns]
  );

  const getLabelToDisplay = React.useCallback(
    (page: { from: number; to: number; count: any }) => {
      let label = "";
      label = `${`${pagination.page}${
        page?.count > 0
          ? page?.count % pagination.size > 0
            ? `${` of ${Math.trunc(page?.count / pagination.size) + 1}`}`
            : `${` of ${page?.count / pagination.size}`}`
          : ""
      }`}`;
      return label;
    },
    [pagination.size, pagination.page]
  );

  const onUpdateMultiSelect = React.useCallback(
    (values: string[] | Status[], label: string) => {
      onUpdateValue(values, label);
      setPagination({
        page: APP_CONSTANTS.defaultPage,
        size: APP_CONSTANTS.defaultPageSize,
      });
      let filterValueIndex = filterValues?.findIndex(
        (value) => value?.label === label
      );
      if (filterValueIndex > -1) {
        filterValues[filterValueIndex].label = label;
        filterValues[filterValueIndex].values = values;
      } else {
        setFilterValues([
          ...filterValues,
          {
            label,
            values,
          },
        ]);
      }
    },
    [onUpdateValue, filterValues]
  );
  const renderTableHeaderColumns = () =>
    tableColumns.map((column, index) => {
      return (
        <CustomizedTableHeaderCell
          key={`${index}${column?.id}`}
          align={column?.align}
        >
          {column?.isMultiSelectionEnabled ? (
            <MultipleSelectCheckmark
              selectedIndex={index}
              label={column?.label}
              values={column?.values}
              onUpdate={(value) => onUpdateMultiSelect(value, column?.label)}
              isOpen={tableColumns[index].isMultiSelectionDropdownOpen}
              handleClose={toggleMultiSelectionDropdown}
              handleOpen={toggleMultiSelectionDropdown}
              selectedFilterValues={filterValues}
            />
          ) : (
            <>{column?.label}</>
          )}
        </CustomizedTableHeaderCell>
      );
    });

  const renderTableBody = () =>
    tableRows.map((row, index) => {
      return (
        <CustomizedTableBodyRow
          role="checkbox"
          tabIndex={-1}
          key={`${row.id}${index}`}
        >
          {tableColumns.map((column, index) => {
            const value = row[column?.id];
            return (
              <CustomizedTableBodyCell
                key={`${column?.id}${index}`}
                align={column?.align}
              >
              {column?.isMenuRequired &&
              (row?.status === Status.SALES_APPROVAL || row?.status === Status.SALES_MANAGER_REVIEW) ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <MaterialButton
                      id="send-otp"
                      title={"Approve"}
                      onClick={() => onClickApproveButton(row?.id)}
                      fontSize={16}
                      size={"small"}
                      sx={{ mr: 3 }}
                    />
                    <ThreeDotMenu
                      menuItem={column?.moreOptions}
                      onClickSelectedMenu={(id) => onClickSingleOption(id, row)}
                      disabled={row?.status !== Status.SALES_APPROVAL && row?.status !== Status.SALES_MANAGER_REVIEW}
                    />
                  </Box>
                ) : isColumnShowsStatus(column) && value ? (
                  <StatusBox
                    sx={{
                      m: 0,
                      color: getColorBasedOnWorkflowState(value),
                      cursor: "pointer",
                    }}
                    onClick={() => onClickTableRow(row?.id)}
                  >
                    {value}
                  </StatusBox>
                ) : column?.isEditIconVisible ? (
                  <ModeEditOutlinedIcon
                    htmlColor={
                      row?.disabled ? Colors.lightGreyColor : Colors.titleColor
                    }
                    fontSize={"small"}
                    sx={{ cursor: "pointer" }}
                    {...(!row?.disabled && {
                      onClick: () => onClickEdit(row?.id),
                    })}
                  />
                ) : (
                  <CustomizedText
                    onClick={() => onClickTableRow(row?.id)}
                    style={{ cursor: "pointer" }}
                    fontSize={16}
                    font={"Lato-Regular"}
                  >
                    {value}
                  </CustomizedText>
                )}
              </CustomizedTableBodyCell>
            );
          })}
        </CustomizedTableBodyRow>
      );
    });
  return (
    <>
      <CustomizedTableContainer sx={{ m: 0 }}>
        <Table
          sx={{
            backgroundColor: Colors.cyanBlueColor,
          }}
        >
          <TableHead>
            <TableRow>{renderTableHeaderColumns()}</TableRow>
          </TableHead>
          <TableBody>
            {tableRows?.length ? (
              renderTableBody()
            ) : (
              <TableRow>
                <TableCell
                  colSpan={10}
                  sx={{ textAlign: "center", color: Colors.greyColor }}
                >
                  {t("app_common.no_records_found")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CustomizedTableContainer>
      <TablePagination
        rowsPerPageOptions={[6, 10, 20]}
        component={"div"}
        count={count}
        rowsPerPage={pagination.size}
        page={pagination.page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton={pagination.page !== 1}
        showLastButton={true}
        sx={{ color: Colors.titleColor, fontFamily: "Lato-Regular" }}
        labelDisplayedRows={getLabelToDisplay}
        backIconButtonProps={{ disabled: pagination.page === 1 }}
      />
    </>
  );
}

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { toast } from "react-toastify";
import {
  ActionInnerContainer,
  ButtonContainer,
  CloseButtonContainer,
  ConfirmBookingActionContainer,
  UnitAndCustomerDetailsContainer,
} from "./styled-components";
import { CustomizedLink, CustomizedText } from "../styled-componnets";
import Colors from "../../utils/theme/color";
import { useTranslation } from "react-i18next";
import TextInput from "../text-input/text-input";
import { Action } from "../../constants/action";
import MaterialButton from "../button";
import { FC, useCallback, useEffect, useState } from "react";
import {
  useGetBookingDetailsByIdQuery,
  useUpdateBookingActionsMutation,
  useUpdateBookingCommentsMutation,
} from "../../../features/api/booking-api-slice";
import {
  useRedirectToLogin,
  useGetUserDetailsHooks,
  useScreenSizeApplier,
} from "../../hooks";
import { IAction } from "../../pages/create-booking/booking-form";
import { EMPTY_OVER_VIEW_DETAILS } from "../../utils/booking-utils";
import { API_CONSTANTS } from "../../constants";

interface ConfirmBookingActionProps {
  action: IAction;
  handleClose: () => void;
  bookingId?: string;
  navigateToBooking: (bookingId?: string) => void;
  updateBookingDetails: () => void;
}
interface BookingDetailsProp {
  firstName: string;
  lastName: string;
  project: string;
  unit: string;
  applicant: { applicant_name: string; last_name: string };
}
const ERROR = "error";
const SUCCESS = "success";

const ConfirmBookingActionComponent: FC<ConfirmBookingActionProps> = ({
  action,
  handleClose,
  bookingId,
  navigateToBooking,
  updateBookingDetails,
}) => {
  const { t } = useTranslation();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();
  const { getUserToken } = useGetUserDetailsHooks();
  const { mergeStyles } = useScreenSizeApplier();

  const [comment, setComment] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isActionConfirmed, setIsActionConfirmed] = useState(false);
  const [bookingDetails, setBookingDetails] = useState<BookingDetailsProp>(
    EMPTY_OVER_VIEW_DETAILS
  );
  const [isBookingDetailsLoading, setIsBookingDetailsLoading] =
    useState<boolean>(true);

  const {
    data: getBookingDetailsByIdResponse,
    isSuccess: isGetBookingDetailsByIdSuccess,
    isError: isGetBookingDetailsByIdFailed,
    error: getBookingDetailsByIdError,
    refetch: refetchBookingDetails,
    isFetching: isBookingDetailsFetching,
  } = useGetBookingDetailsByIdQuery(
    {
      bookingId,
      userToken: getUserToken(),
    },
    { skip: !Boolean(bookingId) }
  );

  const [
    updateBookingComments,
    {
      isSuccess: isUpdateBookingCommentsSuccess,
      isError: isUpdateBookingCommentsFailed,
      data: updateBookingCommentsResponse,
      error: updateBookingCommentsError,
    },
  ] = useUpdateBookingCommentsMutation();

  const [
    updateBookingActions,
    {
      isSuccess: isUpdateBookingActionsSuccess,
      isError: isUpdateBookingActionsFailed,
      data: updateBookingActionsResponse,
      error: updateBookingActionsError,
    },
  ] = useUpdateBookingActionsMutation();

  const updateStatusOfBooking = useCallback(() => {
    setIsActionConfirmed(true);
    const payload = {
      booking_id: bookingId,
      action: action?.action,
      next_workflow_state: action.next_state,
    };
    updateBookingActions({ userToken: getUserToken(), payload });
  }, [
    action.action,
    action.next_state,
    bookingId,
    updateBookingActions,
    getUserToken,
  ]);

  useEffect(() => {
    if (isBookingDetailsLoading && !isBookingDetailsFetching) {
      if (isGetBookingDetailsByIdSuccess) {
        if (getBookingDetailsByIdResponse?.data?.name === bookingId) {
          const bookingDetails = getBookingDetailsByIdResponse?.data;
          setBookingDetails({
            firstName: bookingDetails?.first_name,
            lastName: bookingDetails?.last_name,
            project: bookingDetails?.project,
            unit: bookingDetails?.unit,
            applicant: bookingDetails?.applicants[0],
          });
        } else if (getBookingDetailsByIdResponse?.message) {
          toast(getBookingDetailsByIdResponse?.message, {
            type: ERROR,
          });
          setBookingDetails(EMPTY_OVER_VIEW_DETAILS);
        }
        setIsBookingDetailsLoading(false);
      } else if (isGetBookingDetailsByIdFailed) {
        toast(t("error_messages.get_unit_details_error_message"), {
          type: ERROR,
        });
        redirectToLoginBasedOnResponse(
          getBookingDetailsByIdError,
          getBookingDetailsByIdResponse
        );
        setBookingDetails(EMPTY_OVER_VIEW_DETAILS);
        setIsBookingDetailsLoading(false);
      }
    }

    // eslint-disable-next-line
  }, [
    getBookingDetailsByIdResponse,
    isGetBookingDetailsByIdSuccess,
    isGetBookingDetailsByIdFailed,
    t,
    getBookingDetailsByIdError,
    bookingId,
    isBookingDetailsFetching,
    isBookingDetailsLoading,
  ]);

  useEffect(() => {
    if (isButtonLoading) {
      if (isUpdateBookingCommentsSuccess) {
        if (updateBookingCommentsResponse?.data) {
          setIsButtonLoading(false);
          updateStatusOfBooking();
        } else if (updateBookingCommentsResponse?.message) {
          setIsButtonLoading(false);
        }
      } else if (isUpdateBookingCommentsFailed) {
        redirectToLoginBasedOnResponse(
          updateBookingCommentsError,
          updateBookingCommentsResponse
        );
        setIsButtonLoading(false);
      }
    }
    //eslint - disable - next - line
  }, [
    isButtonLoading,
    isUpdateBookingCommentsSuccess,
    isUpdateBookingCommentsFailed,
    updateBookingCommentsResponse,
    updateBookingCommentsError,
    bookingId,
    updateStatusOfBooking,
    redirectToLoginBasedOnResponse,
  ]);

  useEffect(() => {
    if (isActionConfirmed) {
      if (isUpdateBookingActionsSuccess) {
        if (
          updateBookingActionsResponse?.data?.booking_id &&
          updateBookingActionsResponse?.status_code ===
            API_CONSTANTS.successStatusCode
        ) {
          handleClose();
          toast(updateBookingActionsResponse?.message, {
            type: SUCCESS,
          });
          setIsActionConfirmed(false);
          if (!isBookingDetailsFetching) {
            refetchBookingDetails();
            updateBookingDetails();
          }
        } else if (updateBookingActionsResponse?.message) {
          toast(updateBookingActionsResponse?.message, {
            type: ERROR,
          });
          setIsActionConfirmed(false);
        }
      } else if (isUpdateBookingActionsFailed) {
        redirectToLoginBasedOnResponse(
          updateBookingActionsError,
          updateBookingActionsResponse
        );
        setIsActionConfirmed(false);
      }
    }
    //eslint-disable-next-line
  }, [
    isButtonLoading,
    isUpdateBookingActionsSuccess,
    isUpdateBookingActionsFailed,
    updateBookingActionsResponse,
    updateBookingActionsError,
    isActionConfirmed,
    bookingId,
    updateBookingCommentsResponse,
    handleClose,
    refetchBookingDetails,
  ]);

  useEffect(() => {
    refetchBookingDetails();
  }, [refetchBookingDetails]);

  const onChangeComment = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setComment(event.target.value);
    },
    []
  );

  const onClickConfirmAction = useCallback(() => {
    setIsButtonLoading(true);
    const payload = {
      booking_id: bookingId,
      comment,
    };
    updateBookingComments({ userToken: getUserToken(), payload });
  }, [bookingId, comment, updateBookingComments, getUserToken]);

  const renderAction = () => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <CustomizedText fontSize={24} fontColor={Colors.titleColor}>
        {t("action_confirmation.action_title", { action: action?.action })}
      </CustomizedText>
      {action?.action === Action.REVIEW ? (
        <FindInPageIcon
          fontSize={"small"}
          sx={{ color: Colors.carrotOrange, ml: 1 }}
        />
      ) : action?.action === Action.REJECT ? (
        <CancelRoundedIcon
          fontSize={"small"}
          sx={{ color: Colors.redColor, ml: 1 }}
        />
      ) : (
        <CheckCircleRoundedIcon
          fontSize={"small"}
          sx={{ color: Colors.greenColor, ml: 1 }}
        />
      )}
    </Box>
  );

  const renderUnit = () => (
    <CustomizedText fontSize={28} fontColor={Colors.primaryColor}>
      {bookingDetails?.unit}
    </CustomizedText>
  );

  const renderApplicantAndLead = () => (
    <Box sx={{ display: "flex" }}>
      {bookingDetails?.firstName && (
        <>
          <Tooltip title={"Lead"} arrow placement="right-start">
            <AccountCircleOutlinedIcon
              htmlColor={Colors.primaryColor}
              fontSize={"small"}
              sx={mergeStyles({
                tabletStyles: { fontSize: 14 },
              })}
            />
          </Tooltip>
          <CustomizedText fontSize={18} sx={{ paddingLeft: 1 }}>
            {`${bookingDetails?.firstName} ${bookingDetails?.lastName}`}
          </CustomizedText>
        </>
      )}
      {bookingDetails?.applicant?.applicant_name && (
        <>
          <Tooltip title={"Primary Applicant"} arrow placement="right-start">
            <AccountCircleOutlinedIcon
              htmlColor={Colors.primaryColor}
              fontSize={"small"}
              sx={mergeStyles({
                styles: { paddingLeft: bookingDetails?.firstName ? 2 : 0 },
                tabletStyles: { fontSize: 14 },
              })}
            />
          </Tooltip>
          <CustomizedText fontSize={18} sx={{ paddingLeft: 1 }}>
            {`${bookingDetails?.applicant?.applicant_name} ${bookingDetails?.applicant?.last_name}`}
          </CustomizedText>
        </>
      )}
    </Box>
  );

  const renderYourComments = () => (
    <Box sx={{ marginRight: 2.5 }}>
      <TextInput
        id="comment"
        value={comment}
        label={!comment ? t("action_confirmation.comment_placeholder") : ""}
        size="medium"
        onChange={onChangeComment}
        outerLabel={t("action_confirmation.your_comment")}
        markRequiredField={true}
        multiline={true}
        styles={{
          outerLabel: {
            marginTop: "1rem",
          },
        }}
        rows={4}
      />
    </Box>
  );

  const renderButton = () => (
    <ButtonContainer>
      <CustomizedLink onClick={() => navigateToBooking(bookingId)}>
        {t("action_confirmation.view_booking_details")}
      </CustomizedLink>
      <MaterialButton
        title={t("action_confirmation.submit")}
        id={"apply-filter-button"}
        onClick={onClickConfirmAction}
        disabled={isButtonLoading || !Boolean(comment)}
        isLoading={isButtonLoading}
      />
    </ButtonContainer>
  );

  const renderCloseButton = () => (
    <CloseButtonContainer
      marginRight={0.5}
      padding={2}
      paddingBottom={0}
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" htmlColor={Colors.titleColor} />
    </CloseButtonContainer>
  );

  return (
    <ConfirmBookingActionContainer>
      {renderCloseButton()}
      <ActionInnerContainer>
        {renderAction()}
        <UnitAndCustomerDetailsContainer>
          {bookingDetails?.unit ? (
            <>
              <CustomizedText
                fontSize={14}
                fontColor={Colors.greyColor}
                font={"Lato-Regular"}
              >
                {t("action_confirmation.unit_and_customer_details")}
              </CustomizedText>
              {renderUnit()}
              {renderApplicantAndLead()}
            </>
          ) : (
            <CircularProgress
              size={"1rem"}
              sx={{ color: Colors.primaryColor }}
            />
          )}
        </UnitAndCustomerDetailsContainer>
        {renderYourComments()}
      </ActionInnerContainer>
      {renderButton()}
    </ConfirmBookingActionContainer>
  );
};

export default ConfirmBookingActionComponent;

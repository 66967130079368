import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Colors from "../../utils/theme/color";
import "./applicant-form.css";
import { CustomizedText } from "../../components/styled-componnets";
import MaterialDialog from "../../components/dialog";
import DocumentPreview from "../../components/documents-preview";
import { useCallback, useState } from "react";
import { Box } from "@mui/system";

interface ApplicantPhotoTypes {
  fileName?: string;
  removePhoto?(): void;
  styles?: { container?: {} };
  isCloseIconRequired?: boolean;
  file?: { attachment?: string; name?: string };
}
const materialModalProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};
const SelectedFile = ({
  fileName = "",
  removePhoto = () => {},
  styles,
  isCloseIconRequired = true,
  file = { attachment: "", name: "" },
}: ApplicantPhotoTypes) => {
  const [isFileModalVisible, setIsFileModalVisible] = useState<boolean>(false);

  const toggleFilePreview = useCallback(
    () => setIsFileModalVisible(!isFileModalVisible),
    [isFileModalVisible]
  );

  const openDocuments = useCallback(
    () => setIsFileModalVisible(Boolean(file?.name)),
    [file]
  );

  const renderFiles = () => (
    <DocumentPreview
      documents={[file]}
      closeDocumentPreview={toggleFilePreview}
    />
  );

  return (
    <div
      className="selected-file-container"
      style={{ ...{ width: "50%" }, ...styles?.container }}
    >
      <PhotoOutlinedIcon sx={{ color: Colors.titleColor }} />
      <CustomizedText
        fontSize={16}
        font={"Lato-Regular"}
        sx={{ mr: 1, ml: 1, width: "50%" }}
      >
        {fileName}
      </CustomizedText>
      <Box sx={{ display: "flex" }}>
        <IconButton
          children={<VisibilityIcon fontSize="small" onClick={openDocuments} />}
          sx={{
            color: Colors.greyColor,
            cursor: "pointer",
            padding: 0,
          }}
          onClick={openDocuments}
        />
        {isCloseIconRequired && (
          <IconButton
            children={<CloseIcon sx={{ marginLeft: 1 }} fontSize="small" />}
            sx={{
              color: Colors.greyColor,
              cursor: "pointer",
              padding: 0,
            }}
            onClick={removePhoto}
          />
        )}
      </Box>
      <MaterialDialog
        children={renderFiles()}
        sx={materialModalProps}
        open={isFileModalVisible}
        onClose={toggleFilePreview}
        maxWidth={"lg"}
      />
    </div>
  );
};
export default SelectedFile;

import { useCallback, useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import BookingDetailsTitle from "./booking-details-title";
import TextInput from "../../components/text-input/text-input";
import { CardContainer, StyledCard } from "./styled-component";
import { VALIDATION_REGEXPS } from "../../constants";
import { useScreenSizeApplier } from "../../hooks";
import { EMPTY_LEGAL_DETAILS, LegalFormTypes } from "../../utils/booking-utils";

const LegalForm = ({
  legalData,
  onUpdate,
  bookingId = "",
  isEditable = true,
}: LegalFormTypes) => {
  const { t } = useTranslation();
  const [legalDetails, setLegalDetails] = useState(legalData);
  const legalFormRef = useRef(legalDetails);
  const { mergeStyles } = useScreenSizeApplier();

  useEffect(() => {
    if (!Object.is(legalDetails, EMPTY_LEGAL_DETAILS)) {
      legalFormRef.current = legalDetails;
      onUpdate(legalDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalDetails]);

  useEffect(() => {
    setLegalDetails(legalData);
  }, [legalData]);

  const onChangePowerOfAttorneyHolderName = useCallback(
    (e: { target: { value: string } }) => {
      setLegalDetails({
        ...legalFormRef?.current,
        powerOfAttorneyHolderName: e.target.value.replace(
          VALIDATION_REGEXPS.matchesAnyCharacters,
          ""
        ),
        phoneErrorMsg: "",
        emailErrorMsg: "",
        nameErrorMsg: "",
      });
    },
    [setLegalDetails]
  );

  const onChangePowerOfAttorneyHolderEmailId = useCallback(
    (e: { target: { value: string } }) => {
      setLegalDetails({
        ...legalFormRef?.current,
        powerOfAttorneyHolderEmailId: e.target.value.trim(),
        emailErrorMsg: "",
      });
    },
    [setLegalDetails]
  );

  const onChangePowerOfAttorneyHolderPhone = useCallback(
    (e: { target: { value: string } }) => {
      setLegalDetails({
        ...legalFormRef?.current,
        powerOfAttorneyHolderPhone: `${e.target.value.replace(
          VALIDATION_REGEXPS.matchesAnyNumbers,
          ""
        )}`,
        phoneErrorMsg: "",
      });
    },
    [setLegalDetails]
  );

  const onChangePowerOfAttorneyHolderAddress = useCallback(
    (e: { target: { value: string } }) => {
      setLegalDetails({
        ...legalFormRef?.current,
        powerOfAttorneyHolderAddress: e.target.value,
      });
    },
    [setLegalDetails]
  );

  const renderPowerAttorneyHolderName = () => (
    <>
      <TextInput
        id="power-of-attorney-account-holder-name"
        value={legalDetails?.powerOfAttorneyHolderName}
        label={
          !legalFormRef?.current.powerOfAttorneyHolderName
            ? t(
                "create_booking.legal.power_of_attorney_holder_name_placeholder"
              )
            : ""
        }
        onChange={onChangePowerOfAttorneyHolderName}
        outerLabel={t("create_booking.legal.power_of_attorney_holder_name")}
        markRequiredField={false}
        error={Boolean(legalDetails?.nameErrorMsg)}
        helperText={legalDetails?.nameErrorMsg}
        disabled={!isEditable}
      />
      <TextInput
        id="power-of-attorney-account-holder-email"
        value={legalDetails?.powerOfAttorneyHolderEmailId}
        label={
          !legalFormRef?.current.powerOfAttorneyHolderEmailId
            ? t(
                "create_booking.legal.power_of_attorney_holder_email_placeholder"
              )
            : ""
        }
        onChange={onChangePowerOfAttorneyHolderEmailId}
        outerLabel={t("create_booking.legal.power_of_attorney_holder_email")}
        markRequiredField={false}
        error={Boolean(legalDetails?.emailErrorMsg)}
        helperText={legalDetails?.emailErrorMsg}
        disabled={!isEditable}
      />
      <TextInput
        id="power-of-attorney-account-holder-phone"
        value={legalDetails?.powerOfAttorneyHolderPhone}
        label={
          !legalFormRef?.current.powerOfAttorneyHolderPhone
            ? t(
                "create_booking.legal.power_of_attorney_holder_phone_placeholder"
              )
            : ""
        }
        onChange={onChangePowerOfAttorneyHolderPhone}
        outerLabel={t("create_booking.legal.power_of_attorney_holder_phone")}
        markRequiredField={false}
        error={Boolean(legalDetails?.phoneErrorMsg)}
        helperText={legalDetails?.phoneErrorMsg}
        disabled={!isEditable}
      />
    </>
  );

  const renderPowerAttorneyHolderAddress = () => (
    <TextInput
      id="power-of-attorney-account-holder-address"
      value={legalDetails?.powerOfAttorneyHolderAddress}
      label={
        !legalFormRef?.current.powerOfAttorneyHolderAddress
          ? t(
              "create_booking.legal.power_of_attorney_holder_address_placeholder"
            )
          : ""
      }
      onChange={onChangePowerOfAttorneyHolderAddress}
      outerLabel={t("create_booking.legal.power_of_attorney_holder_address")}
      markRequiredField={false}
      styles={{
        textInputContainer: mergeStyles({
          styles: { marginLeft: 40 },
          belowLargeStyles: { marginLeft: 15 },
        }),
      }}
      multiline
      size="medium"
      rows={4}
      disabled={!isEditable}
    />
  );

  return (
    <div className="details-container">
      <BookingDetailsTitle title={t("create_booking.legal.title")} />
      <StyledCard
        raised
        sx={{
          pl: 2,
          pr: 2,
          pt: 0,
        }}
      >
        <CardContainer sx={{ pt: 0 }}>
          <div className="booking-row-input-container">
            <CardContainer
              sx={mergeStyles({
                styles: { pl: 0, pb: 0, pt: 0 },
                belowLargeStyles: { pl: 2 },
              })}
            >
              {renderPowerAttorneyHolderName()}
            </CardContainer>
            {renderPowerAttorneyHolderAddress()}
          </div>
        </CardContainer>
      </StyledCard>
      <ToastContainer />
    </div>
  );
};

export default LegalForm;

import { apiSlice } from "./api-slice";
import {
  getListByNameOrPhoneNumber,
  getListByStatus,
} from "../../app/utils/functions";

export const BookingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createBooking: builder.mutation({
      query: ({ payload, userToken }) => ({
        url: `bren_erp.v8.booking.create_booking`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
      invalidatesTags: ["proposal", "Booking"],
    }),
    getBookingDetailsById: builder.query({
      query: ({ bookingId, userToken }) => ({
        url: `bren_erp.v8.booking.get_booking?booking_id=${bookingId}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        refetchOnMountOrArgChange: true,
        timeout: 60000,
      }),
    }),
    getBookingList: builder.query({
      query: ({ paginationInfo, selectedStatus, searchText, userToken }) => ({
        url: `bren_erp.v8.booking.get_bookings?page=${
          paginationInfo.page
        }&size=${paginationInfo.size}${getListByStatus(selectedStatus)}${
          searchText ? getListByNameOrPhoneNumber(searchText) : ""
        }`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    addApplicants: builder.mutation({
      query: ({ payload, userToken }) => ({
        url: `bren_erp.v8.booking.add_booking_applicants`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
    }),
    getBookingNotes: builder.query({
      query: ({ userToken, bookingId }) => ({
        url: `bren_erp.v8.booking.get_booking_notes_signature?booking_id=${bookingId}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        refetchOnMountOrArgChange: true,
      }),
    }),
    updateBookingNotes: builder.mutation({
      query: ({ userToken, payload }) => ({
        url: `bren_erp.v8.booking.update_booking_notes_signature`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
    }),
    fetchBookingActions: builder.query({
      query: ({ userToken, role, currentWorkFlow }) => ({
        url: `bren_erp.v8.booking.get_booking_actions_and_workflows?role=${role}&current_workflow_state=${currentWorkFlow}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    updateBookingComments: builder.mutation({
      query: ({ userToken, payload }) => ({
        url: `bren_erp.v8.booking.add_booking_comments`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
    }),
    updateBookingActions: builder.mutation({
      query: ({ userToken, payload }) => ({
        url: `bren_erp.v8.booking.update_workflow`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
    }),
    fetchBookingComments: builder.query({
      query: ({ userToken, bookingId }) => ({
        url: `bren_erp.v8.booking.get_booking_internal_comments?booking_id=${bookingId}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    fetchBookingDocument: builder.query({
      query: ({ userToken, bookingId }) => ({
        url: `bren_erp.v8.booking.generate_booking_pdf?booking_id=${bookingId}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    updateBooking: builder.mutation({
      query: ({ payload, userToken }) => ({
        url: `bren_erp.v8.booking.update_booking_v1`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
    }),
    deleteApplicants: builder.mutation({
      query: ({ payload, userToken }) => ({
        url: `bren_erp.v8.booking.remove_applicants`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
    }),
    fetchSalutation: builder.query({
      query: ({ userToken }: { userToken: string }) => ({
        url: `bren_erp.v8.booking.get_salutations`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
  }),
});

export const {
  useCreateBookingMutation,
  useGetBookingDetailsByIdQuery,
  useGetBookingListQuery,
  useUpdateBookingMutation,
  useAddApplicantsMutation,
  useUpdateBookingNotesMutation,
  useGetBookingNotesQuery,
  useFetchBookingActionsQuery,
  useUpdateBookingCommentsMutation,
  useUpdateBookingActionsMutation,
  useFetchBookingCommentsQuery,
  useFetchBookingDocumentQuery,
  useDeleteApplicantsMutation,
  useFetchSalutationQuery,
} = BookingApiSlice;

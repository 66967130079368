import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useTranslation } from "react-i18next";
import MaterialTabs from "../../components/tabs";
import Colors from "../../utils/theme/color";
import "./applicant-form.css";
import SelectedFile from "./selected-file";
import SignaturePadWithFileUpload from "../../components/signature-pad-with-file-upload";
import {
  ApplicantSignatureTypes,
  EMPTY_APPLICANT_DETAILS,
} from "../../utils/booking-utils";
import MaterialDialog from "../../components/dialog";
import { CustomizedLink } from "../../components/styled-componnets";
import { SignatureDialogBox, StyledCloseIcon } from "./styled-component";

const tabLabelStyle = {
  fontFamily: "Lato-Regular",
  fontSize: 16,
  color: Colors.titleColor,
};

const tabContainerStyle = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

const materialModalProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

const ApplicantSignature = ({
  signatureData = {},
  onUpdate = () => {},
  numberOfApplicant,
  isEditable = true,
  saveSignature = () => {},
}: ApplicantSignatureTypes) => {
  const { t } = useTranslation();
  const [signatureDetails, setSignatureDetails] = useState(signatureData);
  const signatureDetailsRef = useRef(signatureDetails);
  const [isSignatureCanvasVisible, setSignatureCanvasVisible] = useState(false);

  useEffect(() => {
    if (!Object.is(signatureDetails, EMPTY_APPLICANT_DETAILS)) {
      signatureDetailsRef.current = signatureDetails;
      onUpdate(signatureDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureDetails]);

  useEffect(() => {
    setSignatureDetails(signatureData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signatureTabItems = [
    { id: "DRAW", label: "DRAW" },
    { id: "FILE", label: "UPLOAD" },
  ];
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const materialTabProps = {
    backgroundColor: Colors.lightBlueColor,
    border: `10px solid ${Colors.veryLightBlueColor}`,
    borderRadius: 5,
    boxSizing: "border-box",
  };

  const onChangeTab = useCallback(
    (e: object, value: SetStateAction<number>) => {
      setSelectedTabIndex(value);
    },
    [setSelectedTabIndex]
  );

  const toggleSignatureCanvas = useCallback(
    () => setSignatureCanvasVisible(!isSignatureCanvasVisible),
    [isSignatureCanvasVisible]
  );

  const onUploadFile = useCallback(
    (fileData: string) => {
      saveSignature(
        "signature",
        fileData,
        t("create_booking.signature", {
          applicant: numberOfApplicant,
          type: "png",
        })
      );
      setSignatureDetails({
        ...signatureDetailsRef.current,
        signature: {
          name: t("create_booking.signature", {
            applicant: numberOfApplicant,
            type: "png",
          }),
          type: "png",
          data: fileData,
        },
      });
      toggleSignatureCanvas();
    },
    [numberOfApplicant, saveSignature, t, toggleSignatureCanvas]
  );

  const removeFile = useCallback(() => {
    setSignatureDetails({
      ...signatureDetailsRef.current,
      signature: {
        name: "",
        type: "",
        data: "",
        isDeleted: true,
      },
    });
  }, [setSignatureDetails]);

  const isFileUploadSelected = useMemo(
    () => selectedTabIndex === 1,
    [selectedTabIndex]
  );

  const renderTabPanel = () => (
    <SignaturePadWithFileUpload
      title={t("create_booking.applicant_signature.drop_signature")}
      description={t("create_booking.photo_type")}
      secondaryButtonTitle={t("create_booking.choose_file")}
      Icon={InsertDriveFileOutlinedIcon}
      onChangeUploadFile={onUploadFile}
      isFileUpload={isFileUploadSelected}
    />
  );

  const renderTitle = () => (
    <div className="photo-label-container">
      <p className="photo-label">
        {t("create_booking.applicant", {
          number: numberOfApplicant,
        })}
      </p>
      <p className="photo-label-required">*</p>
    </div>
  );

  const renderSelectedFile = () => (
    <SelectedFile
      fileName={signatureDetailsRef?.current.signature?.name}
      removePhoto={removeFile}
      isCloseIconRequired={isEditable}
      {...(!isEditable && {
        styles: {
          container: { justifyContent: "flex-start" },
        },
      })}
      file={signatureDetailsRef?.current.signature}
    />
  );

  const renderTab = () => (
    <SignatureDialogBox>
      <StyledCloseIcon
        sx={{
          color: Colors.greyColor,
          cursor: "pointer",
          alignSelf: "flex-end",
          marginBottom: 1,
        }}
        onClick={toggleSignatureCanvas}
      />
      <MaterialTabs
        tabItems={signatureTabItems}
        value={selectedTabIndex}
        TabIndicatorProps={{
          sx: {
            bgcolor: Colors.white,
          },
        }}
        tabLabelStyle={{
          ...tabLabelStyle,
        }}
        onChange={onChangeTab}
        sx={materialTabProps}
        selectedTabIndex={selectedTabIndex}
        styles={{
          selectedTabStyle: {
            backgroundColor: Colors.white,
            borderRadius: 5,
          },
          boxContainer: tabContainerStyle,
        }}
        isTabPanelRequired={true}
        tabContent={renderTabPanel()}
      />
    </SignatureDialogBox>
  );

  const renderOptionsToUploadFile = () =>
    !isSignatureCanvasVisible ? (
      <CustomizedLink onClick={toggleSignatureCanvas}>
        {t("create_booking.applicant_signature.add_signature")}
      </CustomizedLink>
    ) : (
      <MaterialDialog
        children={renderTab()}
        sx={materialModalProps}
        open={isSignatureCanvasVisible}
        onClose={toggleSignatureCanvas}
        maxWidth={"lg"}
      />
    );

  return (
    <div className="single-detail-container">
      {renderTitle()}
      {signatureDetailsRef?.current.signature?.name
        ? renderSelectedFile()
        : isEditable && renderOptionsToUploadFile()}
    </div>
  );
};
export default ApplicantSignature;

import {
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box, Container } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { ActionMeta } from "react-select";
import moment from "moment";
import { CustomizedDivider, CustomizedText } from "../styled-componnets";
import SiteVisitListTable from "./table";
import {
  BottomButtonContainer,
  SiteVisitContainer,
  SiteVisitTitleContainer,
  SiteVisitUpdateModal,
} from "./styled-components";
import MaterialButton from "../button";
import MaterialModal from "../modal";
import Colors from "../../utils/theme/color";
import DatePickerComponent from "../date-picker";
import {
  YYYY_MM_DD_WITH_HYPHEN,
  convertMinutesToHours,
  findTimeDifference,
  getTimeList,
  isTimeAfterCheckInTime,
  isTimeBetweenStartAndEndTime,
} from "../../utils/date";
import TextInput from "../text-input/text-input";
import Dropdown from "../dropdown";
import { useGetUserDetailsHooks } from "../../hooks";

export const materialModalProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "50%",
};
const CHECK_IN_START_TIME = "7:45 AM";
const CHECK_IN_END_TIME = "11:30 PM";

interface SiteVisitDetailsProps {
  addSiteVisitData: (data: {
    siteVisitDate: string;
    duration: string;
    checkInTime: string;
    checkOutTime: string;
    comment: string;
  }) => void;
  isButtonLoading: boolean;
  isAddSiteVisitRequired?: boolean;
}
const SiteVisitDetails: FC<SiteVisitDetailsProps> = ({
  addSiteVisitData,
  isButtonLoading,
  isAddSiteVisitRequired = true,
}) => {
  const { t } = useTranslation();
  const { isCurrentUserGRE } = useGetUserDetailsHooks();
  const { isCurrentUserCP} =  useGetUserDetailsHooks();

  const [isAddSiteVisitPopupVisible, setIsAddSiteVisitPopupVisible] =
    useState(false);
  const [siteVisitDate, setSiteVisitDate] = useState(
    moment(new Date()).format(YYYY_MM_DD_WITH_HYPHEN)
  );
  const [duration, setDuration] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");
  const [checkInTime, setCheckInTime] = useState("");
  const [comment, setComment] = useState("");

  const [checkOutTimeList, setCheckOutTimeList] = useState<any[]>([]);
  const [checkInTimeList, setCheckInTimeList] = useState<any[]>([]);

  useEffect(() => {
    if (Boolean(checkInTime) && Boolean(checkOutTime)) {
      const differenceBetweenCheckInAncOut = findTimeDifference(
        checkInTime,
        checkOutTime
      );
      const duration = convertMinutesToHours(differenceBetweenCheckInAncOut);
      setDuration(duration ? `${duration}h` : "");
    }
  }, [checkInTime, checkOutTime]);

  const getCheckInTimeList = () => {
    return getTimeList().reduce((acc: string[], time: string) => {
      if (
        isTimeBetweenStartAndEndTime(
          time,
          CHECK_IN_START_TIME,
          CHECK_IN_END_TIME
        )
      )
        acc.push(time);
      setCheckInTimeList(acc);
      return acc;
    }, []);
  };

  const getCheckOutTimeList = useCallback(() => {
    return getTimeList().reduce((acc: string[], time: string) => {
      if (isTimeAfterCheckInTime(time, checkInTime)) acc.push(time);
      setCheckOutTimeList(acc);
      return acc;
    }, []);
  }, [checkInTime]);

  useEffect(() => {
    if (Boolean(checkInTime)) {
      setDuration("");
      setCheckOutTime("");
      getCheckOutTimeList();
    }
  }, [checkInTime, getCheckOutTimeList]);

  useEffect(() => {
    getCheckInTimeList();
  }, []);

  const toggleAddSiteVisitPopUp = useCallback(
    () => setIsAddSiteVisitPopupVisible(!isAddSiteVisitPopupVisible),
    [isAddSiteVisitPopupVisible]
  );

  const onChangeDate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSiteVisitDate(e.target.value);
  }, []);

  const onChangeValue = (
    newValue: any,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>,
    callBack: {
      (value: SetStateAction<string>): void;
      (value: SetStateAction<string>): void;
      (arg0: string): void;
    }
  ) => {
    callBack(newValue?.label);
  };

  const onChangeComment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setComment(e.target.value);
    },
    []
  );

  const isButtonEnabled = useMemo(
    () =>
      Boolean(checkInTime) &&
      Boolean(checkOutTime) &&
      Boolean(comment) &&
      Boolean(duration),
    [checkInTime, checkOutTime, comment, duration]
  );

  const onClickInsert = useCallback(() => {
    let siteVisitData = {
      siteVisitDate,
      duration,
      checkInTime,
      checkOutTime,
      comment,
    };
    toggleAddSiteVisitPopUp();
    addSiteVisitData(siteVisitData);
    setDuration("");
    setCheckInTime("");
    setCheckOutTime("");
    setComment("");
  }, [
    addSiteVisitData,
    checkInTime,
    checkOutTime,
    comment,
    duration,
    siteVisitDate,
    toggleAddSiteVisitPopUp,
  ]);

  const returnLabelAndValue = (times: string[]) =>
    times?.map((item: string) => ({ value: item, label: item }));

  const renderTitle = () => (
    <CustomizedText fontSize={28} gutterBottom font={"Lato-Regular"}>
      {t("opportunity_details.sales_person_site_visit")}
    </CustomizedText>
  );

  const renderAddSiteVisitButton = () => (
    <MaterialButton
      title={t("opportunity_details.add_site_visit")}
      id={"add-site-visit-button"}
      onClick={toggleAddSiteVisitPopUp}
    />
  );

  const renderCloseButton = () => (
    <CloseIcon
      fontSize="small"
      htmlColor={Colors.titleColor}
      onClick={toggleAddSiteVisitPopUp}
    />
  );

  const renderDateAndDuration = () => (
    <Container
      disableGutters
      sx={{
        m: 0,
        display: "flex",
      }}
    >
      <DatePickerComponent
        date={siteVisitDate}
        onChangeDate={onChangeDate}
        outerLabel={t("opportunity_details.site_visit_date")}
        markRequiredField={true}
        maxDate={moment(new Date()).format(YYYY_MM_DD_WITH_HYPHEN)}
        disabled={true}
      />
      <div style={{ width: "10%" }} />
      <TextInput
        id="duration-input"
        value={duration}
        outerLabel={t("opportunity_details.duration")}
        disabled={true}
        markRequiredField={false}
      />
    </Container>
  );

  const renderCheckInCheckoutTime = () => (
    <Container
      disableGutters
      sx={{
        m: 0,
        display: "flex",
      }}
    >
      <Dropdown
        outerLabel={t("opportunity_details.check_in_time")}
        markRequiredField={true}
        dropdownItems={returnLabelAndValue(checkInTimeList)}
        inputLabel={t("opportunity_details.check_in_time_placeholder")}
        value={checkInTime}
        handleChange={(newValue, metaValue) =>
          onChangeValue(newValue, metaValue, setCheckInTime)
        }
      />
      <div style={{ width: "10%" }} />
      <Dropdown
        outerLabel={t("opportunity_details.check_out_time")}
        markRequiredField={true}
        dropdownItems={returnLabelAndValue(checkOutTimeList)}
        inputLabel={t("opportunity_details.check_out_time_placeholder")}
        value={checkOutTime}
        handleChange={(newValue, metaValue) =>
          onChangeValue(newValue, metaValue, setCheckOutTime)
        }
      />
    </Container>
  );

  const renderCommentBox = () => (
    <TextInput
      id="comment-input"
      value={comment}
      label={
        comment ? "" : t("opportunity_details.site_visit_comment_placeholder")
      }
      outerLabel={t("opportunity_details.site_visit_comment")}
      markRequiredField={true}
      multiline={true}
      rows={4}
      onChange={onChangeComment}
    />
  );

  const renderButton = () => (
    <BottomButtonContainer>
      <MaterialButton
        title={t("opportunity_details.insert")}
        id={"insert-button"}
        onClick={onClickInsert}
        disabled={isButtonLoading || !isButtonEnabled}
        isLoading={isButtonLoading}
      />
    </BottomButtonContainer>
  );

  const renderSiteVisitUpdate = () => (
    <SiteVisitUpdateModal>
      <SiteVisitTitleContainer sx={{ m: 0 }}>
        <CustomizedText fontSize={24} font={"Lato-Regular"}>
          {t("opportunity_details.add_site_visit")}
        </CustomizedText>
        {renderCloseButton()}
      </SiteVisitTitleContainer>
      <CustomizedDivider width={"100%"} sx={{ mb: 0, mt: 1 }} />
      <Box sx={{ paddingLeft: 3, paddingRight: 3 }}>
        {renderDateAndDuration()}
        {renderCheckInCheckoutTime()}
        {renderCommentBox()}
      </Box>
      {renderButton()}
    </SiteVisitUpdateModal>
  );

  return (
    <SiteVisitContainer>
      <SiteVisitTitleContainer sx={{ pl: 0, pr: 0 }}>
        {renderTitle()}
        {!isCurrentUserGRE && isCurrentUserCP &&
          isAddSiteVisitRequired &&
          renderAddSiteVisitButton()}
      </SiteVisitTitleContainer>
      <SiteVisitListTable />
      <MaterialModal
        children={renderSiteVisitUpdate()}
        sx={materialModalProps}
        open={isAddSiteVisitPopupVisible}
        onClose={toggleAddSiteVisitPopUp}
      />
    </SiteVisitContainer>
  );
};

export default SiteVisitDetails;

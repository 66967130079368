import { useCallback, useState } from "react";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { DocumentProps } from "../../utils/interface";
import { CustomizedText } from "../styled-componnets";
import {
  Document,
  DocumentsContainer,
  PreviewContainer,
} from "./styled-components";
import Colors from "../../utils/theme/color";
import FileViewerComponent from "../file-viewer";
import { Box } from "@mui/material";
import { useFileDownloadHooks } from "../../hooks";

interface DocumentPreviewProps {
  documents: DocumentProps[];
  closeDocumentPreview: () => void;
}
const EMPTY_DOCUMENT = {
  document_name: "",
  attachment: "",
  data: "",
  type: "",
};

const DocumentPreview = ({
  documents = [EMPTY_DOCUMENT],
  closeDocumentPreview,
}: DocumentPreviewProps) => {
  const [selectedDocument, setSelectedDocument] = useState<DocumentProps>(
    documents?.length ? documents[0] : EMPTY_DOCUMENT
  );
  const { downloadFile } = useFileDownloadHooks();
  const { t } = useTranslation();

  const onClickDocument = useCallback(
    (document: DocumentProps) => setSelectedDocument(document),
    [setSelectedDocument]
  );

  const isDocumentSelected = (document: DocumentProps) =>
    selectedDocument?.attachment === document?.attachment;

  const onClickDownloadDocument = useCallback(() => {
    toast(t("app_common.downloading"), {
      type: "info",
    });
    downloadFile(selectedDocument);
  }, [selectedDocument, downloadFile, t]);

  const renderDocuments = () => (
    <DocumentsContainer>
      {documents?.map((document, index) => (
        <Document
          key={index}
          onClick={() => onClickDocument(document)}
          {...(isDocumentSelected(document) && {
            sx: {
              backgroundColor: Colors.lightBlueColor,
            },
          })}
        >
          <ArticleOutlinedIcon
            fontSize="large"
            sx={{ color: Colors.titleColor }}
          />
          <CustomizedText fontSize={18} sx={{ textAlign: "center" }}>
            {document?.document_name ?? document?.name}
          </CustomizedText>
        </Document>
      ))}
    </DocumentsContainer>
  );

  const renderFileViewer = () => (
    <FileViewerComponent
      docFile={selectedDocument?.attachment}
      base64={selectedDocument?.data}
    />
  );

  const renderCloseAndDownloadIcon = () => (
    <Box sx={{ display: "flex" }}>
      <FileDownloadOutlinedIcon
        sx={{ mr: 3, cursor: "pointer" }}
        onClick={onClickDownloadDocument}
        htmlColor={Colors.primaryColor}
      />
      <CloseIcon
        sx={{ color: Colors.greyColor, cursor: "pointer" }}
        onClick={closeDocumentPreview}
      />
    </Box>
  );

  return (
    <PreviewContainer>
      {renderDocuments()}
      {renderFileViewer()}
      {renderCloseAndDownloadIcon()}
      <ToastContainer />
    </PreviewContainer>
  );
};

export default DocumentPreview;

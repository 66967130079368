import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Colors from "../../utils/theme/color";

export const SaveButton = styled(Button)(({ theme }) => ({
  fontFamily: "Lora-Bold",
  fontSize: 20,
  boxShadow: "",
  borderRadius: "5px",
  color: Colors.actionColor,
  border: `1.2px solid ${Colors.actionColor}`,
  filter:
    "drop-shadow(2px 0px 8px rgba(254, 202, 10, 0.16)) drop-shadow(0px 2px 8px rgba(254, 202, 10, 0.16))",
  "&:hover": {
    color: Colors.titleColor,
    background: Colors.actionColor,
    border: "1px solid rgba(0, 0, 0, 0.08)",
    boxShadow:
      "2px 0px 8px rgba(254, 202, 10, 0.16), 0px 2px 8px rgba(254, 202, 10, 0.16)",
  },
  padding: ".1rem 1.5rem .1rem 1.5rem",
  [theme.breakpoints.down("xl")]: {
    fontSize: 16,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 14,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  alignItems: "center",
  alignSelf: "center",
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  [theme.breakpoints.down("xl")]: {
    fontSize: 16,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 14,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const RejectButton = styled(Button)(({ theme }) => ({
  fontFamily: "Lora-Bold",
  fontSize: 20,
  boxShadow: "",
  borderRadius: "5px",
  color: Colors.redColor,
  border: `1.2px solid ${Colors.greyColor}`,
  filter:
    "drop-shadow(2px 0px 8px rgba(254, 202, 10, 0.16)) drop-shadow(0px 2px 8px rgba(254, 202, 10, 0.16))",
  padding: ".1rem 1.5rem .1rem 1.5rem",
  [theme.breakpoints.down("xl")]: {
    fontSize: 16,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 14,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

import { Container } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Colors from "../utils/theme/color";
import { CustomizedText } from "./styled-componnets";
import { useScreenSizeApplier } from "../hooks";
interface NavigationPathPropsTypes {
  initialPath: string;
  forwardPath: string;
  onClick?: () => void;
}

const NavigationPath = ({
  initialPath,
  forwardPath,
  onClick,
}: NavigationPathPropsTypes) => {
  const { mergeStyles } = useScreenSizeApplier();

  return (
    <Container disableGutters sx={{ display: "flex" }}>
      <CustomizedText
        fontSize={16}
        fontColor={Colors.greyColor}
        font={"Lato-Regular"}
        onClick={onClick}
        sx={{ cursor: "pointer" }}
      >
        {initialPath}
      </CustomizedText>
      {forwardPath ? (
        <>
          <ChevronRightIcon
            fontSize={"small"}
            sx={mergeStyles({
              styles: { m: 0, color: Colors.greyColor },
              belowLargeStyles: { fontSize: 16 },
              tabletStyles: { fontSize: 12 },
            })}
          />
          <CustomizedText
            fontSize={16}
            fontColor={Colors.titleColor}
            font={"Lato-Regular"}
          >
            {forwardPath}
          </CustomizedText>
        </>
      ) : null}
    </Container>
  );
};

export default NavigationPath;

import { Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { CloseButtonContainer, CustomizedText } from "../styled-componnets";
import { FC } from "react";
import Colors from "../../utils/theme/color";
import { Content, MessageContainer } from "./styled-component";

interface IWarningMessage {
  message: string;
  handleClose: () => void;
}
const WarningMessage: FC<IWarningMessage> = ({ message, handleClose }) => {
  const renderCloseButton = () => (
    <CloseButtonContainer onClick={handleClose}>
      <CloseIcon fontSize="small" htmlColor={Colors.titleColor} />
    </CloseButtonContainer>
  );

  return (
    <MessageContainer>
      <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
        {renderCloseButton()}
      </Box>
      <Content>
        <InfoIcon sx={{ color: Colors.redColor, mr: 1 }} />
        <CustomizedText
          fontSize={20}
          fontColor={Colors.primaryColor}
          sx={{ pr: 1 }}
        >
          {message}
        </CustomizedText>
      </Content>
    </MessageContainer>
  );
};

export default WarningMessage;

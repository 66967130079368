import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface userState {
  userDetails: object;
  keyDetails: object;
  userToken: string;
  roleDetails: object[];
}

const initialState: userState = {
  userDetails: {},
  keyDetails: {},
  userToken: "",
  roleDetails: []
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUserDetails: (state, action: PayloadAction<any>) => {
      state.userDetails = action.payload.userDetails;
      state.userToken = `${action.payload.keyDetails?.api_key}:${action.payload.keyDetails?.api_secret}`;
      state.roleDetails = action.payload.roleDetails;
    },
    logout: () => initialState,
  },
});

export const { saveUserDetails, logout } = userSlice.actions;

export default userSlice.reducer;

import { DD_MM_YYY } from "../../utils/date";
import { Box } from "@mui/material";
import moment from "moment";
import Colors from "../../utils/theme/color";
import { CustomizedText } from "../styled-componnets";
import { useTranslation } from "react-i18next";

const Declaration = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: 8, backgroundColor: Colors.cyanColor }}>
      <CustomizedText
        fontSize={20}
        fontColor={Colors.titleColor}
        font={"Lora-bold"}
      >
        {t("create_booking.declaration.text")}
      </CustomizedText>
      <CustomizedText
        fontSize={20}
        fontColor={Colors.titleColor}
        font={"Lora-bold"}
        sx={{ mt: 3 }}
      >
        {t("create_booking.declaration.date", {
          date: moment(new Date()).format(DD_MM_YYY),
        })}
      </CustomizedText>
      <CustomizedText
        fontSize={20}
        fontColor={Colors.titleColor}
        font={"Lora-bold"}
        sx={{ mt: 1 }}
      >
        {t("create_booking.declaration.place")}
      </CustomizedText>
    </Box>
  );
};

export default Declaration;

export enum Action {
  APPROVE = "Approve",
  REVIEW = "Review",
  REJECT = "Reject",
  MARK_AS_LOST = "Mark as Lost",
  REQUEST_APPROVAL = "Request Approval",
  CREATE_BOOKING = "Create Booking",
  ACTIONS = "Actions",
  ADD_DISCOUNT = "Add Discount",
}

import { FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { ActionMeta } from "react-select";
import {
  CustomizedDivider,
  CustomizedLink,
  CustomizedText,
} from "../../../components/styled-componnets";
import { useGetUserDetailsHooks, useRedirectToLogin } from "../../../hooks";
import {
  BottomButtonContainer,
  CloseButtonContainer,
  InfoContainer,
  ModifySalesStageModal,
  RowStyledContainer,
  TitleContainer,
} from "../styled-components";
import MaterialButton from "../../../components/button";
import Colors from "../../../utils/theme/color";
import Dropdown from "../../../components/dropdown";
import TextInput from "../../../components/text-input/text-input";
import { useGetSalesStageListQuery } from "../../../../features/api/opportunity-api-slice";

const ERROR = "error";
const alignSelfStyle = {
  alignSelf: "center",
  mt: 2,
  display: "flex",
  p: 1,
  width: "91%",
};

interface ModifySalesStageModalContentType {
  currentSalesStage?: string;
  handleClose: () => void;
  onClickModify: (stage: string) => void;
  isButtonLoading: boolean;
}

const ModifySalesStageModalContent: FC<ModifySalesStageModalContentType> = ({
  currentSalesStage = "",
  handleClose,
  onClickModify,
  isButtonLoading,
}) => {
  const { t } = useTranslation();
  const { getUserToken } = useGetUserDetailsHooks();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();

  const [selectedValue, setSelectedValue] = useState("");

  const [salesStageList, setSalesStageList] = useState([]);

  const {
    data: getStageListResponse,
    isSuccess: isGetStageListSuccess,
    isError: isGetStageListFailed,
    error: getStageListError,
  } = useGetSalesStageListQuery({
    userToken: getUserToken(),
  });

  useEffect(() => {
    if (isGetStageListSuccess) {
      if (Array.isArray(getStageListResponse?.data)) {
        setSalesStageList(getStageListResponse?.data);
      } else if (getStageListResponse?.message) {
        toast(getStageListResponse?.message, {
          type: ERROR,
        });
        setSalesStageList([]);
      }
    } else if (isGetStageListFailed) {
      redirectToLoginBasedOnResponse(getStageListError, getStageListResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getStageListResponse,
    isGetStageListSuccess,
    isGetStageListFailed,
    t,
    getStageListError,
  ]);

  const renderCloseButton = () => (
    <CloseButtonContainer paddingBottom={0} onClick={handleClose}>
      <CloseIcon fontSize="small" htmlColor={Colors.titleColor} />
    </CloseButtonContainer>
  );

  const renderInfo = () => (
    <InfoContainer sx={alignSelfStyle}>
      <InfoIcon fontSize="small" sx={{ color: Colors.titleColor, mr: 1 }} />
      <CustomizedText fontSize={16} font={"Lato-Regular"}>
        {t("opportunity_details.sales_stage_modifier_info")}
      </CustomizedText>
    </InfoContainer>
  );

  const returnLabelAndValue = (stages: { stage_name: string }[]) =>
    stages?.map((item: { stage_name: string }) => ({
      value: item?.stage_name,
      label: item?.stage_name,
    }));

  const onChangeValue = (
    newValue: any,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>,
    callBack: {
      (value: SetStateAction<string>): void;
      (value: SetStateAction<string>): void;
      (arg0: string): void;
    }
  ) => {
    callBack(newValue?.label);
  };

  const isButtonEnabled = Boolean(selectedValue);

  const renderButton = () => (
    <BottomButtonContainer sx={{ m: 0 }}>
      <CustomizedLink onClick={handleClose}>
        {t("app_common.cancel")}
      </CustomizedLink>
      <MaterialButton
        title={t("opportunity_details.modify")}
        id={"modify-button"}
        onClick={() => onClickModify(selectedValue)}
        disabled={isButtonLoading || !isButtonEnabled}
        isLoading={isButtonLoading}
      />
    </BottomButtonContainer>
  );
  return (
    <ModifySalesStageModal>
      <TitleContainer>
        <CustomizedText fontSize={24} font={"Lato-Regular"}>
          {t("opportunity_details.modify_sales_stage")}
        </CustomizedText>
        {renderCloseButton()}
      </TitleContainer>
      <CustomizedDivider width={"100%"} sx={{ mb: 0, mt: 1 }} />
      {renderInfo()}
      <RowStyledContainer disableGutters>
        <TextInput
          id="first-name-input"
          value={currentSalesStage}
          outerLabel={t("opportunity_details.current_sales_stage")}
          markRequiredField={false}
          disabled={Boolean(currentSalesStage)}
          styles={{ textInputContainer: { width: "47%" } }}
        />
        <Dropdown
          outerLabel={t("opportunity_details.new_sales_stage")}
          markRequiredField={true}
          dropdownItems={returnLabelAndValue(salesStageList)}
          inputLabel={t("opportunity_details.select_stage")}
          value={selectedValue}
          handleChange={(newValue, metaValue) =>
            onChangeValue(newValue, metaValue, setSelectedValue)
          }
          styles={{ textInputContainer: { width: "47%" } }}
        />
      </RowStyledContainer>
      {renderButton()}
    </ModifySalesStageModal>
  );
};

export default ModifySalesStageModalContent;

import { FC } from "react";
import { CommentsProps } from "../../utils/booking-utils";
import {
  CenterStyledBox,
  ColumnStyledBox,
  CommentContainer,
} from "./styed-components";
import { CustomizedLink, CustomizedText } from "../styled-componnets";
import Colors from "../../utils/theme/color";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DO_MMMM_YYYY_HH_MM_A } from "../../utils/date";
interface CommentComponentProps {
  details: CommentsProps;
  onClickViewNotes?: () => void;
  sx?: { commentContainerStyle?: {}; commentStyle?: {} };
  isLinkRequired?: boolean;
}

const CommentComponent: FC<CommentComponentProps> = ({
  details,
  onClickViewNotes = () => {},
  isLinkRequired = true,
  sx = { commentContainerStyle: {}, commentStyle: {} },
}) => {
  const { t } = useTranslation();

  return (
    <CommentContainer sx={{ ...sx.commentContainerStyle, ...{ boxShadow: 1 } }}>
      <ColumnStyledBox>
        <CenterStyledBox>
          {details?.comment_by && (
            <CustomizedText
              fontSize={22}
              fontColor={Colors.titleColor}
              font={"Lato-Regular"}
              sx={{ fontWeight: 600, mr: 2 }}
            >
              {details?.comment_by}
            </CustomizedText>
          )}
          {details?.creation && (
            <CustomizedText
              fontSize={16}
              fontColor={Colors.greyColor}
              font={"Lato-Regular"}
            >
              {moment(details?.creation).format(DO_MMMM_YYYY_HH_MM_A)}
            </CustomizedText>
          )}
        </CenterStyledBox>
        <CustomizedText
          fontSize={18}
          fontColor={Colors.titleColor}
          font={"Lato-Regular"}
          sx={{ width: "60%", ...sx.commentStyle }}
        >
          {details?.comment}
        </CustomizedText>
      </ColumnStyledBox>
      {isLinkRequired && (
        <CustomizedLink sx={{ mr: 1 }} onClick={onClickViewNotes}>
          {t("booking_comments.view_notes")}
        </CustomizedLink>
      )}
    </CommentContainer>
  );
};

export default CommentComponent;

import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Card, CardContent, CircularProgress } from "@mui/material";
import moment from "moment";
import { ActionMeta } from "react-select";
import DrawerComponent from "../../components/drawer";
import Colors from "../../utils/theme/color";
import {
  CommentsProps,
  EMPTY_OVER_VIEW_DETAILS,
  OverViewDetailsProps,
} from "../../utils/booking-utils";
import {
  EMPTY_OPPORTUNITY_DETAILS,
  OpportunityProps,
} from "../../utils/opportunity-utils";
import OverView from "../create-booking/over-view";
import {
  Status,
  BookingProposalUnderReviewStatuses,
} from "../../constants/statuses";
import {
  useAddProposalSiteVisitMutation,
  useGetProposalDetailsQuery,
  useUpdateProposalMutation,
  useUpdateProposalCommentsMutation,
  useFetchProposalCommentsQuery,
  useUpdateProposalWorkFlowMutation,
  useGetProposalWorkFlowQuery,
  useFetchProposalDocumentQuery,
} from "../../../features/api/proposals-api-slice";
import {
  useFileDownloadHooks,
  useGetUserDetailsHooks,
  useRedirectToLogin,
  useScreenSizeApplier,
} from "../../hooks";
import { PROPOSAL_DETAILS_PROPS } from "../../utils/proposals-utils";
import BookingDetailsTitle from "../create-booking/booking-details-title";
import ProjectAndUnitPreference from "../../components/project-and-unit-preference";
import {
  CustomizedDivider,
  CustomizedText,
  OutlinedButton,
} from "../../components/styled-componnets";
import OpportunityDetails from "./components/opportunity-details";
import CustomizedBottomBar from "../../components/bottom-bar";
import { isEmailValid, isPhoneNumberValid } from "../../utils/functions";
import PriceCalculator from "./components/price-calculator";
import {
  ButtonContainer,
  DetailsContainer,
  TitleAndButtonContainer,
} from "./styled-components";

import BookingComments from "../../components/booking-comments";
import {
  Container,
  RowStyledBox,
  CommentAndNotesCard,
} from "./styled-components";
import SiteVisitDetails from "../../components/site-visit-details";
import {
  YYYY_MM_DD_WITH_HYPHEN,
  convertTo24Hour,
  convertToSeconds,
} from "../../utils/date";
import Dropdown from "../../components/dropdown";
import { Action } from "../../constants/action";
import MaterialModal from "../../components/modal";
import RequestApprovalComponent from "./components/request-approval";
import NotInterestedModalContent from "../../components/not-interested-modal-content";
import SendCostBreakUp from "./components/send-cost-break-up";
import { SendCostSheetMethod } from "../../constants/send-cost-sheet-method";
import { isEqual } from "lodash";
import { EventProps } from "../../utils/interface";
import { VALIDATION_REGEXPS } from "../../constants";
import DiscountOrRoundOffInput from "./components/discount-or-round-off-input";

const ERROR = "error";
const PROPOSAL = "Proposal";
const DETAILS = "DETAILS";
const ROUND_OFF_MAX_LENGTH = 4;

const bottomTabContainerStyle = {
  marginLeft: "3rem",
  width: "100%",
  justifyContent: "space-between",
  marginRight: "3rem",
};
export const tabIndicatorProps = {
  sx: {
    bgcolor: Colors.primaryColor,
    borderRadius: 8,
    borderWidth: 2,
  },
};

const materialModalProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

const textInputStyles = {
  backgroundColor: Colors.actionColor,
  marginTop: 0,
  width: "60%",
};

const costBreakUpStyles = {
  marginTop: 0,
  width: "85%",
  marginRight: 8,
  fontSize: 18,
};

const printCostBreakUpStyles = {
  p: 0.7,
  mr: 1,
  width: "50%",
  textTransform: "none",
  color: Colors.titleColor,
  border: `1.2px solid ${Colors.greyColor}`,
};

interface IAction {
  action: Action;
  next_state: Status;
}

const ProposalForm = () => {
  const { t } = useTranslation();
  const params = useParams();
  let navigate = useNavigate();
  const {
    getUserToken,
    highestUserRole,
    isProjectSalesUser,
    isProjectSalesHead,
    isCurrentUserCP,
    isProjectHead,
  } = useGetUserDetailsHooks();
  const { mergeStyles } = useScreenSizeApplier();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();
  const { downloadFileWithBase64 } = useFileDownloadHooks();

  const { proposalId } = params || {};

  const [proposalStatus, setProposalStatus] = useState<Status>(
    Status.LEVEL_ZERO_REVIEW
  );
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [mobileErrorMsg, setMobileErrorMsg] = useState("");
  const [comment, setComment] = useState("");
  const [selectedAction, setSelectedAction] = useState<IAction>({
    action: Action.ACTIONS,
    next_state: Status.LEVEL_ONE_PENDING,
  });
  const [selectedCostBreakUpMethod, setSelectedCostBreakUpMethod] =
    useState<string>(t("proposal_view.send_cost_breakup"));

  const [drawerMenu, setDrawerMenu] = useState(
    require("../../constants/proposal-drawer-menu.json")
  );

  const [currentMenu, setCurrentMenu] = useState(drawerMenu[0]);
  const [proposalDetails, setProposalDetails] = useState<OverViewDetailsProps>(
    EMPTY_OVER_VIEW_DETAILS
  );
  const projectAndUnitPreferenceRef = useRef({
    project: "",
    unitType: "",
    unit: "",
  });
  const [projectAndUnitPreference, setProjectAndUnitPreference] = useState({
    project: "",
    unitType: "",
    unit: "",
  } as {
    project: string;
    unitType: string;
    unit: string;
  });
  const [opportunityDetails, setOpportunityDetails] =
    useState<OpportunityProps>(EMPTY_OPPORTUNITY_DETAILS);
  const [maximumDiscountOffered, setMaximumDiscountOffered] = useState<{
    max_discount_by_sales_executive: number;
    max_discount_by_sales_head: number;
    max_discount_by_sales_lead: number;
    max_discount_by_channel_partner: number;
  }>({
    max_discount_by_sales_executive: 0,
    max_discount_by_sales_head: 0,
    max_discount_by_sales_lead: 0,
    max_discount_by_channel_partner: 0,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const discountAction = {
    action: Action.ADD_DISCOUNT,
    next_state: Status.ALL,
  };

  const [isUpdateProposalLoading, setIsUpdateProposalLoading] = useState(false);
  const [isSavedAsDraft, setIsSavedAsDaft] = useState(false);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [isAddNoteButtonDisabled, setIsAddNoteButtonDisabled] =
    useState<boolean>(true);
  const [isAddNoteLoading, setIsAddNoteLoading] = useState<boolean>(false);
  const [isSiteVisitUpdateLoading, setIsSiteVisitUpdateLoading] =
    useState(false);
  const [isActionPopupVisible, setIsActionPopupVisible] = useState(false);
  const [isActionConfirmationLoading, setIsActionConfirmationLoading] =
    useState(false);
  const [showNoInterestedModal, setShowNotInterestedModal] =
    useState<boolean>(false);
  const [isVewAllLostReasons, setIsViewAllLostReasons] =
    useState<boolean>(false);
  const [isSendCostBreakUpMessageVisible, setIsSendCostBreakUpMessageVisible] =
    useState<boolean>(false);
  const [isAddDiscountPopupVisible, setIsAddDiscountPopupVisible] =
    useState<boolean>(false);
  const [isRoundOffModalVisible, setIsRoundOffModalVisible] = useState(false);
  const [isGetProposalDetailsLoading, setIsGetProposalDetailsLoading] =
    useState(true);
  const [isUpdateWorkflowLoading, setIsUpdateWorkflowLoading] = useState(false);

  const [proposalDocumentPDF, setProposalDocumentPDF] = useState("");
  const [roundOffAdded, setRoundOffAdded] = useState("");
  const [discountBeyondLimitError, setDiscountBeyondLimitError] = useState("");

  const [comments, setComments] = useState<CommentsProps[]>([]);
  const [actionList, setActionList] = useState<
    { action: Action; next_state: Status }[]
  >([]);

  const sendCostBreakUpMethods = [
    { label: SendCostSheetMethod.EMAIL, value: SendCostSheetMethod.EMAIL },
    {
      label: SendCostSheetMethod.WHATSAPP,
      value: SendCostSheetMethod.WHATSAPP,
    },
    {
      label: SendCostSheetMethod.EMAIL_AND_WHATSAPP,
      value: SendCostSheetMethod.EMAIL_AND_WHATSAPP,
    },
  ];

  const {
    data: getProposalDetailResponse,
    isError: isGetProposalDetailFailed,
    isSuccess: isGetProposalDetailSuccess,
    error: getProposalDetailError,
    refetch: refetchProposalDetails,
    isFetching: isProposalFetching,
  } = useGetProposalDetailsQuery(
    {
      userToken: getUserToken(),
      proposalId,
    },
    { skip: !Boolean(proposalId) }
  );

  const [
    updateProposal,
    {
      isSuccess: isUpdateProposalSuccess,
      isError: isUpdateProposalFailed,
      data: updateProposalResponse,
      error: updateProposalError,
    },
  ] = useUpdateProposalMutation();

  const [
    updateProposalComments,
    {
      isSuccess: isUpdateProposalCommentsSuccess,
      isError: isUpdateProposalCommentsFailed,
      data: updateProposalCommentsResponse,
      error: updateProposalCommentsError,
    },
  ] = useUpdateProposalCommentsMutation();

  const {
    data: getProposalCommentsResponse,
    isSuccess: isGetProposalCommentsSuccess,
    isError: isGetProposalCommentsFailed,
    error: getProposalCommentsError,
    refetch: refetchProposalComments,
  } = useFetchProposalCommentsQuery(
    {
      userToken: getUserToken(),
      proposalId,
    },
    { skip: !Boolean(proposalId) }
  );

  const {
    data: getProposalWorkflowResponse,
    isSuccess: isGetProposalWorkflowSuccess,
    isError: isGetProposalWorkflowFailed,
    error: getProposalWorkflowError,
    refetch: refetchProposalWorkflow,
  } = useGetProposalWorkFlowQuery(
    {
      userToken: getUserToken(),
      role: highestUserRole,
      currentWorkFlow: getProposalDetailResponse?.data[0]?.workflow_state,
    },
    {
      skip:
        !Boolean(proposalId) &&
        !Boolean(highestUserRole) &&
        !Boolean(getProposalDetailResponse?.data[0]?.workflow_state),
    }
  );

  const [
    addProposalSiteVisit,
    {
      isSuccess: isAddProposalSiteVisitSuccess,
      isError: isAddProposalSiteVisitFailed,
      data: addProposalSiteVisitResponse,
      error: addProposalSiteVisitError,
    },
  ] = useAddProposalSiteVisitMutation();

  const [
    updateProposalWorkFlow,
    {
      isSuccess: isUpdateProposalWorkflowSuccess,
      isError: isUpdateProposalWorkflowFailed,
      data: updateProposalWorkflowResponse,
      error: updateProposalWorkflowError,
    },
  ] = useUpdateProposalWorkFlowMutation();

  const {
    data: getProposalDocumentResponse,
    isSuccess: isGetProposalDocumentSuccess,
    isError: isGetProposalDocumentsFailed,
    error: getProposalDocumentsError,
    refetch: refetchProposalDocuments,
  } = useFetchProposalDocumentQuery(
    {
      userToken: getUserToken(),
      proposalId,
    },
    { skip: !Boolean(proposalId) }
  );

  const fetchProposalDetails = useCallback(() => {
    setIsGetProposalDetailsLoading(true);
    refetchProposalDetails();
    refetchProposalComments();
    refetchProposalWorkflow();
    refetchProposalDocuments();
  }, [
    refetchProposalComments,
    refetchProposalDetails,
    refetchProposalDocuments,
    refetchProposalWorkflow,
  ]);

  useEffect(() => {
    if (isGetProposalDetailsLoading) {
      fetchProposalDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDetailsTabOpened = useMemo(
    () => currentMenu?.id === DETAILS,
    [currentMenu?.id]
  );

  const updateWorkflowState = useCallback(
    (reason?: string, description?: string, isLost?: boolean) => {
      const payload = {
        booking_proposal_id: proposalId,
        action: selectedAction?.action,
        next_workflow_state: selectedAction?.next_state,
        ...(isLost && {
          proposal_lost_reason: reason,
          detailed_lost_reason: description,
        }),
      };
      updateProposalWorkFlow({ userToken: getUserToken(), payload });
    },
    [
      getUserToken,
      proposalId,
      selectedAction?.action,
      selectedAction?.next_state,
      updateProposalWorkFlow,
    ]
  );

  const addNote = useCallback(() => {
    setIsAddNoteLoading(true);
    const payload = {
      booking_proposal_id: proposalId,
      comment,
    };
    updateProposalComments({ userToken: getUserToken(), payload });
  }, [comment, getUserToken, proposalId, updateProposalComments]);

  const AddNoteAndUpdateWorkflow = useCallback(() => {
    if (Boolean(comment)) {
      addNote();
    }
    updateWorkflowState();
  }, [addNote, comment, updateWorkflowState]);

  const getUserDetails = useCallback(
    (details: PROPOSAL_DETAILS_PROPS) => ({
      id: details?.name,
      firstName: details?.first_name,
      lastName: details?.last_name,
      project: details?.project,
      unit: details?.unit,
      allocatedSalesTeam: details?.allocated_sales_team,
      additionalDiscountRequested: details?.customer_ask_discount,
    }),
    []
  );

  const getOpportunityDetails = useCallback(
    (details: PROPOSAL_DETAILS_PROPS) => ({
      opportunity: details?.opportunity,
      firstName: details?.first_name,
      lastName: details?.last_name,
      mobileNumber: details?.mobile_no,
      email: details?.email_id,
      isMobileSameAsWhatsapp: Boolean(details?.same_whatsapp),
      whatsappNumber: details?.whatsapp_no,
      emailErrorMsg: "",
    }),
    []
  );

  const navigateToProposals = useCallback(
    () => navigate(`/${PROPOSAL.toLocaleUpperCase()}`),
    [navigate]
  );

  const getMenuDetails = useCallback(
    (id: string) =>
      drawerMenu?.filter((item: { id: string }) => item?.id === id)[0],
    [drawerMenu]
  );

  const toggleActionConfirmation = useCallback(() => {
    setIsActionPopupVisible(!isActionPopupVisible);
  }, [isActionPopupVisible]);

  useEffect(() => {
    if (isGetProposalDetailsLoading && !isProposalFetching) {
      if (isGetProposalDetailSuccess) {
        if (getProposalDetailResponse?.data[0]?.name === proposalId) {
          setProposalDetails(
            getUserDetails(getProposalDetailResponse?.data[0])
          );
          setProposalStatus(getProposalDetailResponse?.data[0]?.workflow_state);
          setOpportunityDetails(
            getOpportunityDetails(getProposalDetailResponse?.data[0])
          );
          projectAndUnitPreferenceRef.current = {
            project: getProposalDetailResponse?.data[0]?.project,
            unit: getProposalDetailResponse?.data[0]?.unit,
            unitType: getProposalDetailResponse?.data[0]?.unit_type,
          };
          setProjectAndUnitPreference({
            project: getProposalDetailResponse?.data[0]?.project,
            unit: getProposalDetailResponse?.data[0]?.unit,
            unitType: getProposalDetailResponse?.data[0]?.unit_type,
          });
          setRoundOffAdded(getProposalDetailResponse?.data[0]?.round_off);
        } else if (getProposalDetailResponse?.message) {
          toast(getProposalDetailResponse?.message, {
            type: ERROR,
          });
          setProposalDetails(EMPTY_OVER_VIEW_DETAILS);
        }
        setIsGetProposalDetailsLoading(false);
      } else if (isGetProposalDetailFailed) {
        redirectToLoginBasedOnResponse(
          getProposalDetailError,
          getProposalDetailResponse
        );
        setProposalDetails(EMPTY_OVER_VIEW_DETAILS);
        setIsGetProposalDetailsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getOpportunityDetails,
    getProposalDetailError,
    getProposalDetailResponse,
    getUserDetails,
    isGetProposalDetailFailed,
    isGetProposalDetailSuccess,
    isGetProposalDetailsLoading,
    isProposalFetching,
    proposalId,
  ]);

  useEffect(() => {
    if (isUpdateProposalLoading) {
      if (isUpdateProposalSuccess) {
        if (updateProposalResponse?.data?.proposal_id) {
          if (isSavedAsDraft) navigateToProposals();
          if (isActionConfirmationLoading) {
            setIsActionConfirmationLoading(false);
            setIsUpdateWorkflowLoading(true);
            AddNoteAndUpdateWorkflow();
          } else {
            fetchProposalDetails();
          }
        } else if (updateProposalResponse?.message) {
          toast(updateProposalResponse?.message, {
            type: ERROR,
          });
          setIsActionConfirmationLoading(false);
          setComment("");
        }
        setIsUpdateProposalLoading(false);
        setShowNotInterestedModal(false);
        setIsAddDiscountPopupVisible(false);
      } else if (isUpdateProposalFailed) {
        redirectToLoginBasedOnResponse(
          updateProposalError,
          updateProposalResponse
        );
        setIsUpdateProposalLoading(false);
        setShowNotInterestedModal(false);
        setIsActionConfirmationLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateProposalResponse,
    isUpdateProposalSuccess,
    isUpdateProposalFailed,
    updateProposalError,
    isUpdateProposalLoading,
    isSavedAsDraft,
    navigateToProposals,
    isActionConfirmationLoading,
    AddNoteAndUpdateWorkflow,
    fetchProposalDetails,
  ]);

  useEffect(() => {
    if (isAddNoteLoading) {
      if (isUpdateProposalCommentsSuccess) {
        if (updateProposalCommentsResponse?.data) {
          setComment("");
          refetchProposalComments();
        } else if (updateProposalCommentsResponse?.message) {
          toast(updateProposalCommentsResponse?.message, {
            type: ERROR,
          });
        }
        setIsAddNoteLoading(false);
      } else if (isUpdateProposalCommentsFailed) {
        redirectToLoginBasedOnResponse(
          updateProposalCommentsError,
          updateProposalCommentsResponse
        );
        setIsAddNoteLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateProposalCommentsResponse,
    isUpdateProposalCommentsSuccess,
    isUpdateProposalCommentsFailed,
    updateProposalCommentsError,
    isAddNoteLoading,
  ]);

  useEffect(() => {
    if (isGetProposalCommentsSuccess) {
      if (Array.isArray(getProposalCommentsResponse?.data)) {
        setComments(getProposalCommentsResponse?.data);
      } else if (getProposalCommentsResponse?.message) {
        toast(getProposalCommentsResponse?.message, {
          type: ERROR,
        });
        setComments([]);
      }
    } else if (isGetProposalCommentsFailed) {
      redirectToLoginBasedOnResponse(
        getProposalCommentsError,
        getProposalCommentsResponse
      );
      setComments([]);
    }
    // eslint-disable-next-line
  }, [
    getProposalCommentsResponse,
    isGetProposalCommentsSuccess,
    isGetProposalCommentsFailed,
    getProposalCommentsError,
  ]);

  useEffect(() => {
    if (
      projectAndUnitPreference?.project &&
      projectAndUnitPreference?.unit &&
      opportunityDetails?.whatsappNumber
    ) {
      setIsNextButtonDisabled(false);
    } else {
      setIsNextButtonDisabled(true);
    }
  }, [
    opportunityDetails?.whatsappNumber,
    projectAndUnitPreference?.project,
    projectAndUnitPreference?.unit,
  ]);

  useEffect(() => {
    if (comment) {
      setIsAddNoteButtonDisabled(false);
    } else {
      setIsAddNoteButtonDisabled(true);
    }
  }, [comment]);

  useEffect(() => {
    if (isSiteVisitUpdateLoading) {
      if (isAddProposalSiteVisitSuccess) {
        if (addProposalSiteVisitResponse?.data === proposalId) {
          toast(addProposalSiteVisitResponse.message, {
            type: "success",
          });
        } else if (addProposalSiteVisitResponse?.message) {
          toast(addProposalSiteVisitResponse?.message, {
            type: ERROR,
          });
        }
        setIsSiteVisitUpdateLoading(false);
      } else if (isAddProposalSiteVisitFailed) {
        redirectToLoginBasedOnResponse(
          addProposalSiteVisitError,
          addProposalSiteVisitResponse
        );
        setIsSiteVisitUpdateLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addProposalSiteVisitResponse,
    isAddProposalSiteVisitSuccess,
    isAddProposalSiteVisitFailed,
    addProposalSiteVisitError,
    redirectToLoginBasedOnResponse,
  ]);

  useEffect(() => {
    if (isUpdateWorkflowLoading) {
      if (isUpdateProposalWorkflowSuccess) {
        if (
          updateProposalWorkflowResponse?.data?.booking_proposal_id ===
          proposalId
        ) {
          setIsActionPopupVisible(false);
          selectedAction?.next_state &&
            toast(
              t("proposal_view.proposal_action_updated", {
                nextState: selectedAction?.next_state,
              }),
              {
                type: "success",
              }
            );
          setComment("");
          setSelectedAction({
            action: Action.ACTIONS,
            next_state: Status.LEVEL_ONE_PENDING,
          });
          setShowNotInterestedModal(false);
          fetchProposalDetails();
        } else if (updateProposalWorkflowResponse?.message) {
          toast(updateProposalWorkflowResponse?.message, {
            type: ERROR,
          });
        }
        setIsUpdateWorkflowLoading(false);
      } else if (isUpdateProposalWorkflowFailed) {
        redirectToLoginBasedOnResponse(
          updateProposalWorkflowError,
          updateProposalWorkflowResponse
        );
        setShowNotInterestedModal(false);
        setIsUpdateWorkflowLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUpdateProposalWorkflowSuccess,
    isUpdateProposalFailed,
    updateProposalWorkflowError,
    isActionConfirmationLoading,
    updateProposalCommentsResponse,
    isUpdateProposalWorkflowFailed,
    updateProposalWorkflowResponse,
    proposalId,
    t,
    selectedAction?.next_state,
    fetchProposalDetails,
  ]);

  const isActionEnabled = useMemo(
    () =>
      (isProjectSalesUser && proposalStatus === Status.LEVEL_ZERO_REVIEW) ||
      (isProjectSalesHead && proposalStatus === Status.LEVEL_ONE_PENDING) ||
      (isProjectHead && proposalStatus === Status.LEVEL_TWO_PENDING) ||
      (isCurrentUserCP && (proposalStatus === Status.LEVEL_ZERO_REVIEW || proposalStatus === Status.SALES_MANAGER_REVIEW)),
    [isCurrentUserCP, isProjectHead, isProjectSalesHead, isProjectSalesUser, proposalStatus]
  );


  useEffect(() => {
    if (isGetProposalWorkflowSuccess) {
      if (Array.isArray(getProposalWorkflowResponse?.data)) {
        setActionList(
          !isDetailsTabOpened && isActionEnabled
            ? [
                {
                  ...discountAction,
                },
                ...getProposalWorkflowResponse?.data,
              ]
            : getProposalWorkflowResponse?.data
        );
      }
    } else if (isGetProposalWorkflowFailed) {
      redirectToLoginBasedOnResponse(
        getProposalWorkflowError,
        getProposalWorkflowResponse
      );
      setActionList(
        !isDetailsTabOpened && isActionEnabled ? [discountAction] : []
      );
    }
    // eslint-disable-next-line
  }, [
    getProposalWorkflowResponse,
    isGetProposalWorkflowSuccess,
    getProposalWorkflowError,
    isGetProposalWorkflowFailed,
    isDetailsTabOpened,
    isActionEnabled,
    discountAction,
  ]);

  useEffect(() => {
    if (isGetProposalDocumentSuccess) {
      if (getProposalDocumentResponse?.data) {
        setProposalDocumentPDF(getProposalDocumentResponse?.data);
      } else if (getProposalDocumentResponse?.message) {
        toast(getProposalDocumentResponse?.message, {
          type: ERROR,
        });
        setProposalDocumentPDF("");
      }
    } else if (isGetProposalDocumentsFailed) {
      redirectToLoginBasedOnResponse(
        getProposalDocumentsError,
        getProposalDocumentResponse
      );
      setProposalDocumentPDF("");
    }
    // eslint-disable-next-line
  }, [
    getProposalDocumentResponse,
    isGetProposalDocumentSuccess,
    isGetProposalDocumentsFailed,
    getProposalDocumentsError,
  ]);

  useEffect(() => {
    let drawerMenuList = [...drawerMenu];
    if (
      projectAndUnitPreference?.unit !==
      getProposalDetailResponse?.data[0]?.unit
    ) {
      drawerMenuList[1].isDisabled = true;
    } else {
      drawerMenuList[1].isDisabled = false;
    }
    return setDrawerMenu(drawerMenuList);
  }, [
    drawerMenu,
    getProposalDetailResponse?.data,
    projectAndUnitPreference?.unit,
  ]);

  const onUpdateProjectAndUnitPreference = (
    details: {
      project: string;
      unitType: string;
      unit: string;
    },
    projectDiscountDetails: {
      max_discount_by_sales_executive: number;
      max_discount_by_sales_head: number;
      max_discount_by_sales_lead: number;
      max_discount_by_channel_partner: number;
    }
  ) => {
    setProjectAndUnitPreference(details);
    projectAndUnitPreferenceRef.current = details;
    setMaximumDiscountOffered(projectDiscountDetails);
  };

  const onUpdateOpportunityDetails = (details: OpportunityProps) => {
    setOpportunityDetails(details);
  };

  const updateProposalDetails = useCallback(
    (isActionTriggered: SetStateAction<boolean>, payload: any) => {
      setIsActionConfirmationLoading(isActionTriggered);
      setIsUpdateProposalLoading(true);
      setMobileErrorMsg("");
      setEmailErrorMsg("");
      const payloadWithEmailId = {
        ...payload,
        email_id: opportunityDetails?.email,
      };
      updateProposal({
        userToken: getUserToken(),
        payload: payloadWithEmailId,
      });
    },
    [getUserToken, opportunityDetails?.email, updateProposal]
  );

  const isProjectChanged = useMemo(
    () =>
      projectAndUnitPreference?.project !==
      getProposalDetailResponse?.data[0]?.project,
    [getProposalDetailResponse?.data, projectAndUnitPreference?.project]
  );

  const newProposalPayload = useMemo(() => {
    return {
      name: proposalDetails?.id,
      opportunity: opportunityDetails?.opportunity,
      project: projectAndUnitPreference?.project,
      unit: projectAndUnitPreference?.unit,
      customer_ask_discount: proposalDetails?.additionalDiscountRequested,
      same_whatsapp: opportunityDetails?.isMobileSameAsWhatsapp,
      whatsapp_no: opportunityDetails?.whatsappNumber,
      email_id: opportunityDetails?.email,
      round_off: getProposalDetailResponse?.data[0]?.round_off,
      discount_by_sales_executive: isProjectChanged
        ? 0
        : getProposalDetailResponse?.data[0]?.discount_by_sales_executive,
      discount_by_sales_lead: isProjectChanged
        ? 0
        : getProposalDetailResponse?.data[0]?.discount_by_sales_lead,
      discount_by_sales_head: isProjectChanged
        ? 0
        : getProposalDetailResponse?.data[0]?.discount_by_sales_head,
      discount_by_channel_partner: isProjectChanged
        ? 0
        : getProposalDetailResponse?.data[0]?.discount_by_channel_partner,
    };
    // eslint-disable-next-line
  }, [
    proposalDetails?.id,
    opportunityDetails?.opportunity,
    opportunityDetails?.isMobileSameAsWhatsapp,
    opportunityDetails?.whatsappNumber,
    projectAndUnitPreference?.project,
    projectAndUnitPreference?.unit,
    getProposalDetailResponse?.data,
    isProjectChanged,
  ]);

  const savedProposalDetails = useMemo(() => {
    return {
      name: getProposalDetailResponse?.data[0]?.name,
      opportunity: getProposalDetailResponse?.data[0]?.opportunity,
      project: getProposalDetailResponse?.data[0]?.project,
      unit: getProposalDetailResponse?.data[0]?.unit,
      customer_ask_discount:
        getProposalDetailResponse?.data[0]?.customer_ask_discount,
      same_whatsapp: Boolean(getProposalDetailResponse?.data[0]?.same_whatsapp),
      whatsapp_no: getProposalDetailResponse?.data[0]?.whatsapp_no,
      email_id: getProposalDetailResponse?.data[0]?.email_id,
      round_off: getProposalDetailResponse?.data[0]?.round_off,
      discount_by_sales_executive:
        getProposalDetailResponse?.data[0]?.discount_by_sales_executive,
      discount_by_sales_lead:
        getProposalDetailResponse?.data[0]?.discount_by_sales_lead,
      discount_by_sales_head:
        getProposalDetailResponse?.data[0]?.discount_by_sales_head,
      discount_by_channel_partner:
        getProposalDetailResponse?.data[0]?.discount_by_channel_partner,
    };
  }, [getProposalDetailResponse?.data]);

  const isProposalDetailsSavedAlready = useCallback(
    () => isEqual(newProposalPayload, savedProposalDetails),
    [newProposalPayload, savedProposalDetails]
  );

  useEffect(() => {
    const isActionTriggered = false;
    if (
      !isGetProposalDetailsLoading &&
      !isProposalDetailsSavedAlready() &&
      isDetailsTabOpened
    ) {
      if (
        projectAndUnitPreference?.project &&
        Boolean(proposalDetails?.id) &&
        opportunityDetails?.whatsappNumber &&
        opportunityDetails?.email
      ) {
        if (
          isPhoneNumberValid(opportunityDetails?.whatsappNumber) &&
          isEmailValid(opportunityDetails?.email)
        ) {
          updateProposalDetails(isActionTriggered, newProposalPayload);
        }
      } else {
        toast("Please fill all the mandatory fields to save the details", {
          type: "info",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isGetProposalDetailsLoading,
    opportunityDetails?.whatsappNumber,
    projectAndUnitPreference?.project,
    proposalDetails?.id,
    isProposalDetailsSavedAlready,
    newProposalPayload,
  ]);

  const isProposalEditable = useMemo(
    () => BookingProposalUnderReviewStatuses.includes(proposalStatus),
    [proposalStatus]
  );

  const onChangeComment = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setComment(event.target.value);
    },
    []
  );

  const onClear = () => setComment("");

  const addSiteVisitData = useCallback(
    (data: {
      siteVisitDate: string;
      duration: string;
      checkInTime: string;
      checkOutTime: string;
      comment: string;
    }) => {
      setIsSiteVisitUpdateLoading(true);
      const payload = {
        booking_proposal_id: proposalId,
        site_visits: [
          {
            site_visit_date: Boolean(data?.siteVisitDate)
              ? data?.siteVisitDate
              : moment(new Date()).format(YYYY_MM_DD_WITH_HYPHEN),
            site_visit_time: convertTo24Hour(data?.checkInTime),
            check_out_time: convertTo24Hour(data?.checkOutTime),
            duration: convertToSeconds(data?.duration),
            site_visit_comment: data?.comment,
          },
        ],
      };
      addProposalSiteVisit({ payload, userToken: getUserToken() });
    },
    [addProposalSiteVisit, getUserToken, proposalId]
  );

  const returnLabelAndValue = (
    list: {
      action: Action;
      next_state: Status;
    }[]
  ) =>
    list?.map((item: { action: Action; next_state: Status }) => ({
      value: item.action,
      label: item.action,
      nextState: item?.next_state,
    }));

  const onChangeAction = (
    newValue: any,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>
  ) => {
    setSelectedAction({
      action: newValue?.label,
      next_state: newValue?.nextState,
    });
    switch (newValue?.label) {
      case Action.MARK_AS_LOST:
        toggleNotInterestedModal();
        break;
      case Action.ADD_DISCOUNT:
        onClickAddDiscount();
        break;
      default:
        setIsActionPopupVisible(Boolean(newValue?.label));
    }
  };

  const toggleNotInterestedModal = useCallback(() => {
    setIsViewAllLostReasons(false);
    setShowNotInterestedModal(!showNoInterestedModal);
  }, [showNoInterestedModal]);

  const updateLostReason = async (
    reason: { value: string; label: string }[],
    description?: string
  ) => {
    const isLost = true;
    setIsUpdateWorkflowLoading(true);
    updateWorkflowState(reason[0]?.value, description, isLost);
  };

  const isActionsDisabled = useMemo(
    () => !isActionEnabled || !Boolean(actionList?.length),
    [actionList?.length, isActionEnabled]
  );

  const onClickPrintCostSheet = useCallback(() => {
    toast(t("app_common.downloading"), {
      type: "info",
    });
    downloadFileWithBase64(
      proposalDocumentPDF,
      `${opportunityDetails?.firstName}.pdf`
    );
  }, [
    downloadFileWithBase64,
    opportunityDetails?.firstName,
    proposalDocumentPDF,
    t,
  ]);

  const toggleCostBreakUpPopup = useCallback(() => {
    setIsSendCostBreakUpMessageVisible(!isSendCostBreakUpMessageVisible);
  }, [isSendCostBreakUpMessageVisible]);

  const onChangeCostBreakUpMethod = useCallback(
    (
      newValue: any,
      actionMeta: ActionMeta<{
        value: string;
        label: string;
      }>
    ) => {
      setSelectedCostBreakUpMethod(newValue?.label);
      toggleCostBreakUpPopup();
    },
    [toggleCostBreakUpPopup]
  );

  const toggleAddDiscountPopUp = useCallback(() => {
    setIsAddDiscountPopupVisible(!isAddDiscountPopupVisible);
  }, [isAddDiscountPopupVisible]);

  const onClickAddDiscount = useCallback(
    () => toggleAddDiscountPopUp(),
    [toggleAddDiscountPopUp]
  );

  const validateAndUpdateDetails = useCallback(
    (isActionTriggered: boolean) => {
      if (opportunityDetails?.email && opportunityDetails?.whatsappNumber) {
        if (
          isEmailValid(opportunityDetails?.email) ||
          isPhoneNumberValid(opportunityDetails?.whatsappNumber)
        ) {
          updateProposalDetails(isActionTriggered, newProposalPayload);
        } else {
          setEmailErrorMsg(
            Boolean(opportunityDetails?.email)
              ? isEmailValid(opportunityDetails?.email)
                ? ""
                : t("proposal_view.invalid_email")
              : ""
          );
          setMobileErrorMsg(
            Boolean(opportunityDetails?.whatsappNumber)
              ? isPhoneNumberValid(opportunityDetails?.whatsappNumber)
                ? ""
                : t("proposal_view.invalid_mobile")
              : ""
          );
        }
      } else if (isProposalEditable) {
        toast(t("error_messages.mandatory_field_error_message"), {
          type: ERROR,
        });
      }
    },
    [
      isProposalEditable,
      newProposalPayload,
      opportunityDetails?.email,
      opportunityDetails?.whatsappNumber,
      t,
      updateProposalDetails,
    ]
  );

  const onPressButton = useCallback(() => {
    const isActionTriggered = false;
    setCurrentMenu(drawerMenu[1]);
    validateAndUpdateDetails(isActionTriggered);
  }, [drawerMenu, validateAndUpdateDetails]);

  const saveAsDraft = useCallback(() => {
    setIsSavedAsDaft(true);
    const isActionTriggered = false;
    validateAndUpdateDetails(isActionTriggered);
  }, [validateAndUpdateDetails]);

  const onChangeAdditionalDiscount = (e: EventProps) => {
    setProposalDetails({
      ...proposalDetails,
      additionalDiscountRequested: e.target.value
        ? parseInt(
            e.target.value.replace(VALIDATION_REGEXPS.matchesAnyNumbers, "")
          )
        : 0,
    });
  };

  const toggleAction = (incomingAction?: Action) => {
    const approvalAction = actionList?.find(
      (action: IAction) => action?.action === incomingAction
    );
    if (approvalAction?.action) {
      setSelectedAction({
        action: approvalAction.action,
        next_state: approvalAction.next_state,
      });
    }
  };

  const saveRoundOff = (roundOff: string) => {
    const actionTriggered = false;
    const payload = {
      ...newProposalPayload,
      round_off: roundOff,
    };
    updateProposalDetails(actionTriggered, payload);
  };

  const toggleRoundOffModal = () => {
    setIsRoundOffModalVisible(!isRoundOffModalVisible);
  };

  const onClickSaveRoundOff = () => {
    toggleRoundOffModal();
    saveRoundOff(roundOffAdded);
  };

  const onChangeAmount = (e: EventProps) => {
    setRoundOffAdded(
      e.target.value.replace(VALIDATION_REGEXPS.matchesAnyNumbers, "")
    );
  };

  const blockOtherActions = (message: string) =>
    setDiscountBeyondLimitError(message);

  const renderBottomBar = () => (
    <CustomizedBottomBar
      isButtonDisabled={isNextButtonDisabled || isUpdateProposalLoading}
      onPressButton={onPressButton}
      title={t("app_common.next")}
      styles={{
        bottomTabContainer: mergeStyles({
          styles: { marginLeft: 330 },
          tabletStyles: bottomTabContainerStyle,
        }),
      }}
      saveAsDraft={saveAsDraft}
      isRightButtonRequired={isDetailsTabOpened}
    />
  );

  const renderProjectAndUnit = () => (
    <>
      <CustomizedText fontSize={24} sx={{ mt: 2 }}>
        {t("proposal_view.select_project_unit")}
      </CustomizedText>
      <ProjectAndUnitPreference
        projectDetails={projectAndUnitPreferenceRef?.current}
        onUpdate={onUpdateProjectAndUnitPreference}
        isUnitDetailsRequired
        isUnitMandatory
        isProjectsAndUnitsEditable={isProposalEditable}
      />
    </>
  );

  const renderOpportunityDetails = () => (
    <>
      <CustomizedText fontSize={24} sx={{ mt: 2 }}>
        {t("proposal_view.opportunity_details")}
      </CustomizedText>
      <OpportunityDetails
        details={opportunityDetails}
        onUpdate={onUpdateOpportunityDetails}
        emailErrorMsg={emailErrorMsg}
        mobileErrorMsg={mobileErrorMsg}
        isEditable={isProposalEditable || !isUpdateProposalLoading}
      />
    </>
  );

  const renderSiteVisitDetails = () => (
    <SiteVisitDetails
      addSiteVisitData={addSiteVisitData}
      isButtonLoading={isSiteVisitUpdateLoading}
      isAddSiteVisitRequired={isProposalEditable}
    />
  );

  const renderContentBasedOnMenu = () =>
    isDetailsTabOpened ? (
      <>
        {renderProjectAndUnit()}
        <CustomizedDivider width="100%" sx={{ mt: 5 }} />
        {renderOpportunityDetails()}
        <CustomizedDivider width="100%" sx={{ mt: 5 }} />
        {renderSiteVisitDetails()}
      </>
    ) : (
      <PriceCalculator
        isAddDiscountPopupVisible={isAddDiscountPopupVisible}
        isUpdateProposalLoading={isUpdateProposalLoading}
        toggleAddDiscountPopUp={toggleAddDiscountPopUp}
        maximumDiscountOffered={maximumDiscountOffered}
        updateProposalDetails={updateProposalDetails}
        toggleRoundOffModal={toggleRoundOffModal}
        isEditable={isProposalEditable}
        blockOtherActions={blockOtherActions}
      />
    );

  const renderActions = () => (
    <ButtonContainer>
      <OutlinedButton
        sx={printCostBreakUpStyles}
        fontSize={18}
        onClick={onClickPrintCostSheet}
      >
        {t("proposal_view.print_cost_sheet")}
      </OutlinedButton>
      <Dropdown
        markRequiredField={false}
        dropdownItems={sendCostBreakUpMethods}
        inputLabel={t("proposal_view.send_cost_breakup")}
        value={t("proposal_view.send_cost_breakup")}
        handleChange={onChangeCostBreakUpMethod}
        styles={{
          textInputContainer: {
            ...costBreakUpStyles,
          },
          dropdownIndicator: {
            color: Colors.greyColor,
          },
          inputStyle: {
            color: Colors.titleColor,
          },
          borderColor: Colors.greyColor,
        }}
      />
      {isActionEnabled && (
        <Dropdown
          markRequiredField={false}
          dropdownItems={returnLabelAndValue(actionList)}
          inputLabel={t("proposal_view.actions")}
          value={selectedAction.action}
          handleChange={onChangeAction}
          isDisabled={isActionsDisabled}
          styles={{
            textInputContainer: {
              ...textInputStyles,
              ...(isActionsDisabled && {
                backgroundColor: Colors.transparentColor,
              }),
            },
            dropdownIndicator: {
              color: isActionsDisabled ? Colors.greyColor : Colors.primaryColor,
            },
            inputStyle: {
              color: isActionsDisabled ? Colors.greyColor : Colors.primaryColor,
              fontSize: 18,
            },
          }}
        />
      )}
    </ButtonContainer>
  );

  const renderLoader = () => (
    <CircularProgress size={"1.5rem"} sx={{ alignSelf: "center" }} />
  );

  const renderDetails = () => (
    <Container>
      <TitleAndButtonContainer>
        <BookingDetailsTitle
          title={currentMenu?.label}
          isDividerRequired={false}
        />
        {renderActions()}
      </TitleAndButtonContainer>
      <CustomizedDivider width="100%" />
      {isGetProposalDetailsLoading ? (
        renderLoader()
      ) : (
        <RowStyledBox>
          <Card
            sx={mergeStyles({
              styles: { pl: 2, pr: 2, width: "70%", mr: 2 },
              belowLargeStyles: { width: "95%", mr: 2 },
              tabletStyles: { width: "90%" },
            })}
            raised
          >
            <CardContent sx={{ pt: 1 }}>
              {renderContentBasedOnMenu()}
            </CardContent>
          </Card>
          {renderComments()}
        </RowStyledBox>
      )}
    </Container>
  );

  const renderComments = () => (
    <CommentAndNotesCard>
      <BookingComments
        commentList={comments}
        comment={comment}
        onClickAddNote={addNote}
        onChangeComment={onChangeComment}
        onClear={onClear}
        isButtonDisabled={isAddNoteButtonDisabled}
        isAddNoteLoading={isAddNoteLoading}
        sx={{ bookingCommentsContainerStyle: { width: "100%" } }}
        isCloseIconRequired={false}
        commentStyle={{ width: "90%" }}
      />
    </CommentAndNotesCard>
  );

  const renderActionConfirmation = () => (
    <RequestApprovalComponent
      action={selectedAction}
      handleClose={toggleActionConfirmation}
      onChangeComment={onChangeComment}
      comment={comment}
      isButtonLoading={isActionConfirmationLoading}
      onClickSubmitRequest={() => validateAndUpdateDetails(true)}
      onChangeAdditionalDiscount={onChangeAdditionalDiscount}
      additionalDiscount={proposalDetails?.additionalDiscountRequested}
      offeredPrice={getProposalDetailResponse?.data[0]?.total_cost_of_ownership}
      onClickChangeAction={toggleAction}
      toggleRoundOffModal={toggleRoundOffModal}
      errorMessage={discountBeyondLimitError}
    />
  );

  const renderModalContent = () => (
    <NotInterestedModalContent
      customerName={opportunityDetails?.firstName}
      handleClose={toggleNotInterestedModal}
      updateLostReason={updateLostReason}
      isButtonLoading={isActionConfirmationLoading}
      lostReasons={getProposalDetailResponse?.data[0]?.lost_reasons}
      isLostReasonsViewable={isVewAllLostReasons}
      lostDetail={getProposalDetailResponse?.data[0]?.order_lost_reason}
      id={proposalId}
      isMultipleSelectionEnabled={false}
    />
  );

  const renderCostBreakUpContent = () => (
    <SendCostBreakUp
      name={`${proposalDetails?.firstName} ${proposalDetails?.lastName}`}
      salesperson={proposalDetails?.allocatedSalesTeam}
      selectedMethod={selectedCostBreakUpMethod}
      handleClose={toggleCostBreakUpPopup}
    />
  );

  const renderRoundOffInput = () => (
    <DiscountOrRoundOffInput
      handleClose={toggleRoundOffModal}
      isButtonLoading={isUpdateProposalLoading}
      onClickSave={onClickSaveRoundOff}
      onChangeAmount={onChangeAmount}
      discountOrRoundOffAdded={roundOffAdded}
      outerLabel={t("list_price.round_off_info")}
      maxLength={ROUND_OFF_MAX_LENGTH}
    />
  );

  return (
    <DetailsContainer>
      <DrawerComponent
        menus={drawerMenu}
        selectedMenuId={currentMenu?.id}
        children={
          proposalId && (
            <OverView
              isEditable={isProposalEditable}
              details={proposalDetails}
              status={proposalStatus}
              contentType={PROPOSAL}
            />
          )
        }
        drawerContent={renderDetails()}
        styles={{
          drawerContainer: {
            display: "flex",
            flex: 1,
          },
        }}
        setSelectedMenuId={(id) => setCurrentMenu(getMenuDetails(id))}
        hideBottomBar={true}
        bottomComponent={renderBottomBar()}
      />
      <ToastContainer />
      <MaterialModal
        open={isActionPopupVisible}
        sx={materialModalProps}
        children={renderActionConfirmation()}
      />
      <MaterialModal
        children={renderModalContent()}
        sx={{ ...materialModalProps, ...{ width: "60%" } }}
        open={showNoInterestedModal}
        onClose={toggleNotInterestedModal}
      />
      <MaterialModal
        open={isSendCostBreakUpMessageVisible}
        sx={materialModalProps}
        children={renderCostBreakUpContent()}
      />
      <MaterialModal
        open={isRoundOffModalVisible}
        sx={materialModalProps}
        children={renderRoundOffInput()}
      />
    </DetailsContainer>
  );
};
export default ProposalForm;

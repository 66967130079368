import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Colors from "../../utils/theme/color";
import { headerImages } from "../../assets/image/header";

interface backgroundImageType {
  backgroundImage: string;
}

interface tabLabelStyleType {
  color: string;
  fontSize: number;
  fontFamily: string;
  height: string;
  marginRight: number;
  textTransform: string;
  lineSpacing: number;
}
export const CustomizedTypography = styled(Typography)({
  fontFamily: "Lato-Bold",
  fontSize: 16,
  color: "white",
  backgroundColor: Colors.greyColor,
  padding: "0.15rem 1rem 0.15rem 1rem",
  borderRadius: 20,
  marginTop: 20,
  display: "flex",
  alignItems: "center",
});

export const backgroundImgStyle: backgroundImageType = {
  backgroundImage: `url(${headerImages.HEADER_IMAGE_WITH_LOGO})`,
};

export const styleWithNoBackgroundImage = {
  backgroundImage: "",
};

export const tabLabelStyle: tabLabelStyleType = {
  color: "white",
  fontSize: 24,
  fontFamily: "Lora-Bold",
  height: "100px",
  marginRight: 30,
  textTransform: "none",
  lineSpacing: 0,
};

export const profileNameProps = {
  style: {
    fontFamily: "Lato-Bold",
    fontSize: 20,
    color: Colors.titleColor,
    textAlign: "center",
  },
};

export const tabIndicatorProps = {
  sx: {
    bgcolor: "white",
    borderRadius: 8,
    marginBottom: 0.2,
    height: { xs: "2px", sm: "2px", lg: "5px", xl: "5px" },
  },
};
export const materialModalProps = {
  backgroundColor: "white",
  position: "absolute",
  right: "2.5rem",
  top: 100,
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  borderRadius: "0.6rem",
  display: "flex",
  width: "13%",
};

export const mobileEmailProps = {
  style: {
    fontFamily: "Lato-Regular",
    fontSize: 16,
    color: Colors.titleColor,
    textAlign: "center",
    wordBreak: "break-word",
  },
};

export const logoutButtonProps = {
  style: {
    fontFamily: "Lora-Bold",
    fontSize: 20,
    lineHeight: 0,
    cursor: "pointer",
    color: "#0d4da1",
    display: "flex",
    alignItems: "center",
    padding: "1rem",
  },
};

export const profileImageStyle = {
  style: {
    height: 100,
    width: 100,
  },
  resizedStyle: {
    height: 60,
    width: 60,
  },
};

import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";
import { ActionMeta, SingleValue } from "react-select";
import { InfoContainer } from "../styled-component";
import { EXISTING_CUSTOMER } from "..";
import Dropdown from "../../../components/dropdown";
import {
  useGetUserDetailsHooks,
  useRedirectToLogin,
  useScreenSizeApplier,
} from "../../../hooks";
import { useGetExistingCustomerListQuery } from "../../../../features/api/master-api-slice";
import Colors from "../../../utils/theme/color";
import TextInput from "../../../components/text-input/text-input";
import { CustomizedText } from "../../../components/styled-componnets";
import { VALIDATION_REGEXPS } from "../../../constants";

export const EMPTY_EXISTING_CUSTOMER_DETAILS = {
  id: "",
  mobile: "",
  name: "",
};

export interface ExistingCustomerProps {
  id?: string;
  mobile?: string;
  name?: string;
}

interface ExistingOrCustomerReferralProps {
  source: string;
  subSource: string;
  toggleDropDown: (isOpened: boolean) => void;
  dropdownContainer: {};
  existingCustomer: ExistingCustomerProps;
  onUpdate: (details: ExistingCustomerProps) => void;
  isDisabled: boolean;
}

const MINIMUM_MOBILE_SEARCH_LENGTH = 5;

const ExistingOrCustomerReferral: FC<ExistingOrCustomerReferralProps> = ({
  source,
  subSource,
  toggleDropDown,
  dropdownContainer,
  existingCustomer,
  onUpdate,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const { mergeStyles } = useScreenSizeApplier();
  const { getUserToken } = useGetUserDetailsHooks();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();

  const [customerList, setCustomerList] = useState([]);
  const [existingCustomerDetails, setExistingCustomerDetails] =
    useState(existingCustomer);

  const existingCustomerDetailsRef = useRef(existingCustomerDetails);

  useEffect(() => {
    if (existingCustomer?.mobile) {
      setExistingCustomerDetails(existingCustomer);
      existingCustomerDetailsRef.current = existingCustomer;
    }
  }, [existingCustomer?.mobile, existingCustomer]);

  useEffect(() => {
    if (!Object.is(existingCustomerDetails, EMPTY_EXISTING_CUSTOMER_DETAILS)) {
      existingCustomerDetailsRef.current = existingCustomerDetails;
      onUpdate(existingCustomerDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingCustomerDetails]);

  const {
    data: getExistingCustomerListResponse,
    isSuccess: isGetExistingCustomerListSuccess,
    isError: isGetExistingCustomerListFailed,
    error: getExistingCustomerListError,
    refetch: refetchExistingCustomer,
  } = useGetExistingCustomerListQuery(
    {
      userToken: getUserToken(),
      mobile: existingCustomerDetails?.mobile,
    },
    {
      skip: Boolean(
        existingCustomerDetails?.mobile?.length &&
          existingCustomerDetails?.mobile?.length < MINIMUM_MOBILE_SEARCH_LENGTH
      ),
    }
  );

  useEffect(() => {
    if (isGetExistingCustomerListSuccess) {
      if (Array.isArray(getExistingCustomerListResponse?.data)) {
        setCustomerList(getExistingCustomerListResponse?.data);
      } else if (getExistingCustomerListResponse?.message) {
        toast(getExistingCustomerListResponse?.message, {
          type: "error",
        });
        setCustomerList([]);
      }
    } else if (isGetExistingCustomerListFailed) {
      redirectToLoginBasedOnResponse(
        getExistingCustomerListError,
        getExistingCustomerListResponse
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getExistingCustomerListResponse,
    isGetExistingCustomerListSuccess,
    isGetExistingCustomerListFailed,
    t,
    getExistingCustomerListError,
  ]);

  useEffect(() => {
    refetchExistingCustomer();
  }, [refetchExistingCustomer]);

  const isRegisteredMobileExistInCustomerList = useCallback(
    () =>
      customerList?.some(
        (customer: { customer_mobile: string }) =>
          customer?.customer_mobile === existingCustomerDetails?.mobile
      ),
    [existingCustomerDetails?.mobile, customerList]
  );

  const onChangeReferralName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setExistingCustomerDetails({
        ...existingCustomerDetails,
        name: e.target.value,
      });
    },
    [existingCustomerDetails]
  );

  const getFormattedCustomerList = (
    customerList: {
      customer_mobile: string;
      customer_name: string;
      name: string;
    }[]
  ) =>
    customerList?.map(
      (customer: {
        customer_mobile: string;
        customer_name: string;
        name: string;
      }) => ({
        id: customer?.name,
        label: `${customer?.customer_mobile} ${customer?.customer_name}`,
        value: customer?.customer_mobile,
        name: customer?.customer_name,
      })
    );

  const onChangeRegisteredMobileInput = (newValue: string) => {
    if (newValue) {
      setExistingCustomerDetails({
        ...existingCustomerDetails,
        mobile: newValue.replace(VALIDATION_REGEXPS.matchesAnyNumbers, ""),
        name: "",
      });
    }
  };

  const onChangeRegisteredMobile = (
    newValue: SingleValue<{
      value?: string;
      label?: string;
      name?: string;
      id?: string;
    }>,
    actionMeta: ActionMeta<{ value: string; label: string }>
  ) => {
    setExistingCustomerDetails({
      id: newValue?.id,
      mobile: newValue?.value,
      name: newValue?.name,
    });
  };

  const isExistingCustomer = useCallback(
    () => source === EXISTING_CUSTOMER || subSource === EXISTING_CUSTOMER,
    [source, subSource]
  );

  const renderRegisteredMobile = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: {
          ...dropdownContainer,
          ...{
            width: "100%",
            pb: 0,
            alignItems: "end",
          },
        },
        tabletStyles: { width: "100%" },
      })}
    >
      <Dropdown
        outerLabel={
          isExistingCustomer()
            ? t("create_opportunity.existing_customer_mobile_label")
            : t("create_opportunity.referrer_mobile_label")
        }
        markRequiredField={true}
        dropdownItems={getFormattedCustomerList(customerList)}
        inputLabel={t("create_opportunity.enter_mobile")}
        value={existingCustomerDetailsRef?.current.mobile}
        onInputChange={onChangeRegisteredMobileInput}
        setIsDropdownOpen={toggleDropDown}
        handleChange={onChangeRegisteredMobile}
        isDisabled={isDisabled}
      />
      {isRegisteredMobileExistInCustomerList() && (
        <VerifiedIcon
          fontSize="small"
          sx={{ color: Colors.greenColor, p: 1, pl: 0.5 }}
        />
      )}
      {!isRegisteredMobileExistInCustomerList() && (
        <div style={{ width: "10%" }} />
      )}
      <TextInput
        id="referrer-name"
        value={existingCustomerDetailsRef?.current.name}
        label={
          !existingCustomerDetailsRef?.current.name
            ? t("create_opportunity.first_name_placeholder")
            : ""
        }
        onChange={onChangeReferralName}
        outerLabel={
          isExistingCustomer()
            ? t("create_opportunity.customer_name")
            : t("create_opportunity.referrer_name")
        }
        markRequiredField={false}
        disabled={isDisabled}
      />
    </Container>
  );

  return (
    <>
      <InfoContainer>
        <InfoIcon fontSize="small" sx={{ color: Colors.titleColor, mr: 1 }} />
        <CustomizedText fontSize={16} font={"Lato-Regular"}>
          {isExistingCustomer()
            ? t("create_opportunity.existing_customer_info")
            : t("create_opportunity.customer_referral_info")}
        </CustomizedText>
      </InfoContainer>
      {renderRegisteredMobile()}
    </>
  );
};

export default ExistingOrCustomerReferral;

import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Colors from "../../utils/theme/color";

export const SecondaryButton = styled(Button)(({ theme }) => ({
    fontFamily: "Lora-Bold",
    fontSize: 20,
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.lightBlueColor}`,
    boxShadow: "",
    borderRadius: "5px",
    color: Colors.primaryColor,
    "&:hover": {
        backgroundColor: Colors.white,
        border: `1.2px solid ${Colors.primaryColor}`,
    },
    textTransform: "none",
    padding: "0rem 2.5rem 0rem 2.5rem",
    marginTop: 17,
    [theme.breakpoints.down("xl")]: {
        padding: "0rem 2rem 0rem 2rem",
        fontSize: 18,
    },
    [theme.breakpoints.down("lg")]: {
        padding: "0rem 2rem 0rem 2rem",
        fontSize: 16,
    },
    [theme.breakpoints.down("md")]: {
        padding: "0rem 1rem 0rem 1rem",
        fontSize: 14,
    },
}));

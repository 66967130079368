import moment from "moment";

export const DD_MMM_YYYY = "DD MMM YYYY";
export const DD_MM_YYY = "DD-MM-YYYY";
export const YYYY_MM_DD_WITH_HYPHEN = "YYYY-MM-DD";
export const YY_MM_DD_HH_MM_SS = "YY-MM-DD HH:mm:ss";
export const DO_MMMM_YYYY = "Do MMMM YYYY";
export const DO_MMMM_YYYY_HH_MM_A = "Do MMMM YYYY, hh:mm A";
export const HH_MM_A = "hh:mm A";

export const calculateAge = (date) => {
  let dob = moment(date);
  return moment().diff(dob, "years") ? moment().diff(dob, "years") : "";
};
export const getDateAfterThreeMonths = (date, dateFormat) =>
  moment(date).add(3, "months").format(dateFormat);

export const getTimeList = () => {
  const hourRange = [...Array(24).keys()];
  return hourRange.reduce((acc, hour) => {
    const isAfterNoon = hour >= 12;
    const baseHour = hour % 12 || 12;
    const amPm = isAfterNoon ? "PM" : "AM";
    for (let minute = 0; minute <= 45; minute += 15) {
      const time = `${baseHour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")} ${amPm}`;
      acc.push(time);
    }
    return acc;
  }, []);
};

export const convertTo24Hour = (time12h) =>
  moment(time12h, "h:mm:ss A").format("HH:mm:ss");

export const convertTo12Hour = (time12h) => {
  const format12h = "h:mm A";
  const format24h = "HH:mm:ss";
  const time24h = moment(time12h, format24h).format(format12h);
  return time24h;
};

export const findTimeDifference = (start, end) =>
  moment
    .duration(moment(end, HH_MM_A).diff(moment(start, HH_MM_A)))
    .asMinutes();

export const convertMinutesToHours = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return hours > 0 || remainingMinutes > 0
    ? `${hours}:${remainingMinutes}`
    : "";
};

export const convertToSeconds = (timeString) => {
  const formattedTimeString = timeString.replace("h", ":00");
  const duration = moment.duration(formattedTimeString);
  const seconds = duration.asSeconds();
  return seconds;
};

export const isTimeBetweenStartAndEndTime = (time, start, end) =>
  moment(moment(time, HH_MM_A)).isBetween(
    moment(start, HH_MM_A),
    moment(end, HH_MM_A)
  );

export const isTimeAfterCheckInTime = (time, checkIn) =>
  moment(time, HH_MM_A).isAfter(moment(checkIn, HH_MM_A));

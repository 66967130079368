import { SyntheticEvent } from "react";
import { CircularProgress } from "@mui/material";
import { useScreenSizeApplier } from "../hooks";
import { SolidButton } from "./styled-componnets";
import Colors from "../utils/theme/color";

interface ButtonPropsType {
  id: string;
  variant?: "text" | "outlined" | "contained";
  title: string;
  onClick?(e: SyntheticEvent): void;
  color?:
    | "error"
    | "inherit"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning";
  sx?: {};
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  fontSize?: number;
  isLoading?: boolean;
}

const MaterialButton = ({
  id = "",
  variant = "contained",
  title = "",
  onClick = () => {},
  color = "secondary",
  sx = {},
  disabled = false,
  size = "medium",
  fontSize = 18,
  isLoading = false,
}: ButtonPropsType) => {
  const { mergeStyles } = useScreenSizeApplier();

  return (
    <SolidButton
      id={id}
      variant={variant}
      onClick={onClick}
      color={color}
      sx={mergeStyles({
        styles: sx,
        tabletStyles: { fontSize: 12 },
        belowXlStyles: { fontSize: 16 },
        belowLargeStyles: { fontSize: 14 },
      })}
      disabled={disabled}
      size={size}
      fontSize={fontSize}
    >
      {title}
      {isLoading && (
        <CircularProgress
          size={"1rem"}
          sx={{ ml: 1, color: Colors.primaryColor }}
        />
      )}
    </SolidButton>
  );
};
export default MaterialButton;

import { FC, useState, SyntheticEvent, useMemo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import { Box, FormGroup } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { NumericFormat } from "react-number-format";
import { Action } from "../../../constants/action";
import { Status } from "../../../constants/statuses";
import {
  ActionContainer,
  ActionInnerContainer,
  CloseButtonContainer,
} from "../styled-components";
import Colors from "../../../utils/theme/color";
import {
  CustomizedText,
  InfoContainer,
  CustomizedDivider,
  CustomizedLink,
} from "../../../components/styled-componnets";
import TextInput from "../../../components/text-input/text-input";
import { Label, StyledCheckBox } from "../../create-booking/styled-component";
import MaterialButton from "../../../components/button";
import { TitleAndButtonContainer } from "../styled-components";
import { UNICODE } from "../../../constants";
import { useGetUserDetailsHooks } from "../../../hooks";
import WarningMessage from "../../../components/warning-message";

interface RequestApprovalComponentProps {
  action: {
    action: Action;
    next_state: Status;
  };
  handleClose: () => void;
  onClickSubmitRequest: () => void;
  onChangeComment: (event: React.ChangeEvent<HTMLInputElement>) => void;
  comment: string;
  isButtonLoading: boolean;
  additionalDiscount?: number;
  onChangeAdditionalDiscount: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  offeredPrice: number;
  onClickChangeAction: (action: Action) => void;
  toggleRoundOffModal: () => void;
  errorMessage: string;
}
const buttonContainerProps = {
  display: "flex",
  justifyContent: "flex-end",
  mt: 2,
};

const dividerStyle = {
  pt: 0,
  mt: 0,
  mb: 2,
};

const RequestApprovalComponent: FC<RequestApprovalComponentProps> = ({
  action,
  handleClose = () => {},
  onClickSubmitRequest = () => {},
  onChangeComment,
  comment,
  isButtonLoading,
  additionalDiscount,
  onChangeAdditionalDiscount,
  offeredPrice,
  onClickChangeAction,
  toggleRoundOffModal,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const { isProjectHead } = useGetUserDetailsHooks();

  const [isActionConfirmed, setIsActionConfirmed] = useState(
    action?.action === Action.REVIEW
  );

  const onChangeIsConfirmed = (
    e: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    setIsActionConfirmed(checked);
  };

  const isSendForReview = useMemo(
    () => action?.action === Action.REVIEW,
    [action?.action]
  );

  const isRequestApproval = useMemo(
    () => action?.action === Action.REQUEST_APPROVAL,
    [action?.action]
  );

  const isButtonDisabled = useMemo(
    () => !isActionConfirmed,
    [isActionConfirmed]
  );

  const renderCloseButton = () => (
    <CloseButtonContainer onClick={handleClose}>
      <CloseIcon fontSize="small" htmlColor={Colors.titleColor} />
    </CloseButtonContainer>
  );

  const renderAction = () => (
    <CustomizedText fontSize={24} fontColor={Colors.titleColor}>
      {action?.action}
    </CustomizedText>
  );

  const renderYourComments = () => (
    <TextInput
      id="comment"
      value={comment}
      outerLabel={t("action_confirmation.proposal_comment_label")}
      size="medium"
      onChange={onChangeComment}
      label={
        !comment ? t("action_confirmation.proposal_comment_placeholder") : ""
      }
      markRequiredField={false}
      multiline={true}
      styles={{
        outerLabel: {
          marginTop: "0rem",
        },
      }}
      rows={4}
    />
  );

  const renderInfo = () => (
    <InfoContainer>
      <InfoIcon fontSize="small" sx={{ color: Colors.titleColor, mr: 1 }} />
      <CustomizedText
        fontSize={18}
        font={"Lato-Regular"}
        {...(!isRequestApproval && {
          sx: { fontWeight: "700" },
        })}
      >
        {isRequestApproval ? (
          t("action_confirmation.confirmation_note")
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {t("action_confirmation.total_price")}
            <NumericFormat
              style={{ marginLeft: 5, marginRight: 5 }}
              value={offeredPrice}
              allowLeadingZeros
              thousandSeparator=","
              prefix={` ${UNICODE.rupeeSymbol}`}
              displayType="text"
              thousandsGroupStyle="lakh"
            />
            {
              <CustomizedLink
                fontSize={18}
                sx={{ alignSelf: "end", fontWeight: "200" }}
                onClick={toggleRoundOffModal}
              >
                {t("list_price.roundoff")}
              </CustomizedLink>
            }
          </Box>
        )}
        {!isProjectHead && !isRequestApproval && (
          <CustomizedText fontSize={18} font={"Lato-Regular"}>
            {`${t("action_confirmation.request_approval_info")}`}
            <CustomizedLink
              onClick={() => onClickChangeAction(Action.REQUEST_APPROVAL)}
            >{` ${Action.REQUEST_APPROVAL}`}</CustomizedLink>
          </CustomizedText>
        )}
      </CustomizedText>
    </InfoContainer>
  );

  const renderIsConfirmed = () => (
    <FormGroup sx={{ alignSelf: "flex-start" }}>
      <Label
        disableTypography
        control={
          <StyledCheckBox
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            value={isActionConfirmed}
            sx={{ p: 1 }}
            size={"small"}
            onChange={(e, checked) => onChangeIsConfirmed(e, checked)}
            checked={isActionConfirmed}
          />
        }
        label={t("action_confirmation.confirmation_note_label")}
        labelPlacement="end"
        checked={isActionConfirmed}
        onChange={onChangeIsConfirmed}
        sx={{ color: Colors.greyColor }}
      />
    </FormGroup>
  );

  const renderButton = () => (
    <MaterialButton
      id={"submit"}
      title={t("action_confirmation.submit")}
      disabled={isButtonDisabled}
      onClick={onClickSubmitRequest}
      isLoading={isButtonLoading}
    />
  );

  const renderAdditionalDiscountInput = () => (
    <TextInput
      id={"discount"}
      markRequiredField={false}
      outerLabel={t("proposal_view.additional_discount_requested")}
      isRupeeIconRequired
      styles={{
        outerLabel: { marginTop: 0 },
        textInputContainer: { marginBottom: 25 },
      }}
      onChange={onChangeAdditionalDiscount}
      value={additionalDiscount}
      maxLength={3}
    />
  );

  return Boolean(errorMessage) ? (
    <WarningMessage
      message={`To proceed further, ${errorMessage.toLowerCase()}`}
      handleClose={handleClose}
    />
  ) : (
    <ActionContainer>
      <TitleAndButtonContainer sx={{ p: 2, pt: 0 }}>
        {renderAction()}
        {renderCloseButton()}
      </TitleAndButtonContainer>
      <CustomizedDivider width="100%" sx={dividerStyle} />
      <ActionInnerContainer>
        {isRequestApproval && renderAdditionalDiscountInput()}
        {renderYourComments()}
        {!isSendForReview && (
          <>
            <CustomizedText fontSize={18} sx={{ mt: 2 }}>
              {t("action_confirmation.confirm_information")}
            </CustomizedText>
            {renderInfo()}
            {renderIsConfirmed()}
          </>
        )}
        <Box
          sx={{
            ...buttonContainerProps,
            ...(isRequestApproval && {
              justifyContent: "space-between",
              alignItems: "center",
            }),
          }}
        >
          {isRequestApproval && (
            <CustomizedLink
              onClick={() => onClickChangeAction(Action.CREATE_BOOKING)}
            >{`${Action.CREATE_BOOKING}`}</CustomizedLink>
          )}
          {renderButton()}
        </Box>
      </ActionInnerContainer>
    </ActionContainer>
  );
};

export default RequestApprovalComponent;

import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import type { RootState, AppDispatch } from "./store";
import { logout } from "../features/user/user-slice";
import { getFileTypeFromUrl, isPermissionDenied } from "./utils/functions";
import { API_CONSTANTS, USER_ROLES } from "./constants";

const BOOKING = "BOOKING";
const PROPOSAL = "PROPOSAL";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useScreenSizeApplier = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:768px)");
  const isBelowLarge = useMediaQuery(theme.breakpoints.down("lg"));
  const isBelowXl = useMediaQuery(theme.breakpoints.down("xl"));
  const mergeStyles = ({
    styles = {},
    tabletStyles = {},
    belowXlStyles = {},
    belowLargeStyles = {},
  }: {
    styles?: {};
    tabletStyles?: {};
    belowXlStyles?: {};
    belowLargeStyles?: {};
  }) => {
    return {
      ...styles,
      ...(isBelowXl && belowXlStyles),
      ...(isBelowLarge && belowLargeStyles),
      ...(isTablet && tabletStyles),
    };
  };
  return {
    mergeStyles,
  };
};

export const useRedirectToLogin = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const redirectToLoginBasedOnResponse = (error: any, response: any) => {
    if (isPermissionDenied(error)) {
      toast(t("error_messages.please_login"), {
        type: "error",
      });
      dispatch(logout());
      navigate("/");
    } else if (response?.message) {
      toast(response?.message, {
        type: "error",
      });
    } else {
      toast(t("error_messages.server_error"), {
        type: "error",
      });
    }
  };
  return {
    redirectToLoginBasedOnResponse,
  };
};

export const useFileDownloadHooks = () => {
  const { t } = useTranslation();
  const downloadFile = (file: {
    attachment?: string;
    document_name?: string;
    name?: string;
  }) => {
    fetch(`${API_CONSTANTS.baseURL}${file.attachment}`).then((response) => {
      response
        .blob()
        .then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = file.attachment
            ? `${file?.document_name ?? file?.name}.${getFileTypeFromUrl(
                file.attachment
              )}`
            : "";
          alink.click();
        })
        .catch((e) =>
          toast(t("error_messages.server_error"), {
            type: "error",
          })
        );
    });
  };

  const downloadFileWithBase64 = (pdfFileBase64: string, name: string) => {
    const pdfLink = `data:application/pdf;base64,${pdfFileBase64}`;
    const anchorElement = document.createElement("a");
    const fileName = name;
    anchorElement.href = pdfLink;
    anchorElement.download = fileName;
    anchorElement.click();
  };

  return {
    downloadFile,
    downloadFileWithBase64,
  };
};

export const useGetUserDetailsHooks = () => {
  const userToken = useAppSelector(
    (state: { user: { userToken: string } }) => state.user.userToken
  );
  const roleDetails = useAppSelector((state) => state.user.roleDetails);

  const getUserToken = () => userToken;

  const salesHead = useMemo(
    () =>
      roleDetails.filter(
        (userRole: any) => userRole.role === USER_ROLES.headOfSales
      )[0]?.role,
    [roleDetails]
  );

  const salesLead = useMemo(
    () =>
      roleDetails.filter(
        (userRole: any) => userRole.role === USER_ROLES.ProjectSalesHead
      )[0]?.role,
    [roleDetails]
  );

  const isCurrentUserGRE = useMemo(
    () =>
      roleDetails?.some(
        (userRole: { role: string }) => userRole?.role === USER_ROLES.GRE
      ),
    [roleDetails]
  );

  const isCurrentUserCP = useMemo(
    () =>
      roleDetails?.some(
        (userRole: { role: string }) => userRole?.role === USER_ROLES.ChannelPartner
      ),
    [roleDetails]
  );

  const getUserRole = useMemo(
    () =>
      roleDetails.reduce((roles: string[], user: { role: string }) => {
        if (user?.role && user?.role !== "Employee") roles.push(user?.role);
        return roles;
      }, []),
    [roleDetails]
  );

  const isProjectSalesUser = useMemo(
    () => getUserRole.includes(USER_ROLES.ProjectSalesUser),
    [getUserRole]
  );

  const isProjectSalesHead = useMemo(
    () => getUserRole.includes(USER_ROLES.ProjectSalesHead),
    [getUserRole]
  );

  const isProjectHead = useMemo(
    () => getUserRole.includes(USER_ROLES.headOfSales),
    [getUserRole]
  );

  const highestUserRole = useMemo(() => {
    if (isProjectSalesUser && isProjectSalesHead && isProjectHead) {
      return USER_ROLES.headOfSales;
    } else if (isProjectSalesHead && isProjectHead) {
      return USER_ROLES.headOfSales;
    } else if (isProjectSalesHead && isProjectSalesUser) {
      return USER_ROLES.ProjectSalesHead;
    } else {
      return getUserRole[0];
    }
  }, [getUserRole, isProjectHead, isProjectSalesHead, isProjectSalesUser]);

  return {
    getUserToken,
    salesHead,
    isCurrentUserGRE,
    isCurrentUserCP,
    getUserRole,
    isProjectSalesUser,
    isProjectSalesHead,
    isProjectHead,
    highestUserRole,
    salesLead,
  };
};

export const useNavigateHooks = () => {
  let navigate = useNavigate();

  const navigateToBookings = useCallback(
    () => navigate(`/${BOOKING}`),
    [navigate]
  );

  const navigateToProposals = useCallback(
    () => navigate(`/${PROPOSAL}`),
    [navigate]
  );

  return { navigateToBookings, navigateToProposals };
};

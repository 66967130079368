import { IconButton, IconButtonProps, styled, Typography } from "@mui/material";
import Colors from "../../utils/theme/color";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  color: Colors.primaryColor,
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: Colors.primaryColor,
  fontFamily: "Lora-Bold",
  fontSize: 30,
  [theme.breakpoints.down("xl")]: {
    fontSize: 26,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 24,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 22,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
}));

import MaterialTabs from "../../../components/tabs";
import Colors from "../../../utils/theme/color";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import BookingProposalTable from "./table";
import {
  BookingProposalLostStatuses,
  BookingProposalUnderReviewStatuses,
  Status,
} from "../../../constants/statuses";

const tabLabelStyle = {
  textTransform: "none",
  fontFamily: "Lato-Regular",
  fontSize: 16,
  color: Colors.greyColor,
};
const tabContainerStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  backgroundColor: Colors.white,
  padding: 0,
  margin: 0,
};
const selectedTabStyle = {
  backgroundColor: Colors.cyanBlueColor,
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  color: Colors.primaryColor,
};

interface BookingProposalListTypes {
  searchValue?: string;
}
const BookingProposalList = ({
  searchValue = "",
}: BookingProposalListTypes) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<{
    tabIndex: number;
    tabItem: Status;
  }>({
    tabIndex: 0,
    tabItem: Status.ALL,
  });
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setSearchText(searchValue);
  }, [searchValue]);

  const tabItems = useMemo(
    () => [
      {
        id: Status.ALL,
        label: t("proposal.all"),
      },
      {
        id: Status.UNDER_REVIEW,
        label: t("proposal.under_review"),
      },
      {
        id: Status.CONVERTED,
        label: t("proposal.converted"),
      },
      {
        id: Status.LOST,
        label: t("proposal.lost"),
      },
    ],
    [t]
  );

  const onChangeTab = useCallback(
    (e: object, value: number) => {
      let findSelectedTabItem = tabItems?.filter(
        (item, index) => index === value
      )[0];
      setSelectedTab({ tabIndex: value, tabItem: findSelectedTabItem?.id });
    },
    [setSelectedTab, tabItems]
  );

  const renderTabPanel = () => (
    <BookingProposalTable
      statuses={
        selectedTab?.tabItem === Status.UNDER_REVIEW
          ? BookingProposalUnderReviewStatuses
          : selectedTab?.tabItem === Status.CONVERTED
          ? [Status.BOOKING_INITIATED]
          : selectedTab?.tabItem === Status.LOST
          ? BookingProposalLostStatuses
          : null
      }
      search={searchText}
    />
  );

  const renderTabs = () => (
    <MaterialTabs
      variant={"scrollable"}
      tabItems={tabItems}
      value={selectedTab?.tabIndex}
      TabIndicatorProps={{
        sx: {
          bgcolor: Colors.cyanBlueColor,
          height: 0,
        },
      }}
      tabLabelStyle={tabLabelStyle}
      styles={{
        selectedTabStyle: selectedTabStyle,
        boxContainer: tabContainerStyle,
        tabPanelStyle: { p: 0 },
      }}
      onChange={onChangeTab}
      selectedTabIndex={selectedTab?.tabIndex}
      isTabPanelRequired={true}
      tabContent={renderTabPanel()}
      isScrollable={false}
    />
  );
  return renderTabs();
};

export default BookingProposalList;

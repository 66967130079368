import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CardContent, Card, Box } from "@mui/material";
import { toast } from "react-toastify";
import { ActionMeta, SingleValue } from "react-select";
import moment from "moment";
import TextInput from "../../components/text-input/text-input";
import BookingDetailsTitle from "./booking-details-title";
import "./applicant-form.css";
import { VALIDATION_REGEXPS } from "../../constants";
import {
  EMPTY_PAYMENT_DETAILS,
  PaymentDetailsTypes,
} from "../../utils/booking-utils";
import Dropdown from "../../components/dropdown";
import {
  useGetBankListQuery,
  useGetCityListQuery,
  useGetPaymentModeListQuery,
} from "../../../features/api/master-api-slice";
import { useRedirectToLogin, useGetUserDetailsHooks } from "../../hooks";
import { API_CONSTANTS } from "../../../app/constants";
import DatePickerComponent from "../../components/date-picker";
import {
  getDateAfterThreeMonths,
  YYYY_MM_DD_WITH_HYPHEN,
} from "../../utils/date";
import { useScreenSizeApplier } from "../../hooks";
import ApplicantPhoto from "./applicant-photo";
import { getFileType } from "../../utils/functions";

const ERROR = "error";
export const CHEQUE_OR_DEMAND_DRAFT_NUMBER_MAX_LIMIT = 30;
const PaymentDetails = ({
  paymentData,
  onUpdate,
  isEditable = true,
  numberOfApplicant,
}: PaymentDetailsTypes) => {
  const [cityList, setCityList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [paymentModeList, setPaymentModeList] = useState([]);

  const [paymentDetails, setPaymentDetails] = useState(paymentData);
  const paymentDetailsRef = useRef(paymentDetails);
  const { t } = useTranslation();
  const { getUserToken } = useGetUserDetailsHooks();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();

  const { mergeStyles } = useScreenSizeApplier();

  const {
    data: getCityListResponse,
    isSuccess: isGetCityListSuccess,
    isError: isGetCityListFailed,
    error: getCityListError,
  } = useGetCityListQuery({ userToken: getUserToken() });

  const {
    data: getBankListResponse,
    isSuccess: isGetBankListSuccess,
    isError: isGetBankListFailed,
    error: getBankListError,
  } = useGetBankListQuery({
    userToken: getUserToken(),
  });

  const {
    data: getPaymentModesResponse,
    isSuccess: isGetPaymentModesSuccess,
    isError: isGetPaymentModesFailed,
    error: getPaymentModesError,
  } = useGetPaymentModeListQuery({
    userToken: getUserToken(),
  });

  useEffect(() => {
    if (isGetCityListSuccess) {
      if (
        getCityListResponse?.data?.length &&
        getCityListResponse?.status_code === API_CONSTANTS.successStatusCode
      ) {
        setCityList(getCityListResponse?.data);
      } else if (getCityListResponse?.message) {
        toast(getCityListResponse?.message, {
          type: ERROR,
        });
        setCityList([]);
      }
    } else if (isGetCityListFailed) {
      redirectToLoginBasedOnResponse(getCityListError, getCityListResponse);
      setCityList([]);
    }
    // eslint-disable-next-line
  }, [
    getCityListResponse,
    isGetCityListSuccess,
    isGetCityListFailed,
    t,
    getCityListError,
  ]);

  useEffect(() => {
    if (isGetBankListSuccess) {
      if (
        Array.isArray(getBankListResponse.data) &&
        getBankListResponse?.status_code === API_CONSTANTS.successStatusCode
      ) {
        setBankList(getBankListResponse?.data);
      } else if (getBankListResponse?.message) {
        toast(getBankListResponse?.message, {
          type: ERROR,
        });
        setBankList([]);
      }
    } else if (isGetBankListFailed) {
      redirectToLoginBasedOnResponse(getBankListError, getBankListResponse);
      setBankList([]);
    }
    // eslint-disable-next-line
  }, [
    getBankListResponse,
    isGetBankListSuccess,
    isGetBankListFailed,
    t,
    getBankListError,
  ]);

  useEffect(() => {
    if (isGetPaymentModesSuccess) {
      if (
        getPaymentModesResponse.data?.length &&
        getPaymentModesResponse?.status_code === API_CONSTANTS.successStatusCode
      ) {
        setPaymentModeList(getPaymentModesResponse?.data);
      } else if (getPaymentModesResponse?.message) {
        toast(getPaymentModesResponse?.message, {
          type: ERROR,
        });
        setPaymentModeList([]);
      }
    } else if (isGetPaymentModesFailed) {
      redirectToLoginBasedOnResponse(
        getPaymentModesError,
        getPaymentModesResponse
      );
      setPaymentModeList([]);
    }
    // eslint-disable-next-line
  }, [
    getPaymentModesResponse,
    isGetPaymentModesSuccess,
    isGetPaymentModesFailed,
    t,
    getPaymentModesError,
  ]);

  useEffect(() => {
    if (!Object.is(paymentDetails, EMPTY_PAYMENT_DETAILS)) {
      paymentDetailsRef.current = paymentDetails;
      onUpdate(paymentDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentDetails]);

  useEffect(() => {
    setPaymentDetails(paymentData);
  }, [paymentData]);

  const onChangeReceivedAmount = useCallback(
    (e: { target: { name: string; value: string } }) => {
      setPaymentDetails({
        ...paymentDetailsRef?.current,
        receivedAmount: e.target.value.replace(
          VALIDATION_REGEXPS.matchesAnyNumbers,
          ""
        ),
      });
    },
    [setPaymentDetails]
  );

  const onChangeBranch = useCallback(
    (e: { target: { value: string } }) => {
      setPaymentDetails({
        ...paymentDetailsRef?.current,
        branch: e.target.value.replace(
          VALIDATION_REGEXPS.matchesAnyCharacters,
          ""
        ),
      });
    },
    [setPaymentDetails]
  );

  const onChangeDemandDraftDate = useCallback(
    (e: { target: { value: string } }) => {
      setPaymentDetails({
        ...paymentDetailsRef?.current,
        chequeOrDemandDraftDate: e.target.value,
      });
    },
    [setPaymentDetails]
  );

  const onChangeBranchCity = useCallback(
    (
      newValue: SingleValue<{
        value: string;
        label: string;
      }>,
      actionMeta: ActionMeta<{
        value: string;
        label: string;
      }>
    ) => {
      setPaymentDetails({
        ...paymentDetailsRef?.current,
        city: newValue?.value,
      });
    },
    []
  );

  const onChangeDemandDraftNumber = useCallback(
    (e: { target: { value: string } }) => {
      setPaymentDetails({
        ...paymentDetailsRef?.current,
        chequeOrDemandDraftNumber: e.target.value.replace(
          VALIDATION_REGEXPS.matchesAnyNumbers,
          ""
        ),
      });
    },
    [setPaymentDetails]
  );

  const getBankAsLabelAndValue = useCallback(
    (list: { bank_name: string }[]) =>
      list?.map((item: { bank_name: string }) => {
        return { value: item.bank_name, label: item.bank_name };
      }),
    []
  );

  const onChangeBank = useCallback(
    (
      newValue: SingleValue<{
        value: string;
        label: string;
      }>,
      actionMeta: ActionMeta<{
        value: string;
        label: string;
      }>
    ) => {
      setPaymentDetails({
        ...paymentDetailsRef?.current,
        bank: newValue?.value,
      });
    },
    [setPaymentDetails]
  );

  const onChangePaymentMode = useCallback(
    (
      newValue: SingleValue<{
        value: string;
        label: string;
      }>,
      actionMeta: ActionMeta<{
        value: string;
        label: string;
      }>
    ) => {
      setPaymentDetails({
        ...paymentDetailsRef?.current,
        paymentMode: newValue?.value,
      });
    },
    [setPaymentDetails]
  );

  const uploadPaymentProof = (
    data: string | ArrayBuffer,
    file: { uri: string; type: string; size: number; name: string }
  ) => {
    setPaymentDetails({
      ...paymentDetailsRef?.current,
      paymentProof: {
        ...paymentDetailsRef?.current?.paymentProof,
        name: t("create_booking.payment_details.proof", {
          applicant: numberOfApplicant,
          type: getFileType(file?.name),
        }),
        type: file?.type,
        data: data,
        isDeleted: false,
      },
    });
  };

  const removeProof = () => {
    setPaymentDetails({
      ...paymentDetailsRef?.current,
      paymentProof: {
        name: "",
        type: "",
        data: "",
        attachment: "",
        isDeleted: true,
      },
    });
  };

  const returnLabelAndValue = (list: { city: string }[]) =>
    list?.map((item: { city: string }) => {
      return { value: item?.city, label: item?.city };
    });

  const returnPaymentLabelAndValue = (list: { mode_of_payment: string }[]) =>
    list?.map((item: { mode_of_payment: string }) => {
      return { value: item?.mode_of_payment, label: item?.mode_of_payment };
    });

  const renderReceivedAmountAndDemandDraftDate = () => (
    <div className="booking-row-input-container">
      <TextInput
        id="received-amount"
        value={paymentDetails?.receivedAmount}
        label={
          !paymentDetailsRef?.current.receivedAmount
            ? t("create_booking.payment_details.received_amount_placeholder")
            : ""
        }
        onChange={onChangeReceivedAmount}
        outerLabel={t("create_booking.payment_details.received_amount")}
        isInputAmount={true}
        markRequiredField={true}
        disabled={!isEditable}
      />
      <div style={{ width: "15%" }} />
      <DatePickerComponent
        date={paymentDetailsRef?.current.chequeOrDemandDraftDate}
        onChangeDate={onChangeDemandDraftDate}
        outerLabel={t(
          "create_booking.payment_details.check_or_demand_draft_date"
        )}
        markRequiredField={true}
        minDate={moment(new Date()).format(YYYY_MM_DD_WITH_HYPHEN)}
        maxDate={getDateAfterThreeMonths(
          new Date(),
          YYYY_MM_DD_WITH_HYPHEN
        ).toString()}
        disabled={!isEditable}
      />
    </div>
  );

  const renderDemandDraftNumberAndBank = () => (
    <div className="booking-row-input-container">
      <TextInput
        id="cheque-or-demand-draft-number-input"
        value={paymentDetails?.chequeOrDemandDraftNumber}
        label={
          !paymentDetailsRef?.current.chequeOrDemandDraftNumber
            ? t(
                "create_booking.payment_details.check_or_demand_draft_no_placeholder"
              )
            : ""
        }
        onChange={onChangeDemandDraftNumber}
        outerLabel={t(
          "create_booking.payment_details.check_or_demand_draft_number"
        )}
        markRequiredField={false}
        maxLength={CHEQUE_OR_DEMAND_DRAFT_NUMBER_MAX_LIMIT}
        helperText={paymentDetails.chequeOrDemandDraftNumberErrorMessage}
        error={Boolean(paymentDetails.chequeOrDemandDraftNumberErrorMessage)}
        disabled={!isEditable}
      />
      <div style={{ width: "15%" }} />
      <Dropdown
        outerLabel={t("create_booking.payment_details.bank")}
        markRequiredField={false}
        dropdownItems={getBankAsLabelAndValue(bankList)}
        inputLabel={t("create_booking.payment_details.bank_placeholder")}
        handleChange={onChangeBank}
        value={paymentDetailsRef.current.bank}
        isDisabled={!isEditable}
      />
    </div>
  );

  const renderBranchAndCity = () => (
    <div className="booking-row-input-container">
      <TextInput
        id="branch-input"
        value={paymentDetails?.branch}
        label={
          !paymentDetailsRef?.current.branch
            ? t("create_booking.payment_details.branch_placeholder")
            : ""
        }
        onChange={onChangeBranch}
        outerLabel={t("create_booking.payment_details.branch")}
        markRequiredField={false}
        disabled={!isEditable}
      />
      <div style={{ width: "15%" }} />
      <Dropdown
        outerLabel={t("create_booking.payment_details.city")}
        markRequiredField={false}
        dropdownItems={returnLabelAndValue(cityList)}
        inputLabel={t("create_booking.payment_details.city_placeholder")}
        handleChange={onChangeBranchCity}
        value={paymentDetailsRef.current.city}
        isDisabled={!isEditable}
      />
    </div>
  );

  const renderPaymentMode = () => (
    <Box
      style={mergeStyles({
        styles: { width: "37%" },
        belowLargeStyles: { width: "37%" },
        tabletStyles: { width: "90%" },
      })}
    >
      <Dropdown
        outerLabel={t("create_booking.payment_details.payment_mode")}
        markRequiredField={true}
        dropdownItems={returnPaymentLabelAndValue(paymentModeList)}
        inputLabel={t(
          "create_booking.payment_details.payment_mode_placeholder"
        )}
        handleChange={onChangePaymentMode}
        value={paymentDetailsRef.current.paymentMode}
        isDisabled={!isEditable}
      />
    </Box>
  );
  const renderPaymentProof = () => (
    <Box
      style={mergeStyles({
        styles: { width: "80%" },
      })}
    >
      <ApplicantPhoto
        uploadPhoto={uploadPaymentProof}
        photo={paymentDetailsRef.current.paymentProof}
        removePhoto={removeProof}
        numberOfApplicant={numberOfApplicant.toString()}
        disabled={!isEditable}
        title={t("create_booking.payment_details.payment_proof")}
        type={t("create_booking.payment_details.payment_proof")}
      />
    </Box>
  );
  return (
    <div className="details-container">
      <BookingDetailsTitle title={t("create_booking.payment_details.title")} />
      <Card sx={{ pl: 2, pr: 2 }} raised>
        <CardContent sx={{ pt: 1 }}>
          {renderPaymentMode()}
          {renderReceivedAmountAndDemandDraftDate()}
          {renderDemandDraftNumberAndBank()}
          {renderBranchAndCity()}
          {renderPaymentProof()}
        </CardContent>
      </Card>
    </div>
  );
};

export default PaymentDetails;

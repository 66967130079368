import React from "react";
import LoginCard from "./login-card";
import { loginImages } from "../../assets/image/login";
import "./login-layout.css";

interface backgroundImgStyleType {
  backgroundImage: string;
}
const backgroundImgStyle: backgroundImgStyleType = {
  backgroundImage: `url(${loginImages.BACKGROUND_IMAGE})`,
};
const logoStyle: backgroundImgStyleType = {
  backgroundImage: `url(${loginImages.LOGO_IMAGE})`,
};

const LoginLayout = (Component: any) => {
  class Wrapper extends React.Component {
    render() {
      return (
        <>
          <div style={backgroundImgStyle} className="login-background">
            <div className="background-color" />
            <div className="logo-style" style={logoStyle} />
            <Component />
          </div>
        </>
      );
    }
  }
  return Wrapper;
};
export const Login = LoginLayout(LoginCard);

import { API_CONSTANTS } from "../../app/constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface requestOtpResponseType {
  status_code: number;
  message: string;
}

interface verifyOtpResponseType {
  status_code: number;
  verifyOtpResponse: object;
  api_key: string;
  api_secret: string;
  user_details: object[];
  message: string;
  role_details: object[];
}

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: API_CONSTANTS.apiURL,
  }),
  tagTypes: [
    "opportunity",
    "unitDetails",
    "opportunityDetails",
    "proposal",
    "Booking",
    "BookingNotes",
    "BookingComments",
    "opportunitySiteVisit",
    "OpportunityComments",
    "ProposalComments",
    "proposalSiteVisit",
    "proposalWorkFlow",
    "BookingList",
    "ProposalPdf",
  ],
  endpoints: (builder) => ({
    requestOtp: builder.mutation<requestOtpResponseType, object>({
      query: (payload) => ({
        url: `bren_erp.v8.authentication.send_otp`,
        method: "POST",
        body: payload,
      }),
    }),
    verifyOtp: builder.mutation<verifyOtpResponseType, object>({
      query: (payload) => ({
        url: `bren_erp.v8.authentication.verify_otp`,
        method: "POST",
        body: payload,
      }),
    }),
    getEmploymentAtList: builder.query({
      query: () => ({
        url: `bren_erp.v8.api.get_employed_at`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    }),
    getEmployeeDetails: builder.query({
      query: ({ userToken }) => ({
        url: `bren_erp.v8.employee.get_employee_profile`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    getProfessionList: builder.query({
      query: ({ userToken }) => ({
        url: `/bren_erp.v8.api.get_profession`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
  }),
});

export const {
  useRequestOtpMutation,
  useVerifyOtpMutation,
  useGetEmploymentAtListQuery,
  useGetEmployeeDetailsQuery,
  useGetProfessionListQuery,
} = apiSlice;

import { apiSlice } from "./api-slice";
const getProposalListByStatus = (status: string[]) =>
  status?.length ? `&status=${status}` : "";

const getProposalListByNameOrPhoneNumber = (searchText: string) =>
  `&search=${searchText}`;

export const ProposalApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProposalList: builder.query({
      query: ({ paginationInfo, selectedStatus, searchText, userToken }) => ({
        url: `bren_erp.v8.booking.get_booking_proposals?page=${
          paginationInfo.page
        }&size=${paginationInfo.size}${getProposalListByStatus(
          selectedStatus
        )}${searchText ? getProposalListByNameOrPhoneNumber(searchText) : ""}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
      providesTags: ["proposal"],
    }),
    createProposal: builder.mutation({
      query: ({ userToken, payload }) => ({
        url: `bren_erp.v8.booking.create_booking_proposal`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
      invalidatesTags: ["opportunity", "proposal"],
    }),
    getProposalDetails: builder.query({
      query: ({ userToken, proposalId }) => ({
        url: `bren_erp.v8.booking.get_booking_proposal?booking_proposal_id=${proposalId}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    fetchProposalComments: builder.query({
      query: ({ userToken, proposalId }) => ({
        url: `bren_erp.v8.booking.get_proposal_internal_comments?booking_proposal_id=${proposalId}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        refetchOnMountOrArgChange: true,
      }),
    }),
    getProposalSiteVisitList: builder.query({
      query: ({ proposalId, paginationInfo, userToken }) => ({
        url: `bren_erp.v8.booking.get_proposal_site_visits?booking_proposal_id=${proposalId}&page=${paginationInfo.page}&size=${paginationInfo.size}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        refetchOnMountOrArgChange: true,
      }),
      providesTags: ["proposalSiteVisit"],
    }),
    addProposalSiteVisit: builder.mutation({
      query: ({ payload, userToken }) => ({
        url: `bren_erp.v8.booking.add_proposal_site_visit`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
      invalidatesTags: ["proposalSiteVisit"],
    }),
    updateProposalComments: builder.mutation({
      query: ({ userToken, payload }) => ({
        url: `bren_erp.v8.booking.add_proposal_comments`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
    }),
    getProposalWorkFlow: builder.query({
      query: ({ userToken, role, currentWorkFlow }) => ({
        url: `bren_erp.v8.booking.get_booking_proposal_actions_and_workflows?role=${role}&current_workflow_state=${currentWorkFlow}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        refetchOnMountOrArgChange: true,
      }),
    }),
    updateProposalWorkFlow: builder.mutation({
      query: ({ userToken, payload }) => ({
        url: `bren_erp.v8.booking.update_proposal_workflow`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
    }),
    fetchProposalDocument: builder.query({
      query: ({ userToken, proposalId }) => ({
        url: `bren_erp.v8.booking.generate_proposal_pdf?proposal_id=${proposalId}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    sendCostBreakUp: builder.mutation({
      query: ({ userToken, payload }) => ({
        url: `bren_erp.v8.booking.trigger_messages`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
    }),
    updateProposal: builder.mutation({
      query: ({ userToken, payload }) => ({
        url: `bren_erp.v8.booking.update_booking_proposal`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetProposalListQuery,
  useCreateProposalMutation,
  useGetProposalDetailsQuery,
  useUpdateProposalMutation,
  useUpdateProposalCommentsMutation,
  useFetchProposalCommentsQuery,
  useAddProposalSiteVisitMutation,
  useGetProposalSiteVisitListQuery,
  useUpdateProposalWorkFlowMutation,
  useGetProposalWorkFlowQuery,
  useFetchProposalDocumentQuery,
  useSendCostBreakUpMutation,
} = ProposalApiSlice;

import { useTranslation } from "react-i18next";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { CircularProgress } from "@mui/material";
import MaterialButton from "../button";
import { CustomizedText } from "../styled-componnets";
import "./bottom-bar.css";
import { ButtonContainer, SaveButton } from "./styled-components";
import { useScreenSizeApplier } from "../../hooks";
import Colors from "../../utils/theme/color";
interface CustomizedBottomBarTypes {
  isButtonDisabled?: boolean;
  onPressButton(): void;
  title: string;
  styles?: { bottomTabContainer?: {}; buttonContainer?: {}; buttonStyle?: {} };
  isLink?: boolean;
  leftButtonTitle?: string;
  onClickLeftButton?: () => void;
  rightButtonSupportText?: string;
  saveAsDraft?: () => void;
  isButtonLoading?: boolean;
  isRightButtonRequired?: boolean;
  isLeftButtonRequired?: boolean;
}

const CustomizedBottomBar = ({
  isButtonDisabled = false,
  onPressButton,
  title,
  styles = {},
  isLink = false,
  leftButtonTitle = "",
  onClickLeftButton,
  rightButtonSupportText = "",
  saveAsDraft = () => {},
  isButtonLoading = false,
  isRightButtonRequired = false,
  isLeftButtonRequired = true,
}: CustomizedBottomBarTypes) => {
  const { t } = useTranslation();
  const { mergeStyles } = useScreenSizeApplier();

  return (
    <div
      className="customized-bottom-container"
      style={styles?.bottomTabContainer}
    >
      <ButtonContainer sx={styles?.buttonContainer}>
        {isLeftButtonRequired ? (
          isLink ? (
            <div className="link-container" onClick={onClickLeftButton}>
              <p>{leftButtonTitle}</p>
            </div>
          ) : (
            <SaveButton onClick={saveAsDraft} disabled={isButtonLoading}>
              {t("app_common.save_as_draft")}
              {isButtonLoading && (
                <CircularProgress
                  size={"1rem"}
                  sx={{ ml: 1, color: Colors.primaryColor }}
                />
              )}
            </SaveButton>
          )
        ) : null}
        {isRightButtonRequired && (
          <div
            style={mergeStyles({
              styles: { display: "flex", alignItems: "center" },
              tabletStyles: { display: "flex", flexDirection: "column" },
              belowLargeStyles: { display: "flex", flexDirection: "column" },
            })}
          >
            {rightButtonSupportText ? (
              <CustomizedText
                fontSize={16}
                fontFamily={"Lato-Regular"}
                gutterBottom
                sx={{ mr: 2, display: "flex", alignItems: "center" }}
              >
                <InfoRoundedIcon sx={{ fontSize: 16, mr: 0.5 }} />
                {rightButtonSupportText}
              </CustomizedText>
            ) : null}
            <MaterialButton
              id="send-otp"
              title={title}
              onClick={onPressButton}
              fontSize={20}
              disabled={isButtonDisabled || isButtonLoading}
              size={"small"}
              sx={styles?.buttonStyle}
              isLoading={isButtonLoading}
            />
          </div>
        )}
      </ButtonContainer>
    </div>
  );
};

export default CustomizedBottomBar;

import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { TabPanel } from "./tab-panel-hoc";
import { useScreenSizeApplier } from "../hooks";
import { CustomizedDivider } from "./styled-componnets";

interface TabsPropsType {
  tabItems?: { label: string }[];
  value?: number;
  TabIndicatorProps?: object;
  tabLabelStyle: object;
  onChange(e: object, value: number): void;
  sx?: object;
  selectedTabIndex?: number;
  styles?: {
    tabContainer?: {};
    selectedTabStyle?: {};
    boxContainer?: {};
    tabPanelStyle?: {};
  };
  tabContent?: React.ReactNode;
  isTabPanelRequired?: boolean;
  variant?: "standard" | "scrollable" | "fullWidth";
  isDividerRequired?: boolean;
  isScrollable?: boolean;
}

const MaterialTabs = ({
  tabItems = [{ label: "" }],
  value = 0,
  TabIndicatorProps = {},
  tabLabelStyle = {},
  onChange = () => {},
  sx = {},
  selectedTabIndex = 0,
  styles = { tabContainer: {}, selectedTabStyle: {}, boxContainer: {} },
  tabContent = <div />,
  isTabPanelRequired = false,
  variant = "standard",
  isDividerRequired = false,
  isScrollable = true,
}: TabsPropsType) => {
  const { mergeStyles } = useScreenSizeApplier();

  return (
    <Box
      sx={{
        ...{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        },
        ...styles?.boxContainer,
        ...sx,
      }}
    >
      <Tabs
        value={value}
        variant={variant}
        TabIndicatorProps={TabIndicatorProps}
        onChange={onChange}
        sx={styles?.tabContainer}
        scrollButtons={isScrollable}
        visibleScrollbar={false}
      >
        {tabItems?.map((tab: { label: string }, index: number) => {
          return (
            <Tab
              key={index}
              label={tab?.label}
              style={mergeStyles({
                styles: {
                  ...tabLabelStyle,
                  ...(value === index && styles.selectedTabStyle),
                },
                tabletStyles: { fontSize: 12 },
                belowXlStyles: { fontSize: 16 },
                belowLargeStyles: { fontSize: 14 },
              })}
            />
          );
        })}
      </Tabs>
      {isDividerRequired && (
        <CustomizedDivider width="94%" sx={{ m: 0, p: 0 }} />
      )}
      {isTabPanelRequired ? (
        <TabPanel
          value={value}
          index={selectedTabIndex}
          styles={{ tabPanelContainer: styles?.tabPanelStyle }}
        >
          {tabContent}
        </TabPanel>
      ) : null}
    </Box>
  );
};
export default MaterialTabs;

import { FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { ActionMeta } from "react-select";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Box } from "@mui/material";
import {
  CustomizedDivider,
  CustomizedLink,
  CustomizedText,
} from "../../../components/styled-componnets";
import { useGetUserDetailsHooks, useRedirectToLogin } from "../../../hooks";
import {
  BottomButtonContainer,
  CloseButtonContainer,
  MessageContainer,
  ModifySalesStageModal,
  RowStyledContainer,
  TitleContainer,
} from "../styled-components";
import MaterialButton from "../../../components/button";
import Colors from "../../../utils/theme/color";
import Dropdown from "../../../components/dropdown";
import {
  HH_MM_A,
  YYYY_MM_DD_WITH_HYPHEN,
  convertTo12Hour,
  convertTo24Hour,
  getDateAfterThreeMonths,
  getTimeList,
  isTimeAfterCheckInTime,
} from "../../../utils/date";
import DatePickerComponent from "../../../components/date-picker";
import {
  useGetOpportunityFollowUpQuery,
  useUpdateOpportunityFollowUpMutation,
} from "../../../../features/api/opportunity-api-slice";

const ERROR = "error";
const IST_UTC_OFFSET = "+05:30";
const alignSelfStyle = {
  alignSelf: "center",
  mt: 2,
  display: "flex",
  p: 1,
  width: "91%",
};

interface FollowUpMessageModalContentProps {
  handleClose: () => void;
  name: string;
  project?: string;
  salesExecutive?: string;
}

const FollowUpMessageModalContent: FC<FollowUpMessageModalContentProps> = ({
  handleClose,
  name,
  project = "",
  salesExecutive = "",
}) => {
  const { t } = useTranslation();
  const { getUserToken } = useGetUserDetailsHooks();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();
  const params = useParams();
  const opportunityId = params?.id;

  const [selectedTime, setSelectedTime] = useState("");
  const [date, setDate] = useState("");
  const [followUpName, setFollowUpName] = useState("");

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isGetFollowUpLoading, setIsGetFollowUpLoading] = useState(true);

  const isButtonEnabled =
    Boolean(date) && Boolean(selectedTime) && Boolean(followUpName);

  const timeList = useCallback(
    (timeList: string[]) =>
      timeList?.map((item: string) => ({
        value: item,
        label: item,
      })),
    []
  );

  const {
    data: getFollowUpResponse,
    isSuccess: isGetFollowUpSuccess,
    isError: isGetFollowUpFailed,
    error: getFollowUpError,
    refetch: refetchFollowUp,
  } = useGetOpportunityFollowUpQuery(
    {
      userToken: getUserToken(),
      opportunityId,
    },
    { skip: !Boolean(opportunityId) }
  );

  const [
    updateOpportunityFollowUp,
    {
      isSuccess: isUpdateFollowUpSuccess,
      isError: isUpdateFollowUpFailed,
      data: updateFollowUpResponse,
      error: updateFollowUpError,
    },
  ] = useUpdateOpportunityFollowUpMutation();

  useEffect(() => {
    if (isGetFollowUpLoading) {
      if (isGetFollowUpSuccess) {
        if (getFollowUpResponse?.data[0]?.name) {
          setFollowUpName(getFollowUpResponse?.data[0]?.name);
          setDate(getFollowUpResponse?.data[0]?.send_date);
          setSelectedTime(
            convertTo12Hour(getFollowUpResponse?.data[0]?.send_time)
          );
          setIsGetFollowUpLoading(false);
        } else if (getFollowUpResponse?.message) {
          toast(getFollowUpResponse?.message, {
            type: ERROR,
          });
          setIsGetFollowUpLoading(false);
        }
      } else if (isGetFollowUpFailed) {
        setIsGetFollowUpLoading(false);
        redirectToLoginBasedOnResponse(getFollowUpError, getFollowUpResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getFollowUpResponse,
    isGetFollowUpSuccess,
    isGetFollowUpFailed,
    getFollowUpError,
    isGetFollowUpLoading,
  ]);

  useEffect(() => {
    if (isButtonLoading) {
      if (isUpdateFollowUpSuccess) {
        if (updateFollowUpResponse?.data === opportunityId) {
          toast(updateFollowUpResponse.message, {
            type: "success",
          });
          handleClose();
        } else if (updateFollowUpResponse?.message) {
          toast(updateFollowUpResponse?.message, {
            type: ERROR,
          });
        }
        setIsButtonLoading(false);
      } else if (isUpdateFollowUpFailed) {
        redirectToLoginBasedOnResponse(
          updateFollowUpError,
          updateFollowUpResponse
        );
        setIsButtonLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateFollowUpResponse,
    isUpdateFollowUpSuccess,
    isUpdateFollowUpFailed,
    updateFollowUpError,
    isButtonLoading,
    followUpName,
  ]);

  const getSendTimeList = useCallback(() => {
    const currentDate = moment(new Date())
      .utcOffset(IST_UTC_OFFSET)
      .format(YYYY_MM_DD_WITH_HYPHEN);
    const timeList = getTimeList();
    if (date === currentDate) {
      return timeList.filter((time: string) => {
        const currentTime = moment(new Date())
          .utcOffset(IST_UTC_OFFSET)
          .format(HH_MM_A);
        return isTimeAfterCheckInTime(time, currentTime);
      });
    } else {
      return timeList;
    }
  }, [date]);

  useEffect(() => {
    refetchFollowUp();
  }, [refetchFollowUp]);

  const onChangeValue = (
    newValue: any,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>,
    callBack: {
      (value: SetStateAction<string>): void;
      (value: SetStateAction<string>): void;
      (arg0: string): void;
    }
  ) => {
    callBack(newValue?.label);
  };

  const onChangeDate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
    setSelectedTime("");
  }, []);

  const onClickSend = () => {
    setIsButtonLoading(true);
    const payload = {
      name: followUpName,
      opportunity_id: opportunityId,
      send_date: date,
      send_time: convertTo24Hour(selectedTime),
    };
    updateOpportunityFollowUp({ userToken: getUserToken(), payload });
  };

  const renderButton = () => (
    <BottomButtonContainer sx={{ m: 0 }}>
      <CustomizedLink onClick={handleClose}>
        {t("app_common.cancel")}
      </CustomizedLink>
      <MaterialButton
        title={t("opportunity_details.send")}
        id={"send-button"}
        onClick={onClickSend}
        disabled={isButtonLoading || !isButtonEnabled}
        isLoading={isButtonLoading}
      />
    </BottomButtonContainer>
  );

  const renderCloseButton = () => (
    <CloseButtonContainer paddingBottom={0} onClick={handleClose}>
      <CloseIcon fontSize="small" htmlColor={Colors.titleColor} />
    </CloseButtonContainer>
  );

  const renderMessage = () => (
    <Box sx={{ ml: 3, mt: 2 }}>
      <CustomizedText fontSize={20} font={"Lato-Regular"}>
        {t("opportunity_details.follow_up_message")}
      </CustomizedText>
      <MessageContainer sx={alignSelfStyle}>
        <CustomizedText fontSize={16} font={"Lato-Regular"}>
          {t("opportunity_details.message_template", {
            name: name,
            project: project,
            salesExecutive,
          })}
        </CustomizedText>
      </MessageContainer>
    </Box>
  );

  return (
    <ModifySalesStageModal>
      <TitleContainer>
        <CustomizedText fontSize={24}>
          {t("opportunity_details.schedule_follow_up")}
        </CustomizedText>
        {renderCloseButton()}
      </TitleContainer>
      <CustomizedText fontSize={16} sx={{ pl: 3, pr: 3, mb: 1 }}>
        {t("opportunity_details.follow_up_title", { name })}
      </CustomizedText>
      <CustomizedDivider width={"100%"} sx={{ mb: 0, mt: 1 }} />
      {renderMessage()}
      <RowStyledContainer disableGutters>
        <DatePickerComponent
          date={date}
          onChangeDate={onChangeDate}
          outerLabel={t("create_opportunity.date")}
          markRequiredField={true}
          minDate={moment(new Date()).format(YYYY_MM_DD_WITH_HYPHEN)}
          disabled={false}
          styles={{ textInputContainer: { width: "47%" } }}
          maxDate={getDateAfterThreeMonths(
            new Date(),
            YYYY_MM_DD_WITH_HYPHEN
          ).toString()}
        />
        <Dropdown
          outerLabel={t("opportunity_details.send_time")}
          markRequiredField={true}
          dropdownItems={timeList(getSendTimeList())}
          value={selectedTime}
          handleChange={(newValue, metaValue) =>
            onChangeValue(newValue, metaValue, setSelectedTime)
          }
          styles={{ textInputContainer: { width: "47%" } }}
        />
      </RowStyledContainer>
      {renderButton()}
    </ModifySalesStageModal>
  );
};

export default FollowUpMessageModalContent;

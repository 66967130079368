import ENVIRONMENTS from "./app-environments.json";

export const API_CONSTANTS = {
  apiURL: ENVIRONMENTS.API_URL,
  baseURL: ENVIRONMENTS.BASE_URL,
  createdStatusCode: 201,
  successStatusCode: 200,
  authErrorCode: 401,
  badRequestStatusCode: 404,
  profileRole: "Employee",
  appId: "sales_person_app",
  permissionError: "PermissionError",
  sessionExpiredStatusCode: 403,
};

export const APP_CONSTANTS = {
  indianCountryCode: "91",
  defaultPage: 1,
  defaultPageSize: 6,
  indianCode: "IN",
};

export const UNICODE = {
  dot: "\u2B24",
  rupeeSymbol: "\u20b9 \t",
};
export class VALIDATION_REGEXPS {
  static phoneNumberRegexp = /^[0-9]{10,10}$/;
  static matchesAnyNumbers = /[^0-9]+/g;
  static matchesAnyCharacters = /[^a-zA-Z ]/g;
  static emailIdRegex = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  static matchesAnyCharactersAndNumbers = /[^a-zA-Z,0-9]/g;
  static whiteSpacesRegex = /\s+/g;
}

export const USER_ROLES = {
  headOfSales: "Head Of Sales",
  GRE: "Guest Relation Executive",
  ChannelPartner: "Channel Partner",
  ProjectSalesUser: "Project Sales User",
  ProjectSalesHead: "Project Sales Head",
};

import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, FormGroup, Switch } from "@mui/material";
import { ActionMeta, SingleValue } from "react-select";
import { toast } from "react-toastify";
import { CircleOutlined } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextInput from "../../components/text-input/text-input";
import BookingDetailsTitle from "./booking-details-title";
import {
  CardContainer,
  Label,
  StyledCard,
  StyledCheckBox,
} from "./styled-component";
import { VALIDATION_REGEXPS } from "../../constants";
import Colors from "../../utils/theme/color";
import {
  EMPTY_HOME_LOAN_DETAILS,
  HomeLoanTypes,
} from "../../utils/booking-utils";
import "./applicant-form.css";
import Dropdown from "../../components/dropdown";
import { useGetBankListQuery } from "../../../features/api/master-api-slice";
import {
  useRedirectToLogin,
  useGetUserDetailsHooks,
  useScreenSizeApplier,
} from "../../hooks";
import { API_CONSTANTS } from "../../../app/constants";

const HomeLoan = ({
  homeLoanData,
  onUpdate,
  isEditable = true,
}: HomeLoanTypes) => {
  const [homeLoan, setHomeLoan] = useState(homeLoanData);
  const [bankList, setBankList] = useState([]);

  const homeLoanRef = useRef(homeLoan);
  const { t } = useTranslation();
  const { getUserToken } = useGetUserDetailsHooks();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();
  const { mergeStyles } = useScreenSizeApplier();

  const {
    data: getBankListResponse,
    isSuccess: isGetBankListSuccess,
    isError: isGetBankListFailed,
    error: getBankListError,
  } = useGetBankListQuery({
    userToken: getUserToken(),
  });

  useEffect(() => {
    if (isGetBankListSuccess) {
      if (
        getBankListResponse.data?.length &&
        getBankListResponse?.status_code === API_CONSTANTS.successStatusCode
      ) {
        setBankList(getBankListResponse?.data);
      } else if (getBankListResponse?.message) {
        toast(getBankListResponse?.message, {
          type: "error",
        });
        setBankList([]);
      }
    } else if (isGetBankListFailed) {
      redirectToLoginBasedOnResponse(getBankListError, getBankListResponse);
      setBankList([]);
    }
    // eslint-disable-next-line
  }, [
    getBankListResponse,
    isGetBankListSuccess,
    isGetBankListFailed,
    t,
    getBankListError,
  ]);

  useEffect(() => {
    if (!Object.is(homeLoan, EMPTY_HOME_LOAN_DETAILS)) {
      homeLoanRef.current = homeLoan;
      onUpdate(homeLoan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeLoan]);

  const onChangeFromBank = useCallback(
    (
      newValue: SingleValue<{
        value: string;
        label: string;
      }>,
      actionMeta: ActionMeta<{
        value: string;
        label: string;
      }>
    ) => {
      setHomeLoan({
        ...homeLoanRef?.current,
        fromBank: newValue?.value,
      });
    },
    [setHomeLoan]
  );

  const onChangeServiceBy = useCallback(
    (e: { target: { value: string } }) => {
      setHomeLoan({
        ...homeLoanRef?.current,
        serviceBy: e.target.value?.replace(
          VALIDATION_REGEXPS.matchesAnyCharacters,
          ""
        ),
      });
    },
    [setHomeLoan]
  );

  const onChangeAnnualIncome = useCallback(
    (e: { target: { value: string } }) => {
      setHomeLoan({
        ...homeLoanRef?.current,
        annualIncome: e.target.value.replace(
          VALIDATION_REGEXPS.matchesAnyNumbers,
          ""
        ),
      });
    },
    [setHomeLoan]
  );

  const onChangeInterestedLoanAmount = useCallback(
    (e: { target: { value: string } }) => {
      setHomeLoan({
        ...homeLoanRef?.current,
        loanAmountInterested: e.target.value.replace(
          VALIDATION_REGEXPS.matchesAnyNumbers,
          ""
        ),
      });
    },
    [setHomeLoan]
  );

  const onChangeSanctionedAmount = useCallback(
    (e: { target: { value: string } }) => {
      setHomeLoan({
        ...homeLoanRef?.current,
        sanctionedAmount: e.target.value.replace(
          VALIDATION_REGEXPS.matchesAnyNumbers,
          ""
        ),
      });
    },
    [setHomeLoan]
  );

  const onChangeSetHomeLoan = useCallback(
    (e: object, checked: boolean) => {
      setHomeLoan({
        ...homeLoanRef?.current,
        isHomeLoan: checked,
      });
    },
    [setHomeLoan]
  );

  const onChangeIsLoanSanctioned = useCallback(
    (e: object, checked: boolean) => {
      setHomeLoan({
        ...homeLoanRef?.current,
        isLoanSanctioned: checked,
      });
    },
    []
  );

  const getBankAsLabelAndValue = useCallback(
    (list: { bank_name: string }[]) =>
      list?.map((item: { bank_name: string }) => {
        return { value: item.bank_name, label: item.bank_name };
      }),
    []
  );

  const renderBankAndServiceBy = () => (
    <div className="booking-row-input-container">
      <Dropdown
        outerLabel={t("create_booking.home_loan.from_bank")}
        markRequiredField={false}
        dropdownItems={getBankAsLabelAndValue(bankList)}
        inputLabel={t("create_booking.payment_details.bank_placeholder")}
        handleChange={onChangeFromBank}
        value={homeLoanRef.current.fromBank}
        isDisabled={!isEditable}
      />
      <div style={{ width: "15%" }} />
      <TextInput
        id="service_by"
        value={homeLoan?.serviceBy}
        label={
          !homeLoanRef?.current.serviceBy
            ? t("create_booking.home_loan.services_by_placeholder")
            : ""
        }
        onChange={onChangeServiceBy}
        outerLabel={t("create_booking.home_loan.services_by")}
        markRequiredField={false}
        disabled={!isEditable}
      />
    </div>
  );

  const renderAnnualIncome = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: { m: 0, display: "flex", width: "37.3%" },
        belowLargeStyles: { width: "47.5%" },
        tabletStyles: { width: "100%" },
      })}
    >
      <TextInput
        id="annual-income-input"
        value={homeLoan?.annualIncome}
        label={
          !homeLoanRef?.current.annualIncome
            ? t("create_booking.home_loan.annual_income_placeholder")
            : ""
        }
        onChange={onChangeAnnualIncome}
        outerLabel={t("create_booking.home_loan.annual_income")}
        markRequiredField={false}
        isInputAmount={true}
        disabled={!isEditable}
      />
    </Container>
  );

  const renderLoanAmountInterested = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: { m: 0, display: "flex", width: "37.3%" },
        belowLargeStyles: { width: "47.5%" },
        tabletStyles: { width: "100%" },
      })}
    >
      <TextInput
        id="loan_amount_interested-input"
        value={homeLoan?.loanAmountInterested}
        label={
          !homeLoanRef.current?.loanAmountInterested
            ? t("create_booking.home_loan.enter_amount")
            : ""
        }
        onChange={onChangeInterestedLoanAmount}
        outerLabel={t("create_booking.home_loan.loan_amount")}
        markRequiredField={false}
        isInputAmount={true}
        disabled={!isEditable}
      />
    </Container>
  );

  const renderIsLoanSanctioned = () => (
    <FormGroup sx={{ alignSelf: "flex-start" }}>
      <Label
        disableTypography
        control={
          <StyledCheckBox
            icon={<CircleOutlined />}
            checkedIcon={<CheckCircleIcon />}
            value={homeLoanRef.current?.isLoanSanctioned}
            sx={{ p: 0, pr: 1 }}
            size={"small"}
          />
        }
        label={t("create_booking.home_loan.loan_sanctioned")}
        labelPlacement="end"
        checked={homeLoanRef?.current.isLoanSanctioned}
        onChange={onChangeIsLoanSanctioned}
        value={homeLoanRef.current?.isLoanSanctioned}
        sx={{ color: Colors.greyColor }}
        disabled={!isEditable}
      />
    </FormGroup>
  );

  const renderSanctionedAmount = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: { m: 0, display: "flex", width: "37.3%" },
        belowLargeStyles: { width: "47.5%" },
        tabletStyles: { width: "100%" },
      })}
    >
      <TextInput
        id="sanctioned-amount-input"
        value={homeLoan?.sanctionedAmount}
        label={
          !homeLoanRef?.current.sanctionedAmount
            ? t("create_booking.home_loan.enter_amount")
            : ""
        }
        onChange={onChangeSanctionedAmount}
        outerLabel={t("create_booking.home_loan.sanctioned_amount")}
        markRequiredField={false}
        isInputAmount={true}
        disabled={!isEditable}
      />
    </Container>
  );

  const renderPlanningToTakeHomeLoanQuery = () => (
    <FormGroup sx={{ alignSelf: "flex-start" }}>
      <Label
        disableTypography
        control={
          <Switch
            checked={homeLoanRef?.current.isHomeLoan}
            value={homeLoanRef?.current.isHomeLoan}
          />
        }
        label={t("create_booking.home_loan.planning_to_take_home_loan")}
        labelPlacement="start"
        checked={homeLoanRef?.current.isHomeLoan}
        onChange={onChangeSetHomeLoan}
        value={homeLoanRef.current?.isHomeLoan}
        disabled={!isEditable}
      />
    </FormGroup>
  );

  return (
    <div className="details-container">
      <BookingDetailsTitle title={t("create_booking.home_loan.title")} />
      <StyledCard sx={{ pl: 2, pr: 2, pt: 0 }} raised>
        <CardContainer>
          {renderPlanningToTakeHomeLoanQuery()}
          {homeLoanRef?.current.isHomeLoan ? (
            <>
              {renderBankAndServiceBy()}
              {renderLoanAmountInterested()}
              {renderIsLoanSanctioned()}
              {renderSanctionedAmount()}
              {renderAnnualIncome()}
            </>
          ) : null}
        </CardContainer>
      </StyledCard>
    </div>
  );
};

export default HomeLoan;

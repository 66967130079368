import {
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Colors from "../../utils/theme/color";
import "./text-input.css";
import React, { ChangeEvent } from "react";
import NumberInputField from "../number-input";
import { bookingImages } from "../../assets/image/booking";
import { CustomizedText } from "../styled-componnets";
import { APP_CONSTANTS } from "../../constants";
interface TextInputPropsType {
  id: string;
  value?: string | number | undefined;
  className?: string;
  label?: string;
  variant?: "standard" | "filled" | "outlined" | undefined;
  size?: "small" | "medium" | undefined;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  error?: boolean;
  helperText?: string;
  focused?: boolean;
  outerLabel?: string;
  markRequiredField: boolean;
  multiline?: boolean;
  InputLabelProps?: {};
  type?: string | any;
  styles?: { textInputContainer?: {}; outerLabel?: {} };
  isDob?: boolean;
  icon?: React.ReactElement;
  disabled?: boolean;
  isMobile?: boolean;
  isInputAmount?: boolean;
  rows?: number;
  maxLength?: number;
  isRupeeIconRequired?: boolean;
  onBlur?: () => void;
}

const OUTLINED = "outlined";

const inputPropsStyle = {
  fontFamily: "Lora-Bold",
  fontSize: 18,
  color: Colors.titleColor,
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: Colors.titleColor,
  },
};

const TextInputProps = {
  backgroundColor: Colors.veryLightGreyColor,
  borderColor: Colors.veryLightGreyColor,
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: Colors.transparentColor,
  },
};

const TextInput = ({
  id = "",
  value = "",
  label = "",
  variant = OUTLINED,
  size = "small",
  onChange = () => {},
  error = false,
  helperText = "",
  focused = false,
  outerLabel = "",
  markRequiredField = false,
  InputLabelProps = {},
  type = "text",
  multiline = false,
  styles = { textInputContainer: {}, outerLabel: {} },
  disabled = false,
  isMobile = false,
  isInputAmount = false,
  rows = 1,
  maxLength = 1000,
  isRupeeIconRequired = false,
  onBlur = () => {},
}: TextInputPropsType) => {
  const theme = useTheme();
  const isBelowXl = useMediaQuery(theme.breakpoints.down("xl"));
  const isBelowLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <div className="text-input-container" style={styles?.textInputContainer}>
      {outerLabel ? (
        <div
          className="text-input-outer-label-container"
          style={styles?.outerLabel}
        >
          <p className="text-input-outer-label">{outerLabel}</p>
          <p className="required-star"> {markRequiredField ? "*" : ""}</p>
        </div>
      ) : null}
      <TextField
        id={id}
        name={id}
        value={value == null ? "" : value}
        variant={variant}
        size={size}
        onChange={onChange}
        error={error}
        helperText={helperText}
        FormHelperTextProps={{ className: "helper-text-style" }}
        focused={focused}
        InputLabelProps={{
          ...{
            style: {
              color: Colors.greyColor,
              fontFamily: "Lato-Regular",
            },
          },
          ...{ focused: false },
          ...{ className: "label" },
          ...InputLabelProps,
          ...{
            shrink: false,
          },
        }}
        type={type}
        multiline={multiline}
        InputProps={{
          sx: {
            ...inputPropsStyle,
            ...(isBelowXl && { fontSize: 16 }),
            ...(isBelowLg && { fontSize: 14 }),
          },

          placeholder: label,
          ...(isInputAmount && {
            inputComponent: NumberInputField as any,
          }),
          // Commented International mobile input for future purpose
          //...(isMobile && {
          //   inputComponent: PhoneInputComponent as any,
          // }),
          ...(isMobile && {
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src={bookingImages.INDIAN_FLAG}
                  height={20}
                  width={20}
                  alt={"FLAG"}
                />
                <CustomizedText fontSize={18} marginLeft={1}>
                  {APP_CONSTANTS.indianCountryCode}
                </CustomizedText>
              </InputAdornment>
            ),
          }),
          ...(isRupeeIconRequired && {
            startAdornment: (
              <InputAdornment position="start">
                <CurrencyRupeeIcon fontSize="small" />
              </InputAdornment>
            ),
          }),
        }}
        inputProps={{ maxLength: maxLength }}
        sx={TextInputProps}
        disabled={disabled}
        rows={rows}
        onBlur={onBlur}
      />
    </div>
  );
};

export default TextInput;

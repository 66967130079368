import React from "react";
import { useTranslation } from "react-i18next";
import { CardActions, CardContent, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import { Add } from "@mui/icons-material";
import Colors from "../../utils/theme/color";
import { CustomizedText } from "../../components/styled-componnets";
interface ExpandableItemCardPropsTypes {
  title?: string;
  addApplicant(): void;
}

const AddApplicant = ({
  title,
  addApplicant,
}: ExpandableItemCardPropsTypes) => {
  const { t } = useTranslation();

  const renderAddButton = () => (
    <>
      <IconButton>
        <Add htmlColor={Colors.primaryColor} />
      </IconButton>
      <CustomizedText fontSize={22}>
        {t("create_booking.add_applicant", { applicant: title })}
      </CustomizedText>
    </>
  );

  return (
    <Card className="add-applicant-container">
      <CardContent>
        <CardActions
          disableSpacing
          className="add-applicant-button"
          onClick={addApplicant}
        >
          {renderAddButton()}
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default AddApplicant;

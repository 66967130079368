import { FC, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Container } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import PrintIcon from "@mui/icons-material/Print";
import { useFileDownloadHooks, useScreenSizeApplier } from "../../hooks";
import {
  BookingHeaderContainer,
  boxContainerProps,
  DocumentsRowStyledBox,
  LoaderContainer,
  ProjectAndLeadDetailsContainer,
  RowStyledBox,
  UserDetailsCard,
} from "./styled-component";
import Colors from "../../utils/theme/color";
import {
  CustomizedLink,
  CustomizedText,
  StatusBox,
} from "../../components/styled-componnets";
import {
  CommentsProps,
  EMPTY_OVER_VIEW_DETAILS,
  OverViewDetailsProps,
} from "../../utils/booking-utils";
import NavigationPath from "../../components/navigation-path";
import { getColorBasedOnWorkflowState } from "../../utils/functions";
import { Status } from "../../constants/statuses";
import MaterialDialog from "../../components/dialog";
import DocumentPreview from "../../components/documents-preview";
import { useGetUserDetailsHooks } from "../../hooks";
import { DocumentProps } from "../../utils/interface";
import MaterialModal from "../../components/modal";
import BookingComments from "../../components/booking-comments";
import CommentComponent from "../../components/booking-comments/comment";

const materialModalProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

const bookingCommentContainer = {
  alignSelf: "center",
  maxHeight: 500,
  position: "fixed",
  height: "auto",
};

const printIconStyle = {
  color: Colors.primaryColor,
  ml: "10px",
  cursor: "pointer",
};

const OverView: FC<{
  isEditable: boolean;
  details: OverViewDetailsProps;
  status: Status;
  documents?: DocumentProps[];
  documentPdf?: string;
  comments?: CommentsProps[];
  onClickAddNote?: (comment: string) => void;
  isAddNoteLoading?: boolean;
  contentType?: string;
}> = ({
  isEditable = true,
  details = EMPTY_OVER_VIEW_DETAILS,
  status = Status.DRAFT,
  documents = [],
  documentPdf = "",
  comments = [],
  onClickAddNote = () => {},
  isAddNoteLoading = false,
  contentType = "Booking",
}) => {
  const { t } = useTranslation();
  const { mergeStyles } = useScreenSizeApplier();
  const navigate = useNavigate();
  const { downloadFile, downloadFileWithBase64 } = useFileDownloadHooks();

  const [isDocumentsModalVisible, setIsDocumentModalVisible] =
    useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [showBookingComments, setShowBookingComments] = useState(false);

  const [comment, setComment] = useState("");

  useEffect(() => {
    if (comment) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [comment]);

  const navigateToBookings = useCallback(
    () => navigate(`/${contentType.toLocaleUpperCase()}`),
    [navigate, contentType]
  );

  const openDocuments = useCallback(
    () => setIsDocumentModalVisible(Boolean(documents?.length)),
    [documents?.length]
  );

  const toggleFilePreview = useCallback(
    () => setIsDocumentModalVisible(!isDocumentsModalVisible),
    [isDocumentsModalVisible]
  );

  const {
    isProjectSalesUser,
    isProjectSalesHead,
    isProjectHead,
    isCurrentUserCP,
  } = useGetUserDetailsHooks();
  
  const onButtonClick = useCallback(() => {
    toast(t("app_common.downloading"), {
      type: "info",
    });
    documents.forEach((doc) => {
      downloadFile(doc);
    });
  }, [documents, downloadFile, t]);

  const toggleBookingCommentsView = useCallback(
    () => setShowBookingComments(!showBookingComments),
    [showBookingComments]
  );

  const onChangeComment = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setComment(event.target.value);
    },
    []
  );

  const onClear = () => setComment("");

  const onPrintDocument = useCallback(() => {
    toast(t("app_common.downloading"), {
      type: "info",
    });
    downloadFileWithBase64(documentPdf, `${details?.firstName}.pdf`);
  }, [details?.firstName, documentPdf, downloadFileWithBase64, t]);

  const documentsDownloadAndView = () => (
    <DocumentsRowStyledBox>
      <CustomizedLink
        sx={{ mr: "1.5rem", cursor: "pointer" }}
        onClick={openDocuments}
      >
        {t("create_booking.view_documents")}
      </CustomizedLink>
      <StatusBox sx={{ p: 1, cursor: "pointer" }} onClick={onButtonClick}>
        <FileDownloadOutlinedIcon
          sx={{ color: Colors.primaryColor, mr: "10px" }}
          fontSize={"medium"}
        />
        <CustomizedText
          fontSize={18}
          fontColor={Colors.titleColor}
          font={"Lato-Regular"}
        >
          {t("app_common.download")}
        </CustomizedText>
      </StatusBox>
    </DocumentsRowStyledBox>
  );

  const renderBookingStatus = () => (
    <>
      <StatusBox sx={boxContainerProps}>
        <CustomizedText
          fontSize={18}
          fontColor={getColorBasedOnWorkflowState(status)}
          font={"Lato-Regular"}
        >
          {status}
        </CustomizedText>
      </StatusBox>
      {!isEditable && (
        <CustomizedText
          fontSize={18}
          fontColor={Colors.titleColor}
          font={"Lato-Regular"}
          sx={{ ml: "0.5rem" }}
        >
          {t("create_booking.read_only")}
        </CustomizedText>
      )}
    </>
  );

  const renderDocuments = () => (
    <DocumentPreview
      documents={documents}
      closeDocumentPreview={toggleFilePreview}
    />
  );

  const renderLeadName = () => (
    <>
      {details?.allocatedSalesTeam && (
        <>
          <Tooltip
            title={"Allocated sales executive"}
            arrow
            placement="right-start"
          >
            <AccountCircleOutlinedIcon
              htmlColor={Colors.primaryColor}
              fontSize={"small"}
              sx={mergeStyles({
                styles: { paddingLeft: 2 },
                tabletStyles: { fontSize: 14 },
              })}
            />
          </Tooltip>
          <CustomizedText
            fontSize={18}
            fontColor={Colors.titleColor}
            sx={{ paddingLeft: 1 }}
          >
            {`${details?.allocatedSalesTeam}`}
          </CustomizedText>
        </>
      )}
      {details?.applicant?.applicant_name && (
        <>
          <Tooltip title={"Primary Applicant"} arrow placement="right-start">
            <AccountCircleOutlinedIcon
              sx={mergeStyles({
                styles: { paddingLeft: details?.firstName ? 2 : 0 },
                tabletStyles: { fontSize: 14 },
              })}
              htmlColor={Colors.primaryColor}
              fontSize={"small"}
            />
          </Tooltip>
          <CustomizedText
            fontSize={18}
            fontColor={Colors.titleColor}
            sx={{ paddingLeft: 1 }}
          >
            {`${details?.applicant?.applicant_name} ${details?.applicant?.last_name}`}
          </CustomizedText>
        </>
      )}
    </>
  );

  const renderProject = () => (
    <>
      <HomeOutlinedIcon
        htmlColor={Colors.primaryColor}
        fontSize={"small"}
        sx={mergeStyles({
          tabletStyles: { fontSize: 14 },
        })}
      />
      <CustomizedText
        fontSize={18}
        fontColor={Colors.titleColor}
        sx={{ paddingLeft: 1 }}
      >
        {details?.project}
      </CustomizedText>
      <Box
        borderRight={0.5}
        style={{ paddingInline: 10 }}
        borderColor={Colors.greyColor}
      />
    </>
  );

  const renderUnit = () => (
    <CustomizedText fontSize={28} fontColor={Colors.primaryColor}>
      {details?.unit}
    </CustomizedText>
  );

  const renderBookingComments = () => (
    <BookingComments
      handleClose={toggleBookingCommentsView}
      commentList={comments}
      comment={comment}
      onClickAddNote={() => onClickAddNote(comment)}
      onChangeComment={onChangeComment}
      onClear={onClear}
      isButtonDisabled={isButtonDisabled}
      isAddNoteLoading={isAddNoteLoading}
      sx={{ bookingCommentsContainerStyle: bookingCommentContainer }}
    />
  );

  const renderPrintIcon = () => (
    <PrintIcon
      sx={printIconStyle}
      fontSize={"medium"}
      onClick={onPrintDocument}
    />
  );

  return (
    <BookingHeaderContainer sx={{ paddingTop: 3 }}>
      {details?.unit ? (
        <>
          <Box sx={{ backgroundColor: Colors.white }}>
            <Container sx={{ m: 0, paddingTop: 2 }}>
              <NavigationPath
                initialPath={t("user_details.all", {
                  contentType,
                })}
                forwardPath={
                  isEditable
                    ? t("user_details.edit_title", { contentType })
                    : t("user_details.details", { contentType })
                }
                onClick={navigateToBookings}
              />
            </Container>
            <UserDetailsCard>
              {details?.unit ? (
                <RowStyledBox sx={{ cursor: "default" }}>
                  {renderUnit()}
                  {renderBookingStatus()}
                  {documentPdf && renderPrintIcon()}
                </RowStyledBox>
              ) : null}
              <ProjectAndLeadDetailsContainer>
                {details?.project && renderProject()}
                {renderLeadName()}
              </ProjectAndLeadDetailsContainer>
            </UserDetailsCard>
          </Box>
          {(!isCurrentUserCP || isProjectHead || isProjectSalesHead || isProjectSalesUser) && documents?.length && !isEditable && documentsDownloadAndView()}
          <MaterialDialog
            children={renderDocuments()}
            sx={materialModalProps}
            open={isDocumentsModalVisible}
            onClose={toggleFilePreview}
            maxWidth={"lg"}
          />
        </>
      ) : (
        <LoaderContainer>
          <CircularProgress
            size={"1.5rem"}
            sx={{ color: Colors.primaryColor }}
          />
        </LoaderContainer>
      )}
      {comments?.length && (
        <CommentComponent
          details={comments[0]}
          onClickViewNotes={toggleBookingCommentsView}
          sx={{ commentContainerStyle: { width: "94%" } }}
        />
      )}
      <MaterialModal
        open={showBookingComments}
        sx={materialModalProps}
        children={renderBookingComments()}
      />
    </BookingHeaderContainer>
  );
};

export default OverView;

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import RouteIcon from "@mui/icons-material/Route";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from "@mui/icons-material/Update";
import moment from "moment";
import Layout from "../../components/layout";
import ProjectAndUnitDetails from "./components/project-and-unit-details";
import {
  useAddOpportunitySiteVisitMutation,
  useFetchOpportunityCommentsQuery,
  useGetOpportunityDetailsQuery,
  useUpdateOpportunityCommentsMutation,
  useUpdateOpportunityMutation,
} from "../../../features/api/opportunity-api-slice";
import { useGetUserDetailsHooks, useRedirectToLogin } from "../../hooks";
import { API_CONSTANTS } from "../../constants";
import CustomerDetails from "./components/customer-details";
import { useGetUnitDetailsByIdQuery } from "../../../features/api/project-api-slice";
import { useParams } from "react-router-dom";
import MaterialModal from "../../components/modal";
import NotInterestedModalContent from "../../components/not-interested-modal-content";
import NavigationPath from "../../components/navigation-path";
import {
  CustomizedDivider,
  CustomizedLink,
  CustomizedText,
  SolidButton,
} from "../../components/styled-componnets";
import {
  AssigneeListModalContainer,
  ButtonContainer,
  CommentAndNotesCard,
  DetailsAndNotesEventsContainer,
  OpportunityDetailsAndComments,
  TitleAndButtonContainer,
  StyledButton,
  ConvertAgainButtonContainer,
} from "./styled-components";
import { OpportunityEditableStatuses, Status } from "../../constants/statuses";
import Colors from "../../utils/theme/color";
import { useGetSalesPersonListQuery } from "../../../features/api/master-api-slice";
import SiteVisitDetails from "../../components/site-visit-details";
import {
  YYYY_MM_DD_WITH_HYPHEN,
  convertTo24Hour,
  convertToSeconds,
} from "../../utils/date";
import ModifySalesStageModalContent from "./components/modify-sales-stage-modal";
import BookingComments from "../../components/booking-comments";
import { CommentsProps } from "../../utils/booking-utils";
import PreSalesSiteVisitUpdate from "./components/presales-site-visit-update";
import { useCreateProposalMutation } from "../../../features/api/proposals-api-slice";
import FollowUpMessageModalContent from "./components/trigger-follow-up-message";
import ProjectAndUnitPreference from "../../components/project-and-unit-preference";
import { ModifySalesStageModal } from "./styled-components";
import MaterialButton from "../../components/button";

const ERROR = "error";
const SUCCESS = "success";
const OPPORTUNITIES = "OPPORTUNITIES";
const SITE_VISIT_COMPLETED = "Site Visit Completed";
const PROPOSAL = "proposal";

interface OpportunityDetailsTypes {
  name?: string;
  first_name?: string;
  last_name?: string;
  contact_mobile?: string;
  contact_email?: string;
  status?: Status;
  project?: string;
  allocated_sales_team?: string;
  unit?: string;
  modified?: string;
  unit_type?: string;
  transaction_date?: string;
  budget_range?: string;
  employed_at?: string;
  source?: string;
  sales_stage?: string;
  pre_sales_owner?: string;
  lost_reasons?: { lost_reason: string }[];
  follow_up_date?: string;
  follow_up_time?: string;
  follow_up_comment?: string;
  order_lost_reason?: string;
}

export interface UnitDetailsType {
  name?: string;
  bhk?: string;
  unit?: string;
  floor?: string;
  block?: string;
  total_cost?: string;
  super_builtup_area?: string;
  carpet_area?: string;
}
export const materialModalProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const bottomTabContainerStyle = {
  marginLeft: "3rem",
  width: "100%",
  justifyContent: "space-between",
  marginRight: "3rem",
};

const closeIconStyle = {
  m: 1,
  color: Colors.greyColor,
  alignSelf: "end",
  cursor: "pointer",
};

const assigneeStyle = {
  p: 2,
  pr: 15,
  pl: 15,
  textAlign: "center",
};
const saveButtonStyle = { alignSelf: "center", width: "30%" };

const OpportunityDetails = (props: any) => {
  const params = useParams();
  const { t } = useTranslation();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();

  const { getUserToken, isCurrentUserGRE,  isCurrentUserCP } = useGetUserDetailsHooks();

  const navigate = useNavigate();
  const opportunityId = params?.id;

  const [opportunityDetails, setOpportunityDetails] =
    useState<OpportunityDetailsTypes>({});
  const [unitDetails, setUnitDetails] = useState<UnitDetailsType>({});

  const [showNoInterestedModal, setShowNotInterestedModal] =
    useState<boolean>(false);
  const [isBottomBarHidden, setIsBottomBarHidden] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isReAssignModalVisible, setReAssignModalVisible] = useState(false);
  const [isSiteVisitUpdateLoading, setIsSiteVisitUpdateLoading] =
    useState(false);
  const [isModifySalesStageModalVisible, setIsModifySalesStageModalVisible] =
    useState(false);
  const [isTriggerFollowUpModalVisible, setIsTriggerFollowUpModalVisible] =
    useState(false);
  const [comments, setComments] = useState<CommentsProps[]>([]);
  const [comment, setComment] = useState("");
  const [assigneeList, setAssigneeList] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState<string>();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [isAddNoteLoading, setIsAddNoteLoading] = useState<boolean>(false);
  const [isCreateProposalLoading, setIsCreateProposalLoading] =
    useState<boolean>(false);
  const [isVewAllLostReasons, setIsViewAllLostReasons] =
    useState<boolean>(false);
  const [isConvertAgainModalVisible, setIsConvertAgainModalVisible] =
    useState(false);

  const [projectAndUnitPreference, setProjectAndUnitPreference] = useState<{
    project?: string;
    unit?: string;
  }>({
    project: opportunityDetails?.project,
    unit: opportunityDetails?.unit,
  });

  const projectAndUnitPreferenceRef = useRef(projectAndUnitPreference);

  const {
    data: getOpportunityDetailsResponse,
    isSuccess: isGetOpportunityDetailsSuccess,
    isError: isGetOpportunityDetailsFailed,
    error: getOpportunityDetailsError,
    refetch: refetchOpportunityDetails,
  } = useGetOpportunityDetailsQuery({
    opportunityId,
    userToken: getUserToken(),
  });
  const {
    data: getUnitDetailResponse,
    isSuccess: isGetUnitDetailSuccess,
    isError: isGetUnitDetailFailed,
    error: getUnitDetailError,
    refetch: refetchUnitDetails,
  } = useGetUnitDetailsByIdQuery({
    unit: getOpportunityDetailsResponse?.data?.unit,
    userToken: getUserToken(),
  });

  const [
    updateOpportunity,
    {
      isSuccess: isUpdateOpportunitySuccess,
      isError: isUpdateOpportunityFailed,
      data: updateOpportunityResponse,
      error: updateOpportunityError,
    },
  ] = useUpdateOpportunityMutation();

  const [
    addOpportunitySiteVisit,
    {
      isSuccess: isAddOpportunitySiteVisitSuccess,
      isError: isAddOpportunitySiteVisitFailed,
      data: addOpportunitySiteVisitResponse,
      error: addOpportunitySiteVisitError,
    },
  ] = useAddOpportunitySiteVisitMutation();

  const {
    data: getSalesPersonListResponse,
    isSuccess: isGetSalesPersonListSuccess,
    isError: isGetSalesPersonListFailed,
    error: getSalesPersonListError,
    refetch: refetchSalesPersonList,
  } = useGetSalesPersonListQuery({
    userToken: getUserToken(),
  });
  const [
    updateOpportunityComments,
    {
      isSuccess: isUpdateOpportunityCommentsSuccess,
      isError: isUpdateOpportunityCommentsFailed,
      data: updateOpportunityCommentsResponse,
      error: updateOpportunityCommentsError,
    },
  ] = useUpdateOpportunityCommentsMutation();

  const {
    data: getOpportunityCommentsResponse,
    isSuccess: isGetOpportunityCommentsSuccess,
    isError: isGetOpportunityCommentsFailed,
    error: getOpportunityCommentsError,
    refetch: refetchOpportunityComments,
  } = useFetchOpportunityCommentsQuery(
    {
      userToken: getUserToken(),
      opportunityId,
    },
    { skip: !Boolean(opportunityId) }
  );

  const [
    createProposal,
    {
      isSuccess: isCreateProposalSuccess,
      isError: isCreateProposalFailed,
      data: createProposalResponse,
      error: createProposalError,
    },
  ] = useCreateProposalMutation();

  useEffect(() => {
    if (isAddNoteLoading) {
      if (isUpdateOpportunityCommentsSuccess) {
        if (updateOpportunityCommentsResponse?.data) {
          setIsAddNoteLoading(false);
          toast(updateOpportunityCommentsResponse?.message, {
            type: SUCCESS,
          });
          setComment("");
        } else if (updateOpportunityCommentsResponse?.message) {
          setIsAddNoteLoading(false);
          toast(updateOpportunityCommentsResponse?.message, {
            type: ERROR,
          });
        }
      } else if (isUpdateOpportunityCommentsFailed) {
        redirectToLoginBasedOnResponse(
          updateOpportunityCommentsError,
          updateOpportunityCommentsResponse
        );
        setIsAddNoteLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [
    setIsAddNoteLoading,
    isUpdateOpportunityCommentsSuccess,
    isUpdateOpportunityCommentsFailed,
    updateOpportunityCommentsResponse,
    updateOpportunityCommentsError,
    isAddNoteLoading,
  ]);

  useEffect(() => {
    if (isGetOpportunityCommentsSuccess) {
      if (Array.isArray(getOpportunityCommentsResponse?.data)) {
        setComments(getOpportunityCommentsResponse?.data);
      } else if (getOpportunityCommentsResponse?.message) {
        toast(getOpportunityCommentsResponse?.message, {
          type: ERROR,
        });
        setComments([]);
      }
    } else if (isGetOpportunityCommentsFailed) {
      redirectToLoginBasedOnResponse(
        getOpportunityCommentsError,
        getOpportunityCommentsResponse
      );
      setComments([]);
    }
    // eslint-disable-next-line
  }, [
    getOpportunityCommentsResponse,
    isGetOpportunityCommentsSuccess,
    isGetOpportunityCommentsFailed,
    getOpportunityCommentsError,
  ]);

  useEffect(() => {
    refetchOpportunityComments();
  }, [refetchOpportunityComments]);

  const toggleNotInterestedModal = useCallback(() => {
    setIsViewAllLostReasons(false);
    setShowNotInterestedModal(!showNoInterestedModal);
  }, [showNoInterestedModal]);

  useEffect(() => {
    if (isGetOpportunityDetailsSuccess) {
      if (
        getOpportunityDetailsResponse?.data &&
        getOpportunityDetailsResponse.status_code ===
          API_CONSTANTS.successStatusCode
      ) {
        setOpportunityDetails(getOpportunityDetailsResponse?.data);
      } else if (getOpportunityDetailsResponse?.message) {
        toast(getOpportunityDetailsResponse?.message, {
          type: ERROR,
        });
      }
    } else if (isGetOpportunityDetailsFailed) {
      redirectToLoginBasedOnResponse(
        getOpportunityDetailsError,
        getOpportunityDetailsResponse
      );
    }
  }, [
    getOpportunityDetailsResponse,
    isGetOpportunityDetailsSuccess,
    isGetOpportunityDetailsFailed,
    redirectToLoginBasedOnResponse,
    getOpportunityDetailsError,
  ]);

  useEffect(() => {
    if (isGetUnitDetailSuccess) {
      if (Array.isArray(getUnitDetailResponse?.data)) {
        setUnitDetails(getUnitDetailResponse.data[0]);
      } else if (getUnitDetailResponse?.message) {
        toast(getUnitDetailResponse?.message, {
          type: ERROR,
        });
        setUnitDetails({});
      }
    } else if (isGetUnitDetailFailed) {
      redirectToLoginBasedOnResponse(getUnitDetailError, getUnitDetailResponse);
      setUnitDetails({});
    }
  }, [
    getUnitDetailResponse,
    isGetUnitDetailSuccess,
    isGetUnitDetailFailed,
    redirectToLoginBasedOnResponse,
    getUnitDetailError,
  ]);

  useEffect(() => {
    if (isButtonLoading) {
      if (isUpdateOpportunitySuccess) {
        if (
          updateOpportunityResponse?.data?.name &&
          updateOpportunityResponse?.status_code ===
            API_CONSTANTS.successStatusCode
        ) {
          toast(updateOpportunityResponse.message, {
            type: "success",
          });
          setShowNotInterestedModal(false);
          setIsButtonLoading(false);
        } else if (updateOpportunityResponse?.message) {
          toast(updateOpportunityResponse?.message, {
            type: ERROR,
          });
          setIsButtonLoading(false);
        }
      } else if (isUpdateOpportunityFailed) {
        redirectToLoginBasedOnResponse(
          updateOpportunityError,
          updateOpportunityResponse
        );
        setIsButtonLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateOpportunityResponse,
    isUpdateOpportunitySuccess,
    isUpdateOpportunityFailed,
    updateOpportunityError,
    isButtonLoading,
  ]);

  const isProposalConverted = useMemo(
    () => opportunityDetails?.status === Status.CONVERTED,
    [opportunityDetails?.status]
  );

  useEffect(() => {
    if (
      opportunityDetails?.status &&
      !isProposalConverted &&
      !OpportunityEditableStatuses.includes(opportunityDetails?.status)
    ) {
      setIsBottomBarHidden(true);
    } else {
      setIsBottomBarHidden(false);
    }
  }, [opportunityDetails?.status, isCurrentUserGRE, isCurrentUserCP, isProposalConverted]);

  useEffect(() => {
    if (isGetSalesPersonListSuccess) {
      if (Array.isArray(getSalesPersonListResponse?.message)) {
        setAssigneeList(getSalesPersonListResponse?.message);
      } else if (getSalesPersonListResponse?.message) {
        toast(getSalesPersonListResponse?.message, {
          type: ERROR,
        });
        setAssigneeList([]);
      }
    } else if (isGetSalesPersonListFailed) {
      redirectToLoginBasedOnResponse(
        getSalesPersonListError,
        getSalesPersonListResponse
      );
      setAssigneeList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getSalesPersonListResponse,
    isGetSalesPersonListSuccess,
    t,
    isGetSalesPersonListFailed,
    getSalesPersonListError,
  ]);

  useEffect(() => {
    refetchSalesPersonList();
    refetchOpportunityDetails();
    refetchUnitDetails();
  }, [refetchOpportunityDetails, refetchSalesPersonList, refetchUnitDetails]);

  useEffect(() => {
    if (isSiteVisitUpdateLoading) {
      if (isAddOpportunitySiteVisitSuccess) {
        if (addOpportunitySiteVisitResponse?.data === opportunityId) {
          toast(addOpportunitySiteVisitResponse.message, {
            type: "success",
          });
          setIsSiteVisitUpdateLoading(false);
        } else if (addOpportunitySiteVisitResponse?.message) {
          toast(addOpportunitySiteVisitResponse?.message, {
            type: ERROR,
          });
          setIsSiteVisitUpdateLoading(false);
        }
      } else if (isAddOpportunitySiteVisitFailed) {
        redirectToLoginBasedOnResponse(
          addOpportunitySiteVisitError,
          addOpportunitySiteVisitResponse
        );
        setIsButtonLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addOpportunitySiteVisitResponse,
    isAddOpportunitySiteVisitSuccess,
    isAddOpportunitySiteVisitFailed,
    addOpportunitySiteVisitError,
    redirectToLoginBasedOnResponse,
  ]);

  useEffect(() => {
    if (comment) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [comment]);

  useEffect(() => {
    if (isCreateProposalLoading) {
      if (isCreateProposalSuccess) {
        if (createProposalResponse?.data?.booking_proposal_id) {
          navigateToProposals(
            createProposalResponse?.data?.booking_proposal_id
          );
          toast(createProposalResponse.message, {
            type: "success",
          });
        } else if (createProposalResponse?.message) {
          toast(createProposalResponse?.message, {
            type: ERROR,
          });
        }
        setIsCreateProposalLoading(false);
      } else if (isCreateProposalFailed) {
        redirectToLoginBasedOnResponse(
          createProposalError,
          createProposalResponse
        );
        setIsCreateProposalLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createProposalResponse,
    isCreateProposalSuccess,
    isCreateProposalFailed,
    createProposalError,
    isCreateProposalLoading,
  ]);

  const lostReasons = useCallback(
    (reasons: { value: string; label: string }[]) =>
      reasons?.map((reason: { value: string; label: string }) => ({
        lost_reason: reason?.label,
      })),
    []
  );

  const updateLostReason = async (
    reasons: { value: string; label: string }[],
    description: string
  ) => {
    setIsButtonLoading(true);
    const payload = {
      ...opportunityDetails,
      ...{
        lost_reasons: lostReasons(reasons),
        status: Status.LOST,
        order_lost_reason: description,
      },
    };
    await updateOpportunity({ payload, userToken: getUserToken() }).unwrap();
  };

  const navigateToOpportunityEdit = () =>
    navigate(`/opportunity/${opportunityId}/edit`);

  const toggleReAssignModal = useCallback(
    () => setReAssignModalVisible(!isReAssignModalVisible),
    [isReAssignModalVisible]
  );

  const updateAssignee = useCallback(async () => {
    const { modified, ...restOpportunityDetails } = opportunityDetails;
    const payload = {
      ...restOpportunityDetails,
      allocated_sales_team: selectedAssignee,
    };
    toggleReAssignModal();
    setIsButtonLoading(true);
    await updateOpportunity({ payload, userToken: getUserToken() }).unwrap();
  }, [
    opportunityDetails,
    selectedAssignee,
    toggleReAssignModal,
    updateOpportunity,
    getUserToken,
  ]);

  const onClickAssignee = useCallback(
    (assignee: string) => {
      if (opportunityDetails?.allocated_sales_team === assignee) {
        toast(`Already assigned to ${assignee}`, {
          type: "info",
        });
      } else setSelectedAssignee(assignee);
    },
    [opportunityDetails?.allocated_sales_team]
  );

  const renderModalContent = () => (
    <NotInterestedModalContent
      customerName={opportunityDetails?.first_name}
      handleClose={toggleNotInterestedModal}
      updateLostReason={updateLostReason}
      isButtonLoading={isButtonLoading}
      lostReasons={opportunityDetails?.lost_reasons}
      isLostReasonsViewable={isVewAllLostReasons}
      lostDetail={opportunityDetails?.order_lost_reason}
      id={opportunityId}
    />
  );

  const renderAssignees = () => (
    <AssigneeListModalContainer>
      <CloseIcon
        sx={closeIconStyle}
        fontSize="small"
        onClick={toggleReAssignModal}
      />
      <CustomizedText
        fontSize={20}
        sx={{ fontWeight: 600, alignSelf: "center" }}
      >
        {t("opportunity_details.assign_to")}
      </CustomizedText>
      {assigneeList.map((assignee, index) => (
        <Box
          sx={{
            cursor: "pointer",
            backgroundColor:
              selectedAssignee === assignee
                ? Colors.lightBlueColor
                : Colors.white,
          }}
          key={`${assignee}${index}`}
        >
          <CustomizedText
            fontSize={18}
            sx={assigneeStyle}
            onClick={() => onClickAssignee(assignee)}
          >
            {assignee}
          </CustomizedText>
          <CustomizedDivider width="100%" sx={{ m: 0, p: 0 }} />
        </Box>
      ))}
      <SolidButton
        sx={{ pt: 0.5, pb: 0.5, m: 2 }}
        fontSize={16}
        onClick={updateAssignee}
        disabled={isButtonLoading}
      >
        {t("app_common.save")}
      </SolidButton>
    </AssigneeListModalContainer>
  );

  const navigateToOpportunities = useCallback(() => {
    navigate(`/${OPPORTUNITIES}`);
  }, [navigate]);

  const navigateToProposals = useCallback(
    (proposalId: string) => {
      navigate(`/${PROPOSAL}/${proposalId}`);
    },
    [navigate]
  );

  const toggleModifySalesStageModal = useCallback(
    () => setIsModifySalesStageModalVisible(!isModifySalesStageModalVisible),
    [isModifySalesStageModalVisible]
  );

  const onClickModifySalesStage = async (salesStage: string) => {
    setIsButtonLoading(true);
    toggleModifySalesStageModal();
    let payload = {
      ...opportunityDetails,
      ...{
        sales_stage: salesStage,
      },
    };
    await updateOpportunity({ payload, userToken: getUserToken() }).unwrap();
  };

  const addSiteVisitData = useCallback(
    (data: {
      siteVisitDate: string;
      duration: string;
      checkInTime: string;
      checkOutTime: string;
      comment: string;
    }) => {
      setIsSiteVisitUpdateLoading(true);
      const payload = {
        opportunity_id: opportunityId,
        site_visits: [
          {
            site_visit_date: Boolean(data?.siteVisitDate)
              ? data?.siteVisitDate
              : moment(new Date()).format(YYYY_MM_DD_WITH_HYPHEN),
            site_visit_time: convertTo24Hour(data?.checkInTime),
            check_out_time: convertTo24Hour(data?.checkOutTime),
            duration: convertToSeconds(data?.duration),
            site_visit_comment: data?.comment,
          },
        ],
      };
      addOpportunitySiteVisit({ payload, userToken: getUserToken() });
    },
    [addOpportunitySiteVisit, getUserToken, opportunityId]
  );

  const isOpportunityEditable = useMemo(
    () =>
      opportunityDetails?.status &&
      OpportunityEditableStatuses.includes(opportunityDetails?.status),
    [opportunityDetails?.status]
  );

  const onChangeComment = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setComment(event.target.value);
    },
    []
  );

  const onClear = () => setComment("");

  const onClickAddNote = () => {
    setIsAddNoteLoading(true);
    const payload = {
      opportunity_id: opportunityId,
      comment,
    };
    updateOpportunityComments({ userToken: getUserToken(), payload });
  };

  const convertToProposal = useCallback(
    (project?: string, unit?: string) => {
      setIsCreateProposalLoading(true);
      const payload = {
        opportunity: opportunityId,
        project,
        unit,
      };
      createProposal({ userToken: getUserToken(), payload });
    },
    [createProposal, getUserToken, opportunityId]
  );

  const toggleConvertAgainModal = useCallback(() => {
    setIsConvertAgainModalVisible(!isConvertAgainModalVisible);
  }, [isConvertAgainModalVisible]);

  const onClickConvert = useCallback(() => {
    if (isProposalConverted) {
      toggleConvertAgainModal();
    } else {
      convertToProposal(opportunityDetails?.project, opportunityDetails?.unit);
    }
  }, [
    convertToProposal,
    isProposalConverted,
    opportunityDetails?.project,
    opportunityDetails?.unit,
    toggleConvertAgainModal,
  ]);

  const toggleFollowUpModal = useCallback(
    () => setIsTriggerFollowUpModalVisible(!isTriggerFollowUpModalVisible),
    [isTriggerFollowUpModalVisible]
  );

  const viewAllLostReason = () => {
    setIsViewAllLostReasons(true);
    setShowNotInterestedModal(!showNoInterestedModal);
  };

  const updateProjectAndUnit = useCallback(
    (details: { project: string; unitType: string; unit: string }) => {
      setProjectAndUnitPreference({
        project: details?.project,
        unit: details?.unit,
      });
    },
    []
  );

  const renderTitleAndButton = () => (
    <TitleAndButtonContainer>
      <Box>
        <NavigationPath
          initialPath={t("app_common.dashboard")}
          forwardPath={t("opportunity_details.details")}
          onClick={navigateToOpportunities}
        />
        <CustomizedText fontSize={36} gutterBottom>
          {t("opportunity_details.title")}
        </CustomizedText>
      </Box>
      <ButtonContainer>
        {isOpportunityEditable &&
          opportunityDetails?.sales_stage === SITE_VISIT_COMPLETED &&
          renderFollowUpButton()}
        {isOpportunityEditable &&
          (isCurrentUserGRE || !isCurrentUserCP ? renderReassignButton() : renderModifyButton())}
        {opportunityDetails?.status !== Status.CONVERTED && (
          <StyledButton
            sx={{ pt: 0.5, pb: 0.5 }}
            {...(isOpportunityEditable && {
              endIcon: (
                <ModeEditOutlinedIcon sx={{ color: Colors.actionColor }} />
              ),
            })}
            onClick={navigateToOpportunityEdit}
          >
            {isOpportunityEditable
              ? t("opportunity_details.edit_details")
              : t("opportunity_details.reopen")}
          </StyledButton>
        )}
      </ButtonContainer>
    </TitleAndButtonContainer>
  );

  const renderPreSalesSiteVisitDetails = () => (
    <PreSalesSiteVisitUpdate
      date={opportunityDetails?.follow_up_date}
      time={opportunityDetails?.follow_up_time}
      comment={opportunityDetails?.follow_up_comment}
    />
  );

  const renderOpportunityDetails = () => (
    <Box sx={{ width: "68%" }}>
      <CustomerDetails
        details={opportunityDetails}
        viewAllLostReasons={viewAllLostReason}
      />
      <ProjectAndUnitDetails
        project={opportunityDetails?.project}
        unitDetails={unitDetails}
        opportunityId={opportunityId}
        status={opportunityDetails?.status}
        unitType={opportunityDetails?.unit_type}
      />
      {opportunityDetails?.follow_up_date && renderPreSalesSiteVisitDetails()}
      {!isCurrentUserGRE &&  isCurrentUserCP && renderSiteVisitDetails()}
    </Box>
  );

  const renderComments = () => (
    <CommentAndNotesCard>
      <BookingComments
        commentList={comments}
        comment={comment}
        onClickAddNote={onClickAddNote}
        onChangeComment={onChangeComment}
        onClear={onClear}
        isButtonDisabled={isButtonDisabled}
        isAddNoteLoading={isAddNoteLoading}
        sx={{ bookingCommentsContainerStyle: { width: "100%" } }}
        isCloseIconRequired={false}
      />
    </CommentAndNotesCard>
  );

  const renderContent = () => (
    <DetailsAndNotesEventsContainer>
      <Box sx={{ m: 2, width: "90%" }}>
        {renderTitleAndButton()}
        <OpportunityDetailsAndComments>
          {renderOpportunityDetails()}
          {renderComments()}
        </OpportunityDetailsAndComments>
      </Box>
    </DetailsAndNotesEventsContainer>
  );

  const renderReassignButton = () => (
    <SolidButton
      sx={{ pt: 0.5, pb: 0.5, mr: 1 }}
      fontSize={16}
      onClick={toggleReAssignModal}
    >
      {t("opportunity_details.reassign")}
    </SolidButton>
  );

  const renderSiteVisitDetails = () => (
    <SiteVisitDetails
      addSiteVisitData={addSiteVisitData}
      isButtonLoading={false}
      isAddSiteVisitRequired={isOpportunityEditable}
    />
  );

  const renderModifyButton = () => (
    <StyledButton
      sx={{ pt: 0.5, pb: 0.5, mr: 1 }}
      endIcon={
        <RouteIcon
          sx={{ color: Colors.actionColor, transform: "rotate(90deg)" }}
          fontSize="small"
        />
      }
      onClick={toggleModifySalesStageModal}
    >
      {t("opportunity_details.modify_sales_stage")}
    </StyledButton>
  );

  const renderModifySalesStage = () => (
    <ModifySalesStageModalContent
      handleClose={toggleModifySalesStageModal}
      currentSalesStage={opportunityDetails?.sales_stage}
      onClickModify={onClickModifySalesStage}
      isButtonLoading={isButtonLoading}
    />
  );

  const renderFollowUpButton = () => (
    <StyledButton
      sx={{ pt: 0.5, pb: 0.5, mr: 1 }}
      endIcon={
        <UpdateIcon sx={{ color: Colors.actionColor }} fontSize="small" />
      }
      onClick={toggleFollowUpModal}
    >
      {t("opportunity_details.send_follow_up")}
    </StyledButton>
  );

  const renderFollowUpMessage = () => (
    <FollowUpMessageModalContent
      handleClose={toggleFollowUpModal}
      name={`${opportunityDetails?.first_name} ${opportunityDetails?.last_name}`}
      project={opportunityDetails?.project}
      salesExecutive={opportunityDetails?.allocated_sales_team}
    />
  );

  const renderProjectAndUnit = () => (
    <ModifySalesStageModal sx={{ p: 3 }}>
      <ProjectAndUnitPreference
        projectDetails={{
          project: projectAndUnitPreferenceRef.current?.project,
          unit: projectAndUnitPreferenceRef.current?.unit,
        }}
        onUpdate={updateProjectAndUnit}
        isUnitMandatory
      />
      <ConvertAgainButtonContainer>
        <CustomizedLink sx={{ mr: 1 }} onClick={toggleConvertAgainModal}>
          {t("app_common.cancel")}
        </CustomizedLink>
        <MaterialButton
          id={"save-button"}
          title={t("opportunity_details.save")}
          sx={saveButtonStyle}
          onClick={() =>
            convertToProposal(
              projectAndUnitPreference.project,
              projectAndUnitPreference.unit
            )
          }
          disabled={
            !Boolean(projectAndUnitPreference?.project) ||
            !Boolean(projectAndUnitPreference?.unit)
          }
          isLoading={isCreateProposalLoading}
        />
      </ConvertAgainButtonContainer>
    </ModifySalesStageModal>
  );

  return (
    <>
      <Layout
        children={renderContent()}
        rightButtonTitle={t("opportunity_details.create_booking_proposal")}
        leftButtonTitle={
          opportunityDetails?.first_name
            ? t("opportunity_details.query_not_interested", {
                name: opportunityDetails?.first_name,
              })
            : ""
        }
        hideBottomBar={isBottomBarHidden || isCurrentUserGRE || !isCurrentUserCP}
        styles={{
          ...{ bottomTabContainer: bottomTabContainerStyle },
          ...(isProposalConverted && {
            buttonContainer: { display: "flex", justifyContent: "flex-end" },
          }),
        }}
        onClickLeftButton={() => setShowNotInterestedModal(true)}
        isRightButtonRequired={!isCurrentUserGRE && isCurrentUserCP}
        onPressButton={onClickConvert}
        isRightButtonLoading={isCreateProposalLoading}
        isLeftButtonRequired={!isProposalConverted}
      />
      <MaterialModal
        children={renderModalContent()}
        sx={{ ...materialModalProps, ...{ width: "60%" } }}
        open={showNoInterestedModal}
        onClose={toggleNotInterestedModal}
      />
      <MaterialModal
        children={renderAssignees()}
        sx={materialModalProps}
        open={isReAssignModalVisible}
        onClose={toggleReAssignModal}
      />
      <MaterialModal
        children={renderModifySalesStage()}
        sx={{ ...materialModalProps, ...{ width: "40%" } }}
        open={isModifySalesStageModalVisible}
        onClose={toggleModifySalesStageModal}
      />
      <MaterialModal
        children={renderFollowUpMessage()}
        sx={{ ...materialModalProps, ...{ width: "40%" } }}
        open={isTriggerFollowUpModalVisible}
        onClose={toggleFollowUpModal}
      />
      <MaterialModal
        children={renderProjectAndUnit()}
        sx={{ ...materialModalProps, ...{ width: "40%" } }}
        open={isConvertAgainModalVisible}
        onClose={toggleConvertAgainModal}
      />
      <ToastContainer />
    </>
  );
};
export default OpportunityDetails;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useRedirectToLogin, useGetUserDetailsHooks } from "../../hooks";
import ColumnGroupingTable from "../table";
import { APP_CONSTANTS } from "../../constants";
import { ListColumnType } from "../../utils/interface";
import { useGetOpportunitySiteVisitListQuery } from "../../../features/api/opportunity-api-slice";
import { DD_MM_YYY, convertTo12Hour } from "../../utils/date";
import { SiteVisitCard } from "./styled-components";
import { useGetProposalSiteVisitListQuery } from "../../../features/api/proposals-api-slice";

interface SiteVisitDataTypes {
  idx: number;
  name: string;
  site_visit_date: string;
  site_visit_time: string;
  check_out_time: string;
  duration: string;
  site_visit_comment: string;
}

const ERROR = "error";

const SiteVisitListTable = () => {
  const { getUserToken } = useGetUserDetailsHooks();
  const params = useParams();
  const opportunityId = params?.id;
  const { proposalId } = params || {};

  const { t } = useTranslation();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();

  const [siteVisitList, setSiteVisitList] = useState([]);

  const [totalSiteVisits, setTotalSiteVisits] = useState<number>(0);
  const [pagination, setPagination] = useState<{ page: number; size: number }>({
    page: APP_CONSTANTS.defaultPage,
    size: APP_CONSTANTS.defaultPageSize,
  });

  const {
    data: getSiteVisitListResponse,
    isError: isGetSiteVisitListFailed,
    isSuccess: isGetSiteVisitListSuccess,
    error: getSiteVisitListError,
    refetch: refetchSiteVisit,
  } = useGetOpportunitySiteVisitListQuery(
    {
      opportunityId,
      paginationInfo: pagination,
      userToken: getUserToken(),
    },
    { skip: !Boolean(opportunityId) }
  );

  const {
    data: getProposalSiteVisitListResponse,
    isError: isGetProposalSiteVisitListFailed,
    isSuccess: isGetProposalSiteVisitListSuccess,
    error: getProposalSiteVisitListError,
    refetch: refetchProposalSiteVisit,
  } = useGetProposalSiteVisitListQuery(
    {
      proposalId,
      paginationInfo: pagination,
      userToken: getUserToken(),
    },
    { skip: !Boolean(proposalId) }
  );

  useEffect(() => {
    refetchSiteVisit();
    refetchProposalSiteVisit();
  }, [refetchSiteVisit, refetchProposalSiteVisit]);

  const createRowData = (data: SiteVisitDataTypes[]) =>
    data.map((item: SiteVisitDataTypes, index) => ({
      id: item.name,
      number: item.idx,
      siteVisitDate: moment(item?.site_visit_date).format(DD_MM_YYY),
      checkInTime: convertTo12Hour(item?.site_visit_time),
      checkOutTime: convertTo12Hour(item?.check_out_time),
      siteVisitComment: item?.site_visit_comment,
    }));

  const columns: ListColumnType[] = [
    {
      id: "number",
      label: t("opportunity_details.site_visit_table.no"),
      align: "center",
    },
    {
      id: "siteVisitDate",
      label: t("opportunity_details.site_visit_table.site_visit_date"),
      align: "center",
    },
    {
      id: "checkInTime",
      label: t("opportunity_details.site_visit_table.site_visit_time"),
      align: "center",
    },
    {
      id: "checkOutTime",
      label: t("opportunity_details.site_visit_table.check_out_time"),
      align: "center",
    },
    {
      id: "siteVisitComment",
      label: t("opportunity_details.site_visit_table.site_visit_comment"),
      align: "left",
    },
  ];

  useEffect(() => {
    if (isGetSiteVisitListSuccess) {
      if (Array.isArray(getSiteVisitListResponse?.data?.site_visit)) {
        setSiteVisitList(getSiteVisitListResponse?.data?.site_visit);
        setTotalSiteVisits(getSiteVisitListResponse?.data?.total_visits);
      } else if (getSiteVisitListResponse?.message) {
        toast(getSiteVisitListResponse?.message, {
          type: ERROR,
        });
        setSiteVisitList([]);
      }
    } else if (isGetSiteVisitListFailed) {
      redirectToLoginBasedOnResponse(
        getSiteVisitListError,
        getSiteVisitListResponse
      );
      setSiteVisitList([]);
    }
  }, [
    getSiteVisitListResponse,
    isGetSiteVisitListSuccess,
    isGetSiteVisitListFailed,
    t,
    getSiteVisitListError,
    redirectToLoginBasedOnResponse,
  ]);

  useEffect(() => {
    if (isGetProposalSiteVisitListSuccess) {
      if (Array.isArray(getProposalSiteVisitListResponse?.data?.site_visit)) {
        setSiteVisitList(getProposalSiteVisitListResponse?.data?.site_visit);
        setTotalSiteVisits(
          getProposalSiteVisitListResponse?.data?.total_visits
        );
      } else if (getProposalSiteVisitListResponse?.message) {
        toast(getProposalSiteVisitListResponse?.message, {
          type: ERROR,
        });
        setSiteVisitList([]);
      }
    } else if (isGetProposalSiteVisitListFailed) {
      redirectToLoginBasedOnResponse(
        getProposalSiteVisitListError,
        getProposalSiteVisitListResponse
      );
      setSiteVisitList([]);
    }
  }, [
    getProposalSiteVisitListResponse,
    isGetProposalSiteVisitListSuccess,
    isGetProposalSiteVisitListFailed,
    t,
    getProposalSiteVisitListError,
    redirectToLoginBasedOnResponse,
  ]);

  const handlePageChange = (page: number, size: number) => {
    setPagination({ page, size });
  };

  return (
    <SiteVisitCard>
      <ColumnGroupingTable
        rows={createRowData(siteVisitList)}
        columns={columns}
        handlePageChange={handlePageChange}
        paginationData={pagination}
        count={totalSiteVisits}
      />
      <ToastContainer />
    </SiteVisitCard>
  );
};
export default SiteVisitListTable;

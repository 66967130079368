import { FC } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { CustomizedText } from "../../../components/styled-componnets";
import { PreSalesSiteVisitCard, RowStyledBox } from "../styled-components";
import Colors from "../../../utils/theme/color";
import { DD_MM_YYY, convertTo12Hour } from "../../../utils/date";

interface PreSalesSiteVisitUpdateProps {
  date?: string;
  time?: string;
  comment?: string;
}

const PreSalesSiteVisitUpdate: FC<PreSalesSiteVisitUpdateProps> = ({
  date,
  time,
  comment,
}) => {
  const { t } = useTranslation();

  const renderTitle = () => (
    <CustomizedText fontSize={28} gutterBottom font={"Lato-Regular"}>
      {t("opportunity_details.pre_sales_site_visit")}
    </CustomizedText>
  );

  const renderLabelAndValue = ({
    label,
    value,
    style = {},
  }: {
    label: string;
    value?: string;
    style?: {};
  }) => (
    <Box sx={style}>
      <CustomizedText
        fontSize={20}
        gutterBottom
        font={"Lato-Regular"}
        fontColor={Colors.greyColor}
      >
        {label}
      </CustomizedText>
      <CustomizedText fontSize={20} gutterBottom font={"Lato-Regular"}>
        {value}
      </CustomizedText>
    </Box>
  );

  const renderSiteVisitDateAndTime = () => (
    <RowStyledBox>
      {renderLabelAndValue({
        label: t("opportunity_details.site_visit_date"),
        value: moment(date).format(DD_MM_YYY),
        style: { mr: 5, width: "50%" },
      })}
      {renderLabelAndValue({
        label: t("opportunity_details.site_visit_time"),
        value: convertTo12Hour(time),
      })}
    </RowStyledBox>
  );

  const renderSiteVisitComment = () =>
    renderLabelAndValue({
      label: t("opportunity_details.site_visit_comment"),
      value: comment,
      style: { width: "90%" },
    });

  return (
    <Box sx={{ mt: 3 }}>
      {renderTitle()}
      <PreSalesSiteVisitCard>
        {renderSiteVisitDateAndTime()}
        {comment && renderSiteVisitComment()}
      </PreSalesSiteVisitCard>
    </Box>
  );
};
export default PreSalesSiteVisitUpdate;

import { SetStateAction, useCallback, useMemo, useState } from "react";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useTranslation } from "react-i18next";
import MaterialTabs from "../../components/tabs";
import FileUploadAndCaptureComponent from "../../components/file-capture-and-upload";
import Colors from "../../utils/theme/color";
import SelectedFile from "./selected-file";
import "./applicant-form.css";

type Base64 = string;
interface ApplicantPhotoTypes {
  uploadPhoto(
    fileData: Base64,
    file: {
      name?: string | undefined;
      type?: string | undefined;
      data?: string | ArrayBuffer | null | undefined;
    }
  ): void;
  photo: {
    name?: string | undefined;
    type?: string | undefined;
    data?: string | ArrayBuffer | null | undefined;
    attachment?: string;
  };
  removePhoto(): void;
  numberOfApplicant?: string;
  isSelected?: boolean;
  disabled?: boolean;
  title?: string;
  type?: string;
  placeholder?: string;
  isMandatory?: boolean;
}
const tabLabelStyle = {
  fontFamily: "Lato-Regular",
  fontSize: 16,
  color: Colors.titleColor,
};

const tabContainerStyle = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

const ApplicantPhoto = ({
  uploadPhoto,
  photo,
  removePhoto,
  numberOfApplicant = "",
  disabled = false,
  title = "",
  type = "",
  placeholder = "",
  isMandatory = true,
}: ApplicantPhotoTypes) => {
  const { t } = useTranslation();
  const tabItems = [
    { id: "FILE", label: "UPLOAD" },
    { id: "PICTURE", label: "TAKE A PICTURE" },
  ];
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const materialTabProps = {
    backgroundColor: Colors.lightBlueColor,
    border: `10px solid ${Colors.veryLightBlueColor}`,
    borderRadius: 5,
    boxSizing: "border-box",
  };

  const onChangeTab = useCallback(
    (e: object, value: SetStateAction<number>) => {
      setSelectedTabIndex(value);
    },
    [setSelectedTabIndex]
  );

  const onUploadFile = useCallback(
    (fileData: string, file: Blob) => {
      uploadPhoto(fileData, file);
    },
    [uploadPhoto]
  );

  const isSelectedTabIndexZero = useMemo(
    () => selectedTabIndex === 0,
    [selectedTabIndex]
  );

  const getTitle = useCallback(
    () =>
      isSelectedTabIndexZero
        ? t("create_booking.basic_details.drop_photo", {
            type: placeholder ? placeholder.toLowerCase() : type.toLowerCase(),
          })
        : t("create_booking.basic_details.enable_camera", {
            type: type.toLowerCase(),
          }),
    [isSelectedTabIndexZero, t, type, placeholder]
  );

  const getDescription = useCallback(
    () =>
      isSelectedTabIndexZero
        ? t("create_booking.photo_type")
        : t("create_booking.basic_details.ensure_image_visibility", {
            type: type.toLowerCase(),
          }),
    [isSelectedTabIndexZero, t, type]
  );

  const getButtonTitle = useCallback(
    () =>
      isSelectedTabIndexZero
        ? t("create_booking.choose_file")
        : t("create_booking.basic_details.open_camera"),
    [isSelectedTabIndexZero, t]
  );

  const getIconBasedOnIndex = useCallback(
    () =>
      isSelectedTabIndexZero
        ? InsertDriveFileOutlinedIcon
        : PhotoCameraOutlinedIcon,
    [isSelectedTabIndexZero]
  );

  const renderTabPanel = () => (
    <FileUploadAndCaptureComponent
      title={getTitle()}
      description={getDescription()}
      secondaryButtonTitle={getButtonTitle()}
      Icon={getIconBasedOnIndex()}
      onChangeUploadFile={onUploadFile}
      isFileUpload={isSelectedTabIndexZero}
      styles={{ fileUploadContainer: { padding: 0 } }}
    />
  );

  const renderTitle = () => (
    <div className="photo-label-container">
      <p className="photo-label">{title}</p>
      {isMandatory ? <p className="photo-label-required">*</p> : null}
    </div>
  );

  const renderSelectedFile = () => (
    <SelectedFile
      fileName={photo?.name}
      removePhoto={removePhoto}
      isCloseIconRequired={!disabled}
      {...(disabled && {
        styles: {
          container: { justifyContent: "flex-start" },
        },
      })}
      file={photo}
    />
  );

  const renderOptionsToUploadPhoto = () => (
    <MaterialTabs
      tabItems={tabItems}
      value={selectedTabIndex}
      TabIndicatorProps={{
        sx: {
          bgcolor: "white",
        },
      }}
      tabLabelStyle={{
        ...tabLabelStyle,
      }}
      onChange={onChangeTab}
      sx={materialTabProps}
      selectedTabIndex={selectedTabIndex}
      styles={{
        selectedTabStyle: {
          backgroundColor: "white",
          borderRadius: 5,
        },
        boxContainer: tabContainerStyle,
      }}
      isTabPanelRequired={true}
      tabContent={renderTabPanel()}
    />
  );

  return (
    <>
      {renderTitle()}
      {Boolean(photo?.name)
        ? renderSelectedFile()
        : !disabled && renderOptionsToUploadPhoto()}
    </>
  );
};
export default ApplicantPhoto;

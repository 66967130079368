import { ReactI18NextChild } from "react-i18next";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { YYYY_MM_DD_WITH_HYPHEN } from "./date";
import moment from "moment";
import { Action } from "../constants/action";
import { Status } from "../constants/statuses";

const BASIC_DETAILS = "BASIC_DETAILS";
const PROFESSIONAL_DETAILS = "PROFESSIONAL_DETAILS";
const IDENTIFICATION_DETAILS = "IDENTIFICATION_DETAILS";
const SIGNATURE = "SIGNATURE";

export const AADHAR_NUMBER_MAX_LENGTH = 12;
export const PAN_NUMBER_MAX_LENGTH = 10;

export const PRIMARY_APPLICANT = "PRIMARY";
export interface BasicDetailsTypes {
  firstName?: string;
  lastName?: string;
  fatherOrHusbandName?: string;
  dob?: string;
  gender?: string;
  mobile?: string;
  email?: string;
  correspondenceAddress?: string;
  permanentAddress?: string;
  pin?: string;
  telephone?: string;
  permanentPin?: string;
  permanentTelephone?: string;
  isPermanentAddressSameAsCorrespondence?: string;
  photo?: { data: string; type: string; name: string };
  mobileErrorMsg?: string;
  emailErrorMsg?: string;
}

export interface IdentificationDetailsTypes {
  isApplicantNRI?: boolean;
  aadhaarNumber?: string;
  aadhaarPhoto?: {
    name?: string;
    type?: string;
    data?: string | ArrayBuffer | null;
  };
  aadhaarErrorMessage: string;
  panNumber?: string;
  panPhoto?: {
    name?: string;
    type?: string;
    data?: string | ArrayBuffer | null;
  };
  panErrorMessage: string;
  passportNumber?: string;
  passportPhoto?: {
    name?: string;
    type?: string;
    data?: string | ArrayBuffer | null;
  };
}

export interface IdentificationDetailsFormTypes {
  identificationData?: any;
  numberOfApplicant?: number;
  onUpdate(identificationDetails: IdentificationDetailsTypes): void;
  isEditable?: boolean;
  saveFile?: (type?: string, data?: string, name?: string) => void;
}
export interface ApplicantBasicDetailsFormTypes {
  numberOfApplicant?: number;
  basicData?: any;
  onUpdate(basicDetails: BasicDetailsTypes): void;
  isGenderRequired?: boolean;
  isFatherNameRequired?: boolean;
  isDOBRequired?: boolean;
  isAddressRequired?: boolean;
  isPhotoRequired?: boolean;
  isEmailMandatory?: boolean;
  styles?: { textInputRowContainer?: {} };
  tabItems?: ApplicantTab[];
  isEditable?: boolean;
  isLocationRequired?: boolean;
  savePhoto?: (data?: string, name?: string) => void;
  isAddressSameAsPrimaryApplicant?: string;
  isSalutationRequired?: boolean;
}

export interface ApplicantDetails {
  [x: string]: any;
  map(
    arg0: (applicant: ApplicantDetails, index: any) => JSX.Element
  ):
    | import("react-i18next").ReactI18NextChild
    | Iterable<import("react-i18next").ReactI18NextChild>;
  name?: string;
  idx: number;
  salutation?: string;
  firstName: string;
  lastName: string;
  isFathersName: boolean;
  fatherOrHusbandName: string;
  gender: string;
  mobile: string;
  email: string;
  dob: string;
  photo: {
    name: string;
    type: string;
    data: string;
    attachment: string;
    isDeleted?: boolean;
  };
  correspondenceAddress?: string;
  permanentAddress: string;
  pin: string;
  telephone: string;
  permanentPin: string;
  permanentTelephone: string;
  isPermanentAddressSameAsCorrespondence?: string;
  mobileErrorMsg?: string;
  emailErrorMsg?: string;
  isApplicantNRI: boolean;
  aadhaarNumber: number | null;
  aadhaarPhoto: {
    name: string;
    type: string;
    data?: string;
    isDeleted?: boolean;
    attachment: string;
  };
  panNumber: number | null;
  panPhoto: {
    name: string;
    type: string;
    data?: string;
    isDeleted?: boolean;
    attachment: string;
  };
  passportNumber?: number | null;
  passportPhoto?: {
    name: string;
    type: string;
    data?: string;
    isDeleted?: boolean;
    attachment: string;
  };
  profession: string;
  industry?: string;
  employedAt?: string;
  designation?: string;
  signature: {
    attachment: string;
    name: string;
    type: string;
    data?: string;
    isDeleted?: boolean;
  };
  tabDetails: ApplicantTab[];
  aadhaarErrorMessage: string;
  panErrorMessage: string;
  passportErrorMessage: string;
  relationShipWithPrimaryUser: string;
  isAddressSameAsPrimaryApplicant: string;
}
export interface SignatureDetailsTypes {
  signature?: { name?: ""; type?: ""; data?: ""; isDeleted?: boolean };
}
export interface ApplicantSignatureTypes {
  signatureData: any;
  onUpdate(identificationDetails: SignatureDetailsTypes): void;
  numberOfApplicant: number;
  isEditable?: boolean;
  saveSignature?: (type: string, data: string, name: string) => void;
}

export interface ApplicantTab {
  id: string;
  label: string;
  isSelected: boolean;
  isCompleted: boolean;
  icon: any;
  mandatoryFields: string[];
  tabErrorFields: string[];
  isError: boolean;
}

export interface ProfessionalDetailsTypes {
  profession: string;
  industry: string;
  employedAt: string;
  designation: string;
}
export interface ProfessionalDetailsFormTypes {
  professionalData: any;
  onUpdate(professionalDetails: ProfessionalDetailsTypes): void;
  isEditable?: boolean;
}

export const tabItems: ApplicantTab[] = [
  {
    id: BASIC_DETAILS,
    label: "Basic Details",
    isSelected: true,
    isCompleted: false,
    icon: PersonOutlineOutlinedIcon,
    mandatoryFields: [
      "firstName",
      "lastName",
      "fatherOrHusbandName",
      "dob",
      "mobile",
      "email",
      "correspondenceAddress",
      "pin",
      "photo",
      "isPermanentAddressSameAsCorrespondence",
      "permanentAddress",
      "permanentPin",
    ],
    tabErrorFields: ["mobileErrorMsg", "emailErrorMsg"],
    isError: false,
  },
  {
    id: IDENTIFICATION_DETAILS,
    label: "Identification Details",
    isSelected: false,
    isCompleted: false,
    icon: BadgeOutlinedIcon,
    mandatoryFields: [
      "aadhaarNumber",
      "panNumber",
      "isApplicantNRI",
      "relationShipWithPrimaryUser",
    ],
    tabErrorFields: [
      "aadhaarErrorMessage",
      "panErrorMessage",
      "passportErrorMessage",
    ],
    isError: false,
  },
  {
    id: PROFESSIONAL_DETAILS,
    label: "Professional Details",
    isSelected: false,
    isCompleted: false,
    icon: WorkOutlineOutlinedIcon,
    mandatoryFields: ["profession","industry","employedAt"],
    tabErrorFields: [],
    isError: false,
  },
  {
    id: SIGNATURE,
    label: "Signature",
    isSelected: false,
    isCompleted: false,
    icon: VerifiedOutlinedIcon,
    mandatoryFields: ["signature"],
    tabErrorFields: [],
    isError: false,
  },
];
export const EMPTY_APPLICANT_DETAILS: ApplicantDetails = {
  idx: 0,
  name: "",
  salutation: "",
  firstName: "",
  lastName: "",
  isFathersName: false,
  fatherOrHusbandName: "",
  gender: "",
  mobile: "",
  email: "",
  dob: moment(new Date()).format(YYYY_MM_DD_WITH_HYPHEN),
  photo: { name: "", type: "", data: "", attachment: "", isDeleted: true },
  correspondenceAddress: "",
  permanentAddress: "",
  pin: "",
  telephone: "",
  permanentPin: "",
  permanentTelephone: "",
  isPermanentAddressSameAsCorrespondence: "No",
  mobileErrorMsg: "",
  emailErrorMsg: "",
  isApplicantNRI: false,
  aadhaarNumber: null,
  aadhaarPhoto: {
    name: "",
    type: "",
    data: "",
    isDeleted: true,
    attachment: "",
  },
  panNumber: null,
  panPhoto: {
    name: "",
    type: "",
    data: "",
    isDeleted: true,
    attachment: "",
  },
  passportNumber: null,
  passportPhoto: {
    name: "",
    type: "",
    data: "",
    isDeleted: true,
    attachment: "",
  },
  profession: "",
  industry: "",
  employedAt: "",
  designation: "",
  signature: { name: "", type: "", data: "", isDeleted: true, attachment: "" },
  map: function (
    arg0: (applicant: ApplicantDetails, index: any) => JSX.Element
  ): ReactI18NextChild | Iterable<ReactI18NextChild> {
    throw new Error("Function not implemented.");
  },
  tabDetails: tabItems,
  disableFirstName: false,
  disableLastName: false,
  disableEmail: false,
  disableMobile: false,
  aadhaarErrorMessage: "",
  panErrorMessage: "",
  passportErrorMessage: "",
  relationShipWithPrimaryUser: PRIMARY_APPLICANT,
  isAddressSameAsPrimaryApplicant: "NO",
};

export const EMPTY_LIST_PRICE_DETAILS = {
  saleableArea: 0,
  basicRate: 0,
  totalDiscount: 0,
  premiumLocationCharges: 0,
  bookingFloorPremium: 0,
  additionalExpenses: 0,
  coveredCarParking: 0,
  clubHouse: 0,
  gstAmount: 0,
  gstPercentage: 0,
  totalConsiderationIncludingGst: 0,
  sinkingFund: 0,
  maintenanceCharges: 0,
  legalCharges: 0,
  totalCostOfOwnership: 0,
  ratePerSquareFeet: 0,
  bookingRatePerSquareFeet: 0,
  bookingBasicAmount: 0,
  bookingTotalConsideration: 0,
  basicAmount: 0,
  totalAmount: 0,
  roundOff: 0,
  discountBySalesExecutive: 0,
  discountBySalesLead: 0,
  discountBySalesHead: 0,
  discountByChannelPartner: 0,
};

export const EMPTY_OVER_VIEW_DETAILS = {
  id: "",
  firstName: "",
  lastName: "",
  project: "",
  unit: "",
  applicant: {
    applicant_name: "",
    last_name: "",
  },
  allocatedSalesTeam: "",
  additionalDiscountRequested: 0,
};
export interface HomeLoanDataTypes {
  fromBank?: string;
  serviceBy: string;
  annualIncome: string;
  loanAmountInterested: string;
  sanctionedAmount: string;
  isHomeLoan: boolean;
  isLoanSanctioned: boolean;
}
export interface HomeLoanTypes {
  homeLoanData: HomeLoanDataTypes;
  onUpdate(homeLoanData: HomeLoanDataTypes): void;
  isEditable?: boolean;
}

export const EMPTY_HOME_LOAN_DETAILS: HomeLoanDataTypes = {
  fromBank: "",
  serviceBy: "",
  annualIncome: "",
  loanAmountInterested: "",
  sanctionedAmount: "",
  isHomeLoan: false,
  isLoanSanctioned: false,
};

export const EMPTY_PAYMENT_DETAILS: PaymentDataTypes = {
  receivedAmount: "",
  branch: "",
  chequeOrDemandDraftNumber: "",
  chequeOrDemandDraftDate: moment(new Date()).format(YYYY_MM_DD_WITH_HYPHEN),
  city: "",
  sourceOfBooking: "",
  bank: "",
  enquiryNo: "",
  chequeOrDemandDraftNumberErrorMessage: "",
  paymentMode: "",
  paymentProof: {
    name: "",
    type: "",
    data: "",
    isDeleted: true,
    attachment: "",
  },
};
export interface PaymentDataTypes {
  receivedAmount: string;
  branch: string;
  chequeOrDemandDraftNumber: string;
  chequeOrDemandDraftDate: string;
  city?: string;
  sourceOfBooking: string;
  bank?: string;
  enquiryNo: string;
  chequeOrDemandDraftNumberErrorMessage: string;
  paymentMode?: string;
  paymentProof: {
    name: string;
    type: string;
    data: string | ArrayBuffer;
    isDeleted?: boolean;
    attachment: string;
  };
}

export interface PaymentDetailsTypes {
  paymentData: PaymentDataTypes;
  onUpdate(paymentData: PaymentDataTypes): void;
  isEditable?: boolean;
  numberOfApplicant: number;
}
export interface ProjectAndUnitType {
  project?: string;
  unit?: string;
  unitType?: string;
  floor: string;
  bhk: string;
  saleableArea: string;
  carpetArea: string;
  block: string;
}

export interface legalDataTypes {
  powerOfAttorneyHolderName: string;
  powerOfAttorneyHolderEmailId: string;
  powerOfAttorneyHolderPhone: string;
  powerOfAttorneyHolderAddress: string;
  termsAndConditions: string;
  phoneErrorMsg: string;
  emailErrorMsg: string;
  nameErrorMsg: string;
}

export interface LegalFormTypes {
  legalData: legalDataTypes;
  onUpdate(legalData: legalDataTypes): void;
  bookingId?: string;
  isEditable?: boolean;
}

export interface CommentsProps {
  name: string;
  comment: string;
  comment_by: string;
  creation: string;
}
export interface OverViewDetailsProps {
  id?: string;
  firstName: string;
  lastName: string;
  project: string;
  unit: string;
  applicant?: {
    applicant_name?: string;
    last_name?: string;
  };
  allocatedSalesTeam: string;
  additionalDiscountRequested?: number;
}

export const EMPTY_LEGAL_DETAILS = {
  powerOfAttorneyHolderName: "",
  powerOfAttorneyHolderEmailId: "",
  powerOfAttorneyHolderPhone: "",
  powerOfAttorneyHolderAddress: "",
  termsAndConditions: "",
  phoneErrorMsg: "",
  emailErrorMsg: "",
  nameErrorMsg: "",
};

export const APPLICANT_MANDATORY_FIELDS = [
  "firstName",
  "lastName",
  "fatherOrHusbandName",
  "dob",
  "mobile",
  "email",
  "correspondenceAddress",
  "pin",
  "photo",
  "isPermanentAddressSameAsCorrespondence",
  "permanentAddress",
  "permanentPin",
  "aadhaarNumber",
  "panNumber",
  "profession",
  "industry",
  "employedAt",
  "signature",
  "relationShipWithPrimaryUser",
];

export const NRI_APPLICANT_MANDATORY_FIELDS = [
  "firstName",
  "lastName",
  "fatherOrHusbandName",
  "dob",
  "mobile",
  "email",
  "correspondenceAddress",
  "pin",
  "photo",
  "isPermanentAddressSameAsCorrespondence",
  "permanentAddress",
  "permanentPin",
  "panNumber",
  "passportNumber",
  "profession",
  "signature",
  "relationShipWithPrimaryUser",
];

export const EMPTY_BASIC_DETAILS = {
  salutation: "",
  firstName: "",
  lastName: "",
  isFathersName: false,
  fatherOrHusbandName: "",
  dob: moment().format(YYYY_MM_DD_WITH_HYPHEN),
  gender: "",
  mobile: "",
  email: "",
  correspondenceAddress: "",
  permanentAddress: "",
  pin: "",
  telephone: "",
  permanentPin: "",
  permanentTelephone: "",
  isPermanentAddressSameAsCorrespondence: "No",
  photo: { data: "", type: "", name: "" },
  mobileErrorMsg: "",
  emailErrorMsg: "",
};

export const PAYMENT_MANDATORY_FIELDS = [
  "chequeOrDemandDraftDate",
  "receivedAmount",
  "paymentMode",
  "paymentProof",
];

export const DEFAULT_HEAD_OF_SALES_WORKFLOW_ACTION = {
  action: Action.APPROVE,
  next_state: Status.CRM_CONFIRMATION,
};

export const DEFAULT_Channel_Partner_WORKFLOW_ACTION = {
  action: Action.APPROVE,
  next_state: Status.CRM_CONFIRMATION,
};

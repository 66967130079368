import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useRedirectToLogin, useGetUserDetailsHooks } from "../../hooks";
import { useGetOpportunityListQuery } from "../../../features/api/opportunity-api-slice";
import ColumnGroupingTable from "../../components/table";
import { API_CONSTANTS, APP_CONSTANTS } from "../../constants";
import { OpportunityStatuses, Status } from "../../constants/statuses";
import { useGetProjectsQuery } from "../../../features/api/project-api-slice";
import { ListColumnType } from "../../utils/interface";

interface opportunityDataType {
  name: string;
  customer_name: string;
  first_name?: string;
  last_name?: string;
  contact_mobile: string;
  contact_email: string;
  status: Status;
  project: string;
  allocated_sales_team: string;
  modified: string;
  pre_sales_owner: string;
}
interface opportunityListTableType {
  search?: string;
}
const ERROR = "error";

const OpportunityListTable = ({ search = "" }: opportunityListTableType) => {
  const { getUserToken } = useGetUserDetailsHooks();

  const { t } = useTranslation();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();
  let navigate = useNavigate();

  const [opportunityList, setOpportunityList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState<string[] | Status[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);

  const [searchText, setSearchText] = useState<string>("");
  const [totalOpportunities, setTotalOpportunities] = useState<number>(0);
  const [pagination, setPagination] = useState<{ page: number; size: number }>({
    page: APP_CONSTANTS.defaultPage,
    size: APP_CONSTANTS.defaultPageSize,
  });

  const {
    data: getOpportunityListResponse,
    isError: isGetOpportunityListFailed,
    isSuccess: isGetOpportunityListSuccess,
    error: getOpportunityListError,
    refetch: refetchOpportunity,
  } = useGetOpportunityListQuery({
    paginationInfo: pagination,
    selectedStatus,
    selectedProjects,
    searchText,
    userToken: getUserToken(),
  });

  const {
    data: getProjectListResponse,
    isSuccess: isGetProjectListSuccess,
    isError: isGetProjectListFailed,
  } = useGetProjectsQuery({});

  const createRowData = (data: opportunityDataType[]) => {
    let newData = data.map((item: opportunityDataType) => {
      return {
        id: item.name,
        name: item?.first_name
          ? `${item?.first_name} ${item?.last_name ? item?.last_name : ""}`
          : "",
        phoneNumber: item?.contact_mobile,
        email: item?.contact_email,
        status: item?.status,
        project: item?.project,
        assignedTo: item?.allocated_sales_team,
        modified: item?.modified,
        preSalesOwner: item?.pre_sales_owner,
      };
    });
    return newData;
  };
  const projectName = useMemo(
    () => projectList?.map((project: { name: string }) => project?.name),
    [projectList]
  );

  const columns: ListColumnType[] = [
    {
      id: "name",
      label: t("opportunity.customer_name"),
      align: "center",
    },
    {
      id: "phoneNumber",
      label: t("opportunity.phone_number"),
      align: "center",
    },
    {
      id: "email",
      label: t("opportunity.email"),
      align: "center",
    },
    {
      id: "status",
      label: t("opportunity.status"),
      align: "center",
      isMultiSelectionEnabled: true,
      isMultiSelectionDropdownOpen: false,
      values: OpportunityStatuses,
    },
    {
      id: "project",
      label: t("opportunity.project"),
      align: "center",
      isMultiSelectionEnabled: true,
      isMultiSelectionDropdownOpen: false,
      values: projectName,
    },
    {
      id: "preSalesOwner",
      label: t("opportunity.preSalesOwner"),
      align: "center",
    },
    {
      id: "assignedTo",
      label: t("opportunity.assignedTo"),
      align: "center",
    },
  ];

  useEffect(() => {
    if (isGetOpportunityListSuccess) {
      if (
        getOpportunityListResponse?.data &&
        getOpportunityListResponse?.status_code ===
          API_CONSTANTS.successStatusCode
      ) {
        setOpportunityList(getOpportunityListResponse?.data?.opportunities);
        setTotalOpportunities(
          getOpportunityListResponse?.data?.total_opportunities
        );
      } else if (getOpportunityListResponse?.message) {
        toast(getOpportunityListResponse?.message, {
          type: ERROR,
        });
        setOpportunityList([]);
      }
    } else if (isGetOpportunityListFailed) {
      redirectToLoginBasedOnResponse(
        getOpportunityListError,
        getOpportunityListResponse
      );
      setOpportunityList([]);
    }
  }, [
    getOpportunityListResponse,
    isGetOpportunityListSuccess,
    isGetOpportunityListFailed,
    t,
    getOpportunityListError,
    redirectToLoginBasedOnResponse,
  ]);

  useEffect(() => {
    if (isGetProjectListSuccess) {
      if (getProjectListResponse?.message?.length) {
        setProjectList(getProjectListResponse?.message);
      } else if (!Array.isArray(getProjectListResponse?.message)) {
        toast(getProjectListResponse?.message, {
          type: ERROR,
        });
        setProjectList([]);
      }
    } else if (isGetProjectListFailed) {
      toast(t("error_messages.server_error"), {
        type: ERROR,
      });
      setProjectList([]);
    }
  }, [
    getProjectListResponse,
    isGetProjectListSuccess,
    isGetProjectListFailed,
    t,
  ]);

  useEffect(() => {
    setSearchText(search);
    setPagination({
      page: APP_CONSTANTS.defaultPage,
      size: APP_CONSTANTS.defaultPageSize,
    });
  }, [search]);

  useEffect(() => {
    setPagination({
      page: APP_CONSTANTS.defaultPage,
      size: APP_CONSTANTS.defaultPageSize,
    });
    setSelectedStatus([]);
    refetchOpportunity();
  }, [refetchOpportunity]);

  const onUpdateValues = useCallback(
    (values: string[], label: string) => {
      if (label === t("opportunity.status")) {
        setSelectedStatus(values);
      } else {
        setSelectedProjects(values);
      }
    },
    [t, setSelectedProjects, setSelectedStatus]
  );

  const handlePageChange = (page: number, size: number) => {
    setPagination({ page, size });
  };

  const navigateToOpportunityDetails = useCallback(
    (id: string) => navigate(`/opportunity/${id}`),
    [navigate]
  );

  return (
    <>
      <ColumnGroupingTable
        rows={createRowData(opportunityList)}
        columns={columns}
        onUpdateValue={onUpdateValues}
        handlePageChange={handlePageChange}
        paginationData={pagination}
        count={totalOpportunities}
        onClickTableRow={navigateToOpportunityDetails}
      />
      <ToastContainer />
    </>
  );
};
export default OpportunityListTable;

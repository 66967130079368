import Colors from "../../utils/theme/color";
import { CustomizedText } from "../styled-componnets";
import { ContainerBox } from "./styled-components";

interface LabelAndValueInterface {
  label: string;
  value?: any;
  sx?: { container?: {} };
}
const LabelAndValue = ({
  label,
  value = "" || false,
  sx = {},
}: LabelAndValueInterface) => {
  return (
    <ContainerBox sx={sx?.container}>
      <CustomizedText fontSize={20} fontColor={Colors.greyColor}>
        {label}
      </CustomizedText>
      <CustomizedText fontSize={24} sx={{ marginBottom: 3 }}>
        {value}
      </CustomizedText>
    </ContainerBox>
  );
};

export default LabelAndValue;

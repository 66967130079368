import { Box, Dialog } from "@mui/material";

interface ButtonPropsType {
  open: boolean;
  sx: object;
  onClose(): void;
  children: React.ReactNode;
  maxWidth?: "sm" | "lg" | "xl" | "md";
}

const MaterialDialog = ({
  open,
  onClose,
  sx,
  children,
  maxWidth = "lg",
}: ButtonPropsType) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth}
      data-testid="material-dialog"
      open={open}
      onClose={onClose}
    >
      <Box sx={sx}>{children}</Box>
    </Dialog>
  );
};
export default MaterialDialog;

import {
  Button,
  ButtonProps,
  Checkbox,
  Container,
  ContainerProps,
  Divider,
  DividerProps,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  styled,
  Typography,
  TypographyProps,
  Box,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import Colors from "../utils/theme/color";

interface CustomizedContainerProps extends ContainerProps {
  backgroundColor?: string;
}
interface CustomizedTextProps extends TypographyProps {
  fontSize: number;
  fontColor?: string;
  font?: string;
}
interface CustomizedDividerProps extends DividerProps {
  width?: string;
}
interface OutlinedButtonProps extends ButtonProps {
  fontSize?: number;
}

export const CustomizedContainer = styled((props: CustomizedContainerProps) => {
  const { backgroundColor, ...other } = props;
  return <Container {...other} />;
})(({ theme, backgroundColor }) => ({
  display: "flex",
  justifyContent: "center",
  backgroundColor: backgroundColor,
  flexDirection: "column",
  width: "100%",
}));

export const CustomizedText = styled((props: CustomizedTextProps) => {
  const { fontSize, fontColor, font, ...other } = props;
  return <Typography {...other} />;
})(({ theme, fontSize, fontColor, font }) => ({
  color: fontColor ?? Colors.titleColor,
  fontFamily: font ?? "Lora-Bold",
  fontSize: fontSize,
  wordWrap: "break-word",
  [theme.breakpoints.down("xl")]: {
    fontSize: fontSize - 4,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: fontSize - 6,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: fontSize - 6,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: fontSize - 8,
  },
}));

export const CustomizedDivider = styled((props: CustomizedDividerProps) => {
  const { width, ...other } = props;
  return <Divider {...other} />;
})(({ theme, width }) => ({
  opacity: 0.15,
  backgroundColor: Colors.greyColor,
  width: width,
  alignSelf: "center",
  marginBottom: 25,
  marginTop: 20,
}));

export const CustomizedMenuItem = styled(MenuItem)(({ theme }) => ({
  color: Colors.titleColor,
  fontFamily: "Lato-Regular",
  fontSize: 18,
  backgroundColor: Colors.white,
  [theme.breakpoints.down("xl")]: {
    fontSize: 14,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 12,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
  "&.Mui-selected": {
    color: Colors.primaryColor,
  },
}));

export const StyledRadio = styled(Radio)({
  color: "#ECECEC",
  "&.Mui-checked": {
    color: Colors.primaryColor,
  },
});

export const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  color: Colors.lightTitleColor,
  p: 0,
  m: 1,
  "&.Mui-checked": {
    color: Colors.primaryColor,
  },
}));

export const OutlinedButton = styled((props: OutlinedButtonProps) => {
  const { fontSize, color, ...other } = props;
  return <Button {...other} />;
})(({ theme, fontSize = 16 }) => ({
  fontFamily: "Lora-Bold",
  fontSize: fontSize,
  borderRadius: "5px",
  color: Colors.actionColor,
  border: `1.2px solid ${Colors.actionColor}`,
  filter:
    "drop-shadow(2px 0px 8px rgba(254, 202, 10, 0.16)) drop-shadow(0px 2px 8px rgba(254, 202, 10, 0.16))",
  "&:hover": {
    color: Colors.titleColor,
    background: Colors.actionColor,
    border: "1px solid rgba(0, 0, 0, 0.08)",
    boxShadow:
      "2px 0px 8px rgba(254, 202, 10, 0.16), 0px 2px 8px rgba(254, 202, 10, 0.16)",
  },
  padding: ".1rem 1.5rem .1rem 1.5rem",
  [theme.breakpoints.down("xl")]: {
    fontSize: fontSize - 4,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: fontSize - 6,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: fontSize - 8,
  },
}));

export const SolidButton = styled((props: OutlinedButtonProps) => {
  const { fontSize, ...other } = props;
  return <Button {...other} />;
})(({ theme, fontSize = 16 }) => ({
  backgroundColor: Colors.actionColor,
  boxShadow: `2px 0px 8px ${Colors.metallicYellow} 0px 2px 8px ${Colors.metallicYellow}`,
  border: `1px solid ${Colors.veryLightBlack}`,
  "&:hover": {
    backgroundColor: Colors.goldenPoppy,
  },
  fontFamily: "Lora-Bold",
  fontSize: fontSize,
  color: Colors.primaryColor,
  [theme.breakpoints.down("xl")]: {
    fontSize: fontSize - 4,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: fontSize - 6,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: fontSize - 8,
  },
}));

export const CustomizedInputLabel = styled(InputLabel)(({ theme }) => ({
  color: Colors.titleColor,
  fontFamily: "Lora-Medium",
  fontSize: 18,
  [theme.breakpoints.down("xl")]: {
    fontSize: 14,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 12,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
}));

export const ColumnBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "91%",
}));

export const StatusBox = styled(Box)({
  backgroundColor: Colors.lightWhiteWarmColor,
  border: `1px solid #99A1B025`,
  borderRadius: 5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const CustomizedLink = styled(Link)(({ theme }) => ({
  color: Colors.linkColor,
  fontSize: 18,
  fontFamily: "Lato-Regular",
  cursor: "pointer",
  [theme.breakpoints.down("xl")]: {
    fontSize: 14,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 12,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
}));

export const RadioButtonFormLabel = styled(FormLabel)(({ theme }) => ({
  color: Colors.titleColor,
  fontSize: 20,
  fontFamily: "Lato-Regular",
  wordWrap: "break-word",
  [theme.breakpoints.down("xl")]: {
    fontSize: 16,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 14,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 10,
  },
}));

export const FileViewerContainer = styled(Box)({
  width: "100%",
  height: "100%",
  paddingLeft: 26,
});
export const StyledFormControlLabel = styled(FormControlLabel)({
  color: Colors.titleColor,
  fontFamily: "Lato-Regular",
});

export const StyledFCameraSwitch = styled(CameraswitchIcon)({
  position: "absolute",
  color: Colors.white,
  bottom: 32,
  zIndex: 1000,
});

export const InfoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  backgroundColor: Colors.cyanBlueColor,
  padding: 10,
  borderRadius: 5,
  marginTop: 10,
}));

export const ModalContainer = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.white,
  width: "60%",
  [theme.breakpoints.down("lg")]: {
    width: "63%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

export const SubmitButtonContainer = styled(Box)(({ theme }) => ({
  background: Colors.white,
  boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.12)",
  display: "flex",
  justifyContent: "flex-end",
}));

export const CloseButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  cursor: "pointer",
}));

import React, { SyntheticEvent, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { API_CONSTANTS, VALIDATION_REGEXPS } from "../../constants";
import MaterialButton from "../button";
import TextInput from "../text-input/text-input";
import Colors from "../../utils/theme/color";
import { Container, Link, useMediaQuery } from "@mui/material";
import {
  useRequestOtpMutation,
  useVerifyOtpMutation,
} from "../../../features/api/api-slice";
import OTPInput from "../otp-input/otp-input";
import { isPhoneNumberValid } from "../../utils/functions";
import { useAppDispatch, useScreenSizeApplier } from "../../hooks";
import { saveUserDetails } from "../../../features/user/user-slice";
import "react-toastify/dist/ReactToastify.css";
import "./login-card.css";

const OTP_MAX_LENGTH = 6;

const linkStyle = {
  marginBottom: "1rem",
  marginLeft: "0.5rem",
  color: Colors.linkColor,
  cursor: "pointer",
  fontFamily: "Lato-Bold",
  fontSize: 18,
};
const resendLinkStyle = {
  marginBottom: "1rem",
  marginLeft: "0.5rem",
  color: Colors.linkColor,
  cursor: "pointer",
  fontFamily: "Lato-Bold",
  fontSize: 18,
};
const LoginCard = () => {
  const dispatch = useAppDispatch();
  const [
    requestOtp,
    {
      isLoading: isRequestOtpLoading,
      isSuccess: isRequestOtpSuccess,
      isError: isRequestOtpFailed,
      data: requestOtpResponse,
    },
  ] = useRequestOtpMutation();

  const [
    verifyOtp,
    {
      isLoading: isVerifyOtpLoading,
      isSuccess: isVerifyOtpSuccess,
      isError: isVerifyOtpFailed,
      data: verifyOtpResponse,
    },
  ] = useVerifyOtpMutation();
  const { mergeStyles } = useScreenSizeApplier();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberErrorMsg, setPhoneNumberErrorMsg] = useState("");

  const [otp, setOtp] = useState("");
  const [otpErrorMsg, setOtpErrorMsg] = useState("");

  const [isOTPReceived, setIsOTPReceived] = React.useState(false);
  const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(true);

  const { t } = useTranslation();

  const isTablet = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    if (isVerifyOtpSuccess) {
      if (verifyOtpResponse?.status_code === API_CONSTANTS.successStatusCode) {
        toast(t("login_card.otp_verification_success"), { type: "success" });
        dispatch(
          saveUserDetails({
            userDetails: verifyOtpResponse?.user_details[0],
            keyDetails: {
              api_key: verifyOtpResponse?.api_key,
              api_secret: verifyOtpResponse?.api_secret,
            },
            roleDetails: verifyOtpResponse?.role_details,
          })
        );
      } else {
        if (verifyOtpResponse?.message) {
          setOtpErrorMsg(verifyOtpResponse?.message);
        }
      }
    } else if (isVerifyOtpFailed) {
      toast(t("login_card.otp_verification_failed"), { type: "error" });
    }
  }, [isVerifyOtpSuccess, isVerifyOtpFailed, verifyOtpResponse, t, dispatch]);

  React.useEffect(() => {
    if (isRequestOtpSuccess) {
      if (requestOtpResponse?.status_code === API_CONSTANTS.successStatusCode) {
        toast(t("login_card.otp_sent_success"), { type: "success" });
        setIsOTPReceived(isRequestOtpSuccess);
        setPhoneNumberErrorMsg("");
      } else {
        if (requestOtpResponse?.message) {
          setPhoneNumberErrorMsg(requestOtpResponse?.message);
        }
      }
    } else if (isRequestOtpFailed) {
      setIsOTPReceived(false);
    }
  }, [isRequestOtpSuccess, isRequestOtpFailed, requestOtpResponse, t]);

  useEffect(() => {
    if (otp) {
      isOTPValid();
    }
  });

  const onChangePhoneNumber = (e: any) => {
    setPhoneNumberErrorMsg("");
    setPhoneNumber(
      e.target.value.replace(VALIDATION_REGEXPS.matchesAnyNumbers, "")
    );
  };

  const onChangeOtp = (otp: string) => {
    setOtpErrorMsg("");
    setOtp(otp);
  };

  const onClickRequestOtp = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      if (phoneNumber) {
        if (isPhoneNumberValid(phoneNumber)) {
          setPhoneNumberErrorMsg("");
          let payload = {
            mobile: phoneNumber,
            app_id: API_CONSTANTS.appId,
          };
          await requestOtp(payload).unwrap();
        } else {
          setPhoneNumberErrorMsg(t("login_card.invalid_phone_number"));
        }
      } else {
        setPhoneNumberErrorMsg(t("login_card.please_enter"));
      }
    } catch (err) {
      toast(t("error_messages.server_error"), {
        type: "error",
      });
    }
  };

  const onClickVerifyMobile = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      if (otp && phoneNumber) {
        let payload = {
          mobile: phoneNumber,
          otp,
        };
        await verifyOtp(payload).unwrap();
      }
    } catch (err) {
      toast(t("error_messages.server_error"), {
        type: "error",
      });
    }
  };

  const onClickEditPhoneNumber = () => {
    setIsOTPReceived(false);
    setOtp("");
    setPhoneNumber("");
    setOtpErrorMsg("");
    setPhoneNumberErrorMsg("");
  };

  const isOTPValid = () => {
    if (otp) {
      if (otp?.length === OTP_MAX_LENGTH) {
        setIsVerifyButtonDisabled(false);
      } else {
        setIsVerifyButtonDisabled(true);
      }
    } else {
      setOtpErrorMsg("");
      setIsVerifyButtonDisabled(true);
    }
  };

  const isButtonDisabled = isOTPReceived
    ? isVerifyButtonDisabled
    : isOTPReceived && isVerifyOtpLoading
    ? isVerifyOtpLoading
    : isRequestOtpLoading;

  const renderTextInput = () => {
    return (
      <TextInput
        id="phone-number-input"
        value={phoneNumber}
        className="phone-input-field"
        onChange={onChangePhoneNumber}
        error={Boolean(phoneNumberErrorMsg) ? true : false}
        helperText={phoneNumberErrorMsg}
        markRequiredField={false}
      />
    );
  };

  const renderOtpInput = () => {
    return (
      <OTPInput
        otp={otp}
        handleOtpChange={onChangeOtp}
        hasErrored={otpErrorMsg ? true : false}
        errorMessage={otpErrorMsg}
      />
    );
  };

  const returnTitleBasedOnOtpSent = () =>
    isOTPReceived ? t("login_card.verify") : t("login_card.send_otp");

  const renderButton = () => (
    <MaterialButton
      id="send-otp"
      title={returnTitleBasedOnOtpSent()}
      onClick={isOTPReceived ? onClickVerifyMobile : onClickRequestOtp}
      sx={{
        ...{ marginTop: "2rem" },
        ...(phoneNumberErrorMsg && { marginTop: "1.2rem" }),
        ...(otpErrorMsg && { marginTop: "1.2rem" }),
        ...(isOTPReceived && { marginBottom: "0.5rem" }),
      }}
      fontSize={18}
      size={"small"}
      disabled={isButtonDisabled}
    />
  );

  const renderVerifyOtpLabel = () => (
    <div className="edit-phone-number-label">
      <p className="enter-phone-number">
        {t("login_card.otp_sent_to", { phoneNumber })}
      </p>
      <Link
        onClick={onClickEditPhoneNumber}
        style={mergeStyles({
          styles: linkStyle,
          belowXlStyles: { fontSize: 16 },
        })}
      >
        {t("login_card.edit")}
      </Link>
    </div>
  );

  const renderPhoneNumberLabel = () => (
    <label className="enter-phone-number" htmlFor="phone-number-input">
      {t("login_card.phone_number_label")}
    </label>
  );

  const renderLoginTitle = () => (
    <p className="login-title">
      {isOTPReceived ? t("login_card.otp_verification") : t("login_card.login")}
    </p>
  );

  const renderResendOtpLink = () => (
    <Link
      onClick={onClickRequestOtp}
      style={mergeStyles({
        styles: resendLinkStyle,
        belowXlStyles: { width: "10%" },
      })}
    >
      Resend OTP
    </Link>
  );

  return (
    <Container
      className="login-card-container"
      {...(!isTablet ? { maxWidth: "xs" } : null)}
    >
      {renderLoginTitle()}
      <form
        onSubmit={isOTPReceived ? onClickVerifyMobile : onClickRequestOtp}
        className="form-container"
      >
        {isOTPReceived ? renderVerifyOtpLabel() : renderPhoneNumberLabel()}
        {isOTPReceived ? renderOtpInput() : renderTextInput()}
        {renderButton()}
      </form>
      {isOTPReceived ? renderResendOtpLink() : null}
      <ToastContainer />
    </Container>
  );
};

export default LoginCard;

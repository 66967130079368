export const EMPTY_OPPORTUNITY_DETAILS: OpportunityProps = {
  opportunity: "",
  firstName: "",
  lastName: "",
  email: "",
  whatsappNumber: "",
  isMobileSameAsWhatsapp: false,
  mobileNumber: "",
};

export interface OpportunityProps {
  opportunity: string;
  firstName: string;
  lastName: string;
  email?: string;
  whatsappNumber?: string;
  mobileNumber?: string;
  isMobileSameAsWhatsapp?: boolean;
}

import Colors from "../../utils/theme/color";
import { styled, TableCell, TableContainer, TableRow } from "@mui/material";

export const CustomizedTableContainer = styled(TableContainer)(({ theme }) => ({
  borderWidth: 10,
  borderStyle: "solid",
  borderColor: Colors.cyanBlueColor,
  width: "98.5%",
  borderTopWidth: 0,
}));

export const CustomizedTableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: Colors.titleColor,
  fontFamily: "Lora-Medium",
  fontSize: 18,
  opacity: 0.5,
  [theme.breakpoints.down("xl")]: {
    fontSize: 14,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 12,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
}));

export const CustomizedTableBodyRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: Colors.white,
  borderBottom: "none",
  borderStyle: "solid",
  borderColor: Colors.lightGreyColor,
  borderWidth: 2,
  borderBottomWidth: "20%",
}));

export const CustomizedTableBodyCell = styled(TableCell)(({ theme }) => ({
  color: Colors.titleColor,
  fontFamily: "Lato-Normal",
  fontSize: 16,
  [theme.breakpoints.down("xl")]: {
    fontSize: 14,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 12,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

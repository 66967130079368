import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppBar, Toolbar } from "@mui/material";
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import { useGetUserDetailsHooks } from "../hooks";
import BookingForm from "../pages/create-booking/booking-form";
import CreateOpportunity from "../pages/create-opportunity";
import OpportunityDetails from "../pages/opportunity-details";
import ProposalForm from "../pages/edit-or-view-proposal";
import Header from "../components/nav-header";

const AppRouter = () => {
  const { isCurrentUserGRE, isCurrentUserCP, getUserToken } = useGetUserDetailsHooks();

  const isAuthorized = Boolean(getUserToken());

  return (
    <BrowserRouter>
      {isAuthorized ? (
        <AppBar sx={{ zIndex: 1000, boxShadow: "none" }}>
          <Toolbar disableGutters>
            <Header />
          </Toolbar>
        </AppBar>
      ) : null}
      <Routes>
        <>
          {isAuthorized ? (
            <Route path="/" element={<Dashboard />} />
          ) : (
            <Route path="/" element={<Login />} />
          )}
          {isCurrentUserGRE ? (
            <>
              <Route path="/opportunity/new" element={<CreateOpportunity />} />
              <Route path="/opportunity/:id" element={<OpportunityDetails />} />
              <Route
                path="/opportunity/:id/edit"
                element={<CreateOpportunity />}
              />
            </>
          ) : isCurrentUserCP ? (
            <>
              <Route path="/booking/:id" element={<BookingForm />} />
              <Route path="/booking/new" element={<BookingForm />} />
              <Route path="/proposal/:proposalId" element={<ProposalForm />} />
              {/* Adding the specified routes for isCurrentUserCP */}
              <Route path="/opportunity/new" element={<CreateOpportunity />} />
              <Route path="/opportunity/:id" element={<OpportunityDetails />} />
              <Route
                path="/opportunity/:id/edit"
                element={<CreateOpportunity />}
              />
            </>
          ) : (
            <>
              <Route path="/opportunity/:id" element={<OpportunityDetails />} />
              <Route
                path="/opportunity/:id/edit"
                element={<CreateOpportunity />}
              />
              <Route path="/booking/:id" element={<BookingForm />} />
              <Route path="/booking/new" element={<BookingForm />} />
              <Route path="/proposal/:proposalId" element={<ProposalForm />} />
            </>
          )}
          <Route path="/:active_tab" element={<Dashboard />} />
        </>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;

import { FC, useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CancelIcon from "@mui/icons-material/Cancel";
import { NumericFormat } from "react-number-format";
import {
  CustomizedLink,
  CustomizedText,
} from "../../../components/styled-componnets";
import Colors from "../../../utils/theme/color";
import { API_CONSTANTS, UNICODE } from "../../../constants";
import { useGetProjectsQuery } from "../../../../features/api/project-api-slice";
import {
  CustomizedCard,
  ProjectImageModalContainer,
} from "../styled-components";
import { UnitDetailsType } from "..";
import { useScreenSizeApplier } from "../../../hooks";
import {
  OpportunityEditableStatuses,
  Status,
} from "../../../constants/statuses";
import MaterialModal from "../../../components/modal";

const ERROR = "error";
const customizedCardProps = { p: "1rem", pr: "3rem" };

interface ProjectAndUnitDetailsTypes {
  project?: string;
  unitDetails?: UnitDetailsType;
  opportunityId?: string;
  status?: Status;
  unitType?: string;
}
interface ProjectDetailsType {
  smaller_display_image?: string;
  name?: string;
  location_name?: string;
  short_description?: string;
  minimum_cost?: string;
  maximum_cost?: string;
}
const materialModalProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

const ProjectAndUnitDetails: FC<ProjectAndUnitDetailsTypes> = ({
  project = "",
  unitDetails = {},
  opportunityId = "",
  status = Status.AVAILABLE,
  unitType = "",
}) => {
  const { t } = useTranslation();
  const { mergeStyles } = useScreenSizeApplier();

  const [projectDetails, setProjectDetails] = useState<ProjectDetailsType>({});
  const [showProjectImageModal, setShowProjectImageModal] =
    useState<boolean>(false);
  const {
    data: getProjectListResponse,
    isSuccess: isGetProjectListSuccess,
    isError: isGetProjectListFailed,
  } = useGetProjectsQuery({});

  const returnProjectDetailsById = useCallback(
    (projectList: { name: string }[]) =>
      projectList.filter((item) => item?.name === project)[0],
    [project]
  );

  useEffect(() => {
    if (isGetProjectListSuccess) {
      if (getProjectListResponse?.message?.length) {
        setProjectDetails(
          returnProjectDetailsById(getProjectListResponse?.message)
        );
      } else if (!Array.isArray(getProjectListResponse?.message)) {
        toast(getProjectListResponse?.message, {
          type: ERROR,
        });
        setProjectDetails({});
      }
    } else if (isGetProjectListFailed) {
      toast(t("error_messages.server_error"), {
        type: ERROR,
      });
      setProjectDetails({});
    }
  }, [
    getProjectListResponse,
    isGetProjectListSuccess,
    isGetProjectListFailed,
    t,
    returnProjectDetailsById,
  ]);

  const toggleImageModal = useCallback(
    () => setShowProjectImageModal(!showProjectImageModal),
    [showProjectImageModal]
  );

  const renderProjectImage = () => (
    <img
      src={`${API_CONSTANTS.baseURL}${projectDetails?.smaller_display_image}`}
      height={"100%"}
      width={"100%"}
      alt={"project"}
      style={{ borderRadius: 10, cursor: "pointer" }}
      onClick={toggleImageModal}
    />
  );

  const renderProjectAndUnitName = () =>
    projectDetails?.name && (
      <CustomizedText
        fontSize={24}
        font={"Lora-Medium"}
        fontColor={Colors.primaryColor}
      >
        {`${projectDetails?.name}${
          unitDetails?.unit
            ? `,${unitDetails?.unit}`
            : unitType
            ? `,${unitType}`
            : ""
        }`}
      </CustomizedText>
    );

  const renderUnitDetails = () => (
    <CustomizedText
      fontSize={16}
      font={"Lato-Regular"}
      sx={{ display: "flex", alignItems: "center" }}
      gutterBottom
    >
      {`${unitDetails?.bhk ? unitDetails.bhk : ""}${
        unitDetails?.block
          ? `${unitDetails.bhk && ","} ${t("opportunity_details.block", {
              number: unitDetails.block,
            })}`
          : ""
      }${
        unitDetails?.floor
          ? `${unitDetails.bhk ?? unitDetails.block ? "," : ""} ${t(
              "opportunity_details.floor_no",
              {
                number: unitDetails.floor,
              }
            )}`
          : ""
      }`}
    </CustomizedText>
  );

  const renderUnitDetailsOrLocation = () =>
    unitDetails?.unit
      ? renderUnitDetails()
      : projectDetails?.location_name && (
          <CustomizedText
            fontSize={16}
            font={"Lato-Regular"}
            sx={{ display: "flex", alignItems: "center" }}
            gutterBottom
          >
            <LocationOnIcon sx={{ fontSize: 16 }} />
            {projectDetails?.location_name}
          </CustomizedText>
        );

  const renderUnitArea = () => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {unitDetails?.super_builtup_area && (
        <CustomizedText
          fontSize={16}
          font={"Lora-Medium"}
          fontColor={Colors.greyColor}
        >
          {`${t("opportunity_details.super_built_up_area", {
            area: unitDetails.super_builtup_area,
          })} `}
        </CustomizedText>
      )}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {unitDetails?.super_builtup_area ? (
          <CustomizedText fontSize={10} sx={{ mr: 0.5, ml: 0.5 }}>
            {` ${UNICODE.dot} `}
          </CustomizedText>
        ) : null}
        {unitDetails?.carpet_area && (
          <CustomizedText
            fontSize={16}
            font={"Lora-Medium"}
            fontColor={Colors.greyColor}
          >
            {t("opportunity_details.carpet_area", {
              area: unitDetails.carpet_area,
            })}
          </CustomizedText>
        )}
      </Box>
    </Box>
  );

  const renderProjectDetails = () => (
    <>
      {renderProjectAndUnitName()}
      {renderUnitDetailsOrLocation()}
      {unitDetails?.name ? (
        renderUnitArea()
      ) : (
        <CustomizedText
          fontSize={18}
          font={"Lato-Regular"}
          fontColor={Colors.greyColor}
          sx={{ mt: "0.5rem" }}
          gutterBottom
        >
          {projectDetails?.short_description}
        </CustomizedText>
      )}
    </>
  );

  const renderPriceDetailsAndProceedLink = () => (
    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
      {unitDetails?.name && unitDetails?.total_cost ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CustomizedText
            fontSize={16}
            font={"Lora-Medium"}
            fontColor={Colors.greyColor}
            sx={{ mr: 1 }}
          >
            {t("opportunity_details.total_cost")}
          </CustomizedText>
          <NumericFormat
            style={{
              ...{
                fontFamily: "Lora-Medium",
                fontSize: 20,
                color: Colors.titleColor,
              },
            }}
            value={unitDetails?.total_cost}
            allowLeadingZeros
            thousandSeparator=","
            prefix={`${UNICODE.rupeeSymbol}`}
            displayType="text"
            thousandsGroupStyle="lakh"
          />
          <CustomizedText
            fontSize={16}
            font={"Lora-Medium"}
            fontColor={Colors.greyColor}
            sx={{ display: "flex", alignItems: "center", ml: 1 }}
          >
            {t("opportunity_details.subject_to_change")}
          </CustomizedText>
        </Box>
      ) : projectDetails?.minimum_cost ? (
        <CustomizedText fontSize={20} font={"Lora-Medium"}>
          {`${projectDetails?.minimum_cost}-${projectDetails?.maximum_cost}`}
        </CustomizedText>
      ) : (
        <div />
      )}
      {!unitDetails?.name &&
        status &&
        OpportunityEditableStatuses.includes(status) && (
          <CustomizedLink
            href={`/opportunity/${opportunityId}/edit`}
            fontSize={24}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {t("opportunity_details.proceed_to_add_unit_details")}
            <EastOutlinedIcon sx={{ fontSize: 16, ml: 0.3 }} />
          </CustomizedLink>
        )}
    </Box>
  );

  const renderImage = () => (
    <ProjectImageModalContainer>
      <img
        src={`${API_CONSTANTS.baseURL}${projectDetails?.smaller_display_image}`}
        height={"80%"}
        width={"40%"}
        alt={"project"}
        style={{ borderRadius: 10 }}
      />
      <CancelIcon
        htmlColor={Colors.white}
        sx={{ ml: 1 }}
        fontSize={"small"}
        onClick={toggleImageModal}
      />
    </ProjectImageModalContainer>
  );

  return (
    <>
      <CustomizedText
        fontSize={28}
        gutterBottom
        font={"Lato-Regular"}
        sx={{ mt: 2.5 }}
      >
        {t("opportunity_details.interested_project_and_unit")}
      </CustomizedText>
      <CustomizedCard
        sx={mergeStyles({
          styles: customizedCardProps,
          tabletStyles: { justifyContent: "center" },
          belowLargeStyles: { justifyContent: "center" },
        })}
      >
        {projectDetails?.smaller_display_image && (
          <Box
            sx={mergeStyles({
              styles: { width: "15%" },
              tabletStyles: { width: "100%", m: 2 },
              belowLargeStyles: { width: "100%", m: 2 },
            })}
          >
            {renderProjectImage()}
          </Box>
        )}
        <Box sx={{ ml: 2, width: "100%" }}>
          <Box
            sx={{
              pb: 1,
              borderBottom: `2px solid ${Colors.lightGreyColor}`,
            }}
          >
            {renderProjectDetails()}
          </Box>
          {renderPriceDetailsAndProceedLink()}
        </Box>
      </CustomizedCard>
      <MaterialModal
        children={renderImage()}
        sx={materialModalProps}
        open={showProjectImageModal}
        onClose={toggleImageModal}
      />
      <ToastContainer />
    </>
  );
};

export default ProjectAndUnitDetails;

import { TextField } from "@mui/material";
import moment from "moment";
import { useScreenSizeApplier } from "../../hooks";
import Colors from "../../utils/theme/color";
import { YYYY_MM_DD_WITH_HYPHEN } from "../../utils/date";
import "./date-picker.css";

interface DatePickerType {
  date: string;
  onChangeDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  outerLabel?: string;
  styles?: { outerLabel?: {}; textInputContainer?: {} };
  markRequiredField?: boolean;
  InputLabelProps?: {};
  helperText?: string;
  error?: boolean;
  maxDate?: string;
  disabled?: boolean;
  minDate?: string;
}
const inputStyle = {
  fontFamily: "Lora-Bold",
  fontSize: 18,
  color: Colors.titleColor,
  backgroundColor: "rgba(176, 153, 154, 0.05)",
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: Colors.titleColor,
  },
};
const DatePickerComponent = ({
  date,
  onChangeDate,
  outerLabel,
  styles,
  markRequiredField,
  InputLabelProps,
  helperText = "",
  error = false,
  maxDate = moment().format(YYYY_MM_DD_WITH_HYPHEN),
  disabled = false,
  minDate = moment().format(YYYY_MM_DD_WITH_HYPHEN),
}: DatePickerType) => {
  const { mergeStyles } = useScreenSizeApplier();

  return (
    <div className="date-picker-container" style={styles?.textInputContainer}>
      {outerLabel ? (
        <div
          className="date-picker-outer-label-container"
          style={styles?.outerLabel}
        >
          <p className="date-picker-outer-label">{outerLabel}</p>
          <p className="required-star"> {markRequiredField ? "*" : ""}</p>
        </div>
      ) : null}
      <TextField
        id="date"
        type="date"
        size="small"
        InputLabelProps={{
          ...{
            style: {
              color: Colors.greyColor,
              fontFamily: "Lato-Regular",
            },
          },
          ...{ focused: false },
          ...{ className: "label" },
          ...InputLabelProps,
          ...{
            shrink: false,
          },
        }}
        InputProps={{
          sx: {
            ...mergeStyles({
              styles: inputStyle,
              belowLargeStyles: { fontSize: 14 },
              belowXlStyles: { fontSize: 16 },
            }),
          },
          ...{ inputProps: { max: maxDate, min: minDate } },
        }}
        helperText={helperText}
        error={error}
        FormHelperTextProps={{ className: "helper-text-style" }}
        onChange={onChangeDate}
        disabled={disabled}
        value={moment(
          date ? moment(date).format(YYYY_MM_DD_WITH_HYPHEN) : new Date()
        ).format(YYYY_MM_DD_WITH_HYPHEN)}
      />
    </div>
  );
};

export default DatePickerComponent;

import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, FormGroup } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextInput from "../../../components/text-input/text-input";
import { useScreenSizeApplier } from "../../../hooks";
import { OpportunityProps } from "../../../utils/opportunity-utils";
import { EMPTY_OPPORTUNITY_DETAILS } from "../../../utils/opportunity-utils";
import Colors from "../../../utils/theme/color";
import { Label, StyledCheckBox } from "../../create-booking/styled-component";
import { VALIDATION_REGEXPS } from "../../../constants";

const rowContainerStyle = {
  m: 0,
  display: "flex",
  justifyContent: "space-between",
};

interface OpportunityDetailsProps {
  details: OpportunityProps;
  onUpdate: (details: OpportunityProps) => void;
  emailErrorMsg?: string;
  mobileErrorMsg?: string;
  isEditable?: boolean;
}

const OpportunityDetails: FC<OpportunityDetailsProps> = ({
  details,
  onUpdate,
  emailErrorMsg = "",
  mobileErrorMsg = "",
  isEditable = true,
}) => {
  const { t } = useTranslation();
  const { mergeStyles } = useScreenSizeApplier();

  const [opportunityDetails, setOpportunityDetails] = useState(details);
  const opportunityDetailsRef = useRef(opportunityDetails);

  useEffect(() => {
    opportunityDetailsRef.current = details;
  }, [details]);

  useEffect(() => {
    if (!Object.is(opportunityDetails, EMPTY_OPPORTUNITY_DETAILS)) {
      opportunityDetailsRef.current = opportunityDetails;
      onUpdate(opportunityDetails);
    }
  }, [onUpdate, opportunityDetails]);

  const onChangeIsWhatsappSame = (
    e: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    setOpportunityDetails({
      ...opportunityDetailsRef?.current,
      isMobileSameAsWhatsapp: checked,
      whatsappNumber: checked
        ? opportunityDetailsRef?.current?.mobileNumber
        : "",
    });
  };

  const onChangeWhatsappNumber = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOpportunityDetails({
        ...opportunityDetailsRef.current,
        whatsappNumber: e.target.value.replace(
          VALIDATION_REGEXPS.matchesAnyNumbers,
          ""
        ),
        isMobileSameAsWhatsapp:
          e.target.value === opportunityDetails?.mobileNumber,
      });
    },
    [opportunityDetails]
  );

  const onChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOpportunityDetails({
        ...opportunityDetailsRef.current,
        email: e.target.value.trim().toLowerCase(),
      });
    },
    []
  );

  const renderName = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: rowContainerStyle,
        tabletStyles: { flexDirection: "column" },
      })}
    >
      <TextInput
        id="first_name_input"
        value={opportunityDetailsRef?.current?.firstName}
        label={t("opportunity_details.first_name")}
        outerLabel={t("opportunity_details.first_name")}
        markRequiredField={false}
        styles={{
          textInputContainer: mergeStyles({
            styles: { width: "47%" },
            tabletStyles: { width: "100%" },
          }),
        }}
        disabled
      />
      <TextInput
        id="last_name_input"
        value={opportunityDetailsRef?.current?.lastName}
        label={t("opportunity_details.last_name")}
        outerLabel={t("opportunity_details.last_name")}
        markRequiredField={false}
        styles={{
          textInputContainer: mergeStyles({
            styles: { width: "47%" },
            tabletStyles: { width: "100%" },
          }),
        }}
        disabled
      />
    </Container>
  );

  const renderIsWhatsappSame = () => (
    <FormGroup sx={{ alignSelf: "flex-start" }}>
      <Label
        disableTypography
        control={
          <StyledCheckBox
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            value={opportunityDetailsRef?.current?.isMobileSameAsWhatsapp}
            sx={{ p: 0, pr: 1 }}
            size={"small"}
            onChange={(e, checked) => onChangeIsWhatsappSame(e, checked)}
            checked={opportunityDetailsRef?.current?.isMobileSameAsWhatsapp}
            disabled={!isEditable}
          />
        }
        label={t("opportunity_details.is_number_same_as_whatsapp")}
        labelPlacement="end"
        checked={opportunityDetailsRef?.current?.isMobileSameAsWhatsapp}
        onChange={onChangeIsWhatsappSame}
        sx={{ color: Colors.greyColor }}
        disabled={!isEditable}
      />
    </FormGroup>
  );

  const renderMobileNumbers = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: rowContainerStyle,
        tabletStyles: { flexDirection: "column" },
      })}
    >
      <Box
        sx={mergeStyles({
          styles: { width: "47%" },
          tabletStyles: { width: "100%" },
        })}
      >
        <TextInput
          id="mobile_input"
          value={opportunityDetailsRef?.current?.mobileNumber}
          label={t("opportunity_details.mobile_number")}
          outerLabel={t("opportunity_details.mobile_number")}
          markRequiredField={false}
          styles={{ textInputContainer: { width: "100%" } }}
          disabled
          isMobile
          maxLength={10}
        />
        {renderIsWhatsappSame()}
      </Box>
      <TextInput
        id="whatsapp_input"
        value={opportunityDetails?.whatsappNumber}
        label={t("opportunity_details.enter_whatsapp_number")}
        outerLabel={t("opportunity_details.whatsapp_number")}
        markRequiredField={true}
        styles={{
          textInputContainer: mergeStyles({
            styles: { width: "47%" },
            tabletStyles: { width: "100%" },
          }),
        }}
        onChange={onChangeWhatsappNumber}
        isMobile
        maxLength={10}
        error={Boolean(mobileErrorMsg)}
        helperText={mobileErrorMsg}
        disabled={!isEditable}
      />
    </Container>
  );

  const renderEmail = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: rowContainerStyle,
        tabletStyles: { flexDirection: "column" },
      })}
    >
      <TextInput
        id="email_input"
        value={opportunityDetails?.email}
        label={t("opportunity_details.enter_email")}
        outerLabel={t("opportunity_details.email")}
        markRequiredField={true}
        styles={{
          textInputContainer: mergeStyles({
            styles: { width: "47%" },
            tabletStyles: { width: "100%" },
          }),
        }}
        onChange={onChangeEmail}
        error={Boolean(emailErrorMsg)}
        helperText={emailErrorMsg}
        disabled={!isEditable}
      />
    </Container>
  );

  return (
    <>
      {renderName()}
      {renderMobileNumbers()}
      {renderEmail()}
    </>
  );
};

export default OpportunityDetails;

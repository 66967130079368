import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { ActionMeta } from "react-select";
import {
  CustomizedText,
  CloseButtonContainer,
  InfoContainer,
  ModalContainer,
  SubmitButtonContainer,
} from "./styled-componnets";
import { useGetUserDetailsHooks, useScreenSizeApplier } from "../hooks";
import MaterialButton from "./button";
import Colors from "../utils/theme/color";
import { useGetLostReasonsQuery } from "../../features/api/master-api-slice";
import { API_CONSTANTS } from "../constants";
import TextInput from "./text-input/text-input";
import MultiSelectionDropdown from "./multi-selection-dropdown";
import Dropdown from "./dropdown/index";

const ERROR = "error";
const boxPadding = {
  p: "3rem",
  pt: "0.5rem",
  pb: "1.5rem",
};
const DEFAULT_STATE = { value: "", label: "" };
interface NotInterestedModalContentType {
  id?: string;
  customerName?: string;
  handleClose: () => void;
  updateLostReason: (
    reason: { value: string; label: string }[],
    description: string
  ) => void;
  isButtonLoading: boolean;
  lostReasons?: { lost_reason: string }[];
  isLostReasonsViewable: boolean;
  lostDetail?: string;
  isMultipleSelectionEnabled?: boolean;
}

const NotInterestedModalContent: FC<NotInterestedModalContentType> = ({
  id = "",
  customerName = "",
  handleClose,
  updateLostReason,
  isButtonLoading,
  lostReasons = [],
  isLostReasonsViewable = false,
  lostDetail = "",
  isMultipleSelectionEnabled = true,
}) => {
  const { t } = useTranslation();
  const { mergeStyles } = useScreenSizeApplier();
  const { getUserToken } = useGetUserDetailsHooks();

  const [selectedValues, setSelectedValues] = useState<
    { value: string; label: string }[]
  >([]);

  const [singleSelectedValue, setSingleSelectedValue] = useState<{
    value: string;
    label: string;
  }>(DEFAULT_STATE);

  const [lostReasonInDetail, setLostReasonInDetail] = useState("");

  const [lostReasonList, setLostReasonList] = useState([]);
  const {
    data: getLostReasonsResponse,
    isSuccess: isLostReasonsSuccess,
    isError: isLostReasonsFailed,
  } = useGetLostReasonsQuery({ userToken: getUserToken() });

  useEffect(() => {
    if (isLostReasonsSuccess) {
      if (
        Array.isArray(getLostReasonsResponse?.data) &&
        getLostReasonsResponse?.status_code === API_CONSTANTS.successStatusCode
      ) {
        setLostReasonList(getLostReasonsResponse?.data);
      } else if (getLostReasonsResponse?.message) {
        toast(getLostReasonsResponse?.message, {
          type: ERROR,
        });
        setLostReasonList([]);
      }
    } else if (isLostReasonsFailed) {
      toast(t("error_messages.server_error"), {
        type: ERROR,
      });
      setLostReasonList([]);
    }
  }, [getLostReasonsResponse, isLostReasonsSuccess, isLostReasonsFailed, t]);

  const isSubmitButtonDisabled = useMemo(
    () =>
      isMultipleSelectionEnabled
        ? !selectedValues?.length || !Boolean(lostReasonInDetail)
        : !Boolean(singleSelectedValue?.value) || !Boolean(lostReasonInDetail),
    [
      isMultipleSelectionEnabled,
      lostReasonInDetail,
      selectedValues?.length,
      singleSelectedValue?.value,
    ]
  );

  const getNewValue = useMemo(
    () =>
      lostReasons?.map((value: { lost_reason: string }) => ({
        value: value?.lost_reason,
        label: value?.lost_reason,
      })),
    [lostReasons]
  );

  useEffect(() => {
    if (isLostReasonsViewable) {
      if (lostReasons?.length) {
        setSelectedValues(getNewValue);
      }
      setLostReasonInDetail(lostDetail);
    }
  }, [getNewValue, isLostReasonsViewable, lostDetail, lostReasons]);

  const onChangeLostReason = (
    newValue: any,
    actionMeta: ActionMeta<{ value: string; label: string }>
  ) => {
    setSelectedValues(newValue);
    setSingleSelectedValue(newValue);
  };

  const submit = useCallback(() => {
    if (isLostReasonsViewable) {
      handleClose();
    } else
      updateLostReason(
        isMultipleSelectionEnabled ? selectedValues : [singleSelectedValue],
        lostReasonInDetail
      );
  }, [
    handleClose,
    isLostReasonsViewable,
    isMultipleSelectionEnabled,
    lostReasonInDetail,
    selectedValues,
    singleSelectedValue,
    updateLostReason,
  ]);

  const returnLabelAndValue = (list: { lost_reason: string }[]) =>
    list?.map((item: { lost_reason: string }) => ({
      value: item?.lost_reason,
      label: item?.lost_reason,
    }));

  const onChangeReason = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLostReasonInDetail(e.target.value);
    },
    []
  );

  const renderCloseButton = () => (
    <CloseButtonContainer
      marginRight={0.5}
      padding={2}
      paddingBottom={0}
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" htmlColor={Colors.titleColor} />
    </CloseButtonContainer>
  );

  const renderReasonToNotPurchase = () => {
    return isMultipleSelectionEnabled ? (
      <MultiSelectionDropdown
        outerLabel={
          customerName && !isLostReasonsViewable
            ? t("opportunity_details.select_reason_for_no_interested", {
                name: customerName,
              })
            : t("opportunity_details.lost_reasons", { id })
        }
        markRequiredField={false}
        dropdownItems={returnLabelAndValue(lostReasonList)}
        inputLabel={t("create_opportunity.employed_at_placeholder")}
        handleChange={onChangeLostReason}
        styles={{ textInputContainer: { marginTop: 0 } }}
        {...(isLostReasonsViewable && {
          value: selectedValues,
        })}
        isDisabled={isLostReasonsViewable}
      />
    ) : (
      <Dropdown
        outerLabel={
          customerName && !isLostReasonsViewable
            ? t("opportunity_details.select_reason_for_no_interested", {
                name: customerName,
              })
            : t("opportunity_details.lost_reasons", { id })
        }
        markRequiredField={false}
        dropdownItems={returnLabelAndValue(lostReasonList)}
        inputLabel={t("create_opportunity.employed_at_placeholder")}
        handleChange={onChangeLostReason}
        styles={{ textInputContainer: { marginTop: 0 } }}
        {...(isLostReasonsViewable && {
          value: selectedValues[0]?.label,
        })}
        isDisabled={isLostReasonsViewable}
      />
    );
  };

  const renderDetailedReason = () => (
    <TextInput
      id="lost-reason-in-details"
      value={lostReasonInDetail}
      outerLabel={t("create_opportunity.lost_reason_in_details")}
      onChange={onChangeReason}
      markRequiredField={true}
      disabled={isLostReasonsViewable}
      multiline
      rows={3}
    />
  );

  const renderInfo = () => (
    <InfoContainer
      sx={mergeStyles({
        styles: { p: "1rem", mt: "4rem" },
        tabletStyles: { p: "0.5rem", mt: "3rem" },
        belowLargeStyles: { p: "1rem", mt: "3rem" },
        belowXlStyles: { p: "1rem", mt: "3rem" },
      })}
    >
      <CustomizedText
        fontSize={16}
        sx={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <InfoRoundedIcon sx={{ fontSize: 16, mr: 0.5 }} />
        {t("opportunity_details.not_interested_info")}
      </CustomizedText>
    </InfoContainer>
  );

  const renderConfirmAndSubmitButton = () => (
    <SubmitButtonContainer sx={{ p: "1.25rem", pr: "3rem" }}>
      <MaterialButton
        title={
          isLostReasonsViewable
            ? t("app_common.ok")
            : t("opportunity_details.confirm_and_submit")
        }
        id={"apply-filter-button"}
        onClick={submit}
        {...(!isLostReasonsViewable && {
          disabled: isSubmitButtonDisabled
            ? isSubmitButtonDisabled
            : isButtonLoading,
        })}
        isLoading={isButtonLoading}
      />
    </SubmitButtonContainer>
  );

  return (
    <ModalContainer>
      {renderCloseButton()}
      <Box
        sx={mergeStyles({
          styles: boxPadding,
          tabletStyles: {
            p: "1.5rem",
          },
        })}
      >
        {customerName && !isLostReasonsViewable && (
          <CustomizedText gutterBottom fontSize={24}>
            {t("opportunity_details.query_not_interested", {
              name: customerName,
            })}
          </CustomizedText>
        )}
        {renderReasonToNotPurchase()}
        {renderDetailedReason()}
        {!isLostReasonsViewable && renderInfo()}
      </Box>
      {renderConfirmAndSubmitButton()}
    </ModalContainer>
  );
};

export default NotInterestedModalContent;

import { useRedirectToLogin, useGetUserDetailsHooks } from "../../../hooks";
import { useGetBookingListQuery } from "../../../../features/api/booking-api-slice";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ColumnGroupingTable from "../../../components/table";
import {
  BookingStatuses,
  BookingEditableStatuses,
  Status,
} from "../../../constants/statuses";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../constants";
import { formatPrice } from "../../../utils/functions";
import { Action } from "../../../constants/action";
import { IAction } from "../../create-booking/booking-form";
import { DEFAULT_HEAD_OF_SALES_WORKFLOW_ACTION } from "../../../utils/booking-utils";
import {DEFAULT_Channel_Partner_WORKFLOW_ACTION} from "../../../utils/booking-utils";
import MaterialModal from "../../../components/modal";
import ConfirmBookingActionComponent from "../../../components/confirm-booking-action";
import { ListColumnType } from "../../../utils/interface";

interface BookingListTableType {
  search?: string;
  statuses?: Status[] | null;
}
interface BookingListType {
  name: string;
  first_name: string;
  last_name: string;
  project: string;
  unit: string;
  unit_type: string;
  rate_per_square_feet: number;
  discount_offered: number;
  total_amount: number;
  total_cost_of_ownership: number;
  workflow_state: Status;
  allocated_sales_team: string;
}
const ERROR = "error";

const materialModalProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};
const BookingTable = ({
  search = "",
  statuses = [Status.DRAFT],
}: BookingListTableType) => {
  const { t } = useTranslation();
  const { getUserToken, salesHead,isCurrentUserCP } = useGetUserDetailsHooks();

  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();
  const navigate = useNavigate();

  const [bookingList, setBookingList] = useState<BookingListType[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<Status[]>([]);
  const [pagination, setPagination] = useState<{ page: number; size: number }>({
    page: APP_CONSTANTS.defaultPage,
    size: APP_CONSTANTS.defaultPageSize,
  });
  const [totalBookings, setTotalBookings] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");
  const {
    data: getBookingListResponse,
    isError: isGetBookingListFailed,
    isSuccess: isGetBookingListSuccess,
    error: getBookingListError,
    refetch: refetchBookings,
  } = useGetBookingListQuery({
    paginationInfo: pagination,
    selectedStatus: selectedStatus,
    searchText,
    userToken: getUserToken(),
  });
  const [selectedAction, setSelectedAction] = useState<IAction>(
    DEFAULT_HEAD_OF_SALES_WORKFLOW_ACTION || DEFAULT_Channel_Partner_WORKFLOW_ACTION
  );
  const [showActionConfirmationPopup, setShowActionConfirmationPopup] =
    useState(false);
  const [bookingId, setBookingId] = useState<string>("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getActions = [
    {
      id: Action.REVIEW,
      label: Action.REVIEW,
      action: { action: Action.REVIEW, next_state: Status.INITIATED },
    },
    {
      id: Action.REJECT,
      label: Action.REJECT,
      action: { action: Action.REJECT, next_state: Status.BOOKING_CANCELLED },
    },
  ];

  useEffect(() => {
    if (isGetBookingListSuccess) {
      if (
        Array.isArray(getBookingListResponse?.data?.bookings) &&
        getBookingListResponse?.status_code === API_CONSTANTS.successStatusCode
      ) {
        setBookingList(getBookingListResponse?.data?.bookings);
        setTotalBookings(
          getBookingListResponse?.data?.total_booking[0]?.count ?? 0
        );
      } else if (getBookingListResponse?.message) {
        toast(getBookingListResponse?.message, {
          type: ERROR,
        });
        setBookingList([]);
      }
    } else if (isGetBookingListFailed) {
      redirectToLoginBasedOnResponse(
        getBookingListError,
        getBookingListResponse
      );
      setBookingList([]);
    }
  }, [
    getBookingListResponse,
    isGetBookingListSuccess,
    isGetBookingListFailed,
    getBookingListError,
    redirectToLoginBasedOnResponse,
  ]);

  useEffect(() => {
    if (statuses?.length) {
      setSelectedStatus(statuses);
    } else {
      setSelectedStatus([]);
    }
    setPagination({
      page: APP_CONSTANTS.defaultPage,
      size: APP_CONSTANTS.defaultPageSize,
    });
    refetchBookings();
  }, [refetchBookings, statuses]);

  useEffect(() => {
    setSearchText(search);
    setPagination({
      page: APP_CONSTANTS.defaultPage,
      size: APP_CONSTANTS.defaultPageSize,
    });
  }, [search]);

  useEffect(() => {
    setPagination({
      page: APP_CONSTANTS.defaultPage,
      size: APP_CONSTANTS.defaultPageSize,
    });
    setSelectedStatus([]);
    refetchBookings();
  }, [refetchBookings]);

  const createRowData = (data: BookingListType[]) => {
    let newData = data.map((item: BookingListType) => {
      return {
        id: item.name,
        name: item?.first_name
          ? `${item?.first_name} ${item?.last_name ? item?.last_name : ""}`
          : "",
        project: item.project,
        unit: item?.unit,
        unitType: item?.unit_type,
        ratePerSquareFeet: formatPrice(item?.rate_per_square_feet),
        status: item?.workflow_state,
        discountOffered: formatPrice(item?.discount_offered),
        listPrice: formatPrice(item?.total_amount),
        offeredPrice: formatPrice(item?.total_cost_of_ownership),
        assignedTo: item?.allocated_sales_team,
        moreOptions: "",
        disabled: !BookingEditableStatuses.includes(item?.workflow_state),
      };
    });
    return newData;
  };

  const onUpdateValues = useCallback(
    (values: any, label: string) => {
      setSelectedStatus(values);
    },
    [setSelectedStatus]
  );
  const handlePageChange = (page: number, size: number) => {
    setPagination({ page, size });
  };

  const navigateToBookingDetails = (bookingId?: string) =>
    navigate(`booking/${bookingId}`);

  const toggleActionConfirmationPopup = useCallback(() => {
    setShowActionConfirmationPopup(!showActionConfirmationPopup);
  }, [showActionConfirmationPopup]);

  const onClickApproveButton = useCallback((bookingId: string) => {
    setBookingId(bookingId);
    setSelectedAction(DEFAULT_HEAD_OF_SALES_WORKFLOW_ACTION);
    setSelectedAction(DEFAULT_Channel_Partner_WORKFLOW_ACTION);
    setShowActionConfirmationPopup(Boolean(bookingId));
  }, []);

  const onClickAnAction = useCallback(
    (action?: string, booking?: any) => {
      const getSelectedAction = getActions?.filter(
        (act: { id: string }) => act?.id === action
      )[0];
      setBookingId(booking?.id);
      setSelectedAction({
        action: getSelectedAction?.action?.action,
        next_state: getSelectedAction?.action?.next_state,
      });
      setShowActionConfirmationPopup(Boolean(booking?.id));
    },
    [getActions]
  );

  const bookingAndProposalListColumn: ListColumnType[] = [
    {
      id: "name",
      label: t("booking.customer_name"),
      align: "center",
    },
    {
      id: "unit",
      label: t("booking.unit"),
      align: "center",
    },
    {
      id: "status",
      label: t("booking.status"),
      align: "center",
      isMultiSelectionEnabled: !Boolean(statuses?.length),
      isMultiSelectionDropdownOpen: false,
      values: BookingStatuses,
    },
    {
      id: "ratePerSquareFeet",
      label: t("booking.rate_per_square_feet"),
      align: "center",
    },
    // {
    //   id: "discountOffered",
    //   label: t("booking.discount_offered"),
    //   align: "center",
    // },
    {
      id: "listPrice",
      label: t("booking.list_price"),
      align: "center",
    },
    {
      id: "offeredPrice",
      label: t("booking.offered_price"),
      align: "center",
    },
    {
      id: "assignedTo",
      label: t("booking.assignedTo"),
      align: "center",
    },
    {
      id: "edit",
      label: "",
      align: "center",
      isEditIconVisible: true,
    },
  ];

  const headOfSalesBookingColumn: ListColumnType[] = [
    {
      id: "name",
      label: t("booking.customer_name"),
      align: "center",
    },
    {
      id: "unit",
      label: t("booking.unit"),
      align: "center",
    },
    {
      id: "status",
      label: t("booking.status"),
      align: "center",
      isMultiSelectionEnabled: !Boolean(statuses?.length),
      isMultiSelectionDropdownOpen: false,
      values: BookingStatuses,
    },
    {
      id: "ratePerSquareFeet",
      label: t("booking.rate_per_square_feet"),
      align: "center",
    },
    {
      id: "listPrice",
      label: t("booking.list_price"),
      align: "center",
    },
    {
      id: "offeredPrice",
      label: t("booking.offered_price"),
      align: "center",
    },
    {
      id: "assignedTo",
      label: t("booking.assignedTo"),
      align: "center",
    },
    {
      id: "moreOptions",
      label: t("booking.action"),
      align: "center",
      isMenuRequired: true,
      moreOptions: getActions,
    },
  ];

  const renderActionConfirmation = () => (
    <ConfirmBookingActionComponent
      action={selectedAction}
      handleClose={toggleActionConfirmationPopup}
      bookingId={bookingId}
      navigateToBooking={navigateToBookingDetails}
      updateBookingDetails={refetchBookings}
    />
  );

  return (
    <>
      <ColumnGroupingTable
        rows={createRowData(bookingList)}
        columns={
          salesHead || isCurrentUserCP ? headOfSalesBookingColumn : bookingAndProposalListColumn
        }
        onUpdateValue={onUpdateValues}
        handlePageChange={handlePageChange}
        paginationData={pagination}
        count={totalBookings}
        onClickTableRow={navigateToBookingDetails}
        onClickEdit={navigateToBookingDetails}
        onClickApproveButton={onClickApproveButton}
        onClickSingleOption={onClickAnAction}
      />

      <MaterialModal
        open={showActionConfirmationPopup}
        sx={materialModalProps}
        children={renderActionConfirmation()}
      />
      <ToastContainer />
    </>
  );
};
export default BookingTable;

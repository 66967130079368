import React, { useCallback } from "react";
import { Box } from "@mui/material";
import { SecondaryButton } from "./customized-component";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { WebcamComponent } from "../web-cam-component";
import Colors from ".././../utils/theme/color";
import "react-toastify/dist/ReactToastify.css";
import "./file-upload-and-capture.css";
import { useScreenSizeApplier } from "../../hooks";

const OUTLINED = "outlined";
const MAX_FILE_SIZE_IN_BYTES = 3145728;
interface FileUploadAndCaptureComponentTypes {
  secondaryButtonTitle: string;
  title: string;
  description: string;
  Icon: any;
  onChangeUploadFile(
    fileData: string | ArrayBuffer | null,
    file?: {
      name?: string;
      type?: string;
      data?: string | ArrayBuffer | null;
    }
  ): void;
  isFileUpload: boolean;
  styles?: { fileUploadContainer?: object };
}
type Base64 = string;

const buttonStyle = {
  style: {
    fontFamily: "Lora-Bold",
    fontSize: 20,
    backgroundColor: Colors.white,
    border: `1.2px solid ${Colors.veryLightBlack}`,
    borderRadius: "5px",
    color: Colors.primaryColor,
    textTransform: "none",
    padding: "1rem 2.5rem 1rem 2.5rem",
    "&:hover": {
      border: `1px solid ${Colors.primaryColor}`,
      backgroundColor: Colors.white,
    },
  },
  tabletStyles: {
    padding: "0.5rem 2rem 0.5rem 2rem",
    fontSize: 16,
  },
  belowXlStyles: {
    padding: "0.2rem 2rem 0.2rem 2rem",
    fontSize: 18,
  },
  belowLargeStyles: {
    padding: "0.2rem 1rem 0.2rem 1rem",
    fontSize: 14,
  },
};

const FileUploadAndCaptureComponent = (
  props: FileUploadAndCaptureComponentTypes
) => {
  const { t } = useTranslation();

  const {
    Icon,
    title,
    description,
    onChangeUploadFile,
    isFileUpload,
    styles = {},
  } = props;
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const saveCapturedImageData = (data: Base64, file: Blob) => {
    onChangeUploadFile(data, file);
    toggleModal();
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const onClickShowWebcam = () => {
    toggleModal();
  };

  const { mergeStyles } = useScreenSizeApplier();
  const onDrop = useCallback(
    (
      acceptedFiles:
        | (<T extends File>(
            acceptedFiles: T[],
            fileRejections: FileRejection[],
            event: DropEvent
          ) => void)
        | any
    ) => {
      acceptedFiles.forEach((file: Blob) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.readAsDataURL(file);
        reader.onload = () => {
          let base64 = reader.result;
          if (file.size > MAX_FILE_SIZE_IN_BYTES) {
            toast(t("error_messages.large_file_toast_message"), {
              type: "error",
            });
          } else {
            onChangeUploadFile(base64, file);
          }
        };
        reader.onerror = (error) => {
          toast(t("error_messages.server_error"), { type: "error" });
        };
      });
    },
    [onChangeUploadFile, t]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div
      className="file-upload-container"
      style={styles?.fileUploadContainer}
      {...(isFileUpload && getRootProps())}
    >
      <Icon sx={{ color: Colors.lightBlueColor, fontSize: 60 }} />
      <p className="file-upload-title">{title}</p>
      <p className="file-upload-description">{description}</p>
      {isFileUpload ? (
        <>
          <input {...getInputProps()} />
          <Box
            sx={mergeStyles({
              styles: buttonStyle.style,
              tabletStyles: buttonStyle.tabletStyles,
              belowLargeStyles: buttonStyle.belowLargeStyles,
              belowXlStyles: buttonStyle.belowXlStyles,
            })}
          >
            {props?.secondaryButtonTitle}
          </Box>
        </>
      ) : (
        <>
          <WebcamComponent
            isVisible={isModalVisible}
            toggleModal={toggleModal}
            saveCapturedImageData={saveCapturedImageData}
          />
          <SecondaryButton variant={OUTLINED} onClick={onClickShowWebcam}>
            {props?.secondaryButtonTitle}
          </SecondaryButton>
        </>
      )}
      <ToastContainer />
    </div>
  );
};
export default FileUploadAndCaptureComponent;

import Colors from "../../utils/theme/color";
import { styled, MenuItem } from "@mui/material";

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: Colors.titleColor,
  fontFamily: "Lato-Regular",
  fontSize: 16,
  backgroundColor: Colors.white,
  [theme.breakpoints.down("xl")]: {
    fontSize: 12,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 10,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 8,
  },
  "&.Mui-selected": {
    color: Colors.primaryColor,
  },
}));

import { useState, useEffect, SetStateAction, useCallback } from "react";
import TextInput from "../../components/text-input/text-input";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { Box } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CustomizedText } from "../../components/styled-componnets";
import MaterialDialog from "../../components/dialog";
import DocumentPreview from "../../components/documents-preview";
import {
  SignatureContainer,
  StyledCheckBox,
  StyledIconButton,
} from "./styled-component";
import { CircleOutlined } from "@mui/icons-material";
import Colors from "../../utils/theme/color";
import { EventProps } from "../../utils/interface";

const materialModalProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};
const BookingNotes = ({
  bookingNotes = "",
  onChangeNotes = () => {},
  onChangeCheckBox = () => {},
  isSignatureConfirmed = false,
  signatures = [],
  onClickEditApplicantSignature = () => {},
  isEditable = false,
}: {
  bookingNotes: SetStateAction<string>;
  onChangeNotes(value: string): void;
  onChangeCheckBox(
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void;
  isSignatureConfirmed: boolean;
  signatures: [];
  onClickEditApplicantSignature(): void;
  isEditable: boolean;
}) => {
  const [notes, setNotes] = useState("");
  const [isAttachmentsVisible, setAttachmentsVisible] =
    useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setNotes(bookingNotes);
  }, [bookingNotes]);

  const onChange = (e: EventProps) => {
    setNotes(e.target.value);
    onChangeNotes(e.target.value);
  };

  const toggleAttachmentPreview = useCallback(
    () => setAttachmentsVisible(!isAttachmentsVisible),
    [isAttachmentsVisible]
  );

  const openAttachments = useCallback(() => {
    toggleAttachmentPreview();
  }, [toggleAttachmentPreview]);

  const renderAttachments = () => (
    <DocumentPreview
      documents={signatures}
      closeDocumentPreview={toggleAttachmentPreview}
    />
  );

  const renderCheckbox = () => (
    <StyledCheckBox
      sx={{ m: 0, p: 0 }}
      size={"small"}
      disableTouchRipple
      onChange={onChangeCheckBox}
      icon={<CircleOutlined />}
      checkedIcon={<CheckCircleIcon />}
      value={isSignatureConfirmed}
      checked={isSignatureConfirmed}
    />
  );

  return (
    <>
      <TextInput
        value={notes}
        label={t("create_booking.notes.notes_placeholder")}
        id={"booking-notes"}
        markRequiredField={true}
        multiline
        size="medium"
        rows={10}
        styles={{
          textInputContainer: { width: "60%" },
        }}
        onChange={onChange}
        disabled={!isEditable}
      />
      <SignatureContainer
        sx={{
          border: 0.8,
          borderRadius: 1,
          borderColor: Colors.greyColor,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isEditable && renderCheckbox()}
          <CustomizedText
            fontSize={20}
            fontColor={Colors.titleColor}
            sx={{ marginLeft: 0.5 }}
          >
            {t("create_booking.notes.confirm_signatures")}
          </CustomizedText>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <StyledIconButton
            children={<VisibilityIcon fontSize="small" />}
            onClick={openAttachments}
          />
          {isEditable && (
            <ModeEditOutlinedIcon
              fontSize={"small"}
              sx={{
                cursor: "pointer",
                color: Colors.greyColor,
              }}
              onClick={onClickEditApplicantSignature}
            />
          )}
        </Box>
      </SignatureContainer>
      <MaterialDialog
        children={renderAttachments()}
        sx={materialModalProps}
        open={isAttachmentsVisible}
        onClose={toggleAttachmentPreview}
        maxWidth={"lg"}
      />
    </>
  );
};

export default BookingNotes;

import React, { useCallback } from "react";
import { Box } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import Colors from ".././../utils/theme/color";
import SignaturePadComponent from "./signature-pad";
import "react-toastify/dist/ReactToastify.css";
import "./signature-pad-with-file-upload.css";
import { useScreenSizeApplier } from "../../hooks";

const MAX_FILE_SIZE_IN_BYTES = 3145728;
interface SignaturePadWithFileUploadTypes {
  secondaryButtonTitle: string;
  title: string;
  description: string;
  Icon: React.ElementType;
  onChangeUploadFile(
    fileData: string | ArrayBuffer | null,
    file?: { uri?: string; type?: string; size?: number; name?: string }
  ): void;
  isFileUpload: boolean;
  styles?: { fileUploadContainer?: object };
}

const buttonStyle = {
  fontFamily: "Lora-Bold",
  fontSize: 20,
  backgroundColor: Colors.white,
  border: `1.2px solid ${Colors.veryLightBlack}`,
  borderRadius: "5px",
  color: Colors.primaryColor,
  textTransform: "none",
  padding: "1rem 2.5rem 1rem 2.5rem",
  "&:hover": {
    border: `1px solid ${Colors.primaryColor}`,
    backgroundColor: Colors.white,
  },
};

const SignaturePadWithFileUpload = (props: SignaturePadWithFileUploadTypes) => {
  const { t } = useTranslation();

  const {
    Icon,
    title,
    description,
    onChangeUploadFile,
    isFileUpload,
    styles = {},
  } = props;

  const { mergeStyles } = useScreenSizeApplier();

  const uploadSignature = (data: string | ArrayBuffer | null) => {
    onChangeUploadFile(data);
  };

  const onDrop = useCallback(
    (
      acceptedFiles:
        | (<T extends File>(
            acceptedFiles: T[],
            fileRejections: FileRejection[],
            event: DropEvent
          ) => void)
        | any
    ) => {
      acceptedFiles.forEach((file: Blob) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.readAsDataURL(file);
        reader.onload = () => {
          let base64 = reader.result;
          if (file.size > MAX_FILE_SIZE_IN_BYTES) {
            toast(t("error_messages.large_file_toast_message"), {
              type: "error",
            });
          } else {
            onChangeUploadFile(base64, file);
          }
        };
        reader.onerror = (error) => {
          toast(t("error_messages.server_error"), { type: "error" });
        };
      });
    },
    [onChangeUploadFile, t]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div
      className="file-upload-container"
      style={styles?.fileUploadContainer}
      {...(isFileUpload && getRootProps())}
    >
      {isFileUpload ? (
        <>
          <Icon sx={{ color: Colors.lightBlueColor, fontSize: 60 }} />
          <p className="file-upload-title">{title}</p>
          <p className="file-upload-description">{description}</p>
          <input {...getInputProps()} />
          <Box
            sx={mergeStyles({
              styles: buttonStyle,
              tabletStyles: {
                padding: "0.5rem 2rem 0.5rem 2rem",
                fontSize: 18,
              },
              belowLargeStyles: {
                padding: "0.2rem 1rem 0.2rem 1rem",
                fontSize: 14,
              },

              belowXlStyles: {
                padding: "0.2rem 2rem 0.2rem 2rem",
                fontSize: 16,
              },
            })}
          >
            {props?.secondaryButtonTitle}
          </Box>
        </>
      ) : (
        <div className="signature-canvas-container">
          <SignaturePadComponent setSignatureData={uploadSignature} />
        </div>
      )}
      <ToastContainer />
    </div>
  );
};
export default SignaturePadWithFileUpload;

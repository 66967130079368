import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Typography, useMediaQuery, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { PowerSettingsNew } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import MaterialModal from "../modal";
import CustomizedDialogs from "../customized-dialog";
import {
  useAppDispatch,
  useRedirectToLogin,
  useGetUserDetailsHooks,
  useScreenSizeApplier,
} from "../../hooks";
import { logout } from "../../../features/user/user-slice";
import { useGetEmployeeDetailsQuery } from "../../../features/api/api-slice";
import {
  backgroundImgStyle,
  logoutButtonProps,
  materialModalProps,
  mobileEmailProps,
  profileImageStyle,
  profileNameProps,
  styleWithNoBackgroundImage,
} from "./customized-style-component";
import "./header.css";
import { API_CONSTANTS } from "../../constants";

const Header = (props: { selectedTabIndex?: number }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getUserToken } = useGetUserDetailsHooks();
  const {
    data: getEmployeeDetailsResponse,
    isSuccess: isEmployeeDetailsSuccess,
    isError: isEmployeeDetailsFailed,
    error: employeeDetailsError,
  } = useGetEmployeeDetailsQuery({ userToken: getUserToken() });
  const { mergeStyles } = useScreenSizeApplier();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();

  const [isEmployeeDetailsModalVisible, setIsEmployeeDetailsModalVisible] =
    useState(false);
  const [isLogoutDialogVisible, setIsLogoutDialogVisible] = useState(false);

  const [userDetails, setUserDetails] = useState({
    name: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    profile_image_url: "",
  });
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:768px)");
  const isMobile = useMediaQuery("(max-width:425px)");
  const isBelowXl = useMediaQuery(theme.breakpoints.down("xl"));
  useEffect(() => {
    if (isEmployeeDetailsSuccess) {
      if (getEmployeeDetailsResponse?.message?.first_name) {
        setUserDetails(getEmployeeDetailsResponse?.message);
      } else if (getEmployeeDetailsResponse?.message) {
        toast(getEmployeeDetailsResponse?.message, {
          type: "error",
        });
      }
    } else if (isEmployeeDetailsFailed) {
      redirectToLoginBasedOnResponse(
        employeeDetailsError,
        getEmployeeDetailsResponse
      );
    }
  }, [
    isEmployeeDetailsSuccess,
    isEmployeeDetailsFailed,
    getEmployeeDetailsResponse,
    t,
    redirectToLoginBasedOnResponse,
    employeeDetailsError,
  ]);

  const onClick = () => {
    toggleModal();
  };

  const toggleModal = () => {
    setIsEmployeeDetailsModalVisible(!isEmployeeDetailsModalVisible);
  };

  const toggleDialogBox = () => {
    toggleModal();
    setIsLogoutDialogVisible(!isLogoutDialogVisible);
  };

  const onClickLogout = () => {
    toggleDialogBox();
    dispatch(logout());
    navigate("/");
  };

  const onClickCancel = () => {
    toggleDialogBox();
    setIsEmployeeDetailsModalVisible(false);
  };

  const renderTopography = ({
    props,
    text,
    id,
    testId,
  }: {
    props: object;
    text: string;
    id: string;
    testId: string;
  }) => (
    <Typography id={id} sx={props} data-testid={testId}>
      {text}
    </Typography>
  );

  const renderProfileImage = ({ profileProps }: { profileProps: object }) => (
    <Avatar
      alt={`${userDetails?.first_name}`}
      role={"img"}
      sx={profileProps}
      src={`${API_CONSTANTS.baseURL}${userDetails?.profile_image_url}`}
    />
  );

  const renderProfileName = () =>
    renderTopography({
      id: "profile-name",
      props: { ...profileNameProps.style, ...(isBelowXl && { fontSize: 16 }) },
      text: `${userDetails?.first_name} ${
        userDetails?.last_name ? userDetails?.last_name : ""
      }`,
      testId: "profile-name",
    });

  const renderMobileNumber = () =>
    renderTopography({
      id: "mobile_number",
      props: { ...mobileEmailProps.style, ...(isBelowXl && { fontSize: 14 }) },
      text: userDetails?.mobile,
      testId: "mobile_number",
    });

  const renderEmail = () =>
    renderTopography({
      id: "email",
      props: { ...mobileEmailProps.style, ...(isBelowXl && { fontSize: 14 }) },
      text: userDetails?.email,
      testId: "email",
    });

  const renderProfile = () => (
    <div role={"button"} className="image-container" onClick={onClick}>
      {renderProfileImage({
        profileProps: { height: 30, width: 30 },
      })}
      {!isTablet && (
        <KeyboardArrowDownIcon className="down-arrow" fontSize="large" />
      )}
    </div>
  );

  const renderProfileDetails = () => (
    <>
      <div className="profile-container">
        {renderProfileImage({
          profileProps: isBelowXl
            ? profileImageStyle.resizedStyle
            : profileImageStyle.style,
        })}
        {userDetails?.first_name ? renderProfileName() : null}
        {userDetails?.mobile ? renderMobileNumber() : null}
        {userDetails?.email ? renderEmail() : null}
      </div>
      {renderDivider()}
      {renderLogoutButton()}
    </>
  );

  const renderDivider = () => <div className="divider" />;

  const renderLogoutButton = () => (
    <Typography
      id="logout-button"
      sx={{
        ...logoutButtonProps.style,
        ...(isBelowXl && {
          fontSize: 16,
          padding: "0.8rem",
        }),
      }}
      data-testid="logout-button"
      onClick={toggleDialogBox}
    >
      <PowerSettingsNew
        sx={{
          ...{ mr: 0.7, fontSize: 20 },
          ...(isBelowXl && {
            fontSize: 16,
          }),
        }}
      />
      {t("header.logout")}
    </Typography>
  );

  return (
    <div
      className="header-container"
      style={isTablet ? styleWithNoBackgroundImage : backgroundImgStyle}
    >
      {renderProfile()}
      <MaterialModal
        open={isEmployeeDetailsModalVisible}
        onClose={toggleModal}
        sx={mergeStyles({
          styles: {
            ...materialModalProps,
            ...(isMobile && {
              display: "none",
              width: "40%",
              backgroundColor: "yellow",
            }),
          },
          belowXlStyles: {
            top: 80,
            width: "15%",
          },
          belowLargeStyles: {
            width: "25%",
          },
          tabletStyles: {
            right: ".5rem",
            width: "35%",
          },
        })}
        children={renderProfileDetails()}
      />
      <CustomizedDialogs
        handleOpenAndClose={onClickCancel}
        open={isLogoutDialogVisible}
        subTitle={t("header.logout_dialog_title")}
        description={t("header.logout_dialog_description")}
        primaryButtonTitle={t("app_common.cancel")}
        secondaryButtonTitle={t("header.logout")}
        onClickSecondaryButton={onClickLogout}
        onClickPrimaryButton={onClickCancel}
      />
      <ToastContainer />
    </div>
  );
};

export default Header;

import { Box, Card, IconButton, IconButtonProps, styled } from "@mui/material";
import Colors from "../../utils/theme/color";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export const DetailsContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  backgroundColor: Colors.veryLightPrimaryColor,
  marginTop: 5,
}));

export const CalculatorContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const DiscountContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  backgroundColor: "rgba(13, 77, 161, 0.06)",
  borderRadius: 8,
  marginTop: 20,
  border: "1px solid rgba(153, 161, 176, 0.5)",
  padding: 20,
}));

export const PriceContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  borderRadius: 8,
  marginTop: 20,
  border: "1px solid rgba(153, 161, 176, 0.5)",
  padding: 20,
  flexDirection: "column",
}));

export const PriceAndValueContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  color: Colors.primaryColor,
}));

export const TotalOwnershipContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  backgroundColor: "rgba(13, 77, 161, 0.06)",
  borderRadius: 8,
  marginTop: 20,
  padding: 20,
  flexDirection: "column",
}));

export const Container = styled(Box)(({ theme }) => ({
  padding: "1rem 2rem 1rem 2rem",
  display: "flex",
  flexDirection: "column",
}));

export const RowStyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const TitleAndButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const ActionContainer = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.white,
  display: "flex",
  width: "45%",
  flexDirection: "column",
  borderRadius: 5,
  paddingTop: 20,
  [theme.breakpoints.down("lg")]: {
    width: "63%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

export const CloseButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
}));

export const ActionInnerContainer = styled(Box)(({ theme }) => ({
  padding: 20,
  paddingTop: 0,
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "60%",
}));

export const BottomButtonContainer = styled(Box)(({ theme }) => ({
  background: Colors.white,
  boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.12)",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: 30,
  paddingTop: 15,
  paddingBottom: 15,
  marginTop: 25,
}));

export const MessageContainer = styled(Box)(({ theme }) => ({
  background: Colors.cultured,
  padding: 35,
  paddingTop: 15,
  paddingBottom: 15,
  margin: 25,
  marginTop: 0,
  borderRadius: 5,
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 25,
  paddingRight: 10,
  paddingBottom: 10,
  paddingTop: 10,
}));

export const SuperBuiltUpAreaContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "rgba(13, 77, 161, 0.06)",
  padding: 16,
  borderRadius: 8,
  marginTop: 10,
}));

export const CommentAndNotesCard = styled(Card)(({ theme }) => ({
  borderRadius: "16px 16px 0px 0px",
  boxShadow: "0px 3px 12px -1px rgba(0, 0, 0, 0.16)",
  width: "35%",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginTop: 25,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginTop: 25,
  },
}));

import { useCallback, useMemo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { Avatar } from "@mui/material";
import MaterialButton from "../../components/button";
import { useAppDispatch, useGetUserDetailsHooks } from "../../hooks";
import {
  saveFirstApplicantDetails,
  saveFormDetails,
  saveSecondApplicantDetails,
} from "../../../features/booking/applicant-slice";
import MaterialTabs from "../../components/tabs";
import Layout from "../../components/layout";
import { CustomizedText } from "../../components/styled-componnets";
import { dashboardImages } from "../../assets/image/dashboard";
import {
  AvatarAndWelcomeTextContainer,
  ButtonAndSearchContainer,
} from "./styled-component";
import TextInput from "../../components/text-input/text-input";
import OpportunityListTable from "./opportunity-list-table";
import Colors from "../../utils/theme/color";
import "./dashboard.css";
import BookingProposalList from "./booking-proposal-list";
import BookingList from "./booking-list";
import { VALIDATION_REGEXPS } from "../../constants";
import { EventProps } from "../../utils/interface";

const tabContainerStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};
const tabLabelStyle = {
  textTransform: "none",
  fontFamily: "Lato-Regular",
  fontSize: 16,
  color: Colors.greyColor,
};

const OPPORTUNITIES = "OPPORTUNITIES";
const PROPOSAL = "PROPOSAL";
const BOOKING = "BOOKING";

const Dashboard = () => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { isCurrentUserGRE, isCurrentUserCP } = useGetUserDetailsHooks();

  const [selectedTab, setSelectedTab] = useState<{
    tabIndex: number;
    tabItem: string;
  }>({
    tabIndex: isCurrentUserGRE ||  isCurrentUserCP ? 0 : 2,
    tabItem: isCurrentUserGRE  ||  isCurrentUserCP ? OPPORTUNITIES : BOOKING,
  });

  const onClickCreateNewBooking = () => {
    dispatch(saveFirstApplicantDetails({}));
    dispatch(saveSecondApplicantDetails({}));
    dispatch(saveFormDetails({}));
    navigate("/opportunity/new");
  };

  const { t } = useTranslation();

  const [search, setSearch] = useState<string>("");

  const tabItems = useMemo(
    () =>
      isCurrentUserGRE 
        ? [
            {
              id: OPPORTUNITIES,
              label: t("dashboard.opportunities"),
            },
          ]
        : [
            {
              id: OPPORTUNITIES,
              label: t("dashboard.opportunities"),
            },
            {
              id: PROPOSAL,
              label: t("dashboard.proposals"),
            },
            {
              id: BOOKING,
              label: t("dashboard.bookings"),
            },
          ],
    [t, isCurrentUserGRE]
  );

  useEffect(() => {
    if (params?.active_tab === BOOKING) {
      setSelectedTab({
        tabIndex: tabItems?.length - 1,
        tabItem: BOOKING,
      });
      navigate("/");
    } else if (params?.active_tab === OPPORTUNITIES) {
      setSelectedTab({
        tabIndex: 0,
        tabItem: OPPORTUNITIES,
      });
      navigate("/");
    } else if (params?.active_tab === PROPOSAL) {
      setSelectedTab({
        tabIndex: tabItems?.length - 2,
        tabItem: PROPOSAL,
      });
      navigate("/");
    }
  }, [params, navigate, tabItems?.length]);

  const onChangeTab = useCallback(
    (e: any, value: number) => {
      let findSelectedTabItem = tabItems?.filter(
        (item, index) => index === value
      )[0];
      setSelectedTab({ tabIndex: value, tabItem: findSelectedTabItem?.id });
    },
    [setSelectedTab, tabItems]
  );

  const onSearchChange = (e: EventProps) => {
    setSearch(
      e.target.value?.replace(
        VALIDATION_REGEXPS.matchesAnyCharactersAndNumbers,
        ""
      )
    );
  };

  const renderSearchIcon = () => (
    <SearchIcon htmlColor={Colors.lightTitleColor} fontSize={"small"} />
  );

  const renderTabPanel = () => (
    <div className="tab-panel">
      {renderButtonAndSearchBar()}
      {selectedTab?.tabItem === OPPORTUNITIES && (
        <OpportunityListTable search={search} />
      )}
      {selectedTab?.tabItem === PROPOSAL && (
        <BookingProposalList searchValue={search} />
      )}
      {selectedTab?.tabItem === BOOKING && <BookingList searchValue={search} />}
    </div>
  );

  const renderButtonAndSearchBar = () => (
    <ButtonAndSearchContainer
      maxWidth="xl"
      sx={{ m: 0, mb: "1rem" }}
      disableGutters
    >
      <TextInput
        type={"search"}
        id={"search-input"}
        markRequiredField={false}
        onChange={onSearchChange}
        value={search}
        label={
          selectedTab?.tabItem === PROPOSAL
            ? t("dashboard.proposal_search_by_placeholder")
            : t("dashboard.services_by_placeholder")
        }
        size="small"
        styles={{
          textInputContainer: { width: "25%" },
        }}
        icon={renderSearchIcon()}
      />
      {(isCurrentUserGRE || isCurrentUserCP) && selectedTab?.tabItem === OPPORTUNITIES && (
        <MaterialButton
          id="create-button"
          title={"Create new opportunity"}
          onClick={onClickCreateNewBooking}
        />
      )}
    </ButtonAndSearchContainer>
  );

  const renderAvatarAndWelcomeText = () => (
    <AvatarAndWelcomeTextContainer maxWidth="xl" sx={{ ml: 0.5, mt: "2rem" }}>
      <Avatar alt="hello icon" role={"img"} src={dashboardImages.HELLO_ICON} />
      <CustomizedText
        fontSize={32}
        sx={{ ml: 1 }}
        fontColor={Colors.spanishGray}
        font={"Lora-Medium"}
      >
        {t("dashboard.welcome_text")}
      </CustomizedText>
    </AvatarAndWelcomeTextContainer>
  );

  const renderTabs = () => (
    <MaterialTabs
      variant={"scrollable"}
      tabItems={tabItems}
      value={selectedTab?.tabIndex}
      TabIndicatorProps={{
        sx: {
          bgcolor: Colors.primaryColor,
        },
      }}
      tabLabelStyle={tabLabelStyle}
      styles={{
        selectedTabStyle: {
          backgroundColor: { color: Colors.primaryColor },
          borderRadius: 5,
          color: Colors.primaryColor,
        },
        boxContainer: tabContainerStyle,
        tabContainer: {
          p: "1rem 0rem 0rem 0rem",
        },
      }}
      onChange={onChangeTab}
      selectedTabIndex={selectedTab?.tabIndex}
      isDividerRequired
      isTabPanelRequired={true}
      tabContent={renderTabPanel()}
    />
  );

  const renderContent = () => (
    <>
      {renderAvatarAndWelcomeText()}
      {renderTabs()}
    </>
  );

  return <Layout children={renderContent()} hideBottomBar />;
};

export default Dashboard;

import {
  Checkbox,
  CardContent,
  FormControlLabel,
  Card,
  Box,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import Colors from "../../utils/theme/color";

export const Label = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  marginTop: "0.5rem",
  color: Colors.titleColor,
  fontFamily: "Lato-Regular",
  fontSize: 20,
  [theme.breakpoints.down("xl")]: {
    fontSize: 18,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));

export const StyledCheckBox = styled(Checkbox)({
  display: "flex",
  flexDirection: "column",
  color: Colors.titleColor,
  opacity: 0.3,
  "&.Mui-checked": {
    color: Colors.primaryColor,
    opacity: 1,
  },
});
export const StyledCard = styled(Card)({
  display: "flex",
  alignItems: "center",
});

export const CardContainer = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  pl: 2,
  pr: 2,
});

export const UserDetailsCard = styled(Box)({
  backgroundColor: Colors.white,
  padding: "0rem 1.5rem 1rem 1.5rem",
  display: "flex",
  flexDirection: "column",
});

export const ApplicantTabContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "1rem 0rem 1rem 0rem",
  overflowY: "scroll",
});
export const RowStyledBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  cursor: "pointer",
});
export const DocumentsRowStyledBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginRight: "1.5rem",
  marginBottom: 10,
});
export const BookingHeaderContainer = styled(Box)({
  width: "100%",
  backgroundColor: Colors.white,
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
});
export const StyledIconButton = styled(IconButton)({
  color: Colors.greyColor,
  cursor: "pointer",
  padding: 0,
  alignSelf: "right",
  marginRight: 5,
  marginLeft: 5,
});

export const boxContainerProps = {
  p: 0.8,
  pr: 2,
  pl: 2,
  ml: "1.5rem",
};
export const SignatureContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: "25%",
  justifyContent: "space-around",
  marginTop: 20,
  paddingTop: 10,
  paddingBottom: 10,
  backgroundColor: Colors.veryLightGreyColor,
});
export const SignatureDialogBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: 10,
});
export const StyledCloseIcon = styled(CloseIcon)({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: 10,
});
export const LoaderContainer = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "center",
});
export const ProjectAndLeadDetailsContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

import { FC } from "react";
import {
  CustomizedText,
  CustomizedDivider,
} from "../../components/styled-componnets";

interface BookingDetailsTitleTypes {
  title: string;
  isDividerRequired?: boolean;
}
const BookingDetailsTitle: FC<BookingDetailsTitleTypes> = ({
  title,
  isDividerRequired = true,
}) => {
  return (
    <>
      <CustomizedText fontSize={32}>{title}</CustomizedText>
      {isDividerRequired && <CustomizedDivider width="100%" />}
    </>
  );
};
export default BookingDetailsTitle;

import * as React from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  CustomizedText,
  RadioButtonFormLabel,
  StyledRadio,
} from "./styled-componnets";
import Colors from "../utils/theme/color";

interface RowRadioButtonsGroupTypes {
  labels: any[];
  value?: string;
  handleChange(event: React.ChangeEvent<HTMLInputElement>): void;
  sx?: { formLabel?: {}; labelRequired?: {}; radio?: {}; label?: {} };
  title: string;
  markRequiredField: boolean;
  isRow?: boolean;
  disabled?: boolean;
}

export default function RowRadioButtonsGroup({
  labels,
  value = "",
  handleChange,
  sx = { formLabel: {}, labelRequired: {}, radio: {}, label: {} },
  title,
  markRequiredField,
  isRow = true,
  disabled = false,
}: RowRadioButtonsGroupTypes) {
  return (
    <FormControl>
      <RadioButtonFormLabel
        id="row-radio-buttons-group-label"
        sx={sx?.formLabel}
      >
        {title}
        <FormLabel
          sx={{
            ...{ marginLeft: "0.2rem" },
            ...sx?.labelRequired,
          }}
        >
          {markRequiredField ? "*" : ""}
        </FormLabel>
      </RadioButtonFormLabel>
      <RadioGroup
        row={isRow}
        aria-labelledby="row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {labels?.map((label) => {
          return (
            <FormControlLabel
              key={label?.id}
              value={label?.id}
              control={
                <StyledRadio
                  sx={{ ...sx?.radio }}
                  size={"small"}
                  disableRipple
                  disabled={disabled}
                />
              }
              label={
                <CustomizedText
                  fontSize={20}
                  font={"Lato-Regular"}
                  fontColor={Colors.greyColor}
                >
                  {label?.label}
                </CustomizedText>
              }
              sx={sx?.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

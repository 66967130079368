import { Box, Card, styled } from "@mui/material";
import Colors from "../../utils/theme/color";

export const SiteVisitTitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 25,
  paddingRight: 25,
  margin: 24,
  marginRight: 0,
  marginLeft: 0,
  marginBottom: 16,
}));

export const SiteVisitUpdateModal = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.white,
  display: "flex",
  flexDirection: "column",
  paddingTop: 15,
  borderRadius: 5,
  width: "100%",
}));

export const BottomButtonContainer = styled(Box)(({ theme }) => ({
  background: Colors.white,
  boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.12)",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: 30,
  paddingTop: 15,
  paddingBottom: 15,
  marginTop: 25,
}));
export const SiteVisitContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const SiteVisitCard = styled(Card)(({ theme }) => ({
  borderRadius: 5,
  boxShadow: "0px 3px 12px -1px rgba(0, 0, 0, 0.16)",
  width: "96%",
  display: "flex",
  flexDirection: "column",
  padding: 20,
  [theme.breakpoints.down("lg")]: {
    width: "92%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

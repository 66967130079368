import MaterialTabs from "../../../components/tabs";
import Colors from "../../../utils/theme/color";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import BookingTable from "./table";
import { BookingStatuses, Status } from "../../../constants/statuses";
import { useGetUserDetailsHooks } from "../../../hooks";
const tabLabelStyle = {
  textTransform: "none",
  fontFamily: "Lato-Regular",
  fontSize: 16,
  color: Colors.greyColor,
};
const tabContainerStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  backgroundColor: Colors.white,
  padding: 0,
  margin: 0,
};
const selectedTabStyle = {
  backgroundColor: Colors.cyanBlueColor,
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  color: Colors.primaryColor,
};
interface BookingProposalListTypes {
  searchValue?: string;
}

const BookingList = ({ searchValue = "" }: BookingProposalListTypes) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<{
    tabIndex: number;
    tabItem: Status;
  }>({
    tabIndex: 0,
    tabItem: Status.ALL,
  });
  const { isCurrentUserCP } = useGetUserDetailsHooks();
  const [searchText, setSearchText] = useState("");
  const tabItems = useMemo(() => {
    if (isCurrentUserCP) {
      return [
        {
          id: Status.ALL,
          label: t("booking.all"),
        },
        {
          id: Status.DRAFT,
          label: t("booking.draft"),
        },
        {
          id: Status.INITIATED,
          label: t("booking.initiated"),
        },
        {
          id: Status.SALES_MANAGER_REVIEW,
          label: t("booking.sales_manager_approval"),
        }
      ];
    } else {
      return [
        {
          id: Status.ALL,
          label: t("booking.all"),
        },
        {
          id: Status.DRAFT,
          label: t("booking.draft"),
        },
        {
          id: Status.INITIATED,
          label: t("booking.initiated"),
        },
        {
          id: Status.SALES_APPROVAL,
          label: t("booking.sales_approval"),
        }
      ];
    }
  }, [isCurrentUserCP, t]);


  useEffect(() => {
    setSearchText(searchValue);
  }, [searchValue]);

  const onChangeTab = useCallback(
    (e: object, value: number) => {
      let findSelectedTabItem = tabItems?.filter(
        (item, index) => index === value
      )[0];
      setSelectedTab({ tabIndex: value, tabItem: findSelectedTabItem?.id });
    },
    [setSelectedTab, tabItems]
  );

  const renderTabPanel = () => (
    <BookingTable
      statuses={
        BookingStatuses?.includes(selectedTab?.tabItem)
          ? [selectedTab?.tabItem]
          : null
      }
      search={searchText}
    />
  );

  const renderTabs = () => (
    <MaterialTabs
      variant={"scrollable"}
      tabItems={tabItems}
      value={selectedTab.tabIndex}
      TabIndicatorProps={{
        sx: {
          bgcolor: Colors.cyanBlueColor,
          height: 0,
        },
      }}
      tabLabelStyle={tabLabelStyle}
      styles={{
        selectedTabStyle: selectedTabStyle,
        boxContainer: tabContainerStyle,
        tabPanelStyle: { p: 0 },
      }}
      onChange={onChangeTab}
      selectedTabIndex={selectedTab.tabIndex}
      isTabPanelRequired={true}
      tabContent={renderTabPanel()}
      isScrollable={false}
    />
  );
  return renderTabs();
};

export default BookingList;

import { useCallback, useEffect, useState } from "react";
import { Box, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import ExpandableItemCard from "../expandable-card/expandable-item-card";
import { CustomizedDivider, CustomizedText } from "../styled-componnets";
import { PreviewContainer, RowStyledBox } from "./styled-components";
import {
  ApplicantDetails,
  HomeLoanDataTypes,
  legalDataTypes,
  PaymentDataTypes,
  ProjectAndUnitType,
} from "../../utils/booking-utils";
import ProjectAndUnit from "./project-and-unit";
import ApplicantsDetails from "./applicant-details";
import PaymentDetails from "./payment-details";
import HomeLoanDetails from "./home-loan";
import LegalDetails from "./legal-details";
import MaterialButton from "../button";
import CustomizedDialogs from "../customized-dialog";
import TermsAndConditions from "./terms-and-condition";
import BookingNotes from "./booking-notes";
import MaterialModal from "../modal";

const PROJECT_AND_UNIT = "PROJECT_AND_UNIT";
const APPLICANT_DETAILS = "APPLICANT_DETAILS";
const PAYMENT_DETAILS = "PAYMENT_DETAILS";
const HOME_LOAN = "HOME_LOAN";

const materialModalProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

interface BookingPreviewDetails {
  handleClose: () => void;
  projectsAndUnits: ProjectAndUnitType;
  homeLoanDetails: HomeLoanDataTypes;
  paymentDetails: PaymentDataTypes;
  legalDetails: legalDataTypes;
  applicants: ApplicantDetails[];
  onPressSubmit: () => void;
  isButtonLoading?: boolean;
  isTermsAndConditionAgreed?: boolean;
  isDeclarationAgreed?: boolean;
  bookingNotes?: string;
  bookingSignatures?: [];
  disableSubmitButton?: boolean;
  isSubmitButtonVisible?: boolean;
}
const BookingPreview = ({
  handleClose,
  projectsAndUnits,
  homeLoanDetails,
  paymentDetails,
  legalDetails,
  applicants,
  onPressSubmit,
  isButtonLoading = false,
  isTermsAndConditionAgreed = false,
  isDeclarationAgreed = false,
  bookingNotes = "",
  bookingSignatures = [],
  disableSubmitButton = true,
  isSubmitButtonVisible = false,
}: BookingPreviewDetails) => {
  const { t } = useTranslation();

  const [selectedCard, setSelectedCard] = useState(PROJECT_AND_UNIT);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const previews = [
    {
      id: PROJECT_AND_UNIT,
      label: "Project & Unit",
    },

    {
      id: APPLICANT_DETAILS,
      label: "Applicant Details",
    },
    {
      id: PAYMENT_DETAILS,
      label: "Payment Details",
    },
    {
      id: HOME_LOAN,
      label: "Home Loan",
    },
    {
      id: "LEGAL",
      label: "Legal",
    },
  ];

  useEffect(() => {
    setIsSubmitButtonDisabled(disableSubmitButton);
  }, [disableSubmitButton]);

  const toggleConfirmationModal = useCallback(
    () => setShowConfirmationModal(!showConfirmationModal),
    [showConfirmationModal]
  );

  const handleExpandClick = useCallback((id: string) => {
    setSelectedCard(id);
  }, []);

  const onClickCancel = useCallback(() => setShowConfirmationModal(false), []);

  const onClickConfirmAndSave = useCallback(() => {
    setIsSubmitButtonDisabled(true);
    toggleConfirmationModal();
    onPressSubmit();
  }, [onPressSubmit, toggleConfirmationModal]);

  const renderBookingContent = () =>
    selectedCard === PROJECT_AND_UNIT ? (
      <ProjectAndUnit details={projectsAndUnits} />
    ) : selectedCard === APPLICANT_DETAILS ? (
      <ApplicantsDetails details={applicants} />
    ) : selectedCard === PAYMENT_DETAILS ? (
      <PaymentDetails details={paymentDetails} />
    ) : selectedCard === HOME_LOAN ? (
      <HomeLoanDetails details={homeLoanDetails} />
    ) : (
      <LegalDetails details={legalDetails} />
    );

  const renderTermsAndConditions = () => (
    <>
      <CustomizedText fontSize={28} sx={{ mb: 2 }}>
        {t("create_booking.legal.terms_of_allotment")}
      </CustomizedText>
      <TermsAndConditions
        termsAndConditions={legalDetails.termsAndConditions}
        isTermsAndConditionsAgreed={isTermsAndConditionAgreed}
        isAgreedToDeclaration={isDeclarationAgreed}
        isEditable={false}
      />
    </>
  );

  const renderSubmitButton = () => (
    <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
      <MaterialButton
        id={"submit"}
        title={t("booking_preview.confirm_and_submit")}
        disabled={isSubmitButtonDisabled}
        onClick={toggleConfirmationModal}
        isLoading={isButtonLoading}
      />
    </Box>
  );

  const renderBookingNotes = () => (
    <>
      <CustomizedText fontSize={28} sx={{ mb: 2 }}>
        {t("booking_preview.notes")}
      </CustomizedText>
      <BookingNotes notes={bookingNotes} signatures={bookingSignatures} />
    </>
  );

  const renderLoader = () => <CircularProgress size={"1.5rem"} />;

  return (
    <PreviewContainer maxWidth={"xl"} sx={{ m: 3 }}>
      <RowStyledBox>
        <CustomizedText fontSize={36}>
          {t("booking_preview.preview_submission_title")}
        </CustomizedText>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ fontSize: 14 }}
        >
          <CloseIcon />
        </IconButton>
      </RowStyledBox>
      <CustomizedText fontSize={20}>
        {t("booking_preview.preview_submission_description")}
      </CustomizedText>
      <CustomizedDivider width={"100%"} />
      {previews?.map((menu, index) => (
        <ExpandableItemCard
          key={menu?.id}
          cardId={menu?.id}
          title={menu?.label}
          cardContent={renderBookingContent()}
          handleExpandClick={handleExpandClick}
          selectedCard={selectedCard}
          isDeleteRequired={false}
        />
      ))}
      {renderBookingNotes()}
      {legalDetails?.termsAndConditions && renderTermsAndConditions()}
      {isSubmitButtonVisible && renderSubmitButton()}
      <CustomizedDialogs
        handleOpenAndClose={onClickCancel}
        open={showConfirmationModal}
        subTitle={t("booking_preview.confirmation_dialog_title")}
        description={t("booking_preview.confirmation_dialog_description")}
        primaryButtonTitle={t("app_common.cancel")}
        secondaryButtonTitle={t("booking_preview.confirm_and_submit")}
        onClickSecondaryButton={onClickConfirmAndSave}
        onClickPrimaryButton={onClickCancel}
      />
      <MaterialModal
        open={isButtonLoading}
        sx={materialModalProps}
        children={renderLoader()}
      />
    </PreviewContainer>
  );
};

export default BookingPreview;

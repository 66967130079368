import {
  configureStore,
  combineReducers,
  AnyAction,
  Reducer,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import localforage from "localforage";
import { apiSlice } from "../features/api/api-slice";
import userReducer from "../features/user/user-slice";
import applicantReducer from "../features/booking/applicant-slice";
import { BookingApiSlice } from "../features/api/booking-api-slice";
import { masterApiSlice } from "../features/api/master-api-slice";
import { OpportunityApiSlice } from "../features/api/opportunity-api-slice";
import { projectApiSlice } from "../features/api/project-api-slice";
import { ProposalApiSlice } from "../features/api/proposals-api-slice";

const persistConfig = {
  key: "root",
  storage: localforage,
};

const appReducer = combineReducers({
  user: userReducer,
  applicant: applicantReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [BookingApiSlice.reducerPath]: BookingApiSlice.reducer,
  [OpportunityApiSlice.reducerPath]: OpportunityApiSlice.reducer,
  [masterApiSlice.reducerPath]: masterApiSlice.reducer,
  [projectApiSlice.reducerPath]: projectApiSlice.reducer,
  [ProposalApiSlice.reducerPath]: ProposalApiSlice.reducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "user/logout") {
    storage.removeItem("persist:root");
    state = {} as RootState;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false }).concat([
      apiSlice.middleware,
      BookingApiSlice.middleware,
      masterApiSlice.middleware,
      OpportunityApiSlice.middleware,
      projectApiSlice.middleware,
      ProposalApiSlice.middleware,
    ]);
  },
});
export let persistor = persistStore(store);

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

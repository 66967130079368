import OtpInput from "react18-input-otp";
import Colors from "../../utils/theme/color";
import "./otp-input.css";

interface OTPInputType {
  otp: string;
  handleOtpChange(otp: string): void;
  hasErrored: boolean;
  errorMessage: string;
}
interface OTPInputContainerStyleType {
  border: string;
  borderRadius: string;
}
interface OTPInputStyleType {
  borderWidth: number;
  padding: number;
  backgroundColor: string;
  width: string;
  border: string;
}

const OTPInputContainerStyle: OTPInputContainerStyleType = {
  border: `1px solid ${Colors.CadetGrey}`,
  borderRadius: "0.3rem",
};

const OTPInputStyle: OTPInputStyleType = {
  borderWidth: 0,
  padding: 12,
  backgroundColor: Colors.lightCadetGrey,
  width: "100%",
  border: `0px solid ${Colors.CadetGrey}`,
};

const OTPInputErrorStyle: OTPInputStyleType = {
  padding: 12,
  border: `0px solid red`,
  width: "100%",
  backgroundColor: Colors.lightCadetGrey,
  borderWidth: 0,
};

const OTPInput = ({
  otp,
  handleOtpChange,
  hasErrored,
  errorMessage,
}: OTPInputType) => {
  return (
    <>
      <OtpInput
        value={otp}
        onChange={handleOtpChange}
        numInputs={6}
        containerStyle={{
          ...OTPInputContainerStyle,
          ...(hasErrored && {
            border: `1px solid red`,
            borderRadius: "0.3rem",
          }),
        }}
        inputStyle={{
          ...(!hasErrored && OTPInputStyle),
          ...(hasErrored && OTPInputErrorStyle),
        }}
        hasErrored={hasErrored}
        isInputNum={true}
      />
      {errorMessage ? <p className="error-message">{errorMessage}</p> : null}
    </>
  );
};

export default OTPInput;

import "./app.css";
import "./assets/fonts/Lato-Bold.ttf";
import "./assets/fonts/Lora-Bold.ttf";
import "./assets/fonts/Lora-Medium.ttf";
import "./assets/fonts/Lora-SemiBold.ttf";
import "./assets/fonts/Lato-Regular.ttf";

import AppRouter from "./navigation/router";

const App = () => {
  return <AppRouter />;
};

export default App;

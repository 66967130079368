import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HomeLoanDataTypes } from "../../utils/booking-utils";
import LabelAndValue from "../label-and-value";
import { ColumnStyledBox, WrappedBox } from "./styled-components";
import { formatPrice } from "../../utils/functions";
interface HomeLoanDetailsProps {
  details: HomeLoanDataTypes;
}
const HomeLoanDetails = ({ details }: HomeLoanDetailsProps) => {
  const { t } = useTranslation();

  const getHomeLoanDetails = () => {
    return [
      {
        id: t("booking_preview.from_bank"),
        label: t("booking_preview.from_bank"),
        value: details?.fromBank,
      },
      {
        id: t("booking_preview.services_by"),
        label: t("booking_preview.services_by"),
        value: details?.serviceBy,
      },
      {
        id: t("booking_preview.annual_income"),
        label: t("booking_preview.annual_income"),
        value: formatPrice(parseInt(details?.annualIncome)),
      },
      {
        id: t("booking_preview.loan_amount"),
        label: t("booking_preview.loan_amount"),
        value: formatPrice(parseInt(details?.loanAmountInterested)),
      },
      {
        id: t("booking_preview.loan_sanctioned"),
        label: t("booking_preview.loan_sanctioned"),
        value: details?.isLoanSanctioned
          ? t("app_common.yes")
          : t("app_common.no"),
      },
      {
        id: t("booking_preview.sanctioned_amount"),
        label: t("booking_preview.sanctioned_amount"),
        value: formatPrice(parseInt(details?.sanctionedAmount)),
      },
    ];
  };

  const renderLabelAndValue = ({
    label,
    value,
  }: {
    label: string;
    value?: string | boolean;
  }) => <LabelAndValue key={label} label={label} value={value} />;

  return (
    <ColumnStyledBox>
      <WrappedBox>
        {getHomeLoanDetails()?.map((item) => (
          <Box sx={{ width: "30%" }}>
            {renderLabelAndValue({
              label: item?.label,
              value: item?.value,
            })}
          </Box>
        ))}
      </WrappedBox>
    </ColumnStyledBox>
  );
};

export default HomeLoanDetails;

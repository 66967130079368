import { Box, styled } from "@mui/material";
import Colors from "../../utils/theme/color";

export const CloseButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
}));

export const MessageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.white,
  display: "flex",
  width: "25%",
  flexDirection: "column",
  borderRadius: 5,
  padding: 30,
  paddingTop: 10,
  paddingRight: 10,

  [theme.breakpoints.down("lg")]: {
    width: "63%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  justifyContent: "center",
  display: "flex",
  paddingTop: 10,
}));

import { Box, Modal } from "@mui/material";

interface ButtonPropsType {
  open: boolean;
  sx: object;
  onClose?(): void;
  children: any;
}

const MaterialModal = ({
  open,
  onClose = () => {},
  sx,
  children,
}: ButtonPropsType) => {
  return (
    <Modal
      data-testid="material-modal"
      open={open}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Box sx={sx}>{children}</Box>
    </Modal>
  );
};
export default MaterialModal;

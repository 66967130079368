import { useTranslation } from "react-i18next";
import { legalDataTypes } from "../../utils/booking-utils";
import LabelAndValue from "../label-and-value";
import {
  ColumnStyledBox,
  LegalNameEmailAndAddressContainer,
  RowStyledBox,
} from "./styled-components";
interface LegalDetailsProps {
  details: legalDataTypes;
}
const LegalDetails = ({ details }: LegalDetailsProps) => {
  const { t } = useTranslation();

  const renderLabelAndValue = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => <LabelAndValue key={label} label={label} value={value} />;

  const renderHolderNameAndEmail = () => (
    <>
      {renderLabelAndValue({
        label: t("booking_preview.power_of_attorney_holder_name"),
        value: details?.powerOfAttorneyHolderName,
      })}
      {renderLabelAndValue({
        label: t("booking_preview.power_of_attorney_holder_email"),
        value: details?.powerOfAttorneyHolderEmailId,
      })}
    </>
  );

  const renderHolderAddress = () =>
    renderLabelAndValue({
      label: t("booking_preview.power_of_attorney_holder_address"),
      value: details?.powerOfAttorneyHolderAddress,
    });

  const renderHolderPhone = () =>
    renderLabelAndValue({
      label: t("booking_preview.power_of_attorney_holder_phone"),
      value: details?.powerOfAttorneyHolderPhone,
    });

  return (
    <ColumnStyledBox>
      <RowStyledBox
        sx={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <LegalNameEmailAndAddressContainer>
          {renderHolderNameAndEmail()}
        </LegalNameEmailAndAddressContainer>
        {renderHolderAddress()}
      </RowStyledBox>
      {renderHolderPhone()}
    </ColumnStyledBox>
  );
};

export default LegalDetails;

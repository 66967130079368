import * as React from "react";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledMenuItem } from "./styled-component";
import Colors from "../../utils/theme/color";

interface ThreeDotMenuTypes {
  menuItem?: { id?: string; label?: string }[];
  onClickSelectedMenu(id?: string): void;
  disabled?: boolean;
}
const ThreeDotMenu = ({
  menuItem = [],
  onClickSelectedMenu,
  disabled,
}: ThreeDotMenuTypes) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenu = (id?: string) => {
    handleClose();
    onClickSelectedMenu(id);
  };

  return (
    <div>
      <MoreVertIcon
        {...(!disabled && {
          onClick: handleClick,
        })}
        htmlColor={disabled ? Colors.lightGreyColor : Colors.greyColor}
        fontSize={"small"}
        sx={{ ...(!disabled && { cursor: "pointer" }) }}
      />
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuItem?.map((item, index) => (
          <StyledMenuItem
            disabled={disabled}
            onClick={() => onClickMenu(item?.id)}
            key={item?.id}
          >
            {item?.label}
          </StyledMenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default ThreeDotMenu;

import { API_CONSTANTS, VALIDATION_REGEXPS } from "../../constants";
import {
  BookingProposalLostStatuses,
  BookingProposalUnderReviewStatuses,
  DocStatus,
  Status,
} from "../../constants/statuses";
import Colors from "../theme/color";

export const isPhoneNumberValid = (phoneNumber?: string) => {
  return VALIDATION_REGEXPS.phoneNumberRegexp.test(phoneNumber ?? "");
};

export const isEmailValid = (email?: string) =>
  VALIDATION_REGEXPS.emailIdRegex.test(email ?? "");

export const getTextColorBasedOnProjectStatus = (status: Status) => {
  let color = "";
  switch (status) {
    case Status.LOST:
      color = Colors.flushMahogany;
      break;
    case Status.DRAFT:
      color = Colors.carrotOrange;
      break;
    case Status.INITIATED:
      color = Colors.primaryColor;
      break;

    case Status.BOOKING_CANCELLED:
      color = Colors.flushMahogany;
      break;
    case Status.ACTIVE:
      color = Colors.primaryColor;
      break;
    case Status.REOPEN:
      color = Colors.carrotOrange;
      break;
    case Status.CRM_CONFIRMATION:
      color = Colors.darkMagenta;
      break;
    case Status.UNIT_SOLD:
      color = Colors.barneyPurple;
      break;
    default:
      color = Colors.treeGreen;
  }
  return color;
};
export const isPermissionDenied = (error?: any) => {
  return error?.data?.exc_type === API_CONSTANTS.permissionError;
};

export const getListByStatus = (status: string[]) =>
  status?.length ? `&status=${status}` : "";

export const getListByWorkflowState = (status: string[]) =>
  status?.length ? `&workflow_state=${status}` : "";

export const getListByNameOrPhoneNumber = (searchText: string) =>
  Boolean(searchText) ? `&search=${searchText}` : "";

export const getFileType = (fileName: string) => {
  const splitUrl = fileName.split(".");
  return splitUrl[splitUrl?.length - 1];
};

export const getColorBasedOnWorkflowState = (status: Status) => {
  if (BookingProposalUnderReviewStatuses.includes(status)) {
    return Colors.primaryColor;
  } else if (BookingProposalLostStatuses.includes(status)) {
    return Colors.carrotOrange;
  } else if (status === Status.APPROVED_FOR_BOOKING) {
    return Colors.primaryColor;
  } else if (status === Status.PROPOSAL_CANCELLED) {
    return Colors.flushMahogany;
  } else if (status === Status.CONVERTED) {
    return Colors.barneyPurple;
  } else {
    return getTextColorBasedOnProjectStatus(status);
  }
};

export const formatPrice = (price: number) => {
  let formatValue = price?.toLocaleString("en-IN", {
    currency: "INR",
  });
  return formatValue?.split(".")[0];
};

export const getStatusBasedOnDocStatus = (docStatus: number) => {
  let status;
  switch (docStatus) {
    case 0:
      status = Status.DRAFT;
      break;
    case 1:
      status = Status.SUBMITTED;
      break;
    case 2:
      status = Status.CANCELLED;
      break;
    default:
      status = Status.DRAFT;
  }
  return status;
};
export const getDocStatusBasedOnStatus = (status: Status) => {
  let docStatus;
  switch (status) {
    case Status.DRAFT:
      docStatus = DocStatus.DRAFT;
      break;
    case Status.SUBMITTED:
      docStatus = DocStatus.SUBMITTED;
      break;
    case Status.CANCELLED:
      docStatus = DocStatus.CANCELLED;
      break;
    default:
      docStatus = DocStatus.DRAFT;
  }
  return docStatus;
};

export const getFileTypeFromUrl = (fileUrl: string) => {
  const splitUrl = fileUrl.split(".");
  return splitUrl[splitUrl?.length - 1];
};
export const getOriginalImageSize = (filePath: string) => {
  const image = new Image();
  image.src = filePath;

  return {
    width: image.width,
    height: image.height,
  };
};
export const getBase64FileType = (base64String: string) => {
  const prefix = "data:";
  const parts = base64String.split(";");
  if (parts.length < 2) {
    return null;
  }
  const mimeType = parts[0].substring(prefix.length);
  const fileType = mimeType.split("/")[1];
  return fileType;
};

export const convertToTitleCase = (sentence: string) => {
  const words = sentence.split(" ");
  const titleCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return titleCaseWords.join(" ");
};

const Colors = {
  primaryColor: "#0D4DA1",
  veryLightPrimaryColor: "#0D4DA111",
  actionColor: "#FECA0A",
  metallicYellow: "#feca0a29",
  goldenPoppy: "#f9c403",
  veryLightBlack: "#00000014",
  CadetGrey: "#99A1B080",
  lightCadetGrey: "#99a1b01a",
  white: "#ffff",
  titleColor: "#57585A",
  greyColor: "#99A1B0",
  lightGreyColor: "#99A1B015",
  veryLightGreyColor: "#99A1B010",
  transparentColor: "#99A1B001",
  linkColor: "#44A5AE",
  spanishGray: "#949494",
  cultured: "#F6F6F6",
  crayola: "#23BD73",
  veryLightBlueColor: "#0D4DA102",
  lightBlueColor: "#0D4DA115",
  backgroundColor: "#ECECEC",
  whiteSmoke: "#F3F3F3",
  redColor: "#fe0000",
  cyanColor: "#F8FBFF",
  black: "#000",
  cyanBlueColor: "#F2F5F9",
  lightTitleColor: "#57585A40",
  carrotOrange: "#F08A12",
  treeGreen: "#238E21",
  greenColor: "#12B76A",
  lightWhiteWarmColor: "#F1F1F125",
  flushMahogany: "#C53030",
  GargoyleGas: "#FFD848",
  darkMagenta: "#6E0691",
  barneyPurple: "#AF0180",
  lightDarkGrey: "#99A1B050",
  lightGreen: "#12B76A15",
  litleDarkTitleColor: "#57585A80",
};

export default Colors;

import React, { lazy, Suspense, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API_CONSTANTS } from "../../constants";
import { FileViewerContainer } from "../styled-componnets";
import { useTranslation } from "react-i18next";
import { getBase64FileType, getFileTypeFromUrl } from "../../utils/functions";

const ReactFileViewer = lazy(() => import("react-file-viewer"));

interface FileViewerComponentType {
  docFile?: string;
  base64?: string;
}

const FileViewerComponent: React.FC<FileViewerComponentType> = ({
  docFile = "",
  base64 = "",
}) => {
  const [file, setFile] = useState<string>(docFile);
  const { t } = useTranslation();

  useEffect(() => {
    setFile(docFile);
  }, [docFile]);

  const handleError = (error: any) => {
    toast(t("error_messages.server_error"), {
      type: "error",
    });
  };

  return (
    <FileViewerContainer>
      <Suspense fallback={<div>Loading...</div>}>
        <ReactFileViewer
          key={file}
          fileType={
            docFile
              ? getFileTypeFromUrl(docFile).toLocaleLowerCase()
              : getBase64FileType(base64)?.toLocaleLowerCase()
          }
          filePath={docFile ? `${API_CONSTANTS.baseURL}${docFile}` : base64}
          onError={handleError}
        />
      </Suspense>
    </FileViewerContainer>
  );
};
export default FileViewerComponent;

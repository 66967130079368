import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Colors from "../../utils/theme/color";

const drawerWidth = 300;
const drawerWidthForSizeSmall = 250;

export const drawerProps = {
  width: {
    sm: drawerWidthForSizeSmall,
    lg: drawerWidth,
    xl: drawerWidth,
  },
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: {
      sm: drawerWidthForSizeSmall,
      lg: drawerWidth,
      xl: drawerWidth,
    },
    boxSizing: "border-box",
    backgroundColor: Colors.cultured,
    borderColor: Colors.cultured,
  },
  display: { xs: "none", sm: "block", lg: "block" },
  zIndex: 100,
};

export const drawerBottomBarProps = {
  top: "auto",
  bottom: 0,
  zIndex: 200,
};

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Lora-Bold",
  fontSize: 16,
  color: Colors.greyColor,
  backgroundColor: Colors.cultured,
  paddingLeft: 28,
  paddingTop: 24,
}));

export const Label = styled(Typography)({
  fontFamily: "Lato-Regular",
  fontSize: 16,
  color: Colors.titleColor,
});

export const StyledFormControl = styled(FormControl)({
  background: `linear-gradient(0deg, ${Colors.whiteSmoke}, ${Colors.whiteSmoke}), ${Colors.white};`,
  width: "93%",
});

export const SubMenuFormControl = styled(FormControl)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginRight: 16,
  flexDirection: "row",
});

export const StyledFormControlLabel = styled(FormControlLabel)({
  color: Colors.titleColor,
  fontFamily: "Lato-Regular",
});

export const StyledRadio = styled(Radio)({
  color: "#ECECEC",
  "&.Mui-checked": {
    color: Colors.primaryColor,
  },
});

export const StyledFormLabel = styled(FormLabel)({
  fontFamily: "Lato-Regular",
  justifyContent: "space-between",
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  fontSize: 16,
  "&:hover": {
    cursor: "pointer",
  },
  paddingLeft: 25,
  paddingRight: 25,
});

export const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  color: Colors.veryLightPrimaryColor,
  p: 0,
  m: 1,
  "&.Mui-checked": {
    color: Colors.veryLightPrimaryColor,
  },
}));

import { Box, styled } from "@mui/material";
import Colors from "../../utils/theme/color";

export const BookingCommentsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.white,
  display: "flex",
  width: "50%",
  flexDirection: "column",
  overflow: "scroll",
  borderRadius: "16px 16px 0px 0px",
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  background: Colors.white,
  boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.12)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 10,
  paddingRight: 10,
  border: `1px solid ${Colors.veryLightBlack}`,
}));

export const CommentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.white,
  display: "flex",
  borderRadius: 5,
  margin: "0rem 1.5rem 1rem 1.5rem",
  justifyContent: "space-between",
  padding: 10,
  alignItems: "center",
  borderLeft: `8px solid ${Colors.primaryColor}`,
  paddingTop: 15,
  paddingBottom: 15,
  paddingLeft: 15,
}));

export const CloseButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
  padding: 0,
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingBottom: 15,
  paddingTop: 15,
}));

export const ColumnStyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const RowStyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
}));

export const CenterStyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProjectAndUnitType } from "../../utils/booking-utils";
import LabelAndValue from "../label-and-value";
import { ColumnStyledBox, WrappedBox } from "./styled-components";
interface projectsAndUnitsTypes {
  details: ProjectAndUnitType;
}
const ProjectAndUnit = ({ details }: projectsAndUnitsTypes) => {
  const { t } = useTranslation();

  const getOtherDetails = () => {
    return [
      {
        id: t("project_and_unit_preference.project_label"),
        label: t("project_and_unit_preference.project_label"),
        value: details?.project,
      },
      {
        id: t("project_and_unit_preference.unit_type"),
        label: t("project_and_unit_preference.unit_type"),
        value: details?.unitType,
      },
      {
        id: t("project_and_unit_preference.floor"),
        label: t("project_and_unit_preference.floor"),
        value: details?.floor,
      },
      {
        id: t("project_and_unit_preference.block"),
        label: t("project_and_unit_preference.block"),
        value: details?.block,
      },
      {
        id: t("project_and_unit_preference.saleable_area"),
        label: t("project_and_unit_preference.saleable_area"),
        value: details?.saleableArea,
      },
      {
        id: t("project_and_unit_preference.carpet_area"),
        label: t("project_and_unit_preference.carpet_area"),
        value: details?.carpetArea,
      },
    ];
  };

  const renderLabelAndValue = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => <LabelAndValue key={label} label={label} value={value} />;

  const renderUnit = () => (
    <Box>
      {renderLabelAndValue({
        label: t("project_and_unit_preference.unit_label"),
        value: details?.unit,
      })}
    </Box>
  );

  const renderOtherDetails = () => (
    <WrappedBox>
      {getOtherDetails()?.map((item) => (
        <Box sx={{ width: "30%" }}>
          {renderLabelAndValue({
            label: item?.label,
            value: item?.value,
          })}
        </Box>
      ))}
    </WrappedBox>
  );

  return (
    <ColumnStyledBox>
      {renderUnit()}
      {renderOtherDetails()}
    </ColumnStyledBox>
  );
};

export default ProjectAndUnit;

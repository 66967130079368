 import { FC } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import TextInput from "../../../components/text-input/text-input";
import { EventProps } from "../../../utils/interface";
import { ActionContainer, CloseButtonContainer } from "../styled-components";
import MaterialButton from "../../../components/button";
import Colors from "../../../utils/theme/color";
import { CustomizedText } from "../../../components/styled-componnets";

interface DiscountInputProps {
  handleClose: (discount?: string) => void;
  isButtonLoading: boolean;
  onClickSave: () => void;
  discountInvalidMessage?: string;
  onChangeAmount: (e: EventProps) => void;
  discountOrRoundOffAdded: string;
  onBlur?: () => void;
  outerLabel: string;
  maxLength: number;
}

const DiscountOrRoundOffInput: FC<DiscountInputProps> = ({
  handleClose,
  isButtonLoading,
  onClickSave,
  discountInvalidMessage = "",
  onChangeAmount,
  discountOrRoundOffAdded,
  onBlur = () => {},
  outerLabel,
  maxLength,
}) => {
  const { t } = useTranslation();

  const renderDiscountInput = () => (
    <TextInput
      id={"discount"}
      markRequiredField={false}
      outerLabel={outerLabel}
      isRupeeIconRequired
      styles={{
        outerLabel: { marginTop: 3 },
      }}
      onChange={onChangeAmount}
      value={discountOrRoundOffAdded}
      maxLength={maxLength}
      onBlur={onBlur}
    />
  );

  const renderButton = () => (
    <MaterialButton
      title={t("app_common.okay")}
      id={"save-button"}
      onClick={onClickSave}
      disabled={isButtonLoading || !Boolean(discountOrRoundOffAdded)}
      isLoading={isButtonLoading}
      sx={{ mt: 2 }}
    />
  );

  const renderCloseButton = () => (
    <CloseButtonContainer
      marginRight={0.5}
      paddingBottom={0}
      onClick={() => handleClose(discountOrRoundOffAdded)}
      sx={{ alignSelf: "end" }}
    >
      <CloseIcon fontSize="small" htmlColor={Colors.titleColor} />
    </CloseButtonContainer>
  );

  const renderInvalidMessage = () => (
    <CustomizedText
      fontSize={16}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
      fontColor={Colors.redColor}
    >
      <InfoRoundedIcon sx={{ fontSize: 16, mr: 0.5 }} fontSize="small" />
      {discountInvalidMessage}
    </CustomizedText>
  );
  return (
    <ActionContainer sx={{ p: 3, width: "25%" }}>
      {renderCloseButton()}
      {renderDiscountInput()}
      {Boolean(discountInvalidMessage) && renderInvalidMessage()}
      {renderButton()}
    </ActionContainer>
  );
};

export default DiscountOrRoundOffInput;

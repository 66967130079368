import { Container } from "@mui/material";
import { styled } from "@mui/system";

export const StyledContainer = styled(Container)({
  m: 0,
  width: "100%",
});

export const AvatarAndWelcomeTextContainer = styled(Container)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const ButtonAndSearchContainer = styled(Container)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

import { Box, styled } from "@mui/material";
import Colors from "../../utils/theme/color";

export const CustomizedModalContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  borderRadius: 2,
  backgroundColor: Colors.white,
}));

export const AssigneeModalContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: Colors.white,
  alignSelf: "center",
  paddingBottom: 2,
}));

export const InfoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  backgroundColor: Colors.veryLightPrimaryColor,
  padding: 20,
  borderRadius: 5,
  marginTop: 20,
}));

import { Container } from "@mui/material";
import { SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { SingleValue, ActionMeta } from "react-select";
import {
  useGetProjectsQuery,
  useGetUnitTypeQuery,
  useGetUnitListQuery,
} from "../../../features/api/project-api-slice";
import Dropdown from "../dropdown";
import {
  useRedirectToLogin,
  useGetUserDetailsHooks,
  useScreenSizeApplier,
} from "../../hooks";
import { CustomizedDivider, CustomizedText } from "../styled-componnets";
import TextInput from "../text-input/text-input";
import { API_CONSTANTS } from "../../../app/constants";
import { useGetUnitDetailsByIdQuery } from "../../../features/api/project-api-slice";
export interface ProjectDetailsType {
  project?: string;
  unit?: string;
  unitType?: string;
}

interface UnitDetailsType {
  name?: string;
  bhk?: string;
  unit?: string;
  floor?: string;
  block?: string;
  total_cost?: string;
  super_builtup_area?: string;
  carpet_area?: string;
}
interface ProjectAndUnitPreferenceTypes {
  projectDetails: {
    project?: string;
    unit?: string;
    unitType?: string;
  };
  isUnitDetailsRequired?: boolean;
  onUpdate(
    details: SetStateAction<ProjectDetailsType>,
    projectDetails?: {}
  ): void;
  toggleDropDown?(value: boolean): void;
  isProjectsAndUnitsEditable?: boolean;
  isUnitMandatory?: boolean;
}

const EMPTY_PROJECT_DETAILS = {
  project: "",
  unit: "",
  unitType: "",
};
const ERROR = "error";

const rowContainerStyle = {
  m: 0,
  display: "flex",
  justifyContent: "space-between",
};

const ProjectAndUnitPreference = ({
  isUnitDetailsRequired = false,
  projectDetails = {},
  onUpdate,
  toggleDropDown = () => {},
  isProjectsAndUnitsEditable = true,
  isUnitMandatory = false,
}: ProjectAndUnitPreferenceTypes) => {
  const { t } = useTranslation();
  const { getUserToken } = useGetUserDetailsHooks();

  const { mergeStyles } = useScreenSizeApplier();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();

  const {
    data: getProjectListResponse,
    isSuccess: isGetProjectListSuccess,
    isError: isGetProjectListFailed,
    refetch: refetchProjectList,
  } = useGetProjectsQuery({});

  const [projectAndUnitPreference, setProjectAndUnitPreference] =
    useState(projectDetails);
  const projectAndUnitPreferenceRef = useRef(projectAndUnitPreference);

  const [projectList, setProjectList] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [unitList, setUnitList] = useState([]);

  const [unitDetails, setUnitDetails] = useState<UnitDetailsType>({});

  useEffect(() => {
    if (projectDetails?.project || projectDetails?.unit) {
      setProjectAndUnitPreference(projectDetails);
      projectAndUnitPreferenceRef.current = projectDetails;
    }
  }, [projectDetails]);

  const {
    data: getProjectUnitTypesResponse,
    isSuccess: isGetProjectUnitTypesSuccess,
    isError: isGetProjectUnitTypesFailed,
    error: getProjectUnitTypesError,
  } = useGetUnitTypeQuery(
    {
      project: projectAndUnitPreference?.project,
      userToken: getUserToken(),
    },
    { skip: !Boolean(projectAndUnitPreference?.project) }
  );

  const {
    data: getProjectUnitListResponse,
    isSuccess: isGetProjectUnitListSuccess,
    isError: isGetProjectUnitListFailed,
    error: getProjectUnitListError,
    refetch: refetchUnitList,
  } = useGetUnitListQuery(
    {
      project: projectAndUnitPreference?.project,
      userToken: getUserToken(),
    },
    { skip: !Boolean(projectAndUnitPreference?.project) }
  );

  const {
    data: getUnitDetailsByIdResponse,
    isSuccess: isGetUnitDetailsByIdSuccess,
    isError: isGetUnitDetailsByIdFailed,
    error: getUnitDetailsByIdError,
    refetch: refetchUnitDetails,
  } = useGetUnitDetailsByIdQuery(
    {
      unit: projectAndUnitPreference?.unit,
      userToken: getUserToken(),
    },
    { skip: !Boolean(projectAndUnitPreference?.unit) }
  );

  const getProjectDetails = useMemo(
    () =>
      projectList?.filter(
        (project: { name: string }) =>
          project?.name === projectAndUnitPreference?.project
      )[0],
    [projectAndUnitPreference?.project, projectList]
  );

  useEffect(() => {
    if (!Object.is(projectAndUnitPreference, EMPTY_PROJECT_DETAILS)) {
      projectAndUnitPreferenceRef.current = projectAndUnitPreference;
      onUpdate(projectAndUnitPreference, getProjectDetails);
    }
  }, [getProjectDetails, onUpdate, projectAndUnitPreference]);

  useEffect(() => {
    refetchProjectList();
    refetchUnitDetails();
    refetchUnitList();
  }, [refetchProjectList, refetchUnitDetails, refetchUnitList]);

  useEffect(() => {
    if (isGetProjectListSuccess) {
      if (getProjectListResponse?.message?.length) {
        setProjectList(getProjectListResponse?.message);
      } else if (!Array.isArray(getProjectListResponse?.message)) {
        toast(getProjectListResponse?.message, {
          type: ERROR,
        });
        setProjectList([]);
      }
    } else if (isGetProjectListFailed) {
      toast(t("error_messages.server_error"), {
        type: ERROR,
      });
      setProjectList([]);
    }
  }, [
    getProjectListResponse,
    isGetProjectListSuccess,
    isGetProjectListFailed,
    t,
  ]);

  useEffect(() => {
    if (isGetProjectUnitTypesSuccess) {
      if (
        Array.isArray(getProjectUnitTypesResponse?.data) &&
        getProjectUnitTypesResponse?.status_code ===
          API_CONSTANTS.successStatusCode
      ) {
        setUnitTypes(getProjectUnitTypesResponse?.data);
      } else if (getProjectUnitTypesResponse?.message) {
        toast(getProjectUnitTypesResponse?.message, {
          type: ERROR,
        });
        setUnitTypes([]);
      }
    } else if (isGetProjectUnitTypesFailed) {
      redirectToLoginBasedOnResponse(
        getProjectUnitTypesError,
        getProjectUnitTypesResponse
      );
      setUnitTypes([]);
    }
  }, [
    getProjectUnitTypesResponse,
    isGetProjectUnitTypesSuccess,
    isGetProjectUnitTypesFailed,
    t,
    getProjectUnitTypesError,
    redirectToLoginBasedOnResponse,
  ]);

  useEffect(() => {
    if (isGetProjectUnitListSuccess) {
      if (
        Array.isArray(getProjectUnitListResponse?.data) &&
        getProjectUnitListResponse?.status_code ===
          API_CONSTANTS.successStatusCode
      ) {
        setUnitList(getProjectUnitListResponse?.data);
      } else if (getProjectUnitListResponse?.message) {
        toast(getProjectUnitListResponse?.message, {
          type: ERROR,
        });
        setUnitList([]);
      }
    } else if (isGetProjectUnitListFailed) {
      redirectToLoginBasedOnResponse(
        getProjectUnitListError,
        getProjectUnitListResponse
      );
      setUnitList([]);
    }
  }, [
    getProjectUnitListResponse,
    isGetProjectUnitListSuccess,
    isGetProjectUnitListFailed,
    t,
    getProjectUnitListError,
    redirectToLoginBasedOnResponse,
  ]);

  useEffect(() => {
    if (isGetUnitDetailsByIdSuccess) {
      if (Array.isArray(getUnitDetailsByIdResponse?.data)) {
        setUnitDetails(getUnitDetailsByIdResponse?.data[0]);
      } else if (getUnitDetailsByIdResponse?.message) {
        toast(getUnitDetailsByIdResponse?.message, {
          type: ERROR,
        });
        setUnitDetails({});
      }
    } else if (isGetUnitDetailsByIdFailed) {
      redirectToLoginBasedOnResponse(
        getUnitDetailsByIdError,
        getUnitDetailsByIdResponse
      );
      setUnitDetails({});
    }
  }, [
    getUnitDetailsByIdResponse,
    isGetUnitDetailsByIdSuccess,
    isGetUnitDetailsByIdFailed,
    t,
    getUnitDetailsByIdError,
    redirectToLoginBasedOnResponse,
  ]);

  const onChangeProject = (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>
  ) => {
    setProjectAndUnitPreference({
      ...projectAndUnitPreferenceRef.current,
      project: newValue?.value,
      unit: "",
      unitType: "",
    });
    setUnitTypes([]);
    setUnitList([]);
  };

  const onChangeUnit = (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>
  ) => {
    setProjectAndUnitPreference({
      ...projectAndUnitPreferenceRef.current,
      unit: newValue?.value,
    });
  };

  const returnLabelAndValue = (list: { name: string }[]) =>
    list?.map((project: { name: string }) => {
      return { value: project.name, label: project.name };
    });

  const renderProjectsAndUnits = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: rowContainerStyle,
        tabletStyles: { flexDirection: "column" },
      })}
    >
      <Dropdown
        outerLabel={t(
          "project_and_unit_preference.project_customer_interested"
        )}
        markRequiredField={true}
        dropdownItems={returnLabelAndValue(projectList)}
        inputLabel={t("project_and_unit_preference.project_placeholder")}
        handleChange={onChangeProject}
        value={projectAndUnitPreferenceRef.current.project}
        setIsDropdownOpen={toggleDropDown}
        isDisabled={!isProjectsAndUnitsEditable}
        styles={{
          textInputContainer: mergeStyles({
            styles: { width: "47%" },
            tabletStyles: { width: "100%" },
          }),
        }}
      />
      <Dropdown
        outerLabel={t("project_and_unit_preference.unit")}
        markRequiredField={isUnitMandatory}
        dropdownItems={returnLabelAndValue(unitList)}
        inputLabel={t("project_and_unit_preference.unit_placeholder")}
        handleChange={onChangeUnit}
        value={projectAndUnitPreferenceRef.current.unit}
        setIsDropdownOpen={toggleDropDown}
        isDisabled={!isProjectsAndUnitsEditable}
        styles={{
          textInputContainer: mergeStyles({
            styles: { width: "47%" },
            tabletStyles: { width: "100%" },
          }),
        }}
      />
    </Container>
  );

  const renderUnitFloorAndBlockDetails = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: rowContainerStyle,
        tabletStyles: { flexDirection: "column" },
      })}
    >
      <TextInput
        id="floor-input"
        value={unitDetails?.floor}
        label="0"
        outerLabel={t("project_and_unit_preference.floor")}
        markRequiredField={false}
        disabled={true}
        styles={{
          textInputContainer: mergeStyles({
            styles: { width: "47%" },
            tabletStyles: { width: "100%" },
          }),
        }}
      />
      <TextInput
        id="block-input"
        value={unitDetails?.block}
        label={t("project_and_unit_preference.block")}
        outerLabel={t("project_and_unit_preference.block")}
        markRequiredField={false}
        disabled={true}
        styles={{
          textInputContainer: mergeStyles({
            styles: { width: "47%" },
            tabletStyles: { width: "100%" },
          }),
        }}
      />
    </Container>
  );

  const renderUnitAreaSquareFeetDetails = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: rowContainerStyle,
        tabletStyles: { flexDirection: "column" },
      })}
    >
      <TextInput
        id="saleable-area-input"
        value={unitDetails?.super_builtup_area}
        label="0"
        outerLabel={t("project_and_unit_preference.saleable_area")}
        markRequiredField={false}
        disabled={true}
        styles={{
          textInputContainer: mergeStyles({
            styles: { width: "47%" },
            tabletStyles: { width: "100%" },
          }),
        }}
      />
      <TextInput
        id="carpet-area-input"
        value={unitDetails?.carpet_area}
        label="0"
        outerLabel={t("project_and_unit_preference.carpet_area")}
        markRequiredField={false}
        disabled={true}
        styles={{
          textInputContainer: mergeStyles({
            styles: { width: "47%" },
            tabletStyles: { width: "100%" },
          }),
        }}
      />
    </Container>
  );

  const renderUnitDetails = () => (
    <>
      <CustomizedDivider width="100%" sx={{ mt: 5 }} />
      <CustomizedText fontSize={24} sx={{ mt: 2 }}>
        {t("project_and_unit_preference.unit_details")}
      </CustomizedText>
      {renderUnitFloorAndBlockDetails()}
      {renderUnitAreaSquareFeetDetails()}
    </>
  );

  return (
    <div>
      {isUnitDetailsRequired ? null : (
        <CustomizedText fontSize={24}>
          {t("project_and_unit_preference.title")}
        </CustomizedText>
      )}
      {renderProjectsAndUnits()}
      {isUnitDetailsRequired ? renderUnitDetails() : null}
      <ToastContainer />
    </div>
  );
};

export default ProjectAndUnitPreference;

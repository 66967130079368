import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { ToastContainer, toast } from "react-toastify";
import {
  useScreenSizeApplier,
  useRedirectToLogin,
  useGetUserDetailsHooks,
} from "../../hooks";
import { CustomizedText } from "../styled-componnets";
import Colors from "../../utils/theme/color";
import { useGetBookingDetailsByIdQuery } from "../../../features/api/booking-api-slice";
import { UNICODE } from "../../constants";
import {
  SaleableAreaAndDiscountContainer,
  PriceAttributeBox,
  StandardAndListingPriceBox,
  PriceContainer,
  TotalCostContainer,
  SaleableAreaAndDiscountInnerContainer,
  RowStyledBox,
  ColumnStyledBox,
  StandardAndListPriceBox,
} from "./styled-component";
import { ListPriceDataType } from "./list-price-interface";
interface BookingDetailTypes {
  listPrice: ListPriceDataType;
  bookingId?: string;
}

const ERROR = "error";

const ListPrice = ({ bookingId = "", listPrice }: BookingDetailTypes) => {
  const { t } = useTranslation();
  const { getUserToken } = useGetUserDetailsHooks();
  const { mergeStyles } = useScreenSizeApplier();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();
  const { salesHead } = useGetUserDetailsHooks();

  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:768px)");
  const isBelowLarge = useMediaQuery(theme.breakpoints.down("lg"));

  const [bookingDetails, setBookingDetails] = useState(listPrice);

  const {
    data: getBookingDetailsByIdResponse,
    isSuccess: isGetBookingDetailsByIdSuccess,
    isError: isGetBookingDetailsByIdFailed,
    error: getBookingDetailsByIdError,
    refetch: refetchBookingDetails,
  } = useGetBookingDetailsByIdQuery(
    {
      bookingId: bookingId,
      userToken: getUserToken(),
    },
    { skip: !Boolean(bookingId) }
  );

  useEffect(() => {
    if (isGetBookingDetailsByIdSuccess) {
      if (getBookingDetailsByIdResponse?.data?.name === bookingId) {
        let bookingDetailsPriceList = getBookingDetailsByIdResponse?.data;
        setBookingDetails({
          saleableArea: bookingDetailsPriceList?.saleable_area,
          basicRate: bookingDetailsPriceList?.basic_rate,
          totalDiscount: bookingDetailsPriceList?.discount_offered,
          premiumLocationCharges:
            bookingDetailsPriceList?.premium_location_charges,
          bookingFloorPremium: bookingDetailsPriceList?.booking_floor_premium,
          additionalExpenses: bookingDetailsPriceList?.additional_expenses,
          coveredCarParking: bookingDetailsPriceList?.covered_car_parking,
          clubHouse: bookingDetailsPriceList?.club_house,
          gstAmount: bookingDetailsPriceList?.booking_gst_amount,
          gstPercentage: bookingDetailsPriceList?.booking_gst_percentage,
          totalConsiderationIncludingGst:
            bookingDetailsPriceList?.booking_total_consideration_including_gst,
          sinkingFund: bookingDetailsPriceList?.sinking_fund,
          maintenanceCharges: bookingDetailsPriceList?.maintenance_charges,
          legalCharges: bookingDetailsPriceList?.legal_charges,
          totalCostOfOwnership:
            bookingDetailsPriceList?.total_cost_of_ownership,
          ratePerSquareFeet: bookingDetailsPriceList?.rate_per_square_feet,
          bookingRatePerSquareFeet:
            bookingDetailsPriceList?.effective_cost_per_square_feet,
          bookingBasicAmount: bookingDetailsPriceList?.booking_basic_amount,
          bookingTotalConsideration:
            bookingDetailsPriceList?.booking_total_consideration,
          basicAmount: bookingDetailsPriceList?.basic_amount,
          totalAmount: bookingDetailsPriceList?.total_amount,
          roundOff: bookingDetailsPriceList?.round_off,
          discountBySalesExecutive:
            bookingDetailsPriceList?.discount_by_sales_executive,
          discountBySalesLead: bookingDetailsPriceList?.discount_by_sales_lead,
          discountBySalesHead: bookingDetailsPriceList?.discount_by_sales_head,
          discountByChannelPartner: bookingDetailsPriceList?.discount_by_channel_partner,
        });
      } else if (getBookingDetailsByIdResponse?.message) {
        toast(getBookingDetailsByIdResponse?.message, {
          type: ERROR,
        });
        setBookingDetails(listPrice);
      }
    } else if (isGetBookingDetailsByIdFailed) {
      toast(t("error_messages.get_unit_details_error_message"), {
        type: ERROR,
      });
      redirectToLoginBasedOnResponse(
        getBookingDetailsByIdError,
        getBookingDetailsByIdResponse
      );
      setBookingDetails(listPrice);
    }
    // eslint-disable-next-line
  }, [
    getBookingDetailsByIdResponse,
    isGetBookingDetailsByIdSuccess,
    isGetBookingDetailsByIdFailed,
    t,
    listPrice,
    getBookingDetailsByIdError,
    bookingId,
  ]);

  useEffect(() => {
    refetchBookingDetails();
  }, [refetchBookingDetails]);

  const getTotalDiscountedAmount = (
    totalAmount?: number,
    ownershipCost?: number
  ) => (totalAmount && ownershipCost ? totalAmount - ownershipCost : 0);

  const renderSaleableAreaAndDiscount = () => (
    <SaleableAreaAndDiscountContainer
      sx={mergeStyles({
        tabletStyles: { m: 0, flexDirection: "column" },
      })}
      disableGutters
    >
      <SaleableAreaAndDiscountInnerContainer>
        <CustomizedText fontSize={20}>
          {t("list_price.super_built_up_area")}
        </CustomizedText>
        <CustomizedText fontSize={20}>
          {t("list_price.square_feet_area", {
            squareFeetValue: bookingDetails?.saleableArea,
          })}
        </CustomizedText>
      </SaleableAreaAndDiscountInnerContainer>
    </SaleableAreaAndDiscountContainer>
  );

  const renderPriceAttributes = ({
    label = "",
    value = 0,
    textColor = Colors.titleColor,
    isFontBold = false,
    isLastIndex = false,
    infoIconText = "",
  }: {
    label?: string;
    value?: number | string;
    textColor?: string;
    isFontBold?: boolean;
    isLastIndex?: boolean;
    infoIconText?: string;
  }) => (
    <>
      <PriceAttributeBox borderBottom={isLastIndex ? 0 : 0.5}>
        <RowStyledBox>
          <CustomizedText
            fontWeight={isFontBold ? "700" : "200"}
            fontSize={20}
            color={Colors.titleColor}
            font={"Lato-Regular"}
          >
            {label}
          </CustomizedText>
          {infoIconText ? (
            <Tooltip title={infoIconText ?? ""} arrow placement="right-start">
              <InfoOutlined
                fontSize="inherit"
                htmlColor={Colors.greyColor}
                style={{ marginTop: 3, marginLeft: 3 }}
              />
            </Tooltip>
          ) : null}
        </RowStyledBox>
        {renderAmountInCurrencyFormat({
          price: value,
          fontColor: textColor,
          isFontBold: isFontBold,
        })}
      </PriceAttributeBox>
    </>
  );

  const renderAmountInCurrencyFormat = ({
    price = 0,
    fontColor = Colors.titleColor,
    isFontBold = false,
    isTextStrikeThrough = false,
    fontSize = 18,
  }: {
    price?: number | string;
    fontColor?: string;
    isFontBold?: boolean;
    isTextStrikeThrough?: boolean;
    fontSize?: number;
  }) => (
    <NumericFormat
      style={{
        ...{
          textAlign: "right",
          fontWeight: isFontBold ? "700" : "200",
          fontFamily: "Lato-Regular",
          fontSize: isTablet
            ? fontSize - 4
            : isBelowLarge
            ? 18
            : fontSize
            ? fontSize
            : 18,
          color: fontColor ?? Colors.titleColor,
        },
        ...(isTextStrikeThrough && {
          textDecoration: "line-through",
          marginTop: 8,
          marginRight: 3,
        }),
      }}
      value={price}
      allowLeadingZeros
      thousandSeparator=","
      prefix={`${UNICODE.rupeeSymbol}`}
      displayType="text"
      thousandsGroupStyle="lakh"
    />
  );

  const renderBasicCost = () => (
    <StandardAndListPriceBox>
      <CustomizedText fontSize={22}>
        {t("list_price.basic_cost")}
      </CustomizedText>
      <StandardAndListingPriceBox>
        {renderPriceAttributes({
          label: t("list_price.total_basic_rate"),
          value: bookingDetails?.ratePerSquareFeet,
        })}
        {bookingDetails?.totalDiscount > 0 &&
          renderPriceAttributes({
            label: t("list_price.discount"),
            value: bookingDetails?.totalDiscount,
            textColor: Colors.greenColor,
          })}
        {renderPriceAttributes({
          label: t("list_price.effective_rate"),
          value: bookingDetails?.bookingRatePerSquareFeet,
        })}
        {renderPriceAttributes({
          label: t("list_price.basic_apartment_cost"),
          value: bookingDetails?.bookingBasicAmount,
          isFontBold: true,
          isLastIndex: true,
        })}
      </StandardAndListingPriceBox>
    </StandardAndListPriceBox>
  );

  const renderAdditionalCost = () => (
    <StandardAndListPriceBox sx={{ marginTop: 3 }}>
      <CustomizedText fontSize={22}>
        {t("list_price.additional_cost")}
      </CustomizedText>
      <ColumnStyledBox>
        <StandardAndListingPriceBox>
          {renderPriceAttributes({
            label: t("list_price.additional_expense"),
            value: bookingDetails?.additionalExpenses,
            infoIconText: t("list_price.additional_expense_info_text"),
          })}
          {renderPriceAttributes({
            label: t("list_price.covered_car_parking"),
            value: bookingDetails?.coveredCarParking,
          })}
          {renderPriceAttributes({
            label: t("list_price.club_house"),
            value: bookingDetails?.clubHouse,
          })}
          {renderPriceAttributes({
            label: t("list_price.agreement_value"),
            value: bookingDetails?.bookingTotalConsideration,
            isFontBold: true,
            infoIconText: t("list_price.agreement_value_info_text"),
          })}
          {renderPriceAttributes({
            label: t("list_price.gst_percentage", {
              gstPercentage: bookingDetails?.gstPercentage,
            }),
            value: bookingDetails?.gstAmount,
          })}
          {renderPriceAttributes({
            label: t("list_price.gst_cost"),
            value: bookingDetails?.totalConsiderationIncludingGst,
            infoIconText: t("list_price.gst_cost_info_text"),
            isFontBold: true,
            isLastIndex: true,
          })}
        </StandardAndListingPriceBox>
      </ColumnStyledBox>
    </StandardAndListPriceBox>
  );

  const renderMiscellaneousCost = () => (
    <StandardAndListPriceBox sx={{ marginTop: "1.5rem" }}>
      <CustomizedText fontSize={22}>
        {t("list_price.miscellaneous_cost")}
      </CustomizedText>
      <ColumnStyledBox>
        <StandardAndListingPriceBox>
          {renderPriceAttributes({
            label: t("list_price.corpus_fund"),
            value: bookingDetails?.sinkingFund,
          })}
          {renderPriceAttributes({
            label: t("list_price.maintenance_charge"),
            value: bookingDetails?.maintenanceCharges,
          })}
          {renderPriceAttributes({
            label: t("list_price.legal_charge"),
            value: bookingDetails?.legalCharges,
            infoIconText: t("list_price.legal_charge_info_text"),
          })}
          {Boolean(bookingDetails?.roundOff) &&
            renderPriceAttributes({
              label: t("list_price.round_off"),
              value: bookingDetails?.roundOff,
            })}
        </StandardAndListingPriceBox>
      </ColumnStyledBox>
    </StandardAndListPriceBox>
  );

  const { isCurrentUserCP } = useGetUserDetailsHooks();

  const renderDiscountOffered = () => {
    if (isCurrentUserCP) {
      return (
        <StandardAndListPriceBox sx={{ marginTop: "1.5rem" }}>
          <CustomizedText fontSize={22}>
            {t("list_price.discount_offered")}
          </CustomizedText>
          <ColumnStyledBox>
            <StandardAndListingPriceBox>
              {bookingDetails?.discountByChannelPartner && (
                renderPriceAttributes({
                  label: t("list_price.discount_by_channel_partner"),
                  value: bookingDetails?.discountByChannelPartner,
                  infoIconText: t("list_price.legal_charge_info_text"),
                })
              )}
            </StandardAndListingPriceBox>
          </ColumnStyledBox>
        </StandardAndListPriceBox>
      );
    } else {
      return (
        <StandardAndListPriceBox sx={{ marginTop: "1.5rem" }}>
          <CustomizedText fontSize={22}>
            {t("list_price.discount_offered")}
          </CustomizedText>
          <ColumnStyledBox>
            <StandardAndListingPriceBox>
              {bookingDetails?.discountBySalesExecutive && (
                renderPriceAttributes({
                  label: t("list_price.discount_by_sales_executive"),
                  value: bookingDetails?.discountBySalesExecutive,
                })
              )}
              {bookingDetails?.discountBySalesLead && (
                renderPriceAttributes({
                  label: t("list_price.discount_by_sales_lead"),
                  value: bookingDetails?.discountBySalesLead,
                })
              )}
              {bookingDetails?.discountBySalesHead && (
                renderPriceAttributes({
                  label: t("list_price.discount_by_sales_head"),
                  value: bookingDetails?.discountBySalesHead,
                  infoIconText: t("list_price.legal_charge_info_text"),
                })
              )}
            </StandardAndListingPriceBox>
          </ColumnStyledBox>
        </StandardAndListPriceBox>
      );
    }
  };

  const isHeadOfSales = useMemo(() => Boolean(salesHead), [salesHead]);

  const shouldRenderDiscount = useMemo(
    () =>
      isHeadOfSales &&
      (Boolean(bookingDetails?.discountBySalesExecutive) ||
        Boolean(bookingDetails?.discountBySalesLead) ||
        Boolean(bookingDetails?.discountBySalesHead) ||
        Boolean(bookingDetails?.discountByChannelPartner)),
    [
      bookingDetails?.discountBySalesExecutive,
      bookingDetails?.discountBySalesHead,
      bookingDetails?.discountBySalesLead,
      bookingDetails?.discountByChannelPartner,
      isHeadOfSales,
    ]
  );

  const renderTotalOwnershipCost = () => (
    <StandardAndListPriceBox sx={{ marginTop: 3 }}>
      <CustomizedText fontSize={22}>
        {t("list_price.total_ownership_cost")}
      </CustomizedText>
      <ColumnStyledBox>
        <StandardAndListingPriceBox>
          {renderPriceAttributes({
            label: t("list_price.total_ownership_cost"),
            value: bookingDetails?.totalCostOfOwnership,
            isFontBold: true,
            isLastIndex: true,
          })}
        </StandardAndListingPriceBox>
      </ColumnStyledBox>
    </StandardAndListPriceBox>
  );

  const renderBasicAndAdditionalCost = () => (
    <PriceContainer
      sx={mergeStyles({ tabletStyles: { flexDirection: "column" } })}
      disableGutters
    >
      <Box sx={{ width: "100%", marginRight: 5 }}>
        {renderBasicCost()}
        {renderAdditionalCost()}
      </Box>
      <Box sx={{ width: "100%" }}>
        {renderMiscellaneousCost()}
        {shouldRenderDiscount && renderDiscountOffered()}
        {renderTotalOwnershipCost()}
      </Box>
    </PriceContainer>
  );

  const renderApartmentPriceDetails = ({
    title = "",
    squareFeetValue = 0,
    totalCost = 0.0,
    fontColor = Colors.titleColor,
    isOwnership = false,
    isLastIndex = false,
  }: {
    title?: string;
    squareFeetValue?: number;
    totalCost?: number;
    fontColor?: string;
    isOwnership?: boolean;
    isLastIndex?: boolean;
  }) => (
    <>
      <ColumnStyledBox sx={mergeStyles({ tabletStyles: { marginBottom: 3 } })}>
        <CustomizedText fontSize={30} fontWeight={"400"} font="Lato-Regular">
          {title}
        </CustomizedText>
        <CustomizedText
          fontSize={16}
          font="Lato-Regular"
          fontColor={Colors.greyColor}
        >
          {squareFeetValue
            ? t("list_price.square_feet", {
                squareFeetValue: `${UNICODE.rupeeSymbol} ${squareFeetValue}`,
              })
            : t("list_price.no_value_text")}
        </CustomizedText>
        <RowStyledBox>
          {isOwnership && bookingDetails?.totalDiscount
            ? renderAmountInCurrencyFormat({
                price: bookingDetails?.totalAmount,
                fontColor: Colors.greyColor,
                isTextStrikeThrough: true,
              })
            : null}
          {squareFeetValue ? (
            renderAmountInCurrencyFormat({
              isFontBold: true,
              price: totalCost,
              fontColor: fontColor,
              fontSize: isOwnership ? 32 : 26,
            })
          ) : (
            <CustomizedText
              fontSize={isOwnership ? 32 : 26}
              fontWeight={"600"}
              font="Lato-Regular"
              fontColor={fontColor}
            >
              -
            </CustomizedText>
          )}
        </RowStyledBox>
      </ColumnStyledBox>
      {!isLastIndex && (
        <Box
          borderLeft={0.5}
          style={{ paddingLeft: 5, paddingRight: 7 }}
          borderColor={Colors.greyColor}
        />
      )}
    </>
  );

  const renderApartmentAndOwnershipAndDiscountPrice = () => (
    <TotalCostContainer
      sx={mergeStyles({
        tabletStyles: {
          m: 0,
          marginTop: 2,
          flexDirection: "column",
        },
      })}
      disableGutters
    >
      {renderApartmentPriceDetails({
        title: t("list_price.list_price"),
        squareFeetValue: bookingDetails?.ratePerSquareFeet,
        totalCost: bookingDetails?.totalAmount,
        fontColor: Colors.titleColor,
      })}
      {renderApartmentPriceDetails({
        title: t("list_price.offered_price"),
        squareFeetValue: bookingDetails?.bookingRatePerSquareFeet,
        totalCost: bookingDetails?.totalCostOfOwnership,
        fontColor: Colors.primaryColor,
        isOwnership: true,
        isLastIndex: bookingDetails?.totalDiscount === 0,
      })}
      {bookingDetails?.totalDiscount > 0 &&
        renderApartmentPriceDetails({
          title: t("list_price.total_discount"),
          squareFeetValue: bookingDetails?.totalDiscount
            ? getTotalDiscountedAmount(
                bookingDetails?.ratePerSquareFeet,
                bookingDetails?.bookingRatePerSquareFeet
              )
            : 0,
          totalCost: bookingDetails?.totalDiscount
            ? getTotalDiscountedAmount(
                bookingDetails?.totalAmount,
                bookingDetails?.totalCostOfOwnership
              )
            : 0,
          fontColor: Colors.greenColor,
          isLastIndex: true,
        })}
    </TotalCostContainer>
  );

  return (
    <>
      <CustomizedText fontSize={24} sx={{ marginTop: 2 }}>
        {t("list_price.title")}
      </CustomizedText>
      {renderSaleableAreaAndDiscount()}
      {renderBasicAndAdditionalCost()}
      {renderApartmentAndOwnershipAndDiscountPrice()}

      <ToastContainer />
    </>
  );
};

export default ListPrice;

import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormControlLabel } from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useTranslation } from "react-i18next";
import {
  ColumnBox,
  CustomizedInputLabel,
  CustomizedText,
  OutlinedButton,
  StyledCheckBox,
} from "../styled-componnets";
import Colors from "../../utils/theme/color";
import MaterialButton from "../button";
import { useScreenSizeApplier } from "../../hooks";
import {
  ResetButtonContainer,
  StyledBox,
  StyledFormGroup,
} from "./styled-components";
import { Status } from "../../constants/statuses";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "25%",
    },
  },
};
const textProps = {
  opacity: 0.5,
  ml: 2,
  mt: 1,
  mr: 2,
  mb: 1,
};

interface MultipleSelectCheckmarkType {
  label: string;
  values?: string[] | Status[];
  onUpdate?(values: string[] | Status[]): void;
  handleClose?(index: number): void;
  handleOpen?(index: number): void;
  isOpen?: boolean;
  selectedIndex?: number;
  selectedFilterValues?: { label: string; values: string[] | Status[] }[];
}

export default function MultipleSelectCheckmark({
  label,
  values,
  onUpdate = () => {},
  handleClose = () => {},
  handleOpen = () => {},
  isOpen = false,
  selectedIndex = 0,
  selectedFilterValues = [],
}: MultipleSelectCheckmarkType) {
  const [selectedValues, setSelectedValues] = React.useState([] as any);
  const [appliedFilters, setAppliedFilters] = React.useState([] as any);
  const [isCancelled, setIsCancelled] = React.useState(false);

  const { t } = useTranslation();
  const { mergeStyles } = useScreenSizeApplier();
  const buttonTitleRef = React.useRef("");

  React.useEffect(() => {
    setIsCancelled(false);
    let filterValueIndex = selectedFilterValues?.findIndex(
      (value) => value?.label === label
    );
    if (filterValueIndex > -1) {
      let singleSelectedValue = selectedFilterValues[
        filterValueIndex
      ]?.values?.map((value) => value)[0];
      if (appliedFilters?.includes(singleSelectedValue)) {
        buttonTitleRef.current = t("custom_drop_down.applied");
      } else {
        buttonTitleRef.current = t("custom_drop_down.apply_filters");
      }
    } else {
      buttonTitleRef.current = t("custom_drop_down.apply_filters");
    }
  }, [selectedFilterValues, label, appliedFilters, t]);

  React.useEffect(() => {
    if (selectedFilterValues?.length && isCancelled) {
      setSelectedValues(selectedFilterValues[0]?.values);
    }
  }, [selectedFilterValues, isCancelled]);

  const onApplyFilters = React.useCallback(() => {
    if (!Object.is(selectedValues, [])) {
      setAppliedFilters(selectedValues);
      onUpdate(selectedValues);
    }
  }, [onUpdate, selectedValues]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, name: string | Status) => {
      setAppliedFilters([]);
      buttonTitleRef.current = t("custom_drop_down.apply_filters");
      let newValues = [...selectedValues];
      if (newValues.includes(name)) {
        let selectedValueIndex = selectedValues.findIndex(
          (item: string | Status) => item === name
        );
        newValues.splice(selectedValueIndex, 1);
        setSelectedValues(newValues);
      } else {
        newValues.push(name);
        setSelectedValues(newValues);
      }
    },
    [selectedValues, setSelectedValues, setAppliedFilters, t]
  );

  const restAllValues = React.useCallback(() => {
    buttonTitleRef.current = t("custom_drop_down.apply_filters");
    setSelectedValues([]);
    onUpdate([]);
    setIsCancelled(false);
  }, [onUpdate, t]);

  const renderSelectionTitle = () => (
    <CustomizedText
      fontSize={16}
      fontColor={Colors.titleColor}
      sx={textProps}
      fontFamily={"Lato-Regular"}
    >
      {t("custom_drop_down.drop_down_title")}
    </CustomizedText>
  );

  const renderResetButton = () => (
    <ResetButtonContainer onClick={restAllValues}>
      <CancelRoundedIcon
        htmlColor={Colors.redColor}
        fontSize="inherit"
        sx={{ mr: 0.2, fontSize: 11 }}
      />
      <CustomizedText
        fontSize={14}
        fontColor={Colors.redColor}
        fontFamily={"Lato-Regular"}
      >
        {t("custom_drop_down.reset_all")}
      </CustomizedText>
    </ResetButtonContainer>
  );

  const handleCancel = () => {
    setIsCancelled(true);
    handleClose(selectedIndex);
  };

  const renderCancelButton = () => (
    <OutlinedButton
      fontSize={16}
      sx={mergeStyles({
        styles: { p: 0, pr: 0.8, pl: 0.8 },
        tabletStyles: { width: "10%", mb: 1 },
        belowLargeStyles: { width: "10%", mb: 1 },
      })}
      onClick={handleCancel}
    >
      {t("app_common.cancel")}
    </OutlinedButton>
  );

  const renderApplyFiltersButton = () => (
    <MaterialButton
      title={buttonTitleRef?.current ?? ""}
      id={"apply-filters-button"}
      onClick={onApplyFilters}
      sx={{ ml: 2, p: 0, pr: 0.8, pl: 0.8 }}
      disabled={
        Boolean(appliedFilters.length) || !Boolean(selectedValues.length)
      }
    />
  );

  const renderCheckbox = (name: string | Status) => (
    <StyledCheckBox
      sx={{ m: 0 }}
      size={"small"}
      disableTouchRipple
      onChange={(e) => handleChange(e, name)}
      checked={selectedValues.indexOf(name) > -1}
    />
  );

  const renderCheckboxLabel = () => (
    <CustomizedInputLabel id="multiple-checkbox-label">
      {label}
    </CustomizedInputLabel>
  );

  return (
    <div>
      <FormControl sx={{ width: "100%", alignSelf: "center" }} size={"small"}>
        <Select
          open={isOpen}
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={selectedValues}
          MenuProps={MenuProps}
          size={"small"}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
          }}
          renderValue={(selected) => renderCheckboxLabel()}
          onOpen={() => handleOpen(selectedIndex)}
          onClose={() => handleClose(selectedIndex)}
        >
          {renderSelectionTitle()}
          <StyledFormGroup sx={{ pl: 3, ml: 2, mr: 2 }}>
            {values?.map((name, index) => {
              return (
                <FormControlLabel
                  disableTypography
                  id={name.toString()}
                  key={name}
                  control={renderCheckbox(name)}
                  label={
                    <CustomizedText fontSize={16} font={"Lato-Regular"}>
                      {name}
                    </CustomizedText>
                  }
                  labelPlacement="end"
                  sx={{ color: Colors.greyColor }}
                />
              );
            })}
          </StyledFormGroup>
          <ColumnBox sx={{ p: 2 }}>
            {renderResetButton()}
            <StyledBox sx={mergeStyles({ tabletStyles: { mr: 2 } })}>
              {renderCancelButton()}
              {renderApplyFiltersButton()}
            </StyledBox>
          </ColumnBox>
        </Select>
      </FormControl>
    </div>
  );
}

import { Box, styled } from "@mui/material";
import Colors from "../../utils/theme/color";

export const ConfirmBookingActionContainer = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.white,
  display: "flex",
  width: "45%",
  flexDirection: "column",
  paddingTop: 5,
  borderRadius: 5,
  [theme.breakpoints.down("lg")]: {
    width: "63%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));
export const UnitAndCustomerDetailsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.lightBlueColor,
  display: "flex",
  flexDirection: "column",
  marginTop: 10,
  marginBottom: 10,
  marginRight: 20,
  padding: 10,
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  background: Colors.white,
  boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.12)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 30,
  paddingTop: 15,
  paddingBottom: 15,
}));

export const ActionInnerContainer = styled(Box)(({ theme }) => ({
  padding: 30,
  paddingTop: 0,
  paddingRight: 10,
}));
export const CloseButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  cursor: "pointer",
}));

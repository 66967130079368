import { apiSlice } from "./api-slice";

export const masterApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLostReasons: builder.query({
      query: ({ userToken }) => ({
        url: "bren_erp.v8.api.get_lost_reasons",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    getCityList: builder.query({
      query: ({ userToken }) => ({
        url: "bren_erp.v8.api.get_city",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    getBankList: builder.query({
      query: ({ userToken }) => ({
        url: "bren_erp.v8.api.get_bank",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    getPaymentModeList: builder.query({
      query: ({ userToken }) => ({
        url: "bren_erp.v8.booking.get_mode_of_payment",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    getSalesPersonList: builder.query({
      query: ({ userToken }) => ({
        url: "bren_erp.v8.api.get_sales_person_list",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    getExistingCustomerList: builder.query({
      query: ({ userToken, mobile }) => ({
        url: `bren_erp.v8.api.get_existing_customer?mobile=${mobile}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
  }),
});

export const {
  useGetLostReasonsQuery,
  useGetCityListQuery,
  useGetBankListQuery,
  useGetPaymentModeListQuery,
  useGetSalesPersonListQuery,
  useGetExistingCustomerListQuery,
} = masterApiSlice;

import { FC, useEffect, useState } from "react";
import MaterialButton from "../../components/button";
import "../../components/bottom-bar/bottom-bar.css";
import {
  ButtonContainer,
  RejectButton,
  SaveButton,
} from "../../components/bottom-bar/styled-components";
import { useGetUserDetailsHooks, useScreenSizeApplier } from "../../hooks";
import { Box } from "@mui/material";
import { Status } from "../../constants/statuses";
import { useFetchBookingActionsQuery } from "../../../features/api/booking-api-slice";
import { IAction } from "./booking-form";

interface CustomizedBottomBarTypes {
  isButtonDisabled?: boolean;
  onPressApprove: (action: IAction) => void;
  onPressReject: (action: IAction) => void;
  onPressReview: (action: IAction) => void;
  styles?: { bottomTabContainer?: {}; buttonContainer?: {}; buttonStyle?: {} };
  currentWorkFlow?: Status;
}

const ActionButton: FC<CustomizedBottomBarTypes> = ({
  isButtonDisabled = false,
  onPressApprove,
  onPressReject,
  onPressReview,
  styles = {},
  currentWorkFlow = Status.SALES_APPROVAL || Status.SALES_MANAGER_REVIEW,
}) => {
  const { getUserToken, salesHead, isCurrentUserCP } = useGetUserDetailsHooks();

  const { mergeStyles } = useScreenSizeApplier();

  const [actions, setActions] = useState<IAction[]>([]);

  const { data: getBookingActionsResponse } = useFetchBookingActionsQuery({
    userToken: getUserToken(),
    role: salesHead || isCurrentUserCP,
    currentWorkFlow,
  });

  useEffect(() => {
    if (Array.isArray(getBookingActionsResponse?.data)) {
      setActions(getBookingActionsResponse?.data);
    } else {
      setActions([]);
    }
  }, [getBookingActionsResponse]);

  // Logic to check if the current workflow state is either Sales Approval or Sales Manager Review
  const isWorkflowEnabled = currentWorkFlow === Status.SALES_APPROVAL || currentWorkFlow === Status.SALES_MANAGER_REVIEW;

  return (
    <div
      className="customized-bottom-container"
      style={styles?.bottomTabContainer}
    >
      {Boolean(actions?.length) && isWorkflowEnabled && ( // Check if the workflow is enabled
        <ButtonContainer sx={styles?.buttonContainer}>
          <RejectButton
            onClick={() => onPressReject(actions[actions?.length - 1])}
          >
            {actions[actions?.length - 1]?.action}
          </RejectButton>
          <Box sx={{ display: "flex" }}>
            <SaveButton
              onClick={() => onPressReview(actions[actions?.length - 2])}
              sx={{ mr: 3 }}
            >
              {actions[actions?.length - 2]?.action}
            </SaveButton>
            <Box
              sx={mergeStyles({
                styles: { display: "flex", alignItems: "center" },
                tabletStyles: { display: "flex", flexDirection: "column" },
                belowLargeStyles: { display: "flex", flexDirection: "column" },
              })}
            >
              <MaterialButton
                id="send-otp"
                title={actions[actions?.length - 3]?.action}
                onClick={() => onPressApprove(actions[actions?.length - 3])}
                fontSize={20}
                size={"small"}
                sx={styles?.buttonStyle}
              />
            </Box>
          </Box>
        </ButtonContainer>
      )}
    </div>
  );
};

export default ActionButton;

import { FC } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider } from "@mui/material";
import Colors from "../../utils/theme/color";
import { CustomizedLink, CustomizedText } from "../styled-componnets";
import {
  BookingCommentsContainer,
  ButtonContainer,
  CloseButtonContainer,
  RowStyledBox,
  TitleContainer,
} from "./styed-components";
import TextInput from "../text-input/text-input";
import MaterialButton from "../button";
import CommentComponent from "./comment";
import { CommentsProps } from "../../utils/booking-utils";
import { useScreenSizeApplier } from "../../hooks";
import { UNICODE } from "../../constants";

interface BookingCommentsProps {
  handleClose?: () => void;
  commentList: CommentsProps[];
  comment: string;
  onClickAddNote: () => void;
  onChangeComment: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  isButtonDisabled: boolean;
  isAddNoteLoading: boolean;
  sx?: { bookingCommentsContainerStyle?: {} };
  isCloseIconRequired?: boolean;
  commentStyle?: {};
}
const BookingComments: FC<BookingCommentsProps> = ({
  handleClose = () => {},
  commentList,
  comment,
  onClickAddNote,
  onChangeComment,
  isButtonDisabled,
  isAddNoteLoading,
  onClear,
  sx = { bookingCommentsContainerStyle: {} },
  isCloseIconRequired = true,
  commentStyle = {},
}) => {
  const { t } = useTranslation();

  const { mergeStyles } = useScreenSizeApplier();

  const renderTitle = () => (
    <TitleContainer>
      <CustomizedText fontSize={24} fontColor={Colors.titleColor}>
        {t("booking_comments.title")}
      </CustomizedText>
      {isCloseIconRequired && renderCloseButton()}
    </TitleContainer>
  );

  const renderCommentInput = () => (
    <Box sx={{ pr: 2.5, pl: 2.5, backgroundColor: Colors.cyanColor, mb: 1 }}>
      <TextInput
        id="comment"
        value={comment}
        label={!comment ? t("booking_comments.comment_placeholder") : ""}
        size="medium"
        onChange={onChangeComment}
        outerLabel={""}
        markRequiredField={false}
        multiline={true}
        styles={{
          textInputContainer: {
            marginTop: "1rem",
            backgroundColor: Colors.white,
          },
        }}
        rows={3}
      />
      <ButtonContainer>
        <CustomizedLink
          sx={{
            mr: 4,
            ...(isButtonDisabled && { color: Colors.veryLightBlack }),
          }}
          {...(comment && { onClick: onClear })}
        >
          Clear
        </CustomizedLink>
        <MaterialButton
          title={"Add Note"}
          id={"apply-filter-button"}
          onClick={onClickAddNote}
          disabled={isAddNoteLoading || isButtonDisabled}
          isLoading={isAddNoteLoading}
        />
      </ButtonContainer>
    </Box>
  );

  const renderDot = () => (
    <CustomizedText
      fontSize={10}
      fontColor={Colors.primaryColor}
    >{`${UNICODE.dot}`}</CustomizedText>
  );

  const renderComments = () =>
    commentList?.map((commentDetail: CommentsProps, index) => (
      <RowStyledBox key={`${commentDetail?.comment_by}${index}`}>
        <Divider orientation="vertical" sx={{ ml: 1.5 }} flexItem>
          {renderDot()}
        </Divider>
        <CommentComponent
          details={commentDetail}
          sx={{
            commentContainerStyle: mergeStyles({
              styles: { width: "80%", ml: 2 },
              belowXlStyles: { width: "73%" },
              belowLargeStyles: { width: "87%" },
              tabletStyles: { width: "78%" },
            }),
            commentStyle: commentStyle,
          }}
          isLinkRequired={false}
        />
      </RowStyledBox>
    ));

  const renderCloseButton = () => (
    <CloseButtonContainer
      marginRight={0.5}
      padding={2}
      paddingBottom={0}
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" htmlColor={Colors.titleColor} />
    </CloseButtonContainer>
  );
  return (
    <BookingCommentsContainer sx={sx?.bookingCommentsContainerStyle}>
      {renderTitle()}
      {renderCommentInput()}
      {renderComments()}
    </BookingCommentsContainer>
  );
};

export default BookingComments;

import { apiSlice } from "./api-slice";

export const projectApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: () => ({
        url: "bren_erp.v8.project.get_projects_list",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    }),
    getUnitList: builder.query({
      query: ({ project, userToken }) => ({
        url: `bren_erp.v8.project.get_unit_details_from_project?project=${project}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    getUnitType: builder.query({
      query: ({ project, userToken }) => ({
        url: `bren_erp.v8.project.get_unit_type_details_from_project?project=${
          project ?? ""
        }`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    getUnitDetailsById: builder.query({
      query: ({ unit, userToken }) => ({
        url: `bren_erp.v8.project.get_unit?unit_no=${unit ?? ""}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetUnitListQuery,
  useGetUnitTypeQuery,
  useGetUnitDetailsByIdQuery,
} = projectApiSlice;

import { Button, Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import Colors from "../../utils/theme/color";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const SecondaryButton = styled(Button)(({ theme }) => ({
  fontFamily: "Lora-Bold",
  fontSize: 20,
  backgroundColor: Colors.actionColor,
  border: `1.2px solid ${Colors.actionColor}`,
  boxShadow: `2px 0px 8px ${Colors.actionColor} 0px 2px 8px ${Colors.actionColor}`,
  borderRadius: "5px",
  color: Colors.primaryColor,
  "&:hover": {
    backgroundColor: Colors.goldenPoppy,
    border: `1.2px solid ${Colors.goldenPoppy}`,
    boxShadow: `2px 0px 8px ${Colors.goldenPoppy} 0px 2px 8px ${Colors.goldenPoppy}`,
    color: Colors.white,
  },
  filter: "",
  textTransform: "none",
  padding: "0rem 2.5rem 0rem 2.5rem",
  [theme.breakpoints.down("xl")]: {
    fontSize: 18,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));

export const PrimaryButton = styled(Button)(({ theme }) => ({
  fontFamily: "Lora-Bold",
  fontSize: 20,
  backgroundColor: Colors.white,
  border: `1.2px solid ${Colors.actionColor}`,
  boxShadow: "",
  borderRadius: "5px",
  color: Colors.actionColor,
  filter: `drop-shadow(2px 0px 8px rgba(254, 202, 10, 0.16)) drop-shadow(0px 2px 8px rgba(254, 202, 10, 0.16))`,
  "&:hover": {
    backgroundColor: Colors.actionColor,
    color: Colors.white,
    border: `1.2px solid ${Colors.actionColor}`,
    boxShadow: `2px 0px 8px ${Colors.actionColor} 0px 2px 8px ${Colors.actionColor}`,
  },
  textTransform: "none",
  padding: "0rem 2.5rem 0rem 2.5rem",
  [theme.breakpoints.down("xl")]: {
    fontSize: 18,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));

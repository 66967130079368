import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  useRedirectToLogin,
  useGetUserDetailsHooks,
  useScreenSizeApplier,
} from "../../hooks";
import TextInput from "../../components/text-input/text-input";
import { useGetBookingDetailsByIdQuery } from "../../../features/api/booking-api-slice";
import { ProjectAndUnitType } from "../../utils/booking-utils";

const ERROR = "error";

interface ProjectAndUnitDetailsTypes {
  bookingId?: string;
}

const ProjectAndUnitDetails = ({
  bookingId = "",
}: ProjectAndUnitDetailsTypes) => {
  const { t } = useTranslation();
  const { getUserToken } = useGetUserDetailsHooks();

  const { mergeStyles } = useScreenSizeApplier();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();

  const [projectAndUnit, setProjectAndUnit] = useState<ProjectAndUnitType>({
    project: "",
    unit: "",
    unitType: "",
    floor: "",
    bhk: "",
    saleableArea: "",
    carpetArea: "",
    block: "",
  });

  const {
    data: getBookingDetailsByIdResponse,
    isSuccess: isGetBookingDetailsByIdSuccess,
    isError: isGetBookingDetailsByIdFailed,
    error: getBookingDetailsByIdError,
    refetch: refetchBookingDetails,
  } = useGetBookingDetailsByIdQuery(
    {
      bookingId,
      userToken: getUserToken(),
    },
    { skip: !Boolean(bookingId) }
  );

  useEffect(() => {
    if (isGetBookingDetailsByIdSuccess) {
      if (getBookingDetailsByIdResponse?.data?.name) {
        let data = getBookingDetailsByIdResponse?.data;
        setProjectAndUnit({
          project: data?.project,
          unit: data?.unit,
          unitType: data?.unit_type,
          saleableArea: data?.saleable_area,
          carpetArea: data?.carpet_area,
          bhk: data?.bhk,
          floor: data?.floor,
          block: data?.block,
        });
      } else if (getBookingDetailsByIdResponse?.message) {
        toast(getBookingDetailsByIdResponse?.message, {
          type: ERROR,
        });
      }
    } else if (isGetBookingDetailsByIdFailed) {
      redirectToLoginBasedOnResponse(
        getBookingDetailsByIdError,
        getBookingDetailsByIdResponse
      );
    }
    // eslint-disable-next-line
  }, [
    isGetBookingDetailsByIdSuccess,
    isGetBookingDetailsByIdFailed,
    getBookingDetailsByIdResponse,
    getBookingDetailsByIdError,
  ]);

  useEffect(() => {
    refetchBookingDetails();
  }, [refetchBookingDetails]);

  const renderProjects = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: { m: 0, display: "flex", width: "33%" },
        belowLargeStyles: { width: "47.5%" },
        tabletStyles: { width: "100%" },
      })}
    >
      <TextInput
        id="project-input"
        value={projectAndUnit?.project}
        label={t("project_and_unit_preference.project_placeholder")}
        outerLabel={t(
          "project_and_unit_preference.project_customer_interested"
        )}
        markRequiredField={false}
        disabled={true}
      />
    </Container>
  );

  const renderUnitsAndUnitType = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: { m: 0, display: "flex", width: "70%" },
        belowLargeStyles: { width: "100%" },
        tabletStyles: { flexDirection: "column", width: "100%" },
      })}
    >
      <TextInput
        id="unit-type-input"
        value={projectAndUnit?.unitType}
        label={t("project_and_unit_preference.unit_type_placeholder")}
        outerLabel={t("project_and_unit_preference.unit_type")}
        markRequiredField={false}
        disabled={true}
      />
      <div style={{ width: "10%" }} />
      <TextInput
        id="unit-input"
        value={projectAndUnit?.unit}
        label={t("project_and_unit_preference.unit_placeholder")}
        outerLabel={t("project_and_unit_preference.unit")}
        markRequiredField={false}
        disabled={true}
      />
    </Container>
  );

  const renderUnitFloorAndBlockDetails = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: { m: 0, display: "flex", width: "70%" },
        belowLargeStyles: { width: "100%" },
        tabletStyles: { flexDirection: "column", width: "100%" },
      })}
    >
      <TextInput
        id="floor-input"
        value={projectAndUnit?.floor}
        label={t("project_and_unit_preference.floor")}
        outerLabel={t("project_and_unit_preference.floor")}
        markRequiredField={false}
        disabled={true}
      />
      <div style={{ width: "10%" }} />
      <TextInput
        id="block-input"
        value={projectAndUnit?.block}
        label={t("project_and_unit_preference.block")}
        outerLabel={t("project_and_unit_preference.block")}
        markRequiredField={false}
        disabled={true}
      />
    </Container>
  );

  const renderUnitAreaSquareFeetDetails = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: { m: 0, display: "flex", width: "70%" },
        belowLargeStyles: { width: "100%" },
        tabletStyles: { flexDirection: "column", width: "100%" },
      })}
    >
      <TextInput
        id="saleable-area-input"
        value={projectAndUnit?.saleableArea}
        label={t("project_and_unit_preference.saleable_area")}
        outerLabel={t("project_and_unit_preference.saleable_area")}
        markRequiredField={false}
        disabled={true}
      />
      <div style={{ width: "10%" }} />
      <TextInput
        id="carpet-area-input"
        value={projectAndUnit?.carpetArea}
        label={t("project_and_unit_preference.carpet_area")}
        outerLabel={t("project_and_unit_preference.carpet_area")}
        markRequiredField={false}
        disabled={true}
      />
    </Container>
  );

  const renderUnitDetails = () => (
    <>
      {renderUnitFloorAndBlockDetails()}
      {renderUnitAreaSquareFeetDetails()}
    </>
  );

  return (
    <Box>
      {renderProjects()}
      {renderUnitsAndUnitType()}
      {projectAndUnit?.unit ? renderUnitDetails() : null}
    </Box>
  );
};

export default ProjectAndUnitDetails;

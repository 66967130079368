import React from "react";
import { NumericFormat } from "react-number-format";
import { EventProps } from "../utils/interface";

interface NumberInputPropsType {
  onChange: (props: EventProps) => void;
  name?: string;
}
const NumberInputField = (
  innerProps: NumberInputPropsType,
  ref: any
): JSX.Element => {
  const { onChange = () => {}, ...other } = innerProps;

  return (
    <NumericFormat
      {...other}
      decimalScale={2}
      thousandSeparator
      valueIsNumericString
      prefix="₹ "
      thousandsGroupStyle="lakh"
      onValueChange={(innerValues): void => {
        onChange({
          target: {
            name: innerProps?.name,
            value: innerValues?.value,
          },
        });
      }}
    />
  );
};

export default React.forwardRef(NumberInputField);

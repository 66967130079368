import { Box, Container, styled } from "@mui/material";
import Colors from "../../utils/theme/color";

export const SaleableAreaAndDiscountContainer = styled(Container)({
  m: 0,
  display: "flex",
  backgroundColor: Colors.veryLightPrimaryColor,
  borderRadius: "2px",
  marginTop: 3,
  justifyContent: "space-between",
  padding: "1rem 2rem 1rem 2.5rem",
});

export const PriceAttributeBox = styled(Box)(() => ({
  padding: "0.65rem 1rem 0.65rem 1rem",
  display: "flex",
  justifyContent: "space-between",
  borderColor: Colors.greyColor,
}));

export const StandardAndListingPriceBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: 5,
  paddingInline: 20,
  marginTop: 10,
  borderColor: Colors.greyColor,
  border: `0.5px solid ${Colors.greyColor}`,
}));

export const PriceContainer = styled(Container)({
  m: 0,
  display: "flex",
  borderRadius: 1,
  marginTop: "1rem",
  paddingTop: 2,
  paddingBottom: 2,
  width: "100%",
  flexDirection: "column",
});

export const TotalCostContainer = styled(Container)({
  m: 0,
  display: "flex",
  backgroundColor: Colors.veryLightPrimaryColor,
  borderRadius: "3px",
  marginTop: "1rem",
  padding: "0.65rem 1.5rem 0.65rem 1.5rem",
  justifyContent: "space-between",
});

export const SaleableAreaAndDiscountInnerContainer = styled(Box)(() => ({
  flexDirection: "column",
}));

export const RowStyledBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
}));

export const ColumnStyledBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const StandardAndListPriceBox = styled(Box)(() => ({}));

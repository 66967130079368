import { Box, FormGroup, styled } from "@mui/material";

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
}));
export const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  pl: 3,
  background: "rgba(153, 161, 176, 0.1)",
  ml: 2,
  mr: 2,
}));
export const ResetButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
}));

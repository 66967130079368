import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ActionMeta, SingleValue } from "react-select";
import { toast } from "react-toastify";
import TextInput from "../../components/text-input/text-input";
import RowRadioButtonsGroup from "../../components/radio-button";
import ApplicantPhoto from "./applicant-photo";
import { API_CONSTANTS, VALIDATION_REGEXPS } from "../../constants";
import "./applicant-form.css";
import {
  ApplicantBasicDetailsFormTypes,
  EMPTY_BASIC_DETAILS,
} from "../../utils/booking-utils";
import { convertToTitleCase, getFileType } from "../../utils/functions";
import DatePickerComponent from "../../components/date-picker";
import { YYYY_MM_DD_WITH_HYPHEN } from "../../utils/date";
import Dropdown from "../../components/dropdown";
import { useFetchSalutationQuery } from "../../../features/api/booking-api-slice";
import { useGetUserDetailsHooks, useRedirectToLogin } from "../../hooks";
import { FormGroup } from "@mui/material";
import { Label, StyledCheckBox } from "./styled-component";
import { CircleOutlined } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Colors from "../../utils/theme/color";

const MINIMUM_DOB = "1900-01-01";
const MAXIMUM_PIN_LENGTH = 6;

const ApplicantBasicDetailsForm = ({
  basicData = {},
  numberOfApplicant = 0,
  onUpdate,
  isGenderRequired = true,
  isFatherNameRequired = true,
  isDOBRequired = true,
  isAddressRequired = true,
  isPhotoRequired = true,
  isEmailMandatory = false,
  styles = {},
  isEditable = true,
  savePhoto = () => {},
  isSalutationRequired = false,
}: ApplicantBasicDetailsFormTypes) => {
  const genders = [
    { id: "Male", label: "Male" },
    { id: "Female", label: "Female" },
  ];

  const { t } = useTranslation();
  const { getUserToken } = useGetUserDetailsHooks();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();

  const [basicDetails, setBasicDetails] = useState(basicData);
  const basicDetailsRef = useRef(basicDetails);
  const [salutationList, setSalutationList] = useState([]);

  useEffect(() => {
    if (basicData?.name) {
      setBasicDetails(basicData);
      basicDetailsRef.current = basicData;
    }
  }, [basicData?.name, basicData]);

  useEffect(() => {
    if (!Object.is(basicDetails, EMPTY_BASIC_DETAILS)) {
      basicDetailsRef.current = basicDetails;
      onUpdate(basicDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicDetails]);

  const isAddressSame = useCallback(
    () => basicDetails?.isPermanentAddressSameAsCorrespondence === "YES",
    [basicDetails?.isPermanentAddressSameAsCorrespondence]
  );

  const isAddressSameAsPrimaryApplicant = useCallback(
    () =>
      numberOfApplicant > 1 &&
      basicDetails?.isAddressSameAsPrimaryApplicant === "YES",
    [basicDetails?.isAddressSameAsPrimaryApplicant, numberOfApplicant]
  );

  useEffect(() => {
    if (isAddressSame()) {
      setBasicDetails({
        ...basicDetailsRef?.current,
        permanentAddress: basicDetailsRef?.current.correspondenceAddress,
        permanentPin: basicDetailsRef?.current.pin,
        permanentTelephone: basicDetailsRef?.current.telephone,
      });
    }
  }, [isAddressSame]);

  const {
    data: getSalutationList,
    isSuccess: isGetSalutationListSuccess,
    isError: isGetSalutationListFailed,
    error: getSalutationListError,
    refetch: refetchSalutationList,
  } = useFetchSalutationQuery(
    {
      userToken: getUserToken(),
    },
    { skip: !isSalutationRequired }
  );

  useEffect(() => {
    if (isGetSalutationListSuccess) {
      if (
        getSalutationList?.data?.length &&
        getSalutationList?.status_code === API_CONSTANTS.successStatusCode
      ) {
        setSalutationList(getSalutationList?.data);
      } else if (getSalutationList?.message) {
        toast(getSalutationList?.message, {
          type: "error",
        });
        setSalutationList([]);
      }
    } else if (isGetSalutationListFailed) {
      redirectToLoginBasedOnResponse(getSalutationListError, getSalutationList);
      setSalutationList([]);
    }
    // eslint-disable-next-line
  }, [
    getSalutationList,
    isGetSalutationListSuccess,
    isGetSalutationListFailed,
    t,
    getSalutationListError,
  ]);

  useEffect(() => {
    refetchSalutationList();
  }, [refetchSalutationList]);

  const onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({
      ...basicDetailsRef?.current,
      firstName: convertToTitleCase(
        e.target.value.replace(VALIDATION_REGEXPS.matchesAnyCharacters, "")
      ),
    });
  };

  const onChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({
      ...basicDetailsRef?.current,
      lastName: convertToTitleCase(e.target.value),
    });
  };

  const onChangeFathersName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({
      ...basicDetailsRef?.current,
      fatherOrHusbandName: e.target.value.replace(
        VALIDATION_REGEXPS.matchesAnyCharacters,
        ""
      ),
    });
  };
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({
      ...basicDetailsRef?.current,
      dob: e.target.value,
    });
  };

  const onChangeGender = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({
      ...basicDetailsRef?.current,
      gender: (event.target as HTMLInputElement).value,
    });
  };

  const onChangeMobile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({
      ...basicDetailsRef?.current,
      mobile: event.target.value.replace(
        VALIDATION_REGEXPS.matchesAnyNumbers,
        ""
      ),
      mobileErrorMsg: "",
    });
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({
      ...basicDetailsRef?.current,
      email: event.target.value
        .replace(VALIDATION_REGEXPS.whiteSpacesRegex, "")
        .toLowerCase(),
      emailErrorMsg: "",
    });
  };

  const onChangeCorrespondenceAddress = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isAddressSame()) {
      setBasicDetails({
        ...basicDetailsRef?.current,
        correspondenceAddress: event.target.value,
        permanentAddress: event.target.value,
      });
    } else {
      setBasicDetails({
        ...basicDetailsRef?.current,
        correspondenceAddress: event.target.value,
      });
    }
  };
  const onChangePermanentAddress = (event: { target: { value: string } }) => {
    setBasicDetails({
      ...basicDetailsRef?.current,
      permanentAddress: event.target.value,
    });
  };

  const onChangePin = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isAddressSame()) {
      setBasicDetails({
        ...basicDetailsRef?.current,
        pin: event.target.value.replace(
          VALIDATION_REGEXPS.matchesAnyNumbers,
          ""
        ),
        permanentPin: event.target.value.replace(
          VALIDATION_REGEXPS.matchesAnyNumbers,
          ""
        ),
      });
    } else {
      setBasicDetails({
        ...basicDetailsRef?.current,
        pin: event.target.value.replace(
          VALIDATION_REGEXPS.matchesAnyNumbers,
          ""
        ),
      });
    }
  };

  const onChangeTelephone = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isAddressSame()) {
      setBasicDetails({
        ...basicDetailsRef?.current,
        telephone: event.target.value.replace(
          VALIDATION_REGEXPS.matchesAnyNumbers,
          ""
        ),
        permanentTelephone: event.target.value.replace(
          VALIDATION_REGEXPS.matchesAnyNumbers,
          ""
        ),
      });
    } else {
      setBasicDetails({
        ...basicDetailsRef?.current,
        telephone: event.target.value.replace(
          VALIDATION_REGEXPS.matchesAnyNumbers,
          ""
        ),
      });
    }
  };

  const onChangePermanentPin = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({
      ...basicDetailsRef?.current,
      permanentPin: event.target.value.replace(
        VALIDATION_REGEXPS.matchesAnyNumbers,
        ""
      ),
    });
  };

  const onChangePermanentTelephone = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBasicDetails({
      ...basicDetailsRef?.current,
      permanentTelephone: event.target.value.replace(
        VALIDATION_REGEXPS.matchesAnyNumbers,
        ""
      ),
    });
  };

  const onChangeYesOrNo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({
      ...basicDetailsRef?.current,
      isPermanentAddressSameAsCorrespondence: event.target.value,
    });
  };

  const uploadPhoto = (
    data: string,
    file: { uri: string; type: string; size: number; name: string }
  ) => {
    savePhoto(
      data,
      t("create_booking.photo", {
        applicant: numberOfApplicant,
        type: Boolean(getFileType(file?.name))
          ? getFileType(file?.name)
          : file?.type,
      })
    );
    setBasicDetails({
      ...basicDetailsRef?.current,
      photo: {
        name: t("create_booking.photo", {
          applicant: numberOfApplicant,
          type: Boolean(getFileType(file?.name))
            ? getFileType(file?.name)
            : file?.type,
        }),
        type: file?.type,
        data: data,
      },
    });
  };

  const removePhoto = () => {
    savePhoto();
    setBasicDetails({
      ...basicDetailsRef?.current,
      photo: {
        name: "",
        type: "",
        data: "",
      },
    });
  };

  const onChangeAddressSameAsPrimary = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBasicDetails({
      ...basicDetailsRef?.current,
      isAddressSameAsPrimaryApplicant: event.target.value,
    });
  };

  const getSalutationAsLabelAndValue = useMemo(
    () =>
      salutationList?.map((item: { salutation: string }) => {
        return { value: item?.salutation, label: item?.salutation };
      }),
    [salutationList]
  );

  const onChangeSalutation = (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>
  ) => {
    setBasicDetails({
      ...basicDetailsRef?.current,
      salutation: newValue?.value,
    });
  };

  const onChangeIsFathersName = useCallback((e: object, checked: boolean) => {
    setBasicDetails({
      ...basicDetailsRef?.current,
      isFathersName: checked,
    });
  }, []);

  const renderName = () => (
    <div className="input-container" style={styles.textInputRowContainer}>
      <TextInput
        id="first-name-input"
        value={basicDetails.firstName}
        label={
          !basicDetailsRef?.current.firstName
            ? t("create_booking.basic_details.first_name_placeholder")
            : ""
        }
        onChange={onChangeFirstName}
        outerLabel={t("create_booking.basic_details.first_name")}
        markRequiredField={true}
        disabled={!isEditable}
      />
      <div style={{ width: "10%" }} />
      <TextInput
        id="last-name-input"
        value={basicDetails.lastName}
        label={
          !basicDetailsRef?.current.lastName
            ? t("create_booking.basic_details.last_name_placeholder")
            : ""
        }
        size="small"
        onChange={onChangeLastName}
        outerLabel={t("create_booking.basic_details.last_name")}
        markRequiredField={true}
        disabled={!isEditable}
      />
    </div>
  );

  const renderFatherOrHusbandNameInput = () => (
    <div className="input-container">
      <TextInput
        id="husband-spouse-name-input"
        value={basicDetails?.fatherOrHusbandName}
        label={
          !basicDetailsRef?.current.fatherOrHusbandName
            ? t(
                "create_booking.basic_details.father_or_husband_name_placeholder"
              )
            : ""
        }
        onChange={onChangeFathersName}
        outerLabel={t("create_booking.basic_details.father_or_husband_name")}
        markRequiredField={true}
        disabled={!isEditable}
      />
      <div style={{ width: "10%" }} />
      {isDOBRequired && (
        <DatePickerComponent
          date={basicDetails?.dob}
          onChangeDate={handleDateChange}
          outerLabel={t("create_booking.basic_details.date_of_birth")}
          markRequiredField={true}
          maxDate={moment(new Date()).format(YYYY_MM_DD_WITH_HYPHEN)}
          minDate={MINIMUM_DOB}
          disabled={!isEditable}
        />
      )}
    </div>
  );

  const renderGender = () => (
    <RowRadioButtonsGroup
      title={t("create_booking.basic_details.gender")}
      labels={genders}
      value={basicDetailsRef?.current.gender}
      handleChange={onChangeGender}
      markRequiredField={false}
      sx={{ formLabel: { mt: "2rem" } }}
      disabled={!isEditable}
    />
  );

  const renderMobileNumberAndEmail = () => (
    <div className="input-container" style={styles.textInputRowContainer}>
      <TextInput
        id="mobile-input"
        value={basicDetails?.mobile}
        onChange={onChangeMobile}
        error={Boolean(basicDetailsRef?.current.mobileErrorMsg)}
        helperText={basicDetailsRef?.current.mobileErrorMsg}
        outerLabel={t("create_booking.basic_details.mobile_number")}
        markRequiredField={true}
        styles={{
          ...{
            outerLabel: {
              marginTop: "2rem",
            },
            textInputContainer: {
              ...(basicDetailsRef?.current.mobileErrorMsg && {
                marginTop: 23,
              }),
            },
          },
        }}
        isMobile
        disabled={!isEditable}
      />
      <div style={{ width: "10%" }} />
      <TextInput
        id="email-input"
        value={basicDetails?.email}
        label={
          !basicDetailsRef?.current.email
            ? t("create_booking.basic_details.email")
            : ""
        }
        onChange={onChangeEmail}
        error={Boolean(basicDetailsRef?.current.emailErrorMsg)}
        helperText={basicDetailsRef?.current.emailErrorMsg}
        outerLabel={t("create_booking.basic_details.email")}
        markRequiredField={isEmailMandatory}
        type={"email"}
        styles={{
          outerLabel: {
            marginTop: "2rem",
          },
          textInputContainer: {
            ...(basicDetailsRef?.current.emailErrorMsg && {
              marginTop: 23,
            }),
          },
        }}
        disabled={!isEditable}
      />
    </div>
  );

  const renderCorrespondenceAddress = () => (
    <div className="input-container">
      <TextInput
        id="correspondence-address"
        value={basicDetails?.correspondenceAddress}
        label={
          !basicDetailsRef?.current.correspondenceAddress
            ? t(
                "create_booking.basic_details.correspondence_address_placeholder"
              )
            : ""
        }
        size="medium"
        onChange={onChangeCorrespondenceAddress}
        outerLabel={t("create_booking.basic_details.correspondence_address")}
        markRequiredField={true}
        multiline={true}
        rows={4}
        disabled={!isEditable}
        {...(numberOfApplicant > 1 && {
          styles: {
            outerLabel: { marginTop: 20 },
          },
        })}
      />
    </div>
  );

  const renderPermanentAddress = () => (
    <div className="input-container">
      <TextInput
        id="permanent-address"
        value={basicDetails?.permanentAddress}
        label={
          !basicDetailsRef?.current.permanentAddress
            ? t("create_booking.basic_details.permanent_address_placeholder")
            : ""
        }
        size="medium"
        onChange={onChangePermanentAddress}
        outerLabel={t("create_booking.basic_details.permanent_address")}
        markRequiredField={true}
        multiline={true}
        styles={{
          outerLabel: {
            marginTop: "1rem",
          },
        }}
        rows={4}
        disabled={!isEditable}
      />
    </div>
  );

  const renderPinAndTelPhone = () => (
    <div className="input-container">
      <TextInput
        id="correspondence-pin-input"
        value={basicDetails?.pin}
        label={
          !basicDetailsRef?.current.pin
            ? t("create_booking.basic_details.pin_placeholder")
            : ""
        }
        onChange={onChangePin}
        outerLabel={t("create_booking.basic_details.pin")}
        markRequiredField={true}
        disabled={!isEditable}
        maxLength={MAXIMUM_PIN_LENGTH}
      />
      <div style={{ width: "10%" }} />
      <TextInput
        id="correspondence-tel-input"
        value={basicDetails?.telephone}
        label={
          !basicDetailsRef?.current.telephone
            ? t("create_booking.basic_details.tel_placeholder")
            : ""
        }
        onChange={onChangeTelephone}
        outerLabel={t("create_booking.basic_details.tel")}
        markRequiredField={false}
        disabled={!isEditable}
      />
    </div>
  );

  const renderPermanentPinAndTelPhone = () => (
    <div className="input-container">
      <TextInput
        id="permanent-pin-input"
        value={basicDetails?.permanentPin}
        label={
          !basicDetailsRef?.current.permanentPin
            ? t("create_booking.basic_details.pin_placeholder")
            : ""
        }
        onChange={onChangePermanentPin}
        outerLabel={t("create_booking.basic_details.pin")}
        markRequiredField={true}
        disabled={!isEditable}
        maxLength={MAXIMUM_PIN_LENGTH}
      />
      <div style={{ width: "10%" }} />
      <TextInput
        id="permanent-tel-input"
        value={basicDetails?.permanentTelephone}
        label={
          !basicDetailsRef?.current.permanentTelephone
            ? t("create_booking.basic_details.tel_placeholder")
            : ""
        }
        onChange={onChangePermanentTelephone}
        outerLabel={t("create_booking.basic_details.tel")}
        markRequiredField={false}
        disabled={!isEditable}
      />
    </div>
  );

  const renderYesOrNoRadioButton = () => (
    <RowRadioButtonsGroup
      title={t("create_booking.basic_details.permanent_address_same_query")}
      labels={[
        { id: "YES", label: "Yes" },
        { id: "NO", label: "No" },
      ]}
      value={basicDetailsRef?.current.isPermanentAddressSameAsCorrespondence}
      handleChange={onChangeYesOrNo}
      markRequiredField
      sx={{ formLabel: { mt: "2rem" }, labelRequired: { color: "red" } }}
      disabled={!isEditable}
    />
  );

  const renderCapturedOrUploadedPhoto = () => (
    <div className="single-detail-container">
      <ApplicantPhoto
        uploadPhoto={uploadPhoto}
        photo={basicDetailsRef?.current.photo}
        removePhoto={removePhoto}
        numberOfApplicant={numberOfApplicant.toString()}
        disabled={!isEditable}
        title={t("create_booking.basic_details.photo")}
        type={t("create_booking.basic_details.face")}
        placeholder={t("create_booking.basic_details.photo")}
      />
    </div>
  );

  const renderIsAddressSameQuery = () => (
    <RowRadioButtonsGroup
      title={t(
        "create_booking.basic_details.is_address_same_as_primary_applicant"
      )}
      labels={[
        { id: "YES", label: "Yes" },
        { id: "NO", label: "No" },
      ]}
      value={basicDetailsRef?.current.isAddressSameAsPrimaryApplicant}
      handleChange={onChangeAddressSameAsPrimary}
      markRequiredField={false}
      sx={{ formLabel: { mt: "2rem" } }}
      disabled={!isEditable}
    />
  );

  const renderSalutationList = () => (
    <Dropdown
      outerLabel={t("create_booking.basic_details.salutation")}
      markRequiredField={false}
      dropdownItems={getSalutationAsLabelAndValue}
      inputLabel={t("create_booking.basic_details.salutation_placeholder")}
      handleChange={onChangeSalutation}
      value={basicDetails?.salutation}
      isDisabled={!isEditable}
      styles={{ textInputContainer: { width: "26%" } }}
    />
  );

  const renderIsFathersName = () => (
    <FormGroup sx={{ alignSelf: "flex-start" }}>
      <Label
        disableTypography
        control={
          <StyledCheckBox
            icon={<CircleOutlined />}
            checkedIcon={<CheckCircleIcon />}
            value={basicDetailsRef.current?.isLoanSanctioned}
            sx={{ p: 0, pr: 1 }}
            size={"small"}
          />
        }
        label={t("create_booking.basic_details.father_name")}
        labelPlacement="end"
        checked={basicDetailsRef?.current.isFathersName}
        onChange={onChangeIsFathersName}
        value={basicDetailsRef.current?.isFathersName}
        sx={{ color: Colors.greyColor }}
        disabled={!isEditable}
      />
    </FormGroup>
  );

  return (
    <>
      {isSalutationRequired && renderSalutationList()}
      {renderName()}
      {isFatherNameRequired && renderFatherOrHusbandNameInput()}
      {isFatherNameRequired && renderIsFathersName()}
      {isGenderRequired && renderGender()}
      {renderMobileNumberAndEmail()}
      {numberOfApplicant > 1 && renderIsAddressSameQuery()}
      {isAddressRequired && !isAddressSameAsPrimaryApplicant() && (
        <>
          {renderCorrespondenceAddress()}
          {renderPinAndTelPhone()}
          {renderYesOrNoRadioButton()}
          {!isAddressSame() ? renderPermanentAddress() : null}
          {!isAddressSame() ? renderPermanentPinAndTelPhone() : null}
        </>
      )}
      {isPhotoRequired && renderCapturedOrUploadedPhoto()}
    </>
  );
};
export default ApplicantBasicDetailsForm;

export enum Status {
  ALL = "All",
  DRAFT = "Draft",
  SUBMITTED = "Submitted",
  CANCELLED = "Cancelled",
  OPEN = "Open",
  REPLIED = "Replied",
  LOST = "Lost",
  AVAILABLE = "Available",
  CONVERTED = "Converted",
  QUOTATION = "Quotation",
  CLOSED = "Closed",
  UNDER_REVIEW = "Under Review",
  APPROVED_FOR_BOOKING = "Approved for Booking",
  LEVEL_ZERO_REVIEW = "Level 0 Review",
  SALES_MANAGER_REVIEW = "Sales Manager Review",
  LEVEL_ONE_PENDING = "Level 1 Pending Approval",
  LEVEL_TWO_PENDING = "Level 2 Pending Approval",
  PROPOSAL_CANCELLED = "Proposal Cancelled",
  PROPOSAL_LOST = "Proposal Lost",
  INITIATED = "Initiated",
  SALES_APPROVAL = "Sales Approval",
  ACTIVE = "Active",
  CRM_CONFIRMATION = "CRM Confirmation",
  UNIT_SOLD = "Unit Sold",
  BOOKING_CANCELLED = "Booking Cancelled",
  REOPEN = "Re-Open",
  BOOKING_INITIATED = "Booking Initiated",
  ALLOTMENT_COMPLETE = "Allotment Complete",
  AGREEMENT_COMPLETE = "Agreement Complete",
  BOOKING_NULLIFIED = "Booking Nullified",
  CONFIRMED = "Confirmed",
}

export const OpportunityStatuses = [
  Status.OPEN,
  Status.ACTIVE,
  Status.CONVERTED,
  Status.LOST,
  Status.REOPEN,
];

export const BookingStatuses = [
  Status.DRAFT,
  Status.INITIATED,
  Status.SALES_APPROVAL,
  Status.UNIT_SOLD,
  Status.CRM_CONFIRMATION,
  Status.BOOKING_CANCELLED,
  Status.AGREEMENT_COMPLETE,
  Status.ALLOTMENT_COMPLETE,
  Status.SALES_MANAGER_REVIEW,
  Status.BOOKING_NULLIFIED,
  Status.CONFIRMED,
];

export const ProposalStatuses = [
  Status.LEVEL_ZERO_REVIEW,
  Status.LEVEL_ONE_PENDING,
  Status.LEVEL_TWO_PENDING,
  Status.BOOKING_INITIATED,
  Status.UNIT_SOLD,
  Status.PROPOSAL_LOST,
  Status.SALES_MANAGER_REVIEW,
];

export const BookingProposalUnderReviewStatuses = [
  Status.LEVEL_ZERO_REVIEW,
  Status.LEVEL_ONE_PENDING,
  Status.LEVEL_TWO_PENDING,
  Status.SALES_MANAGER_REVIEW,
];

export const BookingProposalLostStatuses = [Status.PROPOSAL_LOST];

export const OpportunityEditableStatuses = [
  Status.OPEN,
  Status.ACTIVE,
  Status.REOPEN,
];

export const BookingEditableStatuses = [Status.DRAFT, Status.INITIATED, Status.SALES_MANAGER_REVIEW];

export enum DocStatus {
  DRAFT = 0,
  SUBMITTED = 1,
  CANCELLED = 2,
}

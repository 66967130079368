import { useCallback, useEffect, useRef, useState } from "react";
import Switch from "@mui/material/Switch";
import { FormGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextInput from "../../components/text-input/text-input";
import { VALIDATION_REGEXPS } from "../../constants";
import { Label } from "./styled-component";
import {
  AADHAR_NUMBER_MAX_LENGTH,
  EMPTY_APPLICANT_DETAILS,
  IdentificationDetailsFormTypes,
  PAN_NUMBER_MAX_LENGTH,
  PRIMARY_APPLICANT,
} from "../../utils/booking-utils";
import "./applicant-form.css";
import { getFileType } from "../../utils/functions";
import ApplicantPhoto from "./applicant-photo";

const PASSPORT_MAX_LENGTH = 9;

const IdentificationDetailsForm = ({
  identificationData = {},
  numberOfApplicant = 0,
  onUpdate,
  isEditable = true,
  saveFile = () => {},
}: IdentificationDetailsFormTypes) => {
  const { t } = useTranslation();

  const [identificationDetails, setIdentificationDetails] =
    useState(identificationData);
  const identificationDetailsRef = useRef(identificationDetails);

  useEffect(() => {
    if (!Object.is(identificationDetails, EMPTY_APPLICANT_DETAILS)) {
      identificationDetailsRef.current = identificationDetails;
      onUpdate(identificationDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificationDetails]);

  useEffect(() => {
    setIdentificationDetails(identificationData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (numberOfApplicant > 1) {
      if (
        identificationDetails?.relationShipWithPrimaryUser === PRIMARY_APPLICANT
      ) {
        setIdentificationDetails({
          ...identificationDetailsRef?.current,
          relationShipWithPrimaryUser: "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfApplicant, identificationDetails]);

  useEffect(() => {
    if (
      identificationDetails?.panNumber?.length &&
      identificationDetails?.panNumber?.length !== PAN_NUMBER_MAX_LENGTH
    ) {
      setIdentificationDetails({
        ...identificationDetailsRef?.current,
        panErrorMessage: t(
          "create_booking.identification_details.enter_valid_pan"
        ),
      });
    } else {
      setIdentificationDetails({
        ...identificationDetailsRef?.current,
        panErrorMessage: "",
      });
    }
  }, [identificationDetails?.panNumber, t]);

  useEffect(() => {
    if (
      identificationDetails?.aadhaarNumber?.length &&
      identificationDetails?.aadhaarNumber?.length !== AADHAR_NUMBER_MAX_LENGTH
    ) {
      setIdentificationDetails({
        ...identificationDetailsRef?.current,
        aadhaarErrorMessage: t(
          "create_booking.identification_details.enter_valid_aadhar"
        ),
      });
    } else {
      setIdentificationDetails({
        ...identificationDetailsRef?.current,
        aadhaarErrorMessage: "",
      });
    }
  }, [identificationDetails?.aadhaarNumber, t]);

  useEffect(() => {
    if (
      identificationDetails?.passportNumber?.length &&
      identificationDetails?.passportNumber?.length !== PASSPORT_MAX_LENGTH
    ) {
      setIdentificationDetails({
        ...identificationDetailsRef?.current,
        passportErrorMessage: t(
          "create_booking.identification_details.enter_valid_passport"
        ),
      });
    } else {
      setIdentificationDetails({
        ...identificationDetailsRef?.current,
        passportErrorMessage: "",
      });
    }
  }, [identificationDetails?.passportNumber, t]);

  const onChangeAadhaarNumber = (e: { target: { value: string } }) => {
    setIdentificationDetails({
      ...identificationDetailsRef?.current,
      aadhaarNumber: e.target.value.replace(
        VALIDATION_REGEXPS.matchesAnyNumbers,
        ""
      ),
    });
  };

  const onChangePanNumber = (e: { target: { value: string } }) => {
    setIdentificationDetails({
      ...identificationDetailsRef?.current,
      panNumber: e.target.value
        .replace(VALIDATION_REGEXPS.matchesAnyCharactersAndNumbers, "")
        .trim(),
    });
  };

  const onChangePassportNumber = (e: { target: { value: string } }) => {
    setIdentificationDetails({
      ...identificationDetailsRef?.current,
      passportNumber: e.target.value
        .replace(VALIDATION_REGEXPS.matchesAnyCharactersAndNumbers, "")
        .trim(),
    });
  };

  const onChangeRelationShip = useCallback(
    (e: { target: { value: string } }) => {
      setIdentificationDetails({
        ...identificationDetailsRef?.current,
        relationShipWithPrimaryUser: e.target.value,
      });
    },
    []
  );

  const uploadAadhar = (
    data: string,
    file: { uri: string; type: string; size: number; name: string }
  ) => {
    saveFile(
      t("create_booking.identification_details.aadhaar"),
      data,
      t("create_booking.aadhaar", {
        applicant: numberOfApplicant,
        type: Boolean(getFileType(file?.name))
          ? getFileType(file?.name)
          : file?.type,
      })
    );
    setIdentificationDetails({
      ...identificationDetailsRef?.current,
      aadhaarPhoto: {
        ...identificationDetailsRef?.current.aadhaarPhoto,
        name: t("create_booking.aadhaar", {
          applicant: numberOfApplicant,
          type: Boolean(getFileType(file?.name))
            ? getFileType(file?.name)
            : file?.type,
        }),
        type: file?.type,
        data: data,
        isDeleted: false,
      },
    });
  };

  const uploadPan = (
    data: string,
    file: { uri: string; type: string; size: number; name: string }
  ) => {
    saveFile(
      t("create_booking.identification_details.pan"),
      data,
      t("create_booking.pan", {
        applicant: numberOfApplicant,
        type: Boolean(getFileType(file?.name))
          ? getFileType(file?.name)
          : file?.type,
      })
    );
    setIdentificationDetails({
      ...identificationDetailsRef?.current,
      panPhoto: {
        ...identificationDetailsRef?.current?.panPhoto,
        name: t("create_booking.pan", {
          applicant: numberOfApplicant,
          type: Boolean(getFileType(file?.name))
            ? getFileType(file?.name)
            : file?.type,
        }),
        type: file?.type,
        data: data,
        isDeleted: false,
      },
    });
  };

  const uploadPassport = (
    data: string,
    file: { uri: string; type: string; size: number; name: string }
  ) => {
    saveFile(
      t("create_booking.identification_details.passport"),
      data,
      t("create_booking.passport", {
        applicant: numberOfApplicant,
        type: Boolean(getFileType(file?.name))
          ? getFileType(file?.name)
          : file?.type,
      })
    );
    setIdentificationDetails({
      ...identificationDetailsRef?.current,
      passportPhoto: {
        ...identificationDetailsRef?.current.passportPhoto,
        name: t("create_booking.passport", {
          applicant: numberOfApplicant,
          type: Boolean(getFileType(file?.name))
            ? getFileType(file?.name)
            : file?.type,
        }),
        type: file?.type,
        data: data,
        isDeleted: false,
      },
    });
  };

  const removeAadhar = () => {
    saveFile(t("create_booking.identification_details.aadhaar"));
    setIdentificationDetails({
      ...identificationDetailsRef?.current,
      aadhaarPhoto: {
        name: "",
        type: "",
        data: "",
        attachment: "",
        isDeleted: true,
      },
    });
  };

  const removePan = () => {
    saveFile(t("create_booking.identification_details.pan"));
    setIdentificationDetails({
      ...identificationDetailsRef?.current,
      panPhoto: {
        name: "",
        type: "",
        data: "",
        attachment: "",
        isDeleted: true,
      },
    });
  };

  const removePassport = () => {
    saveFile(t("create_booking.identification_details.passport"));
    setIdentificationDetails({
      ...identificationDetailsRef?.current,
      passportPhoto: {
        name: "",
        type: "",
        data: "",
        attachment: "",
        isDeleted: true,
      },
    });
  };

  const onChangeSetNRI = (e: object, checked: boolean) => {
    setIdentificationDetails({
      ...identificationDetailsRef?.current,
      isApplicantNRI: checked,
    });
  };

  const renderAadhaarNumber = () => (
    <TextInput
      id="aadhaar-number-input"
      value={identificationDetails?.aadhaarNumber}
      label={
        !identificationDetailsRef.current.aadhaarNumber
          ? t(
              "create_booking.identification_details.aadhaar_number_placeholder"
            )
          : ""
      }
      onChange={onChangeAadhaarNumber}
      outerLabel={t("create_booking.identification_details.aadhaar_number")}
      markRequiredField={!identificationData.isApplicantNRI}
      maxLength={12}
      helperText={identificationDetailsRef.current?.aadhaarErrorMessage}
      error={Boolean(identificationDetailsRef.current?.aadhaarErrorMessage)}
      disabled={!isEditable}
    />
  );

  const renderPanNumber = () => (
    <TextInput
      id="pan-number-input"
      value={identificationDetails?.panNumber}
      label={
        !identificationDetailsRef.current.panNumber
          ? t("create_booking.identification_details.pan_number_placeholder")
          : ""
      }
      onChange={onChangePanNumber}
      outerLabel={t("create_booking.identification_details.pan_number")}
      markRequiredField={true}
      maxLength={10}
      helperText={identificationDetailsRef.current.panErrorMessage}
      error={Boolean(identificationDetailsRef.current.panErrorMessage)}
      disabled={!isEditable}
    />
  );

  const renderPassportNumberInput = () => (
    <TextInput
      id="passport-input"
      value={identificationDetails?.passportNumber}
      label={
        !identificationDetailsRef.current.passportNumber
          ? t(
              "create_booking.identification_details.passport_number_placeholder"
            )
          : ""
      }
      onChange={onChangePassportNumber}
      outerLabel={t("create_booking.identification_details.passport_number")}
      markRequiredField={true}
      styles={{
        ...{
          outerLabel: {
            marginTop: "1.5rem",
          },
        },
      }}
      maxLength={PASSPORT_MAX_LENGTH}
      helperText={identificationDetailsRef.current.passportErrorMessage}
      error={Boolean(identificationDetailsRef.current.passportErrorMessage)}
      disabled={!isEditable}
    />
  );

  const renderAadhaarFileUpload = () => (
    <ApplicantPhoto
      uploadPhoto={uploadAadhar}
      photo={identificationDetailsRef.current.aadhaarPhoto}
      removePhoto={removeAadhar}
      numberOfApplicant={numberOfApplicant.toString()}
      disabled={!isEditable}
      title={t("create_booking.identification_details.aadhaar_photo")}
      type={t("create_booking.identification_details.aadhaar")}
      isMandatory={false}
    />
  );

  const renderPanFileUpload = () => (
    <ApplicantPhoto
      uploadPhoto={uploadPan}
      photo={identificationDetailsRef.current.panPhoto}
      removePhoto={removePan}
      numberOfApplicant={numberOfApplicant.toString()}
      disabled={!isEditable}
      title={t("create_booking.identification_details.pan_photo")}
      type={t("create_booking.identification_details.pan")}
      isMandatory={false}
    />
  );

  const renderPassportFileUpload = () => (
    <ApplicantPhoto
      uploadPhoto={uploadPassport}
      photo={identificationDetailsRef.current.passportPhoto}
      removePhoto={removePassport}
      numberOfApplicant={numberOfApplicant.toString()}
      disabled={!isEditable}
      title={t("create_booking.identification_details.passport_photo")}
      type={t("create_booking.identification_details.passport")}
      isMandatory={false}
    />
  );

  const renderNRIQuery = () => (
    <FormGroup sx={{ alignSelf: "flex-start" }}>
      <Label
        disableTypography
        control={<Switch />}
        label={t("create_booking.identification_details.nri_query")}
        labelPlacement="start"
        checked={identificationDetails.isApplicantNRI}
        onChange={(e, checked) => onChangeSetNRI(e, checked)}
        value={identificationDetails.isApplicantNRI}
        disabled={!isEditable}
      />
    </FormGroup>
  );

  const renderRelationShipWithPrimaryUser = () => (
    <TextInput
      id="relationship-with-primary-user"
      value={identificationDetailsRef.current.relationShipWithPrimaryUser}
      label={
        !identificationDetailsRef.current.relationShipWithPrimaryUser
          ? t(
              "create_booking.identification_details.relationship_with_primary_applicant_placeholder"
            )
          : ""
      }
      onChange={onChangeRelationShip}
      outerLabel={t(
        "create_booking.identification_details.relationship_with_primary_applicant"
      )}
      markRequiredField={true}
      disabled={!isEditable}
    />
  );

  return (
    <div className="single-detail-container">
      {numberOfApplicant > 1 && renderRelationShipWithPrimaryUser()}
      {renderAadhaarNumber()}
      {renderAadhaarFileUpload()}
      {renderPanNumber()}
      {renderPanFileUpload()}
      {renderNRIQuery()}
      {identificationData.isApplicantNRI ? (
        <>
          {renderPassportNumberInput()}
          {renderPassportFileUpload()}
        </>
      ) : null}
    </div>
  );
};
export default IdentificationDetailsForm;

import { useScreenSizeApplier } from "../../hooks";
import { AppBar, Box, Toolbar } from "@mui/material";
import CustomizedBottomBar from "../bottom-bar";

interface LayoutPropsTypes {
  children: React.ReactNode;
  rightButtonTitle?: string;
  styles?: {
    drawerContainer?: {};
    bottomTabContainer?: {};
    buttonContainer?: {};
  };
  leftButtonTitle?: string;
  hideBottomBar?: boolean;
  isButtonDisabled?: boolean;
  onPressButton?(): void;
  onClickLeftButton?: () => void;
  rightButtonSupportText?: string;
  isRightButtonRequired?: boolean;
  isRightButtonLoading?: boolean;
  isLeftButtonRequired?: boolean;
}
export const drawerBottomBarProps = {
  top: "auto",
  bottom: 0,
};

const bottomBarStyle = {
  marginLeft: 10,
  width: "100%",
  justifyContent: "space-around",
  padding: 0,
};
const Layout = ({
  children,
  rightButtonTitle = "",
  styles = {},
  leftButtonTitle = "",
  hideBottomBar = false,
  isButtonDisabled = false,
  onPressButton = () => {},
  onClickLeftButton,
  rightButtonSupportText = "",
  isRightButtonRequired = true,
  isRightButtonLoading = false,
  isLeftButtonRequired = false,
}: LayoutPropsTypes) => {
  const { mergeStyles } = useScreenSizeApplier();

  const renderBottomBar = () => (
    <AppBar position="fixed" color={"inherit"} sx={drawerBottomBarProps}>
      <Toolbar disableGutters>
        <CustomizedBottomBar
          isButtonDisabled={isButtonDisabled}
          onPressButton={onPressButton}
          title={rightButtonTitle}
          isLink={true}
          onClickLeftButton={onClickLeftButton}
          leftButtonTitle={leftButtonTitle}
          styles={{
            bottomTabContainer: mergeStyles({
              styles: { ...bottomBarStyle, ...styles.bottomTabContainer },
            }),
            buttonContainer: styles.buttonContainer,
            buttonStyle: { mb: 1 },
          }}
          rightButtonSupportText={rightButtonSupportText}
          isRightButtonRequired={isRightButtonRequired}
          isButtonLoading={isRightButtonLoading}
          isLeftButtonRequired={isLeftButtonRequired}
        />
      </Toolbar>
    </AppBar>
  );
  return (
    <Box
      sx={{
        ...{ display: "flex" },
        ...styles.drawerContainer,
      }}
    >
      <Box sx={{ m: 0, mt: 10, width: "100%" }}>{children}</Box>
      {!hideBottomBar && renderBottomBar()}
    </Box>
  );
};

export default Layout;

import React, { useCallback, useState } from "react";
import Webcam from "react-webcam";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import MaterialButton from "../button";
import MaterialModal from "../modal";
import "react-toastify/dist/ReactToastify.css";
import "./web-cam.css";
import { Box } from "@mui/material";
import { StyledFCameraSwitch } from "../styled-componnets";

const materialModalProps = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
};

const webcamProps = {
  position: "relative",
  display: "flex",
  justifyContent: "center",
};

const JPEG = "jpeg";
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

export const WebcamComponent = ({
  isVisible,
  toggleModal,
  saveCapturedImageData,
}) => {
  const webcamRef = React.useRef(null);
  const { t } = useTranslation();

  const [isSubmitButtonVisible, setIsSubmitButtonVisible] = useState(false);

  const [imageData, setImageData] = useState("");
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

  const videoConstraints = {
    facingMode: FACING_MODE_USER,
  };

  const capture = useCallback(() => {
    try {
      const imageBase64 = webcamRef.current.getScreenshot();
      setImageData(imageBase64);
      if (imageBase64) {
        setIsSubmitButtonVisible(true);
      }
    } catch (err) {
      toast(t("error_messages.server_error"), {
        type: "error",
      });
    }
  }, [setImageData, setIsSubmitButtonVisible, t, webcamRef]);

  const confirmAndSubmit = () => {
    const file = {
      type: JPEG,
      size: null,
      name: "",
    };
    saveCapturedImageData(imageData, file);
  };

  const onClickLink = useCallback(() => {
    setImageData("");
    setIsSubmitButtonVisible(false);
  }, [setIsSubmitButtonVisible, setImageData]);

  const renderCapturedImage = () => (
    <img src={imageData} height={300} width={340} alt={"applicant"} />
  );

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  const renderWebCam = () => (
    <Box sx={webcamProps}>
      <StyledFCameraSwitch onClick={handleClick} />
      <Webcam
        audio={false}
        height={300}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={350}
        videoConstraints={{
          ...videoConstraints,
          facingMode,
        }}
        onUserMedia={(e) => {
          toast(e, {
            type: "error",
          });
        }}
      />
    </Box>
  );

  const renderSubmitButtonAndRetakeLink = () => (
    <div className="link-and-button-container">
      <button className="link-container" onClick={onClickLink}>
        {t("web_cam.retake")}
      </button>
      <MaterialButton
        id="send-otp"
        title={t("web_cam.confirm_and_submit")}
        onClick={confirmAndSubmit}
        sx={{
          width: "60%",
          m: 0,
        }}
        size={"small"}
      />
    </div>
  );

  const renderCaptureButton = () => (
    <MaterialButton
      id="send-otp"
      title={t("web_cam.capture")}
      onClick={capture}
      sx={{
        width: "50%",
        m: 0,
      }}
      size={"small"}
    />
  );

  const renderImageCaptureComponent = () => (
    <div className="webcam-container">
      <p className="capture-image-title">{t("web_cam.title")}</p>
      {imageData ? renderCapturedImage() : renderWebCam()}
      <div className="web-cam-button-container">
        {isSubmitButtonVisible
          ? renderSubmitButtonAndRetakeLink()
          : renderCaptureButton()}
      </div>
    </div>
  );

  return (
    <>
      <MaterialModal
        open={isVisible}
        onClose={toggleModal}
        sx={materialModalProps}
        children={renderImageCaptureComponent()}
      />
      <ToastContainer />
    </>
  );
};

import Colors from "../../utils/theme/color";
import { Box, Container, styled } from "@mui/material";

export const PreviewContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  padding: 24,
  backgroundColor: Colors.backgroundColor,
  justifyContent: "space-between",
  width: "100%",
  height: "100vh",
}));
export const DocumentsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRightWidth: 1,
  borderRightStyle: "solid",
  borderRightColor: Colors.lightBlueColor,
  padding: 2,
  paddingRight: 5,
}));

export const Document = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: 14,
}));

import { FC, useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import {
  CustomizedLink,
  CustomizedText,
  StatusBox,
} from "../../../components/styled-componnets";
import Colors from "../../../utils/theme/color";
import { DD_MM_YYY } from "../../../utils/date";
import { useGetProjectsQuery } from "../../../../features/api/project-api-slice";
import { useScreenSizeApplier } from "../../../hooks";
import { CustomizedCard, CustomizedDetailsBox } from "../styled-components";
import { getTextColorBasedOnProjectStatus } from "../../../utils/functions";
import { Status } from "../../../constants/statuses";

const customizedCardProps = { p: "2rem", pt: "1rem", pb: "1rem" };
const ERROR = "error";

interface CustomerDetailsTypes {
  details?: {
    name?: string;
    first_name?: string;
    last_name?: string;
    contact_mobile?: string;
    contact_email?: string;
    allocated_sales_team?: string;
    project?: string;
    modified?: string;
    status?: Status;
    source?: string;
    sales_stage?: string;
    pre_sales_owner?: string;
    lost_reasons?: { lost_reason: string }[];
  };
  viewAllLostReasons?: () => void;
}

const CustomerDetails: FC<CustomerDetailsTypes> = ({
  details = {},
  viewAllLostReasons = () => {},
}) => {
  const { t } = useTranslation();
  const { mergeStyles } = useScreenSizeApplier();

  const [projectPublicUrl, setProjectPublicUrl] = useState<string>("");

  const {
    data: getProjectListResponse,
    isSuccess: isGetProjectListSuccess,
    isError: isGetProjectListFailed,
  } = useGetProjectsQuery({});

  const returnProjectUrlById = useCallback(
    (projectList: { name: string; public_url: string }[]) =>
      projectList.filter((item) => item?.name === details?.project),
    [details.project]
  );

  useEffect(() => {
    if (isGetProjectListSuccess) {
      if (getProjectListResponse?.message?.length) {
        setProjectPublicUrl(
          returnProjectUrlById(getProjectListResponse?.message)[0]?.public_url
        );
      } else if (!Array.isArray(getProjectListResponse?.message)) {
        toast(getProjectListResponse?.message, {
          type: ERROR,
        });
        setProjectPublicUrl("");
      }
    } else if (isGetProjectListFailed) {
      toast(t("error_messages.server_error"), {
        type: ERROR,
      });
      setProjectPublicUrl("");
    }
  }, [
    getProjectListResponse,
    isGetProjectListSuccess,
    isGetProjectListFailed,
    t,
    returnProjectUrlById,
  ]);

  const renderKeyAndValue = ({
    key = "",
    value = "",
    isLink = false,
    link = "",
    Icon = null,
  }: {
    key: string;
    value?: string;
    isLink?: boolean;
    link?: string;
    Icon?: any;
  }) => (
    <Box sx={{ display: "flex" }}>
      <CustomizedText
        fontSize={18}
        fontColor={Colors.greyColor}
        fontFamily={"Lato-Regular"}
        sx={{ mr: 0.5 }}
      >
        {`${key}:`}
      </CustomizedText>
      {isLink ? (
        value ? (
          <CustomizedLink
            {...(link && {
              target: "_blank",
              href: link,
            })}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {value}
            <Icon sx={{ fontSize: 16, ml: 0.3 }} />
          </CustomizedLink>
        ) : null
      ) : value ? (
        <CustomizedText fontSize={18} fontFamily={"Lato-Regular"}>
          {`${value}`}
        </CustomizedText>
      ) : null}
    </Box>
  );

  const renderBasicDetails = () => (
    <>
      {details?.first_name && (
        <CustomizedText fontSize={32} gutterBottom>
          {`${details?.first_name} ${
            details?.last_name ? details?.last_name : ""
          }`}
        </CustomizedText>
      )}
      {details?.status && (
        <StatusBox sx={{ width: "20%", mb: 1 }}>
          <CustomizedText
            fontSize={16}
            fontColor={getTextColorBasedOnProjectStatus(details?.status)}
          >
            {details?.status}
          </CustomizedText>
        </StatusBox>
      )}
      {details?.lost_reasons && details?.lost_reasons?.length > 0 && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CustomizedText fontSize={18} font="Lato-Italic">
            {t("opportunity_details.reasons")}
          </CustomizedText>
          <CustomizedText fontSize={18} font="Lato-Italic">
            {`${details?.lost_reasons[0].lost_reason}... `}
          </CustomizedText>
          <CustomizedLink fontSize={18} onClick={viewAllLostReasons}>
            {t("opportunity_details.view_all")}
          </CustomizedLink>
        </Box>
      )}
      {details?.contact_mobile && (
        <Box sx={{ display: "flex" }}>
          <PhoneOutlinedIcon
            sx={{
              fontSize: 20,
              color: Colors.greyColor,
            }}
          />
          <CustomizedText fontSize={20} font="Lato-Regular" sx={{ ml: 1 }}>
            {details?.contact_mobile}
          </CustomizedText>
        </Box>
      )}
      {details?.contact_email && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <EmailOutlinedIcon
            sx={{
              fontSize: 20,
              color: Colors.greyColor,
            }}
          />
          <CustomizedText fontSize={20} font="Lato-Regular" sx={{ ml: 1 }}>
            {details?.contact_email}
          </CustomizedText>
        </Box>
      )}
    </>
  );

  const renderOpportunityOtherDetails = () => (
    <Box
      sx={mergeStyles({
        styles: { p: "2rem", width: "25%" },
        belowXlStyles: { pr: 0 },
        belowLargeStyles: { p: 0, pt: "1rem" },
        tabletStyles: { p: 0, pt: "1rem", width: "30%" },
      })}
    >
      {details?.sales_stage &&
        renderKeyAndValue({
          key: t("opportunity_details.sales_stage"),
          value: details?.sales_stage,
        })}
      {details?.pre_sales_owner &&
        renderKeyAndValue({
          key: t("opportunity_details.pre_sales_owner"),
          value: details?.pre_sales_owner,
        })}
      {renderKeyAndValue({
        key: t("opportunity_details.last_edited"),
        value: moment(details?.modified).format(DD_MM_YYY),
      })}
    </Box>
  );

  return (
    <>
      <CustomizedCard sx={customizedCardProps}>
        <CustomizedDetailsBox>{renderBasicDetails()}</CustomizedDetailsBox>
        <CustomizedDetailsBox
          sx={mergeStyles({
            styles: { p: "2rem", pr: 0 },
            belowXlStyles: { pr: 0 },
            belowLargeStyles: { p: 0, pt: "1rem" },
            tabletStyles: { p: 0, pt: "1rem" },
          })}
        >
          {renderKeyAndValue({
            key: t("opportunity_details.opportunity_id"),
            value: details?.name,
          })}
          {renderKeyAndValue({
            key: t("opportunity_details.project"),
            value: details?.project,
            isLink: true,
            link: projectPublicUrl,
            Icon: OpenInNewRoundedIcon,
          })}
          {details?.allocated_sales_team &&
            renderKeyAndValue({
              key: t("opportunity_details.agent"),
              value: details?.allocated_sales_team,
            })}
          {details?.source &&
            renderKeyAndValue({
              key: t("opportunity_details.source"),
              value: details?.source,
            })}
        </CustomizedDetailsBox>
        {renderOpportunityOtherDetails()}
      </CustomizedCard>
      <ToastContainer />
    </>
  );
};

export default CustomerDetails;

import { useTranslation } from "react-i18next";
import Colors from "../../utils/theme/color";
import { Box } from "@mui/material";
import {
  CustomizedText,
  StyledCheckBox,
  StyledFormControlLabel,
} from "../styled-componnets";
import { TermsAndConditionContainer } from "./styled-components";
import Declaration from "./declaration";

const TermsAndConditions = ({
  termsAndConditions,
  sx = {},
  handleChange = () => {},
  handleDeclaration = () => {},
  isTermsAndConditionsAgreed = false,
  isAgreedToDeclaration = false,
  isEditable = false,
}: {
  termsAndConditions: string;
  sx?: { container?: {} };
  handleChange?(e: any, checked: boolean): void;
  handleDeclaration?(e: any, checked: boolean): void;
  isTermsAndConditionsAgreed: boolean;
  isAgreedToDeclaration: boolean;
  isEditable: boolean;
}) => {
  const { t } = useTranslation();

  const renderTermsAndConditions = () => (
    <TermsAndConditionContainer
      sx={{
        ...{
          background: Colors.cyanColor,
          width: "97%",
        },
        ...sx?.container,
      }}
    >
      <Box
        style={{
          color: Colors.titleColor,
          fontFamily: "Lora-Medium",
        }}
        dangerouslySetInnerHTML={{
          __html: termsAndConditions,
        }}
      />
    </TermsAndConditionContainer>
  );
  return (
    <>
      {renderTermsAndConditions()}
      <StyledFormControlLabel
        disableTypography
        control={
          <StyledCheckBox
            size={"small"}
            disableTouchRipple
            onChange={handleChange}
            checked={isTermsAndConditionsAgreed}
            disableFocusRipple
          />
        }
        label={t("booking_preview.agree_terms_and_condition_label")}
        sx={{ marginTop: 2 }}
        checked={isTermsAndConditionsAgreed}
        disabled={!isEditable}
      />
      <CustomizedText fontSize={28} gutterBottom sx={{ marginTop: 3 }}>
        {t("booking_preview.declaration")}
      </CustomizedText>
      <Declaration />
      <StyledFormControlLabel
        disableTypography
        control={
          <StyledCheckBox
            size={"small"}
            disableTouchRipple
            onChange={handleDeclaration}
            checked={isAgreedToDeclaration}
            disableFocusRipple
          />
        }
        label={t("booking_preview.agree_declaration_label")}
        sx={{ marginTop: 2 }}
        checked={isAgreedToDeclaration}
        disabled={!isEditable}
      />
    </>
  );
};
export default TermsAndConditions;

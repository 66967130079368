import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import Colors from "../../utils/theme/color";
import { ApplicantDetails, PRIMARY_APPLICANT } from "../../utils/booking-utils";
import LabelAndValue from "../label-and-value";
import { CustomizedDivider, CustomizedText } from "../styled-componnets";
import {
  ApplicantContainer,
  ColumnStyledBox,
  PhotoContainer,
  WrappedBox,
} from "./styled-components";
import SelectedFile from "../../pages/create-booking/selected-file";
import { ExpandMore } from "../expandable-card/styled-component";
import moment from "moment";
import { DD_MM_YYY } from "../../utils/date";
interface ApplicantsDetailsTypes {
  details: ApplicantDetails[];
}
const FIRST_APPLICANT = 1;
const ApplicantsDetails = ({ details }: ApplicantsDetailsTypes) => {
  const { t } = useTranslation();
  const [selectedApplicant, setSelectedApplicant] = useState(FIRST_APPLICANT);

  const getBasicDetails = (basicDetails: ApplicantDetails) => {
    return [
      {
        id: t("booking_preview.first_name"),
        label: t("booking_preview.first_name"),
        value: basicDetails?.firstName,
      },
      {
        id: t("booking_preview.last_name"),
        label: t("booking_preview.last_name"),
        value: basicDetails?.lastName,
      },
      {
        id: t("booking_preview.gender"),
        label: t("booking_preview.gender"),
        value: basicDetails?.gender,
      },
      {
        id: t("booking_preview.date_of_birth"),
        label: t("booking_preview.date_of_birth"),
        value: moment(basicDetails?.dob).format(DD_MM_YYY),
      },
      {
        id: t("booking_preview.mobile_number"),
        label: t("booking_preview.mobile_number"),
        value: basicDetails?.mobile,
      },
      {
        id: t("booking_preview.email"),
        label: t("booking_preview.email"),
        value: basicDetails?.email,
      },
    ];
  };
  const getProfessionalDetails = (basicDetails: ApplicantDetails) => {
    return [
      {
        id: t("booking_preview.profession"),
        label: t("booking_preview.profession"),
        value: basicDetails?.profession,
      },
      {
        id: t("booking_preview.industry"),
        label: t("booking_preview.industry"),
        value: basicDetails?.industry,
      },
      {
        id: t("booking_preview.employed_at"),
        label: t("booking_preview.employed_at"),
        value: basicDetails?.employedAt,
      },
      {
        id: t("booking_preview.designation"),
        label: t("booking_preview.designation"),
        value: basicDetails?.designation,
      },
    ];
  };

  const getAddressDetails = (addressDetails: {
    address?: string;
    pin?: string;
    tel?: string;
    addressLabel: string;
    pinLabel: string;
    telLabel: string;
  }) => {
    return [
      {
        id: addressDetails?.addressLabel,
        label: addressDetails?.addressLabel,
        value: addressDetails?.address,
      },
      {
        id: addressDetails?.pinLabel,
        label: addressDetails?.pinLabel,
        value: addressDetails?.pin,
      },
      {
        id: addressDetails?.telLabel,
        label: addressDetails?.telLabel,
        value: addressDetails?.tel,
      },
    ];
  };

  const handleExpandClick = useCallback(
    (selectedId: number) => {
      if (selectedId === selectedApplicant) {
        setSelectedApplicant(0);
      } else {
        setSelectedApplicant(selectedId);
      }
    },
    [selectedApplicant]
  );

  const renderLabelAndValue = ({
    label,
    value = "",
  }: {
    label: string;
    value?: string | number | null | Date;
  }) => <LabelAndValue key={label} label={label} value={value} />;

  const renderPhoto = ({
    label,
    value = "",
    file = { data: "", type: "", name: "", attachment: "" },
  }: {
    label: string;
    value?: string | null;
    file?: { data?: string; type?: string; name?: string; attachment?: string };
  }) => {
    return (
      <PhotoContainer sx={{ mb: 3 }}>
        <CustomizedText gutterBottom fontSize={20} fontColor={Colors.greyColor}>
          {label}
        </CustomizedText>
        {value && (
          <SelectedFile
            fileName={value}
            styles={{
              container: { width: "100%" },
            }}
            isCloseIconRequired={false}
            file={file}
          />
        )}
      </PhotoContainer>
    );
  };

  const renderOtherDetails = (basicDetails: ApplicantDetails) => (
    <WrappedBox>
      {getBasicDetails(basicDetails)?.map((item) => (
        <Box sx={{ width: "30%" }}>
          {renderLabelAndValue({
            label: item?.label,
            value: item?.value,
          })}
        </Box>
      ))}
    </WrappedBox>
  );
  const renderCorrespondenceAddress = (basicDetails: ApplicantDetails) => (
    <WrappedBox>
      {getAddressDetails({
        address: basicDetails?.correspondenceAddress,
        pin: basicDetails?.pin,
        tel: basicDetails?.telephone,
        addressLabel: t("booking_preview.correspondence_address"),
        pinLabel: t("booking_preview.pin"),
        telLabel: t("booking_preview.tel"),
      })?.map((item) => (
        <Box sx={{ width: "30%" }}>
          {renderLabelAndValue({
            label: item?.label,
            value: item?.value,
          })}
        </Box>
      ))}
    </WrappedBox>
  );
  const renderPermanentAddress = (basicDetails: ApplicantDetails) => (
    <WrappedBox>
      {getAddressDetails({
        address: basicDetails?.permanentAddress,
        pin: basicDetails?.permanentPin,
        tel: basicDetails?.permanentTelephone,
        addressLabel: t("booking_preview.permanent_address"),
        pinLabel: t("booking_preview.pin"),
        telLabel: t("booking_preview.tel"),
      })?.map((item) => (
        <Box sx={{ width: "30%" }}>
          {renderLabelAndValue({
            label: item?.label,
            value: item?.value,
          })}
        </Box>
      ))}
    </WrappedBox>
  );
  const renderBasicDetails = (basicDetails: ApplicantDetails) => (
    <>
      <CustomizedText fontSize={24} gutterBottom>
        {t("booking_preview.basic_details")}
      </CustomizedText>
      {renderPhoto({
        label: t("booking_preview.photo"),
        value: basicDetails?.photo?.name,
        file: basicDetails?.photo,
      })}
      {renderOtherDetails(basicDetails)}
      {renderCorrespondenceAddress(basicDetails)}
      {renderPermanentAddress(basicDetails)}
    </>
  );

  const renderIdentificationDetails = (
    identificationDetails: ApplicantDetails
  ) => (
    <>
      <CustomizedDivider width="100%" sx={{ mt: 0, mb: 3 }} />
      <CustomizedText fontSize={24} gutterBottom>
        {t("booking_preview.identification_details")}
      </CustomizedText>
      {identificationDetails?.relationShipWithPrimaryUser !==
        PRIMARY_APPLICANT && (
        <Box sx={{ width: "43%" }}>
          {renderLabelAndValue({
            label: t("booking_preview.relationship_with_primary_applicant"),
            value: identificationDetails?.relationShipWithPrimaryUser,
          })}
        </Box>
      )}
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ width: "43%" }}>
          {renderLabelAndValue({
            label: t("booking_preview.aadhaar_number"),
            value: identificationDetails?.aadhaarNumber,
          })}
        </Box>
        <Box sx={{ width: "100%" }}>
          {renderPhoto({
            label: t("booking_preview.aadhaar_photo"),
            value: identificationDetails?.aadhaarPhoto?.name,
            file: identificationDetails?.aadhaarPhoto,
          })}
        </Box>
      </Box>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ width: "43%" }}>
          {renderLabelAndValue({
            label: t("booking_preview.pan_number"),
            value: identificationDetails?.panNumber,
          })}
        </Box>
        <Box sx={{ width: "100%" }}>
          {renderPhoto({
            label: t("booking_preview.pan_photo"),
            value: identificationDetails?.panPhoto?.name,
            file: identificationDetails?.panPhoto,
          })}
        </Box>
      </Box>
      {identificationDetails?.isApplicantNRI && (
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box sx={{ width: "43%" }}>
            {renderLabelAndValue({
              label: t("booking_preview.passport_number"),
              value: identificationDetails?.passportNumber,
            })}
          </Box>
          <Box sx={{ width: "100%" }}>
            {renderPhoto({
              label: t("booking_preview.passport_photo"),
              value: identificationDetails?.passportPhoto?.name,
              file: identificationDetails?.passportPhoto,
            })}
          </Box>
        </Box>
      )}
    </>
  );

  const renderProfessionalDetails = (professionalDetails: ApplicantDetails) => (
    <>
      <CustomizedDivider width="100%" sx={{ mt: 0, mb: 3 }} />
      <CustomizedText fontSize={24} gutterBottom>
        {t("booking_preview.professional_details")}
      </CustomizedText>
      <WrappedBox>
        {getProfessionalDetails(professionalDetails)?.map((item) => (
          <Box sx={{ width: "30%" }}>
            {renderLabelAndValue({
              label: item?.label,
              value: item?.value,
            })}
          </Box>
        ))}
      </WrappedBox>
    </>
  );

  const renderSignature = (signatureDetails: ApplicantDetails) => (
    <>
      <CustomizedDivider width="100%" sx={{ mt: 0, mb: 3 }} />
      <CustomizedText fontSize={24} gutterBottom>
        {t("booking_preview.applicant_signature", {
          applicant: 1,
          type: t("booking_preview.signature"),
        })}
      </CustomizedText>
      {renderPhoto({
        label: t("booking_preview.signature"),
        value: signatureDetails?.signature?.name,
        file: signatureDetails?.signature,
      })}
      <CustomizedDivider width="100%" sx={{ mt: 2, mb: 1 }} />
    </>
  );

  const renderApplicantHeader = (count: number) => (
    <ApplicantContainer>
      <CustomizedText fontSize={28} sx={{ marginRight: 3 }}>
        {t("booking_preview.applicant", { number: count })}
      </CustomizedText>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CustomizedText
          fontSize={22}
          font={"Lato-Regular"}
          fontColor={Colors.primaryColor}
        >
          {selectedApplicant === count
            ? t("booking_preview.see_less")
            : t("booking_preview.see_full")}
        </CustomizedText>
        <ExpandMore
          expand={selectedApplicant === count}
          onClick={() => handleExpandClick(count)}
          aria-expanded={selectedApplicant === count}
          aria-label="see full"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Box>
    </ApplicantContainer>
  );

  const renderApplicantDetails = (
    applicantDetails: ApplicantDetails,
    index: number
  ) => (
    <>
      {renderApplicantHeader(index + 1)}
      {selectedApplicant === index + 1 && (
        <CustomizedDivider width="100%" sx={{ pt: 0, mt: 0 }} />
      )}
      {selectedApplicant !== index + 1 && index !== details?.length - 1 && (
        <CustomizedDivider width="100%" sx={{ mt: 0, mb: 0.5 }} />
      )}
      {selectedApplicant === index + 1 && (
        <>
          {renderBasicDetails(applicantDetails)}
          {renderIdentificationDetails(applicantDetails)}
          {renderProfessionalDetails(applicantDetails)}
          {renderSignature(applicantDetails)}
        </>
      )}
    </>
  );

  return (
    <ColumnStyledBox>
      {details?.map((item, index) => renderApplicantDetails(item, index))}
    </ColumnStyledBox>
  );
};

export default ApplicantsDetails;

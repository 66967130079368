import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactSketchCanvas, ReactSketchCanvasRef } from "react-sketch-canvas";
import { ToastContainer, toast } from "react-toastify";
import Colors from "../../utils/theme/color";
import { SecondaryButton } from "./customized-component";
import "./signature-pad-with-file-upload.css";

type Base64 = string;

interface SignaturePadComponentTypes {
  setSignatureData(data: Base64): void;
}
const PNG = "png";
const ERROR = "error";
const OUTLINED = "outlined";

const formLabelStyles = {
  borderWidth: 1,
  borderStyle: "dashed",
  fontFamily: "Lora-Bold",
  borderTopWidth: 0.5,
  borderLeftWidth: 0.5,
  borderRightWidth: 0.5,
  borderLeftColor: Colors.lightCadetGrey,
  borderRightColor: Colors.lightCadetGrey,
  borderTopColor: Colors.lightCadetGrey,
  borderBottomColor: Colors.greyColor,
  height: "40vh",
};

const SignaturePadComponent = ({
  setSignatureData,
}: SignaturePadComponentTypes) => {
  const [showButton, setShowButton] = useState(false);
  const canvasRef = useRef<ReactSketchCanvasRef>(null);
  const { t } = useTranslation();

  const onClickConfirmAndSave = () => {
    canvasRef?.current
      ?.exportImage(PNG)
      .then((data) => setSignatureData(data))
      .catch((err) => toast(t("error_messages.server_error"), { type: ERROR }));
  };

  const onChange = (updatedPaths: object[]) => {
    if (updatedPaths?.length) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const onClickClear = () => {
    canvasRef?.current?.clearCanvas();
  };

  return (
    <>
      <div className="signature-pad-container">
        {showButton && (
          <button className="clear-link" onClick={onClickClear}>
            {t("signature_pad_component.clear")}
          </button>
        )}
        <ReactSketchCanvas
          ref={canvasRef}
          style={{
            ...formLabelStyles,
            ...(!showButton && { margin: 0, marginTop: 19 }),
          }}
          width="100%"
          strokeWidth={4}
          strokeColor={Colors.greyColor}
          onChange={onChange}
          canvasColor={Colors.cultured}
        />
        {showButton ? (
          <SecondaryButton variant={OUTLINED} onClick={onClickConfirmAndSave}>
            {t("signature_pad_component.confirm_and_save")}
          </SecondaryButton>
        ) : (
          <p className="signature-pad-description">
            {t("signature_pad_component.description")}
          </p>
        )}
      </div>
      <ToastContainer />
    </>
  );
};
export default SignaturePadComponent;

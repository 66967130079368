import { useCallback } from "react";
import Select, { SingleValue, ActionMeta, InputActionMeta } from "react-select";
import { useScreenSizeApplier } from "../../hooks";
import Colors from "../../utils/theme/color";
import "./dropdown.css";

interface DropdownPropsTypes {
  value?: string;
  outerLabel?: string;
  styles?: {
    outerLabel?: {};
    textInputContainer?: {};
    dropdownIndicator?: {};
    inputStyle?: {};
    borderColor?: string;
  };
  markRequiredField: boolean;
  dropdownItems: { value: string; label: string }[];
  inputLabel?: string;
  handleChange?:
    | ((
        newValue: SingleValue<{
          value: string;
          label: string;
        }>,
        actionMeta: ActionMeta<{ value: string; label: string }>
      ) => void)
    | undefined;
  setIsDropdownOpen?(value: boolean): void;
  isDisabled?: boolean;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
}
const inputStyle = {
  fontFamily: "Lora-Bold",
  fontSize: 18,
  color: Colors.titleColor,
};

const hoverStyle = {
  cursor: "pointer",
  "&:hover": {
    backgroundColor: Colors.lightGreyColor,
  },
};

const Dropdown = ({
  value = "",
  outerLabel,
  styles = { borderColor: "rgba(153, 161, 176, 0.5)" },
  markRequiredField = false,
  dropdownItems,
  inputLabel = "",
  handleChange = () => {},
  setIsDropdownOpen = () => {},
  isDisabled = false,
  onInputChange = () => {},
}: DropdownPropsTypes) => {
  const { mergeStyles } = useScreenSizeApplier();

  const onMenuOpen = useCallback(
    () => setIsDropdownOpen(true),
    [setIsDropdownOpen]
  );

  const onMenuClose = useCallback(
    () => setIsDropdownOpen(false),
    [setIsDropdownOpen]
  );

  const dropdownStyles = {
    placeholder: (defaultStyles: object) => {
      return {
        ...defaultStyles,
        color: Colors.greyColor,
        fontFamily: "Lato-Regular",
      };
    },
    menuList: (defaultStyles: object) => {
      return mergeStyles({
        styles: { ...defaultStyles, ...inputStyle },
        belowLargeStyles: { fontSize: 14 },
        belowXlStyles: { fontSize: 16 },
      });
    },
    singleValue: (defaultStyles: object) => {
      return mergeStyles({
        styles: {
          ...defaultStyles,
          ...inputStyle,
          ...styles?.inputStyle,
        },
        belowLargeStyles: { fontSize: 14 },
        belowXlStyles: { fontSize: 16 },
      });
    },
    Container: (defaultStyles: object) => {
      return {
        ...defaultStyles,
        backgroundColor: "rgba(176, 153, 154, 0.05)",
      };
    },
    control: (defaultStyles: object) => ({
      ...defaultStyles,
      backgroundColor: "rgba(153, 161, 176, 0.05)",
      border: `1px solid ${styles.borderColor}`,
      borderRadius: 5,
      width: "100%",
      display: "flex",
      boxShadow: "none",
      cursor: "pointer",
      "&:hover": {
        border: `1px solid ${Colors.black}`,
      },
    }),
    dropdownIndicator: (defaultStyles: object) => ({
      ...defaultStyles,
      color: Colors.titleColor,
      ...styles?.dropdownIndicator,
    }),
    option: (defaultStyles: object, state: { isSelected: boolean }) => ({
      ...mergeStyles({
        styles: { ...defaultStyles, ...inputStyle, ...hoverStyle },
        belowLargeStyles: { fontSize: 14 },
        belowXlStyles: { fontSize: 16 },
      }),
      backgroundColor: Colors.white,
      color: state.isSelected ? Colors.primaryColor : Colors.titleColor,
      padding: 10,
      borderBottomWidth: 1,
      borderBottom: `1.2px solid #99A1B015`,
    }),
  };

  return (
    <div
      className="dropdown-picker-container"
      style={styles?.textInputContainer}
    >
      {outerLabel ? (
        <div
          className="dropdown-outer-label-container"
          style={styles?.outerLabel}
        >
          <p className="dropdown-outer-label">{outerLabel}</p>
          <p className="required-star"> {markRequiredField ? "*" : ""}</p>
        </div>
      ) : null}
      <Select
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        options={dropdownItems}
        onChange={handleChange}
        menuPosition={"fixed"}
        placeholder={inputLabel}
        styles={dropdownStyles}
        {...(value && {
          value: {
            value,
            label: value,
          },
        })}
        isDisabled={isDisabled}
        onInputChange={onInputChange}
        isClearable={true}
      />
    </div>
  );
};

export default Dropdown;

import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Colors from "../../utils/theme/color";
import {
  BootstrapDialog,
  PrimaryButton,
  SecondaryButton,
} from "./customized-style";
import "./customized-dialog.css";

const OUTLINED = "outlined";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
interface subTitleStyleType {
  fontFamily: string;
  fontSize: number;
  color: string;
}

interface CustomizedDialogProps {
  open: boolean;
  handleOpenAndClose: () => void;
  onClickSecondaryButton: () => void;
  onClickPrimaryButton: () => void;
  subTitle: string;
  description: string;
  primaryButtonTitle: string;
  secondaryButtonTitle: string;
}

const subTitleStyle: subTitleStyleType = {
  fontFamily: "Lato-Bold",
  fontSize: 20,
  color: Colors.titleColor,
};

const descriptionStyle: subTitleStyleType = {
  fontFamily: "Lato-Regular",
  fontSize: 16,
  color: Colors.titleColor,
};

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 3 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const CustomizedDialogs = ({
  open,
  handleOpenAndClose,
  subTitle,
  description,
  primaryButtonTitle,
  secondaryButtonTitle,
  onClickSecondaryButton,
  onClickPrimaryButton,
}: CustomizedDialogProps) => {
  const renderDivider = () => <div className="divider" />;

  return (
    <BootstrapDialog
      onClose={handleOpenAndClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleOpenAndClose}
      />
      <DialogContent
        className="dialog-container"
        style={{ padding: "0rem 2.5rem 0rem 2.5rem" }}
      >
        <Typography sx={subTitleStyle}>{subTitle}</Typography>
        <Typography sx={descriptionStyle}>{description}</Typography>
      </DialogContent>
      {renderDivider()}
      <DialogActions
        className="dialog-button-container"
        style={{
          padding: "1rem 2.5rem 0rem 2.5rem",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <PrimaryButton variant={OUTLINED} onClick={onClickPrimaryButton}>
          {primaryButtonTitle}
        </PrimaryButton>
        <SecondaryButton variant={OUTLINED} onClick={onClickSecondaryButton}>
          {secondaryButtonTitle}
        </SecondaryButton>
      </DialogActions>
    </BootstrapDialog>
  );
};
export default CustomizedDialogs;

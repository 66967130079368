import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FormGroup } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CircleRounded } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import Colors from "../../utils/theme/color";
import {
  Title,
  drawerBottomBarProps,
  drawerProps,
  StyledFormControl,
  StyledFormLabel,
  StyledFormControlLabel,
  Label,
  SubMenuFormControl,
  StyledCheckBox,
} from "./styled-component";
import { CheckMark } from "../../assets/svg/check-mark-icon";
import { CustomizedDivider } from "../styled-componnets";
import "./drawer.css";
import { ApplicantDetails } from "../../utils/booking-utils";
interface subMenuType {
  id: string;
  label?: string;
  isCompleted: boolean;
  icon: React.ReactNode;
  isSelected: boolean;
  mandatoryFields: string[];
}
export interface DrawerComponentPropsTypes {
  menus: {
    id: string;
    label: string;
    subMenus: [
      { id: string },
      {
        id: string;
        label: string;
      },
      {
        id: string;
        label: string;
      }
    ];
    isDisabled?: boolean;
    hasError?: boolean;
  }[];
  selectedMenuId: string;
  children?: React.ReactNode;
  setSelectedMenuId(ids: any): void;
  drawerContent: any;
  bottomComponent?: React.ReactNode;
  styles?: any;
  subMenuTitle?: string;
  selectedSubMenu?: number;
  setSelectedSubMenu?(id: number): void;
  hideBottomBar?: boolean;
  isMandatoryFieldCompleted?(
    tab: subMenuType,
    applicant?: ApplicantDetails
  ): boolean | undefined;

  isError?(tab: subMenuType, applicant?: ApplicantDetails): boolean | undefined;
  isHighMarginRequired?: boolean;

  isVeryHighMarginRequired?: boolean;
}

export default function DrawerComponent({
  menus,
  selectedMenuId,
  children = <div />,
  setSelectedMenuId,
  drawerContent,
  bottomComponent,
  styles = {},
  subMenuTitle = "",
  selectedSubMenu,
  setSelectedSubMenu = () => {},
  hideBottomBar = false,
  isMandatoryFieldCompleted = () => false,
  isError = () => false,
  isHighMarginRequired = true,
  isVeryHighMarginRequired = false,
}: DrawerComponentPropsTypes) {
  const [selectedSubMenuToExpand, setSelectedSubMenuToExpand] =
    useState(selectedSubMenu);

  useEffect(() => {
    setSelectedSubMenuToExpand(selectedSubMenu);
  }, [selectedSubMenu]);

  const onClickExpandListItem = (menuId: string) => {
    setSelectedMenuId(menuId);
  };

  const isSelected = (menuId: string) => selectedMenuId === menuId;

  const expandSubMenu = (subMenuIndex: any) => {
    if (isSubMenuSelectedToExpand(subMenuIndex)) {
      setSelectedSubMenuToExpand(-1);
      setSelectedSubMenu(-1);
    } else {
      setSelectedSubMenuToExpand(subMenuIndex);
      setSelectedSubMenu(subMenuIndex);
    }
  };

  const isSubMenuSelectedToExpand = (index: number) =>
    selectedSubMenuToExpand === index;

  const renderDrawerChildren = () => (
    <AppBar sx={{ boxShadow: "none", marginTop: 8, zIndex: 600 }}>
      {children}
    </AppBar>
  );

  const renderTitle = () => <Title>Booking Details</Title>;

  const renderAccordion = () => (
    <ListItemIcon className="list-item-icon">
      <ExpandMoreIcon fontSize="inherit" htmlColor={Colors.titleColor} />
    </ListItemIcon>
  );

  const renderDrawerMenu = () => (
    <List sx={{ backgroundColor: Colors.cultured, paddingTop: 0 }}>
      {menus.map((menu, index) => (
        <ListItem disableGutters key={menu?.id} className="list-item">
          {renderMainMenu(menu)}
          {menu?.subMenus?.length && isSelected(menu?.id)
            ? renderSubMenu(menu?.subMenus)
            : null}
        </ListItem>
      ))}
    </List>
  );

  const renderMainMenu = (menu: {
    id: any;
    label: any;
    subMenus?: [
      { id: string },
      { id: string; label: string },
      { id: string; label: string }
    ];
    isDisabled?: boolean;
    hasError?: boolean;
  }) => (
    <ListItemButton
      className="list-button"
      style={{
        ...(isSelected(menu.id)
          ? {
              background: `linear-gradient(0deg,rgba(13, 77, 161, 0.1),rgba(13, 77, 161, 0.1)),${Colors.white}`,
            }
          : null),
      }}
      onClick={() => onClickExpandListItem(menu?.id)}
      disabled={menu?.isDisabled}
    >
      {menu?.hasError ? (
        <InfoIcon htmlColor="red" fontSize="inherit" sx={{ mr: 1 }} />
      ) : null}
      <ListItemText primary={<Label>{menu?.label}</Label>} />
      {menu?.subMenus?.length ? renderAccordion() : null}
    </ListItemButton>
  );

  const renderMenuRadioButton = (
    subMenu: subMenuType[],
    subMenuIndex: number
  ) => (
    <StyledFormControl key={subMenuIndex}>
      <SubMenuFormControl
        onClick={() => expandSubMenu(subMenuIndex)}
        sx={{
          ...{ mt: 1 },
          ...(!isSubMenuSelectedToExpand(subMenuIndex) && { mb: 1.5 }),
        }}
      >
        <StyledFormLabel id={`${subMenuTitle} ${subMenuIndex + 1}`}>
          {`${subMenuTitle} ${subMenuIndex + 1}`}
        </StyledFormLabel>
        {renderAccordion()}
      </SubMenuFormControl>
      {isSubMenuSelectedToExpand(subMenuIndex) ? (
        <>
          <CustomizedDivider width={"100%"} sx={{ mb: 0, mt: 1 }} />
          <FormGroup sx={{ pl: 3 }}>
            {subMenu?.map((menu: subMenuType, index: number) => {
              return (
                <StyledFormControlLabel
                  key={index}
                  disableTypography
                  id={`${subMenuTitle} ${index}`}
                  control={
                    <StyledCheckBox
                      icon={
                        isError(menu) ? (
                          <InfoIcon htmlColor="red" />
                        ) : (
                          <CircleRounded />
                        )
                      }
                      checkedIcon={
                        isError(menu) ? (
                          <InfoIcon htmlColor="red" />
                        ) : (
                          <CheckMark />
                        )
                      }
                      {...(isMandatoryFieldCompleted(menu) && {
                        sx: {
                          ml: 0.2,
                        },
                      })}
                      size={"small"}
                      disableTouchRipple
                      color="success"
                      checked={isMandatoryFieldCompleted(menu)}
                      id={`${subMenuTitle} ${index}`}
                    />
                  }
                  label={
                    <Label
                      {...(isMandatoryFieldCompleted(menu) && {
                        sx: {
                          color: Colors.primaryColor,
                        },
                      })}
                    >
                      {menu?.label}
                    </Label>
                  }
                  labelPlacement="end"
                  sx={{ color: Colors.greyColor }}
                  checked={isMandatoryFieldCompleted(menu)}
                />
              );
            })}
          </FormGroup>
        </>
      ) : null}
    </StyledFormControl>
  );

  const renderSubMenu = (subMenus: any) =>
    subMenus?.map((subMenu: subMenuType[], index: number) =>
      renderMenuRadioButton(subMenu, index)
    );

  const renderBottomBar = () => (
    <AppBar position="fixed" color={"inherit"} sx={drawerBottomBarProps}>
      <Toolbar disableGutters>{bottomComponent}</Toolbar>
    </AppBar>
  );

  return (
    <Box
      sx={{
        ...{ display: "flex" },
        ...styles.drawerContainer,
      }}
    >
      {renderDrawerChildren()}
      <Drawer
        sx={{ ...drawerProps, ...styles?.drawer }}
        variant={"permanent"}
        open
        anchor="left"
      >
        <Toolbar
          style={{
            marginTop: isHighMarginRequired
              ? 150
              : isVeryHighMarginRequired
              ? 260
              : 25,
          }}
        />
        {renderTitle()}
        {renderDrawerMenu()}
        <Toolbar />
      </Drawer>
      <div className="drawer-content-container">
        <Toolbar
          style={{
            marginTop: isHighMarginRequired
              ? 150
              : isVeryHighMarginRequired
              ? 260
              : 25,
          }}
        />
        {drawerContent}
        <Toolbar style={{ marginBottom: 30 }} />
        {!hideBottomBar && renderBottomBar()}
      </div>
    </Box>
  );
}

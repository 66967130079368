import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import {
  CustomizedDivider,
  CustomizedText,
} from "../../../components/styled-componnets";
import {
  ActionContainer,
  BottomButtonContainer,
  CloseButtonContainer,
  MessageContainer,
  TitleContainer,
} from "../styled-components";
import Colors from "../../../utils/theme/color";
import MaterialButton from "../../../components/button";
import { useSendCostBreakUpMutation } from "../../../../features/api/proposals-api-slice";
import { useGetUserDetailsHooks, useRedirectToLogin } from "../../../hooks";
import { SendCostSheetMethod } from "../../../constants/send-cost-sheet-method";

interface SendCostBreakUpProps {
  name: string;
  salesperson: string;
  selectedMethod: string;
  handleClose: () => void;
}
const ERROR = "error";
const messageTitleStyle = { p: 3, pt: 0, pb: 1 };

const SendCostBreakUp: FC<SendCostBreakUpProps> = ({
  name,
  salesperson,
  selectedMethod,
  handleClose,
}) => {
  const { t } = useTranslation();
  const { getUserToken } = useGetUserDetailsHooks();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();
  const params = useParams();
  const { proposalId } = params || {};

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [
    sendCostBreakUp,
    {
      isSuccess: isSendCostBreakUpSuccess,
      isError: isSendCostBreakUpFailed,
      data: sendCostBreakUpResponse,
      error: sendCostBreakUpError,
    },
  ] = useSendCostBreakUpMutation();

  const isSendViaWhatsapp = useMemo(
    () => selectedMethod === SendCostSheetMethod.WHATSAPP,
    [selectedMethod]
  );
  const isSendViaEmail = useMemo(
    () => selectedMethod === SendCostSheetMethod.EMAIL,
    [selectedMethod]
  );

  const isSendViaWhatsappAndEmail = useMemo(
    () => selectedMethod === SendCostSheetMethod.EMAIL_AND_WHATSAPP,
    [selectedMethod]
  );

  useEffect(() => {
    if (isButtonLoading) {
      if (isSendCostBreakUpSuccess) {
        if (sendCostBreakUpResponse?.data === proposalId) {
          toast(sendCostBreakUpResponse.message, {
            type: "success",
          });
          handleClose();
        } else if (sendCostBreakUpResponse?.message) {
          toast(sendCostBreakUpResponse?.message, {
            type: ERROR,
          });
        }
        setIsButtonLoading(false);
      } else if (isSendCostBreakUpFailed) {
        redirectToLoginBasedOnResponse(
          sendCostBreakUpError,
          sendCostBreakUpResponse
        );
        setIsButtonLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sendCostBreakUpResponse,
    isSendCostBreakUpSuccess,
    isSendCostBreakUpFailed,
    sendCostBreakUpError,
    isButtonLoading,
  ]);

  const onClickSend = () => {
    setIsButtonLoading(true);
    const payload = {
      booking_proposal_id: proposalId,
      send_whatsapp: isSendViaWhatsapp,
      send_email: isSendViaEmail,
      send_both: isSendViaWhatsappAndEmail,
    };
    sendCostBreakUp({ userToken: getUserToken(), payload });
  };

  const renderTitle = () => (
    <CustomizedText fontSize={24} fontColor={Colors.titleColor}>
      {t("proposal_view.send_cost_breakup_to", { name })}
    </CustomizedText>
  );

  const renderButton = () => (
    <BottomButtonContainer>
      <MaterialButton
        title={t("proposal_view.share", { method: selectedMethod })}
        id={"share-button"}
        onClick={onClickSend}
        disabled={isButtonLoading}
        isLoading={isButtonLoading}
      />
    </BottomButtonContainer>
  );

  const renderCloseButton = () => (
    <CloseButtonContainer
      marginRight={0.5}
      paddingBottom={0}
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" htmlColor={Colors.titleColor} />
    </CloseButtonContainer>
  );

  return (
    <ActionContainer sx={{ WIDTH: "100%" }}>
      <TitleContainer>
        {renderTitle()}
        {renderCloseButton()}
      </TitleContainer>
      <CustomizedDivider width="100%" sx={{ pt: 0, mt: 0 }} />
      <CustomizedText
        fontSize={18}
        fontColor={Colors.titleColor}
        sx={messageTitleStyle}
      >
        {t("proposal_view.message")}
      </CustomizedText>
      <MessageContainer>
        <CustomizedText fontSize={16} fontColor={Colors.titleColor}>
          {t("proposal_view.message_template", {
            customer: name,
            salesperson,
          })}
        </CustomizedText>
      </MessageContainer>
      {renderButton()}
    </ActionContainer>
  );
};

export default SendCostBreakUp;

import { FC } from "react";
import Select, { ActionMeta, MultiValue, PropsValue } from "react-select";
import { useScreenSizeApplier } from "../../hooks";
import Colors from "../../utils/theme/color";
import "../dropdown/dropdown.css";

interface DropdownPropsTypes {
  outerLabel?: string;
  styles?: { outerLabel?: {}; textInputContainer?: {} };
  markRequiredField: boolean;
  dropdownItems: { value: string; label: string }[];
  inputLabel: string;
  handleChange?: (
    newValue: MultiValue<{ value: string; label: string }>,
    actionMeta: ActionMeta<{ value: string; label: string }>
  ) => void;
  value?: PropsValue<{ value: string; label: string }>;
  isDisabled?: boolean;
}

const inputStyle = {
  fontFamily: "Lora-Bold",
  fontSize: 18,
  color: Colors.titleColor,
};

const hoverStyle = {
  cursor: "pointer",
  "&:hover": {
    backgroundColor: Colors.lightGreyColor,
  },
};

const MultiSelectionDropdown: FC<DropdownPropsTypes> = ({
  outerLabel,
  styles = {},
  dropdownItems,
  inputLabel = "",
  handleChange = () => {},
  value,
  isDisabled = false,
}) => {
  const { mergeStyles } = useScreenSizeApplier();

  const dropdownStyles = {
    placeholder: (defaultStyles: object) => ({
      ...defaultStyles,
      color: Colors.greyColor,
      fontFamily: "Lato-Regular",
    }),
    menuList: (defaultStyles: object) =>
      mergeStyles({
        styles: { ...defaultStyles, ...inputStyle },
        belowLargeStyles: { fontSize: 14 },
        belowXlStyles: { fontSize: 16 },
      }),
    singleValue: (defaultStyles: object) =>
      mergeStyles({
        styles: {
          ...defaultStyles,
          ...inputStyle,
        },
        belowLargeStyles: { fontSize: 14 },
        belowXlStyles: { fontSize: 16 },
      }),
    Container: (defaultStyles: object) => ({
      ...defaultStyles,
      backgroundColor: "rgba(176, 153, 154, 0.05)",
    }),
    control: (defaultStyles: object) => ({
      ...defaultStyles,
      backgroundColor: "rgba(153, 161, 176, 0.05)",
      border: "1px solid rgba(153, 161, 176, 0.5)",
      borderRadius: 5,
      width: "100%",
      display: "flex",
      boxShadow: "none",
      "&:hover": {
        border: `1px solid ${Colors.black}`,
      },
    }),
    dropdownIndicator: (defaultStyles: object) => ({
      ...defaultStyles,
      color: Colors.titleColor,
    }),
    option: (defaultStyles: object, state: { isSelected: boolean }) => ({
      ...mergeStyles({
        styles: { ...defaultStyles, ...inputStyle, ...hoverStyle },
        belowLargeStyles: { fontSize: 14 },
        belowXlStyles: { fontSize: 16 },
      }),
      backgroundColor: Colors.white,
      color: state.isSelected ? Colors.primaryColor : Colors.titleColor,
      padding: 10,
      borderBottomWidth: 1,
      borderBottom: `1.2px solid #99A1B015`,
    }),
  };

  return (
    <div
      className="dropdown-picker-container"
      style={styles?.textInputContainer}
    >
      {outerLabel ? (
        <div
          className="dropdown-outer-label-container"
          style={styles?.outerLabel}
        >
          <p className="dropdown-outer-label">{outerLabel}</p>
        </div>
      ) : null}
      <Select
        options={dropdownItems}
        onChange={handleChange}
        menuPosition={"fixed"}
        placeholder={inputLabel}
        styles={dropdownStyles}
        isClearable={true}
        isMulti={true}
        value={value}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default MultiSelectionDropdown;

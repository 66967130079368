import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NumericFormat } from "react-number-format";
import { useParams } from "react-router-dom";
import {
  CustomizedDivider,
  CustomizedLink,
  CustomizedText,
} from "../../../components/styled-componnets";
import {
  CalculatorContainer,
  DiscountContainer,
  PriceAndValueContainer,
  PriceContainer,
  ExpandMore,
  TotalOwnershipContainer,
  SuperBuiltUpAreaContainer,
} from "../styled-components";
import { EventProps } from "../../../utils/interface";
import Colors from "../../../utils/theme/color";
import { UNICODE, USER_ROLES, VALIDATION_REGEXPS } from "../../../constants";
import { EMPTY_LIST_PRICE_DETAILS } from "../../../utils/booking-utils";
import { ListPriceDataType } from "../../../components/list-price/list-price-interface";
import { useGetUserDetailsHooks } from "../../../hooks";
import MaterialModal from "../../../components/modal";
import DiscountOrRoundOffInput from "./discount-or-round-off-input";
import { useGetProposalDetailsQuery } from "../../../../features/api/proposals-api-slice";
import { PROPOSAL_DETAILS_PROPS } from "../../../utils/proposals-utils";


const totalDiscountContainerProps = {
  backgroundColor: Colors.lightGreen,
  display: "flex",
  justifyContent: "center",
  border: ` 1px solid ${Colors.greenColor}`,
  padding: 1,
  borderRadius: 1,
};

const strickenPriceStyleProps = {
  textDecoration: "line-through",
  marginTop: 8,
  marginRight: 3,
  color: Colors.spanishGray,
};

const materialModalProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

interface PriceCalculatorProps {
  isAddDiscountPopupVisible: boolean;
  isUpdateProposalLoading: boolean;
  toggleAddDiscountPopUp: () => void;
  maximumDiscountOffered: {
    max_discount_by_sales_executive: number;
    max_discount_by_sales_head: number;
    max_discount_by_sales_lead: number;
    max_discount_by_channel_partner: number;
  };
  updateProposalDetails: (actionTriggered: boolean, payload: {}) => void;
  toggleRoundOffModal: () => void;
  isEditable: boolean;
  blockOtherActions: (errorMessage: string) => void;
}

const DISCOUNT_MAX_LENGTH = 3;

const PriceCalculator: FC<PriceCalculatorProps> = ({
  isAddDiscountPopupVisible,
  toggleAddDiscountPopUp,
  isUpdateProposalLoading,
  maximumDiscountOffered,
  updateProposalDetails,
  toggleRoundOffModal,
  isEditable,
  blockOtherActions,
}) => {
  const { t } = useTranslation();
  const {
    isProjectSalesUser,
    isProjectSalesHead,
    isProjectHead,
    isCurrentUserCP,
    highestUserRole,
    salesHead,
    salesLead,
  } = useGetUserDetailsHooks();

  const [discountDetails, setDiscountDetails] = useState<ListPriceDataType>(
    EMPTY_LIST_PRICE_DETAILS
  );
  const discountDetailsRef = useRef(discountDetails);

  const [isBasicCostExpandable, setIsBasicCostExpandable] = useState(true);
  const [isAdditionalCostExpandable, setIsAdditionalCostExpandable] =
    useState(true);
  const [isTaxesCostExpandable, setIsTaxesCostExpandable] = useState(true);
  const [isMiscellaneousCostExpandable, setIsMiscellaneousCostExpandable] =
    useState(true);

  const [discountAdded, setDiscountAdded] = useState("");
  const [discountErrorMessage, setDiscountErrorMessage] = useState("");

  const params = useParams();

  const { getUserToken } = useGetUserDetailsHooks();

  const { proposalId } = params || {};

  const { data: getProposalDetailResponse, refetch: refetchProposalDetails } =
    useGetProposalDetailsQuery(
      {
        userToken: getUserToken(),
        proposalId,
      },
      { skip: !Boolean(proposalId) }
    );

  const getPriceDetails = useCallback(
    (details: PROPOSAL_DETAILS_PROPS) => ({
      discountBySalesExecutive: details?.discount_by_sales_executive,
      discountBySalesLead: details?.discount_by_sales_lead,
      discountBySalesHead: details?.discount_by_sales_head,
      discountByChannelPartner: details?.discount_by_channel_partner,
      totalDiscount: details?.discount_offered,
      saleableArea: details?.apartment_area,
      bookingBasicAmount: details?.booking_basic_amount,
      bookingTotalConsideration: details?.booking_total_consideration,
      basicRate: details?.basic_rate,
      premiumLocationCharges: details?.premium_location_charges,
      bookingFloorPremium: details?.booking_floor_premium,
      additionalExpenses: details?.additional_expenses,
      coveredCarParking: details?.covered_car_parking,
      clubHouse: details?.club_house,
      gstAmount: details?.booking_gst_amount,
      gstPercentage: details?.booking_gst_percentage,
      totalConsiderationIncludingGst:
        details?.total_consideration_including_gst,
      sinkingFund: details?.sinking_fund,
      maintenanceCharges: details?.maintenance_charges,
      legalCharges: details?.legal_charges,
      totalCostOfOwnership: details?.total_cost_of_ownership,
      ratePerSquareFeet: details?.rate_per_square_feet,
      bookingRatePerSquareFeet: details?.booking_rate_per_square_feet,
      basicAmount: details?.basic_amount,
      totalAmount: details?.total_amount,
      roundOff: details?.round_off,
      discount: details?.total_discount,
      totalEffectiveCostBeforeRoundOff: details?.booking_total_amount,
      effectiveCostBeforeRoundOff: details?.effective_cost_per_square_feet,
      totalConsideration: details?.total_consideration,
      bookingTotalConsiderationIncludingGst:
        details?.booking_total_consideration_including_gst,
    }),
    []
  );

  const totalDiscount = useMemo(
    () =>
      parseInt(discountDetails?.discountBySalesExecutive) +
      parseInt(discountDetails?.discountBySalesLead) +
      parseInt(discountDetails?.discountBySalesHead) +
      parseInt(discountDetails?.discountByChannelPartner),
    [
      discountDetails?.discountBySalesExecutive,
      discountDetails?.discountBySalesHead,
      discountDetails?.discountBySalesLead,
      discountDetails?.discountByChannelPartner,
    ]
  );

  const additionalDetails = useMemo(
    () =>
      discountDetails?.additionalExpenses +
      discountDetails?.coveredCarParking +
      discountDetails?.clubHouse,
    [
      discountDetails?.additionalExpenses,
      discountDetails?.clubHouse,
      discountDetails?.coveredCarParking,
    ]
  );

  const effectiveRate = useMemo(
    () => discountDetails.ratePerSquareFeet - totalDiscount,
    [discountDetails.ratePerSquareFeet, totalDiscount]
  );

  const totalEffectiveRate = useMemo(
    () => effectiveRate * discountDetails?.saleableArea,
    [discountDetails?.saleableArea, effectiveRate]
  );

  const additionalCost = useMemo(
    () => totalEffectiveRate + additionalDetails,
    [additionalDetails, totalEffectiveRate]
  );

  const gstPercentage = useMemo(
    () => discountDetails?.gstPercentage / 100,
    [discountDetails?.gstPercentage]
  );

  const gstAmount = useMemo(
    () => additionalCost * gstPercentage,
    [additionalCost, gstPercentage]
  );

  const considerationIncludingGst = useMemo(
    () => gstAmount + additionalCost,
    [additionalCost, gstAmount]
  );

  const miscellaneousCost = useMemo(
    () =>
      discountDetails?.legalCharges +
      discountDetails?.maintenanceCharges +
      discountDetails?.sinkingFund,
    [
      discountDetails?.legalCharges,
      discountDetails?.maintenanceCharges,
      discountDetails?.sinkingFund,
    ]
  );

  const totalCostOfOwnership = useMemo(
    () =>
      (
        considerationIncludingGst +
        miscellaneousCost -
        discountDetails?.roundOff
      ).toFixed(),
    [considerationIncludingGst, discountDetails?.roundOff, miscellaneousCost]
  );

  const discount = useMemo(
    () => discountDetailsRef?.current?.basicAmount - totalEffectiveRate,
    [totalEffectiveRate]
  );

  const calculateDiscounts = useCallback(() => {
    setDiscountDetails({
      ...discountDetails,
      totalDiscount: totalDiscount,
      bookingRatePerSquareFeet: effectiveRate,
      bookingBasicAmount: totalEffectiveRate,
      bookingTotalConsideration: additionalCost,
      gstAmount: gstAmount,
      bookingTotalConsiderationIncludingGst: considerationIncludingGst,
      totalCostOfOwnership: totalCostOfOwnership,
      effectiveCostBeforeRoundOff: (
        (totalEffectiveRate - discountDetails?.roundOff) /
        discountDetails?.saleableArea
      ).toFixed(),
      totalEffectiveCostBeforeRoundOff:
        considerationIncludingGst + miscellaneousCost,
      discount,
    });
  }, [
    additionalCost,
    considerationIncludingGst,
    discountDetails,
    effectiveRate,
    gstAmount,
    miscellaneousCost,
    totalCostOfOwnership,
    totalDiscount,
    totalEffectiveRate,
    discount,
  ]);

  useEffect(() => {
    if (!Object.is(discountDetails, EMPTY_LIST_PRICE_DETAILS)) {
      discountDetailsRef.current = discountDetails;
    }
  }, [discountDetails]);

  useEffect(() => {
    refetchProposalDetails();
  }, [refetchProposalDetails]);

  useEffect(() => {
    if (isAddDiscountPopupVisible) {
      setDiscountErrorMessage("");
    }
  }, [isAddDiscountPopupVisible]);

  useEffect(() => {
    blockOtherActions(discountErrorMessage);
  }, [blockOtherActions, discountErrorMessage]);

  useEffect(() => {
    if (!Boolean(discountAdded)) {
      if (getProposalDetailResponse?.data?.length)
        setDiscountDetails(getPriceDetails(getProposalDetailResponse?.data[0]));
      discountDetailsRef.current = getPriceDetails(
        getProposalDetailResponse?.data[0]
      );
    } else {
      calculateDiscounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountAdded, getPriceDetails, getProposalDetailResponse?.data]);

  const onClickViewDetails = (id: string) => {
    if (id === t("list_price.basic_apartment_cost")) {
      setIsBasicCostExpandable(!isBasicCostExpandable);
    } else if (id === t("list_price.additional_cost")) {
      setIsAdditionalCostExpandable(!isAdditionalCostExpandable);
    } else if (id === t("list_price.taxes")) {
      setIsTaxesCostExpandable(!isTaxesCostExpandable);
    } else {
      setIsMiscellaneousCostExpandable(!isMiscellaneousCostExpandable);
    }
  };

  const expandBasedOnId = (id: string) =>
    id === t("list_price.basic_apartment_cost")
      ? isBasicCostExpandable
      : id === t("list_price.additional_cost")
      ? isAdditionalCostExpandable
      : id === t("list_price.taxes")
      ? isTaxesCostExpandable
      : isMiscellaneousCostExpandable;

  const onChangeAmount = (e: EventProps) => {
    setDiscountErrorMessage("");
    setDiscountAdded(
      e.target.value.replace(VALIDATION_REGEXPS.matchesAnyNumbers, "")
    );
    setDiscountDetails({
      ...discountDetails,
      discountByChannelPartner:
      highestUserRole === USER_ROLES.ChannelPartner
        ? parseInt(
            e.target.value.replace(VALIDATION_REGEXPS.matchesAnyNumbers, "")
          )
        : discountDetails?.discountByChannelPartner,      
      discountBySalesExecutive:
        highestUserRole === USER_ROLES.ProjectSalesUser
          ? parseInt(
              e.target.value.replace(VALIDATION_REGEXPS.matchesAnyNumbers, "")
            )
          : discountDetails?.discountBySalesExecutive,
      discountBySalesLead:
        highestUserRole === salesLead
          ? parseInt(
              e.target.value.replace(VALIDATION_REGEXPS.matchesAnyNumbers, "")
            )
          : discountDetails?.discountBySalesLead,
      discountBySalesHead:
        highestUserRole === salesHead
          ? parseInt(
              e.target.value.replace(VALIDATION_REGEXPS.matchesAnyNumbers, "")
            )
          : discountDetails?.discountBySalesHead,
    });
  };

  const discountInvalidMessage = useCallback(
    (limit: number) =>
      setDiscountErrorMessage(t("list_price.discount_limit_error", { limit })),
    [t]
  );

  const updateDiscount = useCallback(() => {
    setDiscountAdded("");
    const actionTriggered = false;
    const payload = {
      name: getProposalDetailResponse?.data[0]?.name,
      opportunity: getProposalDetailResponse?.data[0]?.opportunity,
      project: getProposalDetailResponse?.data[0]?.project,
      unit: getProposalDetailResponse?.data[0]?.unit,
      customer_ask_discount:
        getProposalDetailResponse?.data[0]?.customer_ask_discount,
      discount_by_sales_executive: discountDetails?.discountBySalesExecutive,
      discount_by_sales_lead: discountDetails?.discountBySalesLead,
      discount_by_sales_head: discountDetails?.discountBySalesHead,
      discount_by_channel_partner: discountDetails?.discountByChannelPartner,
      round_off: discountDetails?.roundOff,
      same_whatsapp: getProposalDetailResponse?.data[0]?.same_whatsapp,
      whatsapp_no: getProposalDetailResponse?.data[0]?.whatsapp_no,
    };
    updateProposalDetails(actionTriggered, payload);
  }, [
    discountDetails?.discountBySalesExecutive,
    discountDetails?.discountBySalesHead,
    discountDetails?.discountBySalesLead,
    discountDetails?.discountByChannelPartner,
    discountDetails?.roundOff,
    getProposalDetailResponse?.data,
    updateProposalDetails,
  ]);


  const maximumDiscountForSalesLead = useMemo(
    () =>
      maximumDiscountOffered?.max_discount_by_sales_lead +
      maximumDiscountOffered?.max_discount_by_sales_executive -
      discountDetails?.discountBySalesExecutive,
    [
      discountDetails?.discountBySalesExecutive,
      maximumDiscountOffered?.max_discount_by_sales_executive,
      maximumDiscountOffered?.max_discount_by_sales_lead,
    ]
  );

  const maximumDiscountForSalesHead = useMemo(
    () =>
      maximumDiscountOffered?.max_discount_by_sales_head +
      maximumDiscountOffered?.max_discount_by_sales_lead +
      maximumDiscountOffered?.max_discount_by_sales_executive -
      (discountDetails?.discountBySalesExecutive +
        discountDetails?.discountBySalesLead),
    [
      discountDetails?.discountBySalesExecutive,
      discountDetails?.discountBySalesLead,
      maximumDiscountOffered?.max_discount_by_sales_executive,
      maximumDiscountOffered?.max_discount_by_sales_head,
      maximumDiscountOffered?.max_discount_by_sales_lead,
    ]
  );

  const isChannelPartnerDiscountValid = useCallback(
    (discount?: string) =>
      discount &&
      parseInt(discount) <=
        maximumDiscountOffered?.max_discount_by_channel_partner,
    [maximumDiscountOffered?.max_discount_by_channel_partner]
  );

  const isSalesUserDiscountValid = useCallback(
    (discount?: string) =>
      discount &&
      parseInt(discount) <=
        maximumDiscountOffered?.max_discount_by_sales_executive,
    [maximumDiscountOffered?.max_discount_by_sales_executive]
  );

  const isSalesLeadDiscountValid = useCallback(
    (discount?: string) =>
      discount && parseInt(discount) <= maximumDiscountForSalesLead,
    [maximumDiscountForSalesLead]
  );

  const isSalesHeadDiscountValid = useCallback(
    (discount?: string) =>
      discount && parseInt(discount) <= maximumDiscountForSalesHead,
    [maximumDiscountForSalesHead]
  );

  const saveAndUpdateDiscount = useCallback(
    (discount: string) => {
      if (isProjectSalesUser) {
        if (isSalesUserDiscountValid(discount)) {
          updateDiscount();
        } else {
          discountInvalidMessage(
            maximumDiscountOffered?.max_discount_by_sales_executive
          );
        }
      } else if (isProjectSalesHead) {
        if (isSalesLeadDiscountValid(discount)) {
          updateDiscount();
        } else {
          discountInvalidMessage(maximumDiscountForSalesLead);
        }
      } else if (isProjectHead) {
        if (isSalesHeadDiscountValid(discount)) {
          updateDiscount();
        } else {
          discountInvalidMessage(maximumDiscountForSalesHead);
        }
      } else if (isCurrentUserCP) {
        if (isChannelPartnerDiscountValid(discount)) {
          updateDiscount();
        } else {
          discountInvalidMessage(
            maximumDiscountOffered?.max_discount_by_channel_partner
          );
        }
      } else {
        setDiscountErrorMessage("");
      }
    },
    [
      discountInvalidMessage,
      isProjectHead,
      isCurrentUserCP,
      isProjectSalesHead,
      isProjectSalesUser,
      isSalesHeadDiscountValid,
      isSalesLeadDiscountValid,
      isSalesUserDiscountValid,
      isChannelPartnerDiscountValid,
      maximumDiscountForSalesHead,
      maximumDiscountForSalesLead,
      maximumDiscountOffered?.max_discount_by_channel_partner,
      maximumDiscountOffered?.max_discount_by_sales_executive,
      updateDiscount,
    ]
  );

  const onClickSaveDiscount = useCallback(() => {
    saveAndUpdateDiscount(discountAdded);
  }, [discountAdded, saveAndUpdateDiscount]);

  const validateDiscountAndClose = (discount?: string) => {
    toggleAddDiscountPopUp();
    if (Boolean(discount)) {
      if (isProjectSalesUser) {
        if (!isSalesUserDiscountValid(discount)) {
          discountInvalidMessage(
            maximumDiscountOffered?.max_discount_by_sales_executive
          );
        }
      } else if (isProjectSalesHead) {
        if (!isSalesLeadDiscountValid(discount)) {
          discountInvalidMessage(maximumDiscountForSalesLead);
        }
      } else if (isProjectHead) {
        if (!isSalesHeadDiscountValid(discount)) {
          discountInvalidMessage(maximumDiscountForSalesHead);
        }
      } else if (isCurrentUserCP) {
        if (!isChannelPartnerDiscountValid(discount)) {
          discountInvalidMessage(maximumDiscountOffered?.max_discount_by_channel_partner);
        }
      } else {
        setDiscountErrorMessage("");
      }
    }
  };

  const renderLinkToViewDetails = ({ id }: { id: string }) => (
    <Box>
      <CustomizedLink onClick={() => onClickViewDetails(id)}>
        {t("list_price.view_details")}
      </CustomizedLink>
      <ExpandMore
        expand={expandBasedOnId(id)}
        onClick={() => onClickViewDetails(id)}
        aria-expanded={expandBasedOnId(id)}
        aria-label="show more"
      >
        <ExpandMoreIcon sx={{ color: Colors.linkColor }} />
      </ExpandMore>
    </Box>
  );

  const renderAmountInCurrencyFormat = ({
    price = 0,
    fontColor = Colors.titleColor,
    isFontBold = false,
    isTextStrikeThrough = false,
    fontSize = 18,
    textAlign = "right",
  }: {
    price?: number | string;
    fontColor?: string;
    isFontBold?: boolean;
    isTextStrikeThrough?: boolean;
    fontSize?: number;
    textAlign?: any;
  }) => (
    <NumericFormat
      style={{
        ...{
          textAlign: textAlign,
          fontWeight: isFontBold ? "700" : "200",
          fontFamily: "Lato-Regular",
          fontSize: fontSize,
          color: fontColor ?? Colors.titleColor,
        },
        ...(isTextStrikeThrough && strickenPriceStyleProps),
      }}
      value={price}
      allowLeadingZeros
      thousandSeparator=","
      prefix={`${UNICODE.rupeeSymbol}`}
      displayType="text"
      thousandsGroupStyle="lakh"
    />
  );

  const renderPriceAndValue = ({
    label,
    value,
    isFontBold = false,
    actualValue,
  }: {
    label: string;
    value: any;
    isFontBold?: boolean;
    actualValue?: any;
  }) => {
    return (
      <PriceAndValueContainer>
        <CustomizedText
          fontSize={18}
          sx={{ fontWeight: isFontBold ? "700" : "200", mr: 5 }}
          font="Lato-Regular"
        >
          {label}
        </CustomizedText>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {totalDiscount > 0 &&
            actualValue &&
            renderAmountInCurrencyFormat({
              price: parseInt(actualValue).toFixed(),
              isFontBold: false,
              isTextStrikeThrough: true,
            })}
          {renderAmountInCurrencyFormat({
            price: parseInt(value).toFixed(),
            isFontBold,
          })}
        </Box>
      </PriceAndValueContainer>
    );
  };

  const renderSuperBuiltUpArea = () => (
    <SuperBuiltUpAreaContainer>
      <CustomizedText fontSize={20} font={"Lato-Regular"}>
        {t("list_price.super_built_up_area")}
      </CustomizedText>
      <CustomizedText
        fontSize={20}
        sx={{ fontWeight: "700" }}
        font={"Lato-Regular"}
      >
        {t("list_price.square_feet_area", {
          squareFeetValue: discountDetailsRef?.current?.saleableArea,
        })}
      </CustomizedText>
    </SuperBuiltUpAreaContainer>
  );

  const renderAdditionalCostDetails = () => (
    <Box sx={{ width: "100%" }}>
      {renderPriceAndValue({
        label: t("list_price.additional_expense"),
        value: discountDetailsRef?.current?.additionalExpenses,
      })}
      {renderPriceAndValue({
        label: t("list_price.covered_car_parking"),
        value: discountDetailsRef?.current?.coveredCarParking,
      })}
      {renderPriceAndValue({
        label: t("list_price.club_house"),
        value: discountDetailsRef?.current?.clubHouse,
      })}
    </Box>
  );

  const renderBasicCostDetails = () => (
    <Box sx={{ width: "100%" }}>
      {renderPriceAndValue({
        label: t("list_price.basic_rate"),
        value: discountDetailsRef?.current?.ratePerSquareFeet,
      })}
      {renderPriceAndValue({
        label: t("list_price.total_discount_per_sq_ft"),
        value: discountDetailsRef?.current?.totalDiscount,
      })}
      {renderPriceAndValue({
        label: t("list_price.effective_rate"),
        value: discountDetailsRef?.current?.bookingRatePerSquareFeet,
      })}
    </Box>
  );

  const renderTaxDetails = () => (
    <Box sx={{ width: "100%" }}>
      {renderPriceAndValue({
        label: t("list_price.gst_percentage", {
          gstPercentage: discountDetailsRef?.current?.gstPercentage,
        }),
        value: discountDetailsRef?.current?.gstAmount,
      })}
    </Box>
  );

  const renderMiscellaneousDetails = () => (
    <Box sx={{ width: "100%" }}>
      {renderPriceAndValue({
        label: t("list_price.corpus_fund", {
          gstPercentage: discountDetailsRef?.current?.gstPercentage,
        }),
        value: discountDetailsRef?.current?.sinkingFund,
      })}
      {renderPriceAndValue({
        label: t("list_price.maintenance_charge", {
          gstPercentage: discountDetailsRef?.current?.maintenanceCharges,
        }),
        value: discountDetailsRef?.current?.maintenanceCharges,
      })}
      {renderPriceAndValue({
        label: t("list_price.legal_charge", {
          gstPercentage: discountDetailsRef?.current?.legalCharges,
        }),
        value: discountDetailsRef?.current?.legalCharges,
      })}
    </Box>
  );

  const renderAdditionalCost = () => (
    <>
      <CustomizedText fontSize={20} font="Lato-Regular">
        {t("list_price.additional_cost")}
      </CustomizedText>
      {renderPriceAndValue({
        label: t("list_price.agreement_value"),
        value: discountDetailsRef?.current?.bookingTotalConsideration,
        isFontBold: true,
        actualValue: discountDetailsRef?.current?.totalConsideration,
      })}
    </>
  );

  const renderTaxes = () => (
    <>
      <CustomizedText fontSize={20} font="Lato-Regular">
        {t("list_price.taxes")}
      </CustomizedText>
      {renderPriceAndValue({
        label: t("list_price.gst_cost"),
        value:
          discountDetailsRef?.current?.bookingTotalConsiderationIncludingGst,
        isFontBold: true,
        actualValue:
          discountDetailsRef?.current?.totalConsiderationIncludingGst,
      })}
    </>
  );

  const renderMiscellaneousCost = () => (
    <>
      <CustomizedText fontSize={20} font="Lato-Regular">
        {t("list_price.miscellaneous_cost")}
      </CustomizedText>
      {renderPriceAndValue({
        label: t("list_price.total_incl_misc_cost"),
        value:
          discountDetailsRef?.current?.totalEffectiveCostBeforeRoundOff?.toFixed(),
        isFontBold: true,
        actualValue: discountDetailsRef?.current?.totalAmount,
      })}
    </>
  );

  const renderRoundOffAmount = () =>
    renderPriceAndValue({
      label: t("list_price.round_off_amount"),
      value: discountDetailsRef?.current?.roundOff,
      isFontBold: true,
    });

  const renderTotalOwnershipCost = () => (
    <TotalOwnershipContainer>
      {renderPriceAndValue({
        label: t("list_price.total_ownership_cost"),
        value: discountDetailsRef?.current?.totalCostOfOwnership,
        isFontBold: true,
      })}
      { (!isCurrentUserCP || isProjectHead || isProjectSalesHead || isProjectSalesUser) && isEditable && (
        <CustomizedLink
        
          onClick={toggleRoundOffModal}
          sx={{ ml: 1, alignSelf: "end" }}
        >
          {t("list_price.roundoff")}
        </CustomizedLink>
      )}
    </TotalOwnershipContainer>
  );

  const renderTotalDiscount = () => (
    <DiscountContainer sx={totalDiscountContainerProps}>
      <CustomizedText
        fontSize={20}
        font="Lora-Bold"
        fontColor={Colors.greenColor}
        sx={{ display: "flex" }}
      >
        {t("list_price.discount_value")}
        <Box sx={{ mr: 1 }} />
        {renderAmountInCurrencyFormat({
          price: discountDetailsRef?.current?.discount,
          fontColor: Colors.greenColor,
          fontSize: 16,
        })}
        <CustomizedText
          fontSize={20}
          font="Lora-Bold"
          fontColor={Colors.greenColor}
          sx={{ ml: 1 }}
        >
          {`@ ${t("list_price.square_feet", {
            squareFeetValue: discountDetailsRef?.current?.totalDiscount,
          })}`}
        </CustomizedText>
      </CustomizedText>
    </DiscountContainer>
  );

  const renderDivider = () => (
    <CustomizedDivider width="100%" sx={{ mb: 2, mt: 0 }} />
  );

  const renderDiscountOfferedBy = () => (
    <DiscountContainer sx={{ mt: 1 }}>
      <Box sx={{ width: "100%" }}>
        {isCurrentUserCP ? (
          renderPriceAndValue({
            label: t("list_price.discount_by_channel_partner"),
            value: discountDetailsRef?.current?.discountByChannelPartner,
            isFontBold: true,
          })
        ) : (
          <>
            {renderPriceAndValue({
              label: t("list_price.discount_by_sales_executive"),
              value: discountDetailsRef?.current?.discountBySalesExecutive,
              isFontBold: true,
            })}
            {renderPriceAndValue({
              label: t("list_price.discount_by_sales_lead"),
              value: discountDetailsRef?.current?.discountBySalesLead,
              isFontBold: true,
            })}
            {isProjectHead &&
              renderPriceAndValue({
                label: t("list_price.discount_by_sales_head"),
                value: discountDetailsRef?.current?.discountBySalesHead,
                isFontBold: true,
              })}
            {renderPriceAndValue({
              label: t("list_price.additional_discount_requested"),
              value: getProposalDetailResponse?.data[0]?.customer_ask_discount,
              isFontBold: true,
            })}
          </>
        )}
      </Box>
    </DiscountContainer>
  );

  const renderPriceDetails = () => (
    <Box sx={{ width: "100%" }}>
      <PriceContainer>
        <CustomizedText fontSize={20} font="Lato-Regular">
          {t("list_price.title")}
        </CustomizedText>
        {renderPriceAndValue({
          label: t("list_price.basic_apartment_cost"),
          value: discountDetailsRef?.current?.bookingBasicAmount,
          isFontBold: true,
          actualValue: discountDetailsRef?.current?.basicAmount,
        })}
        {renderLinkToViewDetails({ id: t("list_price.basic_apartment_cost") })}
        {isBasicCostExpandable && (
          <DiscountContainer sx={{ mt: 1 }}>
            {renderBasicCostDetails()}
          </DiscountContainer>
        )}
        {renderDivider()}
        {renderAdditionalCost()}
        {renderLinkToViewDetails({ id: t("list_price.additional_cost") })}
        <Collapse in={isAdditionalCostExpandable} timeout="auto" unmountOnExit>
          <DiscountContainer sx={{ mt: 1 }}>
            {renderAdditionalCostDetails()}
          </DiscountContainer>
        </Collapse>
        {renderDivider()}
        {renderTaxes()}
        {renderLinkToViewDetails({ id: t("list_price.taxes") })}
        <Collapse in={isTaxesCostExpandable} timeout="auto" unmountOnExit>
          <DiscountContainer sx={{ mt: 1 }}>
            {renderTaxDetails()}
          </DiscountContainer>
        </Collapse>
        {renderDivider()}
        {renderMiscellaneousCost()}
        {renderLinkToViewDetails({ id: t("list_price.miscellaneous_cost") })}
        <Collapse
          in={isMiscellaneousCostExpandable}
          timeout="auto"
          unmountOnExit
        >
          <DiscountContainer sx={{ mt: 1 }}>
            {renderMiscellaneousDetails()}
          </DiscountContainer>
        </Collapse>
        {renderDivider()}
        {Boolean(discountDetailsRef?.current?.roundOff) &&
          renderRoundOffAmount()}
        {renderTotalOwnershipCost()}
      </PriceContainer>
      {!(highestUserRole === USER_ROLES.ProjectSalesUser) &&
        renderDiscountOfferedBy()}
      {Boolean(totalDiscount) && renderTotalDiscount()}
    </Box>
  );

  const renderAddDiscountInput = () => (
    <DiscountOrRoundOffInput
      handleClose={validateDiscountAndClose}
      isButtonLoading={isUpdateProposalLoading}
      onClickSave={onClickSaveDiscount}
      discountInvalidMessage={discountErrorMessage}
      onChangeAmount={onChangeAmount}
      discountOrRoundOffAdded={discountAdded}
      onBlur={calculateDiscounts}
      outerLabel={t("list_price.LO_discount")}
      maxLength={DISCOUNT_MAX_LENGTH}
    />
  );

  return (
    <>
      <CustomizedText fontSize={24} sx={{ mt: 2 }}>
        {t("proposal_view.calculating_price")}
      </CustomizedText>
      {renderSuperBuiltUpArea()}
      <CalculatorContainer>{renderPriceDetails()}</CalculatorContainer>
      <MaterialModal
        open={isAddDiscountPopupVisible}
        sx={materialModalProps}
        children={renderAddDiscountInput()}
      />
    </>
  );
};

export default PriceCalculator;

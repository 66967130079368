import {
  useRedirectToLogin,
  useGetUserDetailsHooks,
  useNavigateHooks,
} from "../../../hooks";
import { useGetProposalListQuery } from "../../../../features/api/proposals-api-slice";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ColumnGroupingTable from "../../../components/table";
import { ProposalStatuses, Status } from "../../../constants/statuses";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../constants";
import { useCreateBookingMutation } from "../../../../features/api/booking-api-slice";
import { formatPrice } from "../../../utils/functions";
import { ListColumnType, RowColumnType } from "../../../utils/interface";

interface ProposalListTableType {
  search?: string;
  statuses?: Status[] | null;
}
interface ProposalListType {
  name: string;
  first_name: string;
  last_name: string;
  project: string;
  unit: string;
  unit_type: string;
  rate_per_square_feet: number;
  workflow_state: Status;
  discount_offered: number;
  total_consideration_including_gst: number;
  booking_total_consideration_including_gst: number;
  total_cost_of_ownership: number;
  total_amount: number;
  allocated_sales_team: string;
  mobile_no: string;
}
const ERROR = "error";
const SUCCESS = "success";
const CONVERT_TO_BOOKING = "CONVERT_TO_BOOKING";

const BookingProposalTable = ({
  search = "",
  statuses = [],
}: ProposalListTableType) => {
  const { t } = useTranslation();
  const { getUserToken } = useGetUserDetailsHooks();
  const { navigateToBookings } = useNavigateHooks();

  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();
  const navigate = useNavigate();

  const [proposalList, setProposalList] = useState<ProposalListType[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[] | Status[]>([]);

  const [pagination, setPagination] = useState<{ page: number; size: number }>({
    page: APP_CONSTANTS.defaultPage,
    size: APP_CONSTANTS.defaultPageSize,
  });
  const [totalProposals, setTotalProposals] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");
  const [isCreateBookingLoading, setCreateBookingLoading] = useState(false);
  const {
    data: getProposalListResponse,
    isError: isGetProposalListFailed,
    isSuccess: isGetProposalListSuccess,
    error: getProposalListError,
    refetch: refetchProposals,
  } = useGetProposalListQuery({
    paginationInfo: pagination,
    selectedStatus,
    searchText,
    userToken: getUserToken(),
  });

  const [
    createBooking,
    {
      data: createBookingResponse,
      isError: isCreateBookingFailed,
      isSuccess: isCreateBookingSuccess,
      error: createBookingError,
    },
  ] = useCreateBookingMutation({});

  useEffect(() => {
    if (isGetProposalListSuccess) {
      if (
        Array.isArray(getProposalListResponse?.data?.booking_proposals) &&
        getProposalListResponse?.status_code === API_CONSTANTS.successStatusCode
      ) {
        setProposalList(getProposalListResponse?.data?.booking_proposals);
        setTotalProposals(
          getProposalListResponse?.data?.total_booking_proposals ?? 0
        );
      } else if (getProposalListResponse?.message) {
        toast(getProposalListResponse?.message, {
          type: ERROR,
        });
        setProposalList([]);
      }
    } else if (isGetProposalListFailed) {
      redirectToLoginBasedOnResponse(
        getProposalListError,
        getProposalListResponse
      );
      setProposalList([]);
    }
  }, [
    getProposalListResponse,
    isGetProposalListSuccess,
    isGetProposalListFailed,
    getProposalListError,
    redirectToLoginBasedOnResponse,
  ]);

  useEffect(() => {
    if (isCreateBookingLoading) {
      if (isCreateBookingSuccess) {
        if (
          createBookingResponse?.data &&
          createBookingResponse?.status_code === API_CONSTANTS.successStatusCode
        ) {
          toast(createBookingResponse?.message, {
            type: SUCCESS,
          });
          navigateToBookings();
        } else if (createBookingResponse?.message) {
          toast(createBookingResponse?.message, {
            type: ERROR,
          });
        }
        setCreateBookingLoading(false);
      } else if (isCreateBookingFailed) {
        redirectToLoginBasedOnResponse(
          createBookingError,
          createBookingResponse
        );
        setCreateBookingLoading(false);
      }
    }
  }, [
    isCreateBookingSuccess,
    isCreateBookingFailed,
    createBookingResponse,
    createBookingError,
    redirectToLoginBasedOnResponse,
    isCreateBookingLoading,
    navigateToBookings,
  ]);

  useEffect(() => {
    if (statuses?.length) {
      setSelectedStatus(statuses);
    } else {
      setSelectedStatus([""]);
    }
    setPagination({
      page: APP_CONSTANTS.defaultPage,
      size: APP_CONSTANTS.defaultPageSize,
    });
  }, [refetchProposals, statuses]);

  useEffect(() => {
    setPagination({
      page: APP_CONSTANTS.defaultPage,
      size: APP_CONSTANTS.defaultPageSize,
    });
    setSearchText(search);
  }, [search]);

  useEffect(() => {
    setPagination({
      page: APP_CONSTANTS.defaultPage,
      size: APP_CONSTANTS.defaultPageSize,
    });
    refetchProposals();
  }, [refetchProposals, selectedStatus]);

  useEffect(() => {
    setPagination({
      page: APP_CONSTANTS.defaultPage,
      size: APP_CONSTANTS.defaultPageSize,
    });
    setSelectedStatus([]);
    refetchProposals();
  }, [refetchProposals]);

  const createRowData = (data: ProposalListType[]) => {
    let newData = data.map((item: ProposalListType) => {
      return {
        id: item.name,
        name: item?.first_name
          ? `${item?.first_name} ${item?.last_name ? item?.last_name : ""}`
          : "",
        phoneNumber: item?.mobile_no,
        project: item.project,
        unit: item?.unit,
        unitType: item?.unit_type,
        ratePerSquareFeet: formatPrice(item?.rate_per_square_feet),
        status: item?.workflow_state,
        discountOffered: formatPrice(item?.discount_offered),
        listPrice: formatPrice(item?.total_amount),
        offeredPrice: formatPrice(item?.total_cost_of_ownership),
        moreOptions: "",
        disabled: item?.workflow_state !== Status.CONVERTED,
        assignedTo: item?.allocated_sales_team,
      };
    });
    return newData;
  };

  const navigateToProposalDetails = (proposalId?: string) =>
    navigate(`proposal/${proposalId}`);

  const onUpdateValues = useCallback(
    (values: string[] | Status[], label: string) => {
      setSelectedStatus(values);
    },
    [setSelectedStatus]
  );
  const handlePageChange = (page: number, size: number) => {
    setPagination({ page, size });
  };

  const onSelectOption = async (optionId?: string, row?: RowColumnType) => {
    if ((optionId = CONVERT_TO_BOOKING)) {
      setCreateBookingLoading(true);
      let payload = {
        booking_proposal: row?.id,
      };
      await createBooking({ payload, userToken: getUserToken() }).unwrap();
    }
  };
  const bookingAndProposalListColumn: ListColumnType[] = [
    {
      id: "name",
      label: t("proposal.customer_name"),
      align: "center",
    },
    {
      id: "phoneNumber",
      label: t("proposal.mobile"),
      align: "center",
    },
    {
      id: "unit",
      label: t("proposal.unit"),
      align: "center",
    },
    {
      id: "status",
      label: t("proposal.status"),
      align: "center",
      isMultiSelectionEnabled: !Boolean(statuses?.length),
      isMultiSelectionDropdownOpen: false,
      values: ProposalStatuses,
    },
    {
      id: "ratePerSquareFeet",
      label: t("proposal.rate_per_square_feet"),
      align: "center",
    },
    // {
    //   id: "discountOffered",
    //   label: t("proposal.discount_offered"),
    //   align: "center",
    // },
    {
      id: "listPrice",
      label: t("proposal.list_price"),
      align: "center",
    },
    {
      id: "offeredPrice",
      label: t("proposal.offered_price"),
      align: "center",
    },
    {
      id: "assignedTo",
      label: t("opportunity.assignedTo"),
      align: "center",
    },
    {
      id: "moreOptions",
      label: "",
      align: "center",
      isMenuRequired: true,
      moreOptions: [
        {
          id: CONVERT_TO_BOOKING,
          label: t("proposal.convert_to_booking"),
        },
      ],
    },
  ];

  return (
    <>
      <ColumnGroupingTable
        rows={createRowData(proposalList)}
        columns={bookingAndProposalListColumn}
        onUpdateValue={onUpdateValues}
        handlePageChange={handlePageChange}
        paginationData={pagination}
        count={totalProposals}
        onClickTableRow={navigateToProposalDetails}
        onClickSingleOption={onSelectOption}
      />
      <ToastContainer />
    </>
  );
};
export default BookingProposalTable;

import TermsAndConditions from "../../components/booking-preview/terms-and-condition";

const BookingTermsAndConditions = ({
  terms,
  handleTermsAndCondition = () => {},
  handleDeclaration = () => {},
  isTermsAndConditionsAgreed = false,
  isAgreedToDeclaration = false,
  isEditable = false,
}: {
  terms: string;
  handleTermsAndCondition(e: any, checked: boolean): void;
  handleDeclaration(e: any, checked: boolean): void;
  isTermsAndConditionsAgreed: boolean;
  isAgreedToDeclaration: boolean;
  isEditable: boolean;
}) => {
  return (
    <>
      <TermsAndConditions
        termsAndConditions={terms}
        handleChange={handleTermsAndCondition}
        handleDeclaration={handleDeclaration}
        isTermsAndConditionsAgreed={isTermsAndConditionsAgreed}
        isAgreedToDeclaration={isAgreedToDeclaration}
        isEditable={isEditable}
      />
    </>
  );
};
export default BookingTermsAndConditions;

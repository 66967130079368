import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface applicantState {
  firstApplicantDetails: object;
  secondApplicantDetails: object;
  otherFormDetails: object;
}

const initialState: applicantState = {
  firstApplicantDetails: {},
  secondApplicantDetails: {},
  otherFormDetails: {},
};

const applicantSlice = createSlice({
  name: "applicant",
  initialState,
  reducers: {
    saveFirstApplicantDetails: (state, action: PayloadAction<any>) => {
      state.firstApplicantDetails = action.payload?.applicantDetails;
    },
    saveSecondApplicantDetails: (state, action: PayloadAction<any>) => {
      state.secondApplicantDetails = action.payload?.applicantDetails;
    },
    saveFormDetails: (state, action: PayloadAction<any>) => {
      state.otherFormDetails = action.payload?.formDetails;
    },
  },
});

export const {
  saveFirstApplicantDetails,
  saveSecondApplicantDetails,
  saveFormDetails,
} = applicantSlice.actions;

export default applicantSlice.reducer;
